import React from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Spin,
  Tooltip,
  Modal,
  Form,
} from "antd";
import { EditOutlined, EyeOutlined, FilterFilled } from "@ant-design/icons";
import moment from "moment";
import { ProjectsService } from "../../services/projects"
import { FloatingButton } from "../../components/floating-button";
import ProjectAddModal from "../../components/project-add-modal";

moment.locale("pt-BR");

const { Search } = Input;

class NewProject extends React.Component {
  state = {
    show: false,
    fetching: false,
    projectList: [],
    projectDetails: [],
    projectListSearch: [],
    modal: false,
    Id: undefined,
    projectTitle: undefined
  };

  formRef = React.createRef();

  fetchDetails = async () => {
    this.setState({ fetching: true, edit: true });
    this.setState({ fetching: false });
  };

  handleOk = (e) => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ fetching: true });
    await ProjectsService.getProjects().then((r) => {
      const projectData = r.data.data;

      this.setState({
        projectList: projectData,
        projectsListBackup: projectData,
        fetching: false,
      });
    });
  };

  searchTerm = async (value) => {
    if (value) {
      this.setState({ fetching: true });
      await this.fetch();
      const list = this.state.projectList.filter((x) => {
        return x.Title != null
      });
      const filtrado = list.filter((x) => {
        return x.Title.toLowerCase().includes(value.toLowerCase()) || x.Code.toLowerCase().includes(value.toLowerCase())  || x.CustomerName.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ projectList: filtrado, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  onModal = async (value, type) => {
    let projectId = '';
    let projectTitle = '';
    let projectData = {
      Id: 0,
      Code: '',
      Title: '',
      Status: '',
      CustomerId: 0,
      CustomerAdressId: 0,
      ResponsibleId: 0,
      ProjectType: '',
      ProjectModelCode: 0,
      EstimatedTimeAmount: 0,
      Value: 0,
      StartDate: '',
      EndDate: '',
      Workflow: 0,
      Workflow2: 0,
      LimitNotes: '',
      Description: ''
    };

    if (type !== 'add') {
      projectId = value.Id;
      projectTitle = value.Title;

      const project = await ProjectsService.getProjectById(value.Id).then((r) => {
        return r.data.data;
      });

      projectData = project;
    };

    this.setState({ modal: true, Id: projectId, projectDetails: projectData, fetching: true, projectTitle: projectTitle, type: type });
  }

  render() {

    const columns = [
      {
        title: "Código",
        dataIndex: "Id",
        key: "Id",
        sorter: true,
        width: 150,
        defaultSortOrder: "descend",
        render: (text, record) => <span>{record.Id}</span>
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        sorter: true,
        width: 350,
        render: (text, record) => <span>{record.Status}</span>
      },
      {
        title: "Código",
        dataIndex: "Code",
        key: "Code",
        sorter: true,
        width: 250,
        render: (text, record) => <span>{record.Code}</span>
      },
      {
        title: "Projeto",
        dataIndex: "Title",
        key: "Title",
        sorter: true,
        width: 250,
        render: (text, record) => <span>{record.Title}</span>
      },
      {
        title: "Cliente",
        dataIndex: "CustomerName",
        key: "CustomerName",
        sorter: true,
        width: 250,
        render: (text, record) => <span>{record.CustomerName}</span>
      },
      { 
        title: 'Início',
        dataIndex: "StartDate",
        key: 'StartDate',
        sorter: (a, b) => b.StartDate,
        width: 30,
        render: (text, record) => <span>{(record.StartDate ? moment(record.StartDate).format('DD/MM/YYYY') : '')}</span>,
        align: 'center',
      },
      {
        title: 'Término',
        dataIndex: "EndDate",
        key: 'EndDate',
        sorter: (a, b) => b.EndDate,
        width: 30,
        render: (text, record) => <span>{(record.EndDate ? moment(record.EndDate).format('DD/MM/YYYY') : '')}</span>,
        align: 'center',
      },
      {
        title: "Ação",
        key: "Action",
        id: "details-button",
        width: 110,
        align: 'center',
        render: (text, record) => (
          <span>
            <Tooltip placement="top" title="Vizualizar">
              <a style={{ padding: "5px" }}>
                <EyeOutlined onClick={() => this.onModal(record, 'view')} />
              </a>
            </Tooltip>
            <Tooltip placement="top" title="Editar">
              <a style={{ padding: "10px" }}>
                <EditOutlined onClick={() => this.onModal(record, 'edit')} />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ];

    const fetching = this.state.fetching;
    const projectsList = this.state.projectList;

    return (
      <Spin spinning={fetching} delay={500}>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "left", fontSize: "30px" }}>
              Cadastro de Projetos
            </b>
          </div>
          <div style={{ width: "35%" }}>
            <Search
              placeholder="Pesquisar"
              onSearch={(value) => this.searchTerm(value)}
            />
          </div>
        </Row>

        <Row>
          <div style={{ width: "100%" }}>
            <ProjectAddModal
              title={this.state.type === 'add' ? 'Novo Projeto' : `${this.state.Id} - ${this.state.projectTitle}`}
              visible={this.state.modal}
              footer={null}
              width={"75%"}
              onCancel={() => this.setState({ modal: false, fetching: false })}
              data={this.state.projectDetails}
              type={this.state.type} />

            <Table
              dataSource={projectsList}
              columns={columns}
              style={{ height: "100%" }}
              pagination={true}
            />
          </div>
        </Row>

        <Row>
          <FloatingButton icon="+" onClick={() => this.onModal('', 'add')} />
        </Row>

      </Spin>
    );
  }
}

export default NewProject;
