import React, { useState, useEffect } from "react";
import { Row, Col, Input, Modal, Button, message } from 'antd';
import ProjectModelService from '../../services/project-model'

const ModalProjectModel = (props) => {

    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    const onChange = (property, value) => {
        let inputs = {
            ...data,
            [property]: value
        }

        setData(inputs);
    }

    const AddNew = async () => {

        if (validateFields()) {
            if (props.id !== '') {
                message.loading("Atualizando o modelo ...", 100);
                await ProjectModelService.patch(data, data.id).then((res) => {
                    message.destroy();
                    message.success("Modelo atualizado com sucesso", 3);
                }).catch(() => {
                    message.error("Erro ao atualizar", 3);
                    message.destroy();
                });
            } else {
                message.loading("Adicionando novo modelo ...", 100);
                await ProjectModelService.post(data).then((res) => {
                    message.destroy();
                    message.success("Modelo adicionado com sucesso", 3);
                }).catch(() => {
                    message.destroy();
                    message.error("Erro ao atualizar", 3);
                });
            }
        }


    }

    const validateFields = () => {
        if (data.description === '') {
            message.error('Campo Descrição Em Branco', 10);
            return false;
        }
        return true;
    }

    return (
        <Modal title="Modelo de Projetos" visible={props.visible}
            onOk={props.onOk}
            onCancel={props.onCancel}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={600}>
            <Row>
                <Col span={2}>
                    <h3>Id</h3>
                    <Input value={data.key} disabled={true} />
                </Col>

                <Col offset={1} span={15}>
                    <h3>Descrição</h3>
                    <Input value={data.description} onChange={e => onChange('description', e.target.value)} />
                </Col>
                <Col offset={2} style={{ marginTop: 33 }} >
                    <Button type="primary" onClick={AddNew}>Inserir</Button>
                </Col>
            </Row>


        </Modal>
    )
}

export default ModalProjectModel;