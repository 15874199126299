import React from "react";
import {
	Row,
	Form,
	message,
	Col,
	Select,
	Button
} from 'antd';
import ProjectActivities from '../project-activities';
import AssignmentList from '../assignment-list';
import { tasksService } from '../../services/tasks';

class AssignTask extends React.Component {

	state = {
		projects: [],
		customerProject: null,
		fetch: false,
		assignmentTable: [],
		hasPendingTasks: false
	}

	filterTasks = value => {
		// seta Id do projeto
		this.setState({ customerProject: value });
		// tentar enviar os dados para o grid daqui

	}

	async loadList() {
		await tasksService.getAssignmentListById(this.props.match.params.id).then((res) => {
			const { data } = res.data;
			let Totalizer = 0;
			
			for (const item of data) {

				Totalizer += parseFloat(item.ApprovedTime)
			}
			data.splice(0, 0, { Description: 'Total de horas', ApprovedTime: Totalizer, Id: '1' })
			this.setState({ assignmentTable: data });

		}).catch((e) => {
			message.error('Ocorreu um erro ao carregar as informações.')
		})
	}

	async componentDidMount() {
		await tasksService.getFilterProject("S").then((res) => {

			const { data } = res.data;
			this.setState({ projects: data });
		}).catch(() => {

		});
		this.loadList();
	}

	async assignAll() {

		message.loading('Atribuindo atividades...');

		let data = {
			projectId: this.state.customerProject,
			analyst: this.props.match.params.id
		};

		const result = await tasksService.assignAllTasks(data);
		if (result.data.error) {
			message.destroy();
			message.error('Ocorreu um erro ao atribuir as atividades.');
		}
		else {
			message.destroy();
			message.success('Atividades adicionadas com sucesso.');
			this.loadList();
		}

		this.setState({fetch: !this.state.fetch});

	}


	render() {


		const { Option } = Select;

		return (
			<Form /* style={{ minHeight: '100vh' }} */>

				<Row style={{ padding: 10 }}>
					<div style={{ width: "60%" }}>
						<b style={{ textAlign: "left", fontSize: "30px", marginBottom: 15 }}>Atribuição de Tarefas</b>
					</div>
					<Col span={24}>
						<div>
							<h4>Projeto</h4>
							<Select
								showSearch
								style={{ width: '100%' }}
								placeholder="Projeto"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								value={this.state.customerProject}
								onChange={this.filterTasks}>
								{this.state.projects && this.state.projects.map(d => (
									<Option value={d.Id}>{`${d.CustomerName} - ${d.Title ? d.Title : d.Description}`}</Option>
								))}
							</Select>
						</div>

					</Col>
				</Row>
				<Row style={{ padding: 10, marginTop: 25 }}>
					<div style={{ width: "60%" }}>
						<b style={{ textAlign: "left", fontSize: "20px" }}>Atividades do Projeto</b>
					</div>
					<div style={{ width: "40%" }}>
						<Button disabled={!(this.state.customerProject && this.state.hasPendingTasks)} style={{ float: 'right' }} type="primary" onClick={() => this.assignAll()}>Atribuir todas</Button>
					</div>
				</Row>
				<Row style={{ border: "1px solid gray", padding: 5 }}>
					<Col span={24}>

						<ProjectActivities
							style={{ width: '100%' }}
							hasPendingTasks={(value) => this.setState({ hasPendingTasks: value })}
							onChange={() => this.loadList()}
							fetchActivities={this.state.fetch}
							id={this.state.customerProject}
							analyst={this.props.match.params.id} />

					</Col>

				</Row>
				<Row style={{ padding: 10, marginTop: 25 }}>
					<div style={{ width: "60%" }}>
						<b style={{ textAlign: "left", fontSize: "20px" }}>Lista de Atribuições</b>
					</div>
				</Row>
				<Row style={{ border: "1px solid gray", padding: 5 }}>
					<Col span={24}>

						<AssignmentList
							style={{ width: '100%' }}
							data={this.state.assignmentTable}
							onFeching={() => this.setState({ fetch: !this.state.fetch })} />

					</Col>
				</Row>
				<Row style={{ padding: 10, marginTop: 15 }}>
					<Col offset={23}>
						<Button type="primary" onClick={() => this.props.history.push('/taskAssignment')}>Voltar</Button>
					</Col>

				</Row>


			</Form>

		)
	}
}

export default AssignTask;