import { request } from '../http';

const ProjectsService = {
  getProjects: () => {
    return request.get('/projects');
  },
  getAdressesProject: (code) => {
    return request.get('/projects/address/' + code);
  },
  getAdressesProjectDetails: (code, adress) => {
    return request.get('/projects/address/details/' + code + '/' + adress);
  },
  updateProject: (data) => {
    return request.put('/projects', data);
  },
  updateProjectStatus: (id, status) => {
    return request.put('/projects/' + id + "/" + status);
  },
  getProjectById: (id) => {
    return request.get('/projects/details/' + id);
  },
  updateTotalTimeProject: (data) => {
    return request.put('/projects/timeproject/', data);
  },

  getExpensesProject: (ProjectId) => {
    return request.get('/projects/outgoing/' + ProjectId);
    
  },
  postExpenseProject: (data) => {
    return request.post('/projects/outgoing/', data);
  },
  updateExpenseProjectStatus: (id, status) => {
    return request.put('/projects/outgoing/' + id + "/" + status);
  },
  deleteExpenseProject: (id) => {
    return request.delete('/projects/outgoing/' + id);
  },
  
  getChangeProject: (projectId, check) => {
    return request.get('/projects/change-request/' + projectId + '/' + check);
  },
  postChangeRequest: (data) => {
    return request.post('/projects/change-request', data);
  },
  updateChangeRequest: (id, status) => {
    return request.put('/projects/change-request/' + id + "/" + status);
  },
  deleteChangeProject: (id) => {
    return request.delete('/projects/change-request/' + id);
  },
  registerProjectCreate: (data) => {
    return request.post('/projects/register/', data);
  },
  registerProjectUpdate: (data) => {
    return request.put('/projects/register/', data);
  }
}

export {
  ProjectsService
}