import React, { useState } from "react";
import {
  Row,
  Modal,
  Input,
  Select,
  message,
  Form,
  DatePicker,
  Spin,
  InputNumber
} from "antd";
import moment from "moment";
import { outgoingService } from '../../services/outgoing';

const OutgoingModal = (props) => {

  const [spinning, setSpinning] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [measureUnit, setMeasureUnit] = useState([]);

  const [dropDown, setDropDown] = useState('');

  const { Option } = Select;

  const options = [
    {
      value: 'externo',
      label: 'Externo',
    },
    {
      value: 'interno',
      label: 'Interno',
    },
    {
      value: 'ambos',
      label: 'Ambos',
    }
  ]

  const validateFields = (values) => {
    if (!values.Title) {
      message.error('Campo título não preenchido.', 3);
      return false;
    }
    if (!values.Classification) {
      message.error('Campo classificação não preenchido.', 3);
      return false;
    }
    if (!dropDown) {
      message.error('Campo unidade de medida não preenchido.', 3);
      return false;
    }
    if (!startDate && !props.data.StartDate) {
      message.error('Campo data inicial não preenchido.', 3);
      return false;
    }
    return true;
  }

  const onEdit = async (value) => {
    if (validateFields(value)) {
      message.loading('Editando despesa ...', 100);
      setSpinning(true);

      console.log(moment(props.data.StartDate, "YYYY-MM-DD"));
      console.log(startDate ? startDate : moment(props.data.StartDate, "YYYY-MM-DD"));
      console.log(moment(startDate, "YYYY-MM-DD"));
      console.log(moment(startDate, "YYYY-MM-DD").format('YYYY-MM-DD'));

      const inputdata = {
        Id: props.data.Id,
        Title: value.Title,
        Classification: value.Classification,
        Unit: dropDown,
        Value: value.Value,
        StartDate: startDate ? startDate : moment(props.data.StartDate, "YYYY-MM-DD"),
        EndDate: endDate ? endDate : moment(props.data.EndDate, "YYYY-MM-DD")
      };
      console.log(inputdata);
      await outgoingService.editOutgoing(inputdata).then(r => {
        if (r.data.error) {
          message.destroy();
          message.error('Ocorreu um erro ao editar despesa.', 3);
        }
        else {
          message.destroy();
          message.success('Despesa editada com sucesso.', 3);

        }
        props.onFetching()
      });
      setSpinning(false);
    }
  }

  const dateFormat = 'DD/MM/YYYY';

  const setDateStart = e => {
    console.log(e);
    setStartDate(e);
  }
  const setDateEnd = e => {
    console.log(e);
    setEndDate(e);
  }

  return (
    <Modal title={"Editar tipo de despesa"}
      destroyOnClose={props.onClosed}
      width={"50%"}
      visible={props.visible}
      onOk={props.onClosed}
      onCancel={props.onClosed}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{
        form: "edit-form",
        key: "submit",
        htmlType: "submit",
      }}
      okText={"Salvar"}>
      <Spin spinning={spinning}>
        <Form style={{ width: "120%" }} onFinish={onEdit} id="edit-form">
          <Row>
            <div style={{ width: "100%" }}>
              <Input.Group compact>
                <div style={{ width: "27%", paddingRight: "1%" }}>
                  <p>Titulo:</p>
                  <Form.Item name='Title' initialValue={props.data.Title}>
                    <Input />
                  </Form.Item>
                </div>
                <div style={{ width: "29%" }}>
                  <p>Classificação:</p>
                  <Form.Item name="Classification" initialValue={props.data.Classification}>
                    <Select options={options} placeholder="Selecione" />
                  </Form.Item>
                </div>
                <div style={{ width: "29%", padding: "0 1%" }}>
                  <p>Valor de Medida:</p>
                  <Form.Item name='Value' initialValue={props.data.Value}>
                    <InputNumber decimalPlaces="3" decimalSeparator="," thousandSeparator="." style={{ width: "100%" }} />
                  </Form.Item>
                </div>
                <div style={{ width: "27%", paddingRight: "1%" }}>
                  <p>Unidade de medida:</p>
                  <Select placeholder="R$"
                    onChange={(value) => setDropDown(value)}
                    style={{ width: "100%" }}
                    value={props.data.Unit}
                  >
                    <Option value="R$" >R$</Option>
                    <Option value="HORAS">HORAS</Option>
                    <Option value="U$">U$</Option>
                  </Select>
                  <Form.Item name='Unit' initialValue={props.data.Unit}>
                    <Select options={props.dropdown} placeholder="Selecione" />
                  </Form.Item>
                </div>
                <div style={{ width: "29%" }}>
                  <p>Início Vigencia:</p>
                  <Form.Item name="StartDate">
                    <DatePicker format={"DD/MM/YYYY"} defaultValue={moment(props.data.StartDate, 'YYYY-MM-DD')} style={{ width: "100%" }} onChange={(value) => setDateStart(value)} />
                  </Form.Item>
                </div>
                <div style={{ width: "29%", padding: "0 1%" }}>
                  <p>Término Vigencia:</p>
                  <Form.Item name="EndDate">
                    <DatePicker format={"DD/MM/YYYY"} defaultValue={moment(props.data.EndDate, 'YYYY-MM-DD')} style={{ width: "100%" }} onChange={(value) => setDateEnd(value)} />
                  </Form.Item>
                </div>
              </Input.Group>
            </div>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default OutgoingModal;