import React from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Spin,
  Tooltip,
  Modal,
  Form,
} from "antd";
import { EditOutlined, EyeOutlined, FilterFilled } from "@ant-design/icons";
import moment from "moment";
import { customerService } from "../../services/customer"
import { FloatingButton } from "../../components/floating-button";
import CustomerModal from "../../components/customer-modal";

moment.locale("pt-BR");

const { Search } = Input;

class Customers extends React.Component {
  state = {
    show: false,
    fetching: false,
    customerList: [],
    customerDetails: [],
    customerListSearch: [],
    modal: false,
    Id: undefined,
    customerName: undefined
  };

  formRef = React.createRef();

  fetchDetails = async () => {
    this.setState({ fetching: true, edit: true });
    this.setState({ fetching: false });
  };

  handleOk = (e) => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ fetching: true });
    await customerService.getCustomers().then((r) => {
      const customerData = r.data.data;
      this.setState({
        customerList: customerData,
        customersListBackup: customerData,
        fetching: false,
      });
    });
  };

  searchTerm = async (value) => {
    if (value) {
      this.setState({ fetching: true });
      await this.fetch();
      const list = this.state.customerList.filter((x) => {
        return x.Name != null
      });
      const filtrado = list.filter((x) => {
        return x.Name.toLowerCase().includes(value.toLowerCase()) || x.Code.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ customerList: filtrado, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  onModal = async (value, type) => {
    let customerData = {};
    let customerId = '';
    let customerName = '';

    if (type === 'add') {
      customerData = {
        Id: 0,
        CustomerCode: '',
        Company: '',
        CompanyName: '',
        CNPJ: '',
        Address: '',
        Number: 0,
        Complement: '',
        City: '',
        State: '',
        ContactName: '',
        ContactEmail: '',
        PhoneNumber: '',
        Addresses: [
          {
            Id: '',
            CustomerId: '',
            ZipCode: '',
            Address: '',
            Number: 0,
            Neighborhood: '',
            City: '',
            State: '',
            KM: 0,
            Company: ''
          }
        ]
      };
    } else {
      customerId = value.Id;
      customerName = value.Company;

      const customer = await customerService.getCustomersById(value.Id).then((r) => {
        return r.data.data[0];
      });
      const addresses = await customerService.getCustomerAddressById(value.Id).then((r) => {
        return r.data.data;
      });

      customerData = customer;
      customerData.Addresses = addresses;
    };

    this.setState({ modal: true, Id: customerId, customerDetails: customerData, fetching: true, customerName: customerName, type: type });
  }

  render() {

    const columns = [
      {
        title: "Código",
        dataIndex: "Id",
        key: "Id",
        sorter: true,
        width: 150,
        defaultSortOrder: "ascend",
        render: (text, record) => <span>{record.Id}</span>
      },
      {
        title: "Nome",
        dataIndex: "Company",
        key: "Company",
        sorter: true,
        width: 350,
        render: (text, record) => <span>{record.Company}</span>
      },
      {
        title: "CNPJ",
        dataIndex: "CNPJ",
        key: "CNPJ",
        sorter: true,
        width: 250,
        render: (text, record) => <span>{record.CNPJ}</span>
      },
      {
        title: "Ação",
        key: "Action",
        id: "details-button",
        width: 70,
        render: (text, record) => (
          <span>
            <Tooltip placement="top" title="Vizualizar">
              <a style={{ padding: "5px" }}>
                <EyeOutlined onClick={() => this.onModal(record, 'view')} />
              </a>
            </Tooltip>
            <Tooltip placement="top" title="Editar">
              <a style={{ padding: "10px" }}>
                <EditOutlined onClick={() => this.onModal(record, 'edit')} />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ];

    const fetching = this.state.fetching;
    const customersList = this.state.customerList;

    return (
      // <Spin spinning={fetching} delay={500}>
      //   <Row style={{ paddingBottom: "25px" }}>
      //     <div style={{ width: "60%" }}>
      //       <b style={{ textAlign: "left", fontSize: "30px" }}>
      //         Listagem de clientes
      //       </b>
      //     </div>
      //     <div style={{ width: "35%" }}>
      //       <Search
      //         placeholder="Pesquisar"
      //         onSearch={(value) => this.searchTerm(value)}
      //       />
      //     </div>
      //     <div style={{ width: "5%", float: "right" }}>
      //       <center>
      //         <FilterFilled
      //           disabled="true"
      //           style={{ fontSize: "20px", padding: "5px" }}
      //         />
      //       </center>
      //     </div>
      //   </Row>      
      <Spin spinning={fetching} delay={500}>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "left", fontSize: "30px" }}>
              Listagem de clientes
            </b>
          </div>
          <div style={{ width: "35%" }}>
            <Search
              placeholder="Pesquisar"
              onSearch={(value) => this.searchTerm(value)}
            />
          </div>
        </Row>
        <Row>
          <div style={{ width: "100%" }}>
            <CustomerModal
              title={this.state.type === 'add' ? 'Novo Cliente' : `${this.state.Id} - ${this.state.customerName}`}
              visible={this.state.modal}
              footer={null}
              width={"75%"}
              onCancel={() => this.setState({ modal: false, fetching: false })}
              data={this.state.customerDetails}
              // dropdown={this.state.permissionsList}
              // fields={this.state.lockFields}
              type={this.state.type} />

            <Table
              dataSource={customersList}
              columns={columns}
              style={{ height: "100%" }}
              pagination={true}
            />
          </div>
        </Row>

        <Row>
          <FloatingButton icon="+" onClick={() => this.onModal('', 'add')} />
        </Row>

      </Spin>
    );
  }
}

export default Customers;
