import { request } from '../http';

const customerService = {
  getCustomers: () => {
    return  request.get('/BusinessPartners');
  },
  getCustomersById: (id) => {
    return  request.get('/BusinessPartners/'+id);
  },
  getCustomerAllAddress: () => {
    return  request.get('/BusinessPartners/address/all');
  },
  getCustomerAddressById: (id) => {
    return  request.get('/BusinessPartners/address/'+id);
  },
  // atualizar dados do cliente
  updateCustomer: (bodyData) => {
    return request.put('/BusinessPartners/', bodyData);
  },
  // Novo cliente
  createCustomer: (bodyData) => {
    return request.post('/BusinessPartners/', bodyData);
  },
  // atualizar dados do endereço cliente
  updateAddressCustomer: (bodyData) => {
    return request.put('/BusinessPartners/address/', bodyData);
  },
  // Novo endereço do cliente
  createAddressCustomer: (bodyData) => {
    return request.post('/BusinessPartners/address/', bodyData);
  }
}

export {
    customerService
}