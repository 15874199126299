import React, { useState } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Spin,
  Input,
  Button,
  Tabs,
  Modal,
  message,
  Select,
  Form,
  DatePicker
} from "antd";
import * as _ from 'lodash';
import { customerService } from "../../services/customer"

const { TabPane } = Tabs;
const CustomerModal = (props) => {
  const [spinning, setSpinning] = useState(false);
  const onEdit = async (value) => {
    let success = true;
    let newId = 0;

    if (value) {
      message.loading(props.type === 'edit' ? 'Editando cliente ...' : 'Cadastrando cliente ...', 100);
      setSpinning(true);

      const inputdata = {
        Id: props.data.Id,
        CustomerCode: value.CustomerCode ? value.CustomerCode : props.data.CustomerCode ? props.data.CustomerCode : '',
        Company: value.Company ? value.Company : props.data.Company ? props.data.Company : '',
        CompanyName: value.CompanyName ? value.CompanyName : props.data.CompanyName ? props.data.CompanyName : '',
        CNPJ: value.CNPJ ? value.CNPJ : props.data.CNPJ ? props.data.CNPJ : '',
        Address: value.Address ? value.Address : props.data.Address ? props.data.Address : '',
        Number: value.Number ? value.Number : props.data.Number ? props.data.Number : 0,
        Complement: value.Complement ? value.Complement : props.data.Complement ? props.data.Complement : '',
        City: value.City ? value.City : props.data.City ? props.data.City : '',
        State: value.State ? value.State : props.data.State ? props.data.State : '',
        ContactName: value.ContactName ? value.ContactName : props.data.ContactName ? props.data.ContactName : '',
        ContactEmail: value.ContactEmail ? value.ContactEmail : props.data.ContactEmail ? props.data.ContactEmail : '',
        PhoneNumber: value.PhoneNumber ? value.PhoneNumber : props.data.PhoneNumber ? props.data.PhoneNumber : '',
      };

      let CustomerAddresses = [];

      for (let index = 0; index < props.data.Addresses.length; index++) {
        const element = props.data.Addresses[index];

        const addr = {
          Id: eval(`value.Id${index}`) ? eval(`value.Id${index}`) : element.Id ? element.Id : '',
          CustomerId: eval(`value.CustomerId${index}`) ? eval(`value.CustomerId${index}`) : element.CustomerId ? element.CustomerId : '',
          Address: eval(`value.Address${index}`) ? eval(`value.Address${index}`) : element.Address ? element.Address : '',
          Number: eval(`value.Number${index}`) ? eval(`value.Number${index}`) : element.Number ? element.Number : 0,
          Neighborhood: eval(`value.Neighborhood${index}`) ? eval(`value.Neighborhood${index}`) : element.Neighborhood ? element.Neighborhood : '',
          ZipCode: eval(`value.ZipCode${index}`) ? eval(`value.ZipCode${index}`) : element.ZipCode ? element.ZipCode : '',
          City: eval(`value.City${index}`) ? eval(`value.City${index}`) : element.City ? element.City : '',
          State: eval(`value.State${index}`) ? eval(`value.State${index}`) : element.State ? element.State : '',
          KM: eval(`value.KM${index}`) ? eval(`value.KM${index}`) : element.KM ? element.KM : 0,
          Company: eval(`value.Company${index}`) ? eval(`value.Company${index}`) : element.Company ? element.Company : '',
        };

        CustomerAddresses.push(addr);
      }

      // se apresentar erro na tentativa de atualizar os dados, logá-lo para entendimento
      try{
        if (props.type === 'edit') {
          await customerService.updateCustomer(inputdata).then(r => {
            if (r.data.error) {
              success = false;
              console.log('inputdata',inputdata);
              console.log('ERROR',r.data.error);
            }
          });

          if (success && CustomerAddresses && CustomerAddresses.length > 0) {
            for (let index = 0; index < CustomerAddresses.length; index++) {
              const addr = CustomerAddresses[index];

              // eslint-disable-next-line no-loop-func
              await customerService.updateAddressCustomer(addr).then(r => {
                if (r.data.error) {
                  success = false;
                  console.log('addr',addr);
                  console.log('ERROR',r.data.error);
                }
              });
            }
          }

          if (success) {
            message.destroy();
            message.success('Cliente Alterado.');
          } else {
            message.destroy();
            message.error('Falha ao atualizar cliente.')
          }
        } else {
          await customerService.createCustomer(inputdata).then(r => {
            console.log(r);
            if (!r.data.error) {
              // Capturar o id do cliente
              newId = r.data.data.insertId;
            } else {
              success = false;
              console.log('inputdata',inputdata);
              console.log('ERROR',r.data.error);
            }
          });

          if (success && CustomerAddresses && CustomerAddresses.length > 0) {
            for (let index = 0; index < CustomerAddresses.length; index++) {
              CustomerAddresses[index].CustomerId = newId;

              const addr = CustomerAddresses[index];

              // eslint-disable-next-line no-loop-func
              await customerService.createAddressCustomer(addr).then(r => {
                if (r.data.error) {
                  success = false;
                  console.log('addr',addr);
                  console.log('ERROR',r.data.error);
                }
              });
            }
          }

          if (success) {
            message.destroy();
            message.success('Cliente Cadastrado.');
          } else {
            message.destroy();
            message.error('Falha ao cadastrar cliente.')
          }
        }
      }catch(e){
        console.log(e);
        // console.log('user-modal update ERROR: ',e);
      }
      setSpinning(false);
    }
  }

  return (
    <Modal
      title={props.type === 'add' ? 'Novo Cliente' : `${props.data.Id} - ${props.data.Company}`}
      width={props.width}
      visible={props.visible}
      footer={props.footer}
      onOk={props.onCancel}
      onCancel={props.onCancel}
      destroyOnClose={props.onCancel}
    >
      <Spin spinning={spinning}>
        <Form style={{ width: "100%" }} onFinish={onEdit}>
          <Row>
            <Input.Group compact style={{ margin: "0px 1%" }}>
              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Código:</p>
                <Form.Item name={['CustomerCode']} initialValue={props.data.CustomerCode && props.data.CustomerCode != "null" && props.data.CustomerCode != "undefined" && props.data.CustomerCode != "0" ? props.data.CustomerCode : ""}>
                  <Input disabled={props.type !== 'add'} style={{ width: "100%" }} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Nome:</p>
                <Form.Item name={['Company']} initialValue={props.data.Company && props.data.Company != "null" && props.data.Company != "undefined" && props.data.Company != "0" ? props.data.Company : ""}>
                  <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Razão Social:</p>
                <Form.Item name={['CompanyName']} initialValue={props.data.CompanyName && props.data.CompanyName != "null" && props.data.CompanyName != "undefined" && props.data.CompanyName != "0" ? props.data.CompanyName : ""}>
                  <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>CNPJ:</p>
                <Form.Item name={['CNPJ']} initialValue={props.data.CNPJ && props.data.CNPJ != "null" && props.data.CNPJ != "undefined" && props.data.CNPJ != "0" ? props.data.CNPJ : ""}>
                  <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Rua:</p>
                <Form.Item name={['Address']} initialValue={props.data.Address && props.data.Address != "null" && props.data.Address != "undefined" && props.data.Address != "0" ? props.data.Address : ""}>
                  <Input disabled={props.type === 'view'} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Número:</p>
                <Form.Item name={['Number']} initialValue={props.data.Number && props.data.Number != "null" && props.data.Number != "undefined" && props.data.Number != "0" ? props.data.Number : 0}>
                  <Input disabled={props.type === 'view'} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Complemento:</p>
                <Form.Item name={['Complement']} initialValue={props.data.Complement && props.data.Complement != "null" && props.data.Complement != "undefined" && props.data.Complement != "0" ? props.data.Complement : ""}>
                  <Input disabled={props.type === 'view'} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Cidade:</p>
                <Form.Item name={['City']} initialValue={props.data.City && props.data.City != "null" && props.data.City != "undefined" && props.data.City != "0" ? props.data.City : ""}>
                  <Input disabled={props.type === 'view'} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Estado:</p>
                <Form.Item name={['State']} initialValue={props.data.State && props.data.State != "null" && props.data.State != "undefined" && props.data.State != "0" ? props.data.State : ""}>
                  <Input disabled={props.type === 'view'} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Contato:</p>
                <Form.Item name={['ContactName']} initialValue={props.data.ContactName && props.data.ContactName != "null" && props.data.ContactName != "undefined" && props.data.ContactName != "0" ? props.data.ContactName : ""}>
                  <Input disabled={props.type === 'view'} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Email:</p>
                <Form.Item name={['ContactEmail']} initialValue={props.data.ContactEmail && props.data.ContactEmail != "null" && props.data.ContactEmail != "undefined" && props.data.ContactEmail != "0" ? props.data.ContactEmail : ""}>
                  <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                </Form.Item>
              </div>

              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Telefone:</p>
                <Form.Item name={['PhoneNumber']} initialValue={props.data.PhoneNumber && props.data.PhoneNumber != "null" && props.data.PhoneNumber != "undefined" && props.data.PhoneNumber != "0" ? props.data.PhoneNumber : ""}>
                  <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </Input.Group>
          </Row>
          <Row style={{ padding: "10px 0px 5px 0px" }}>
            <Tabs type="card" style={{ width: "100%" }} onTabClick={key => key == props.data.Addresses.length} >
              {props.data.Addresses && props.data.Addresses.map((item, index) => (
                <TabPane tab={"Endereço " + (index + 1)} key={index}>
                  <Input.Group compact style={{ margin: "0px 1%" }}>
                    <div style={{ width: "20%", padding: "0px 1%" }}>
                      <p>Endereço:</p>
                      <Form.Item name={'Address'+index} initialValue={item.Address && item.Address != "null" && item.Address != "undefined" && item.Address != "0" ? item.Address : ""} >
                        <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div style={{ width: "20%", padding: "0px 1%" }}>
                      <p>Número:</p>
                      <Form.Item name={'Number'+index} initialValue={item.Number && item.Number != "null" && item.Number != "undefined" && item.Number != "0" ? item.Number : ""} >
                        <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div style={{ width: "20%", padding: "0px 1%" }}>
                      <p>Bairro:</p>
                      <Form.Item name={'Neighborhood'+index} initialValue={item.Neighborhood && item.Neighborhood != "null" && item.Neighborhood != "undefined" && item.Neighborhood != "0" ? item.Neighborhood : ""} >
                        <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div style={{ width: "20%", padding: "0px 1%" }}>
                      <p>CEP:</p>
                      <Form.Item name={'ZipCode'+index} initialValue={item.ZipCode && item.ZipCode != "null" && item.ZipCode != "undefined" && item.ZipCode != "0" ? item.ZipCode : ""} >
                        <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div style={{ width: "20%", padding: "0px 1%" }}>
                      <p>Cidade:</p>
                      <Form.Item name={'City'+index} initialValue={item.City && item.City != "null" && item.City != "undefined" && item.City != "0" ? item.City : ""} >
                        <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div style={{ width: "20%", padding: "0px 1%" }}>
                      <p>Estado:</p>
                      <Form.Item name={'State'+index} initialValue={item.State && item.State != "null" && item.State != "undefined" && item.State != "0" ? item.State : ""} >
                        <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div style={{ width: "20%", padding: "0px 1%" }}>
                      <p>KM:</p>
                      <Form.Item name={'KM'+index} initialValue={item.KM && item.KM != "null" && item.KM != "undefined" && item.KM != "0" ? item.KM : ""} >
                        <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div style={{ width: "20%", padding: "0px 1%" }}>
                      <p>Company:</p>
                      <Form.Item name={'Company'+index} initialValue={item.Company && item.Company != "null" && item.Company != "undefined" && item.Company != "0" ? item.Company : ""} >
                        <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                  </Input.Group>
                </TabPane>
              ))}
            </Tabs>
            <Row style={{ width: "100%"}}>
              <div style={{ float: "right", width: "100%" }}>
                { props.type !== 'view' &&
                  <div style={{ float: "right", paddingRight: "1%"}}>
                    <Button type="primary" htmlType="submit" onClick={() => props.onCancel()}>Salvar</Button>
                  </div>
                }
                <div style={{ float: "right", paddingRight: "1%" }}>
                  <Button onClick={() => props.onCancel()}>Fechar</Button>
                </div>
              </div>
            </Row>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default CustomerModal;