import React from "react";
import { Form } from 'antd';
import moment from "moment";
moment.locale('pt-BR');

class PowerbiReport extends React.Component {
    render() {
        return (
            <Form /* style={{ minHeight: '100vh' }} */>
                <iframe width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=846c8d6b-15f3-4d28-9507-72d590a996b8&autoAuth=true&ctid=1e2f3ba6-814b-4915-a4ac-41be13c7c2c9&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
            </Form >
        )
    }
}

export default PowerbiReport;