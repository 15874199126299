import { request } from '../http';

const reportsService = {
  userReport: (userId,start,end,projectId,planned) => {
    return request.get('/reports/reportuser/'+userId+'/'+start+'/'+end+'/'+projectId+'/'+(planned?planned:'N'));
  },
  //usersReports: (start,end) => {
  //  return request.get('/reports/reportuser/'+start+'/'+end);
  //}
}

export {
    reportsService
}