import React, { useState } from "react";
import {
  Row,
  Modal,
  Input,
  Spin,
  message,
  Form,
  DatePicker,
  Button,  
  Select,
  InputNumber,
} from "antd";
import moment from "moment";
import * as _ from 'lodash';
import { outgoingService } from '../../services/outgoing';

const OutgoingModal = (props) => {
  const [spinning, setSpinning] = useState(false);
  const [dropDown, setDropDown] = useState('');

  const { Option } = Select;

  const options = [
    {
      value: 'externo',
      label: 'Externo',
    },
    {
      value: 'interno',
      label: 'Interno',
    },
    {
      value: 'ambos',
      label: 'Ambos',
    }
  ]

  const validateFields = (values) => {
    if (!values.Title) {
      message.error('Campo título não preenchido.', 3);
      return false;
    }
    if (!values.Classification) {
      message.error('Campo classificação não preenchido.', 3);
      return false;
    }
    if (!dropDown) {
      message.error('Campo unidade de medida não preenchido.', 3);
      return false;
    }
    if (!values.Value) {
      message.error('Campo valor de medida não preenchido.', 3);
      return false;
    }
    if (!values.startDate) {
      message.error('Campo data inicial não preenchido.', 3);
      return false;
    }
    return true;
  }

  const onCreate = async (value) => {
    if (validateFields(value)) {

      message.loading('Criando despesa ...', 100);
      setSpinning(true);

      const inputdata = {
        Title: value.Title,
        Classification: value.Classification,
        Unit: dropDown,
        Value: value.Value,
        StartDate: value.startDate ? moment(value.startDate).format("YYYY-MM-DD") : "",
        EndDate: value.endDate ? moment(value.endDate).format("YYYY-MM-DD") : ""
      }
      await outgoingService.createOutgoing(inputdata).then(r => {
        if (r.data.error) {
          setSpinning(false);
          message.destroy();
          props.onClosed();
          message.error("Ocorreu um erro ao criar despesa.", 3);
        }
        else {
          props.onClosed();
          setSpinning(false);
          message.destroy();
          message.success("Despesa criada com sucesso.", 3);
        }
        props.onFetching()
      });
      setSpinning(false);
    }
  }

  return (

    <Modal title={"Cadastro de despesas"}
      width={"60%"}
      footer={null}
      visible={props.visible}
      destroyOnClose={props.onClosed}
      onCancel={props.onClosed}
    >
      <Spin spinning={spinning} delay={500}>
        <Form onFinish={onCreate} id="add-form" >
          <Row>
            <Input.Group compact style={{ paddingTop: "1%" }}>
              <div style={{ width: "30%", paddingRight: "1%" }}>
                <p>Titulo:</p>
                <Form.Item name='Title'>
                  <Input />
                </Form.Item>
              </div>
              <div style={{ width: "35%", paddingRight: "1%" }}>
                <p>Classificação:</p>
                <Form.Item name="Classification">
                  <Select options={options} placeholder="Selecione" />
                </Form.Item>
              </div>
              <div style={{ width: "35%", paddingRight: "0 1%" }}>
                <p>Valor de Medida:</p>
                <Form.Item name='Value'>
                  <InputNumber decimalPlaces="3" decimalSeparator="," thousandSeparator="." style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div style={{ width: "30%", paddingRight: "1%" }}>
                <p>Unidade de medida:</p>
                <Select placeholder="R$"
                  onChange={(value) => setDropDown(value)}
                  style={{ width: "100%" }}
                >
                  <Option value="R$" >R$</Option>
                  <Option value="HORAS">HORAS</Option>
                  <Option value="U$">U$</Option>
                </Select>
                {/* <Form.Item name='Unit'>
                    <Select options={props.dropdown} placeholder="Selecione" />
                  </Form.Item> */}
              </div>
              <div style={{ width: "35%", paddingRight: "1%" }}>
                <p>Início Vigencia:</p>
                <Form.Item name="startDate"><DatePicker format="DD/MM/YYYY" placeholder="Selecione uma data" style={{ width: "100%" }} /></Form.Item>
              </div>
              <div style={{ width: "35%", paddingRight: "1%" }}>
                <p>Término Vigencia:</p>
                <Form.Item name="endDate"><DatePicker format="DD/MM/YYYY" placeholder="Selecione uma data" style={{ width: "100%" }} /></Form.Item>
              </div>
              <div style={{ float: "right", paddingTop: "1%" }}>
                <Button type="primary" disabled={spinning} htmlType="submit" >Salvar</Button>
              </div>
            </Input.Group>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default OutgoingModal;