import React, { useState } from "react";
import {
  Row,
  Table,
  Modal,
  Input,
  InputNumber,
  Spin,
  message,
  Tabs,
  Radio,
  Form,
  Button,
  DatePicker,
  Select,
  AutoComplete,
  Popconfirm,
  Tooltip
} from "antd";
import { PlusOutlined, DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

import { itemsService } from '../../services/items';
import { ProjectsService } from "../../services/projects";
import * as _ from 'lodash';
import ModalDocumentEletronic from '../modal-documents';
import config from '../../environment.json';
import { scheduleService } from '../../services/schedule';
import { tasksService } from "../../services/tasks";
import Schedule from "../../pages/schedule";
import { parseISO, format } from 'date-fns';
import { EletronicDocumentsService } from "../../services/eletronic-documents";

const { TabPane } = Tabs;
const { TextArea } = Input;

const ProjectEditModal = (props) => {

  const [spinning, setSpinning] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [buttonDoc, setButtonDoc] = useState(false);
  const [modalDoc, setModalDoc] = useState(false);

  //Dados gerais

  const [tasks, setTasks] = useState([]);
  const [workflow, setWorkflow] = useState();
  const [workflow1, setWorkflow1] = useState();
  const [workflow2, setWorkflow2] = useState();
  const [model, setModel] = useState();
  const [projectType, setprojectType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [addrId, setAddrId] = useState();
  const [toll, setToll] = useState(2);
  const [limitNotes, setLimitNotes] = useState();
  // horas previstas e aporte
  const [budgetedHours, setBudgetedHours] = useState();

  const documents = [
    {
      title: 'Descrição',
      dataIndex: 'Description',
      key: 'Description',
      align: 'left',
      width: 110,
    },
    {
      title: 'Nome do Documento',
      dataIndex: 'FileName',
      key: 'FileName',
      align: 'left',
      width: 80,
    },
    {
      title: 'Categoria',
      dataIndex: 'FileCategoryId',
      key: 'FileCategoryId',
      align: 'center',
      width: 20,
    },
    {
      title: 'Ação',
      key: 'action',
      align: 'center',
      width: 30,
      render: (text, record) => <span>
        <Tooltip placement="top" title={"Baixar"}>
          <a href={config['API_URL'] + '/uploads/EletronicDocuments/' + record.FileName} download target="_blank"><DownloadOutlined />
          </a>
        </Tooltip>
        <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
          onConfirm={() => removeFile(record.Id)} okText="Sim" cancelText="Não">
          <Tooltip placement="top" title={"Excluir"}>
            <a><DeleteOutlined /></a>
          </Tooltip>
        </Popconfirm>
      </span>
    },
  ]

  const itens = [
    {
      title: 'Código do pedido',
      dataIndex: 'Code',
      key: 'Code',
      align: 'center',
      width: 10
    },
    {
      title: 'Item',
      dataIndex: 'ItemCode',
      key: 'ItemCode',
      align: 'center',
      width: 110
    },
    {
      title: 'Descrição',
      dataIndex: 'ItemName',
      key: 'ItemName',
      align: 'center',
      width: 110
    },
    {
      title: 'Quantidade',
      dataIndex: 'Quantity',
      key: 'Quantity',
      align: 'center',
      width: 110,
      render: (text, record) => <span>{_.round(record.Quantity, 2)}</span>
    },
    {
      title: 'Valor Unitário',
      dataIndex: 'Price',
      key: 'Price',
      align: 'center',
      width: 110,
      render: (text, record) => <span>{"R$ " + parseFloat(record.Price).toFixed(2)}</span>
    },
    {
      title: 'Data',
      dataIndex: 'Date',
      key: 'Date',
      align: 'center',
      width: 110,
      render: (text, record) => <span>{moment(record.Date).format('DD/MM/YYYY')}</span>
    },
    {
      title: 'Ação',
      dataIndex: 'Action',
      key: 'Action',
      align: 'center',
      width: 30,
      render: (text, record) => (
        <Button.Group >
          <div style={{ padding: "7px" }}>
            <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
              onConfirm={() => cancelItem(record)} okText="Sim" cancelText="Não">
              <Tooltip placement="top" title={"Excluir"}>
                <a><DeleteOutlined /></a>
              </Tooltip>
            </Popconfirm>
          </div>
        </Button.Group>
      ),
    },
  ];

  const notes = [
    {
      value: 'N',
      label: 'Não',
    },
    {
      value: 'Y',
      label: 'Sim',
    }
  ]

  const options = [
    {
      value: 'externo',
      label: 'Externo',
    },
    {
      value: 'interno',
      label: 'Interno',
    },
    {
      value: 'ambos',
      label: 'Ambos',
    }
  ]

  const models = [
    {
      value: '1',
      label: 'Time Material',
    },
    {
      value: '2',
      label: 'TurnKey',
    },
    {
      value: '3',
      label: 'Banco de Horas',
    }
  ]

  const getAdressDetails = async (value) => {
    setSpinning(true);
    if (!value[0]) {
      setAddresses({});
      setAddrId({});
      setSpinning(false);
    }
    else {
      await ProjectsService.getAdressesProjectDetails(props.projectData.customer, value).then((res) => {
        const data = res.data[0];

        setAddresses({
          clientName: data.CardName,
          fantasyName: data.CardFName,
          street: data.AddrType + "." + data.Street,
          cep: data.CEP,
          city: data.City,
          number: data.StreetNo,
          state: data.State,
          cnpj: data.CNPJ,
        });
        setAddrId(value);
        setSpinning(false);
      });
    }
  }

  const insertItemProject = async (values) => {
    if (validateFieldsItem(values)) {
      message.loading("Adicionando item no pedido ...", 100);
      setSpinning(true);

      const projectData = await ProjectsService.getProjectById(props.projectData.id)
      const project = projectData.data.data[0];

      let item = {
        Description: values.description,
        Hours: values.hours,
        UnitPrice: values.unitPrice,
        TotalPrice: values.unitTotal,
        DateBilling: values.date,
      }


      // if (project.RentabilityPerHour && project.RentabilityPerHour != 0 || project.Rentability && project.Rentability != 0) {
      await itemsService.insertItem(item).then((r) => {
        if (r.data.error) {
          message.destroy();
          message.error("Ocorreu um erro ao inserir o item.", 3);
        }
        else {
          message.destroy();
          message.success("Item adicionado com sucesso.", 3);
          props.fetchDataSource("item");
        }
      });
      // }
      // else {
      //   message.destroy();
      //   message.error("Ocorreu um erro ao inserir o item, projeto sem rentabilidade.", 3);
      // }
      setSpinning(false);
    }
  }

  const createSchedule = async (description) => {

    const input = {
      DraftOrder: '',
      ProjectId: props.projectData.id,
      Description: description,
      ConclusionPercentage: 0,
      ExpectedTime: 0,
      ExpectedStartDate: '',
      ExpectedEndDate: '',
      StartDate: '',
      EndDate: '',
      ParentTask: 1,
      Predecessor: 0,
    }

    const scheduleData = await scheduleService.getScheduleByProjectId(props.projectData.id);
    const schedule = scheduleData.data;
    if (!schedule) {
      await scheduleService.createSchedule(input);
    }
  }

  const onFinishEdit = async (values) => {
    const ProjectData = props.data;

    if (validateFields(values)) {
      const respid = (localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : 0);

      const taskData = await tasksService.getListByProjectCode(props.projectData.id)

      //const normalizedDate = new Date(startDate).toISOString();

      const task = taskData.data;
      setTasks(task)
      const data = {
        Id: props.projectData.id,
        Code: props.projectData.code,
        Status: "Aprovado",
        CustomerId: Number(props.projectData.customer),
        CustomerName: ProjectData.clientName,
        Title: ProjectData.title,
        Value: ProjectData.value ? ProjectData.value : values.projectValue ? values.projectValue : 0,
        Description: values.description ? values.description : "",
        ProjectType: !projectType ? ProjectData.projectType : projectType,
        ProjectModelCode: !model ? ProjectData.model : model,
        // ORIGINAL - EstimatedTimeAmount: values.budgetedHours ? values.budgetedHours : ProjectData.totalTimeAmount,
        EstimatedTimeAmount: ProjectData.totalTimeAmount ? ProjectData.totalTimeAmount : typeof budgetedHours == 'number' ? parseInt(budgetedHours) : ProjectData.totalTimeAmount,
        // TotalTimeAmount: ProjectData.totalTimeAmount ? parseInt(ProjectData.totalTimeAmount) + parseInt(ProjectData.hoursContribution) : parseInt(budgetedHours) + parseInt(ProjectData.hoursContribution),
        TotalTimeAmount: typeof budgetedHours == 'number' ? parseInt(budgetedHours) : parseInt(ProjectData.totalTimeAmount),

        StartDate: startDate ? startDate : ProjectData.startDate,
        EndDate: endDate ? endDate : ProjectData.endDate,

        // StartDate: startDate ? moment(startDate).format('YYYY-MM-DD') !== 'Invalid date' ? moment(startDate).format('YYYY-MM-DD') : '' : moment(ProjectData.startDate).format('YYYY-MM-DD') !== 'Invalid date' ? moment(ProjectData.startDate).format('YYYY-MM-DD') : '',
        // EndDate: endDate ? moment(endDate).format('YYYY-MM-DD') !== 'Invalid date' ? moment(endDate).format('YYYY-MM-DD') : '' : moment(ProjectData.endDate).format('YYYY-MM-DD') !== 'Invalid date' ? moment(ProjectData.endDate).format('YYYY-MM-DD') : '',
        KM: values.KM ? parseInt(values.KM) : 0,
        Reference: values.reference ? values.reference : "",
        ResponsibleId: respid,
        Toll: toll === 1 ? "Y" : "N",
        // alterado chave para mesmo nome do bd projects
        CustomerAdressId: addrId ? addrId : props.data.CustomerAdressId,
        Workflow: !workflow ? ProjectData.dropdown[0].value : workflow,
        Workflow1: !workflow1 ? ProjectData.dropdown[0].value : workflow1,
        //Workflow2: !workflow2 ? ProjectData.dropdown[0].value : workflow2,
        // LimitNotes: limitNotes
        LimitNotes: limitNotes ? limitNotes : props.data.limitNotes
      }

      // conversão das datas para envio no idioma do banco - atualmente inglês
      let UpStart = null;

      if (typeof data.StartDate === 'string') {
        const dataStartSplit = data.StartDate.split('/');
        const newStart = `${dataStartSplit[2]}-${dataStartSplit[1]}-${dataStartSplit[0]}`;
        UpStart = format(parseISO(newStart), 'yyyy-MM-dd');
      } else {
        UpStart = format(data.StartDate._d, 'yyyy-MM-dd');
      }

      let UpEnd = null;

      if (typeof data.EndDate === 'string') {
        const dataEndSplit = data.EndDate.split('/');
        const newEnd = `${dataEndSplit[2]}-${dataEndSplit[1]}-${dataEndSplit[0]}`;
        UpEnd = format(parseISO(newEnd), 'yyyy-MM-dd');
      } else {
        UpEnd = format(data.EndDate._d, 'yyyy-MM-dd');
      }

      // const dataStartSplit = typeof data.StartDate === 'string' ? data.StartDate.split('/') : data.StartDate._i.split('/');
      // const newStart =  `${dataStartSplit[2]}-${dataStartSplit[1]}-${dataStartSplit[0]}`;
      // const dataEndSplit = data.EndDate.split('/');
      // const newEnd =  `${dataEndSplit[2]}-${dataEndSplit[1]}-${dataEndSplit[0]}`;

      // adição das datas convertidas ao data coletado
      const Dataup = {
        ...data,
        StartDate: UpStart,
        EndDate: UpEnd,
      }

      // console.log('Dataup', Dataup);

      message.loading('Editando Projeto ...', 100);
      const projectData = await ProjectsService.updateProject(Dataup);
      const projects = projectData.data;

      if (projects.error) {
        message.destroy();
        // ClosedModal();
        message.error('Ocorreu um erro ao editar projeto.', 3);
      }
      else {
        await createSchedule(ProjectData.title);
        ClosedModal();
        message.destroy();
        message.success('Projeto editado com sucesso.', 3);
        props.onFecthing();
      }
    }
  };

  const removeFile = async (Id) => {
    message.loading("Excluindo Item ...", 3);
    setSpinning(true);

    const r = await EletronicDocumentsService.delete(Id)
    if (!r.data.error) {
      message.destroy();
      message.success("Item excluído com sucesso.", 3);
      props.fetchDataSource("documents");
      setSpinning(false);
    }
    else {
      message.destroy();
      message.error("Erro ao excluir o arquivo.", 3);
      props.fetchDataSource("documents");
      setSpinning(false);
    }
  }

  const cancelItem = async (values) => {
    message.loading("Cancelado Item ...", 3);
    setSpinning(true);

    // const schedulesData = await scheduleService.getSchedule();
    // let schedules = schedulesData.data.data;
    // let verifyUsedScheduler = schedules.map(item => {
    //   return item.Description.includes(values.Name) ? item.Id : null
    // }).filter(filtered => filtered != null);

    // let verifyUsedItem = schedules.map(item => {
    //   return item.ParentTask == verifyUsedScheduler[0] ? item : null
    // }).filter(filtered => filtered != null);

    // if (verifyUsedItem[0]) {
    //   message.destroy();
    //   setSpinning(false);
    //   message.error("Este item está sendo usado no cronograma.", 3);
    // }
    // else {
    const itemData = await itemsService.deleteItem(values.Id, values.Code)
    if (!itemData.data.error) {
      // if (verifyUsedScheduler[0]) {
      // const scheduleData = await scheduleService.deleteSchedule(verifyUsedScheduler[0])
      // if (!scheduleData.data.error) {
      message.destroy();
      message.success("Item cancelado com sucesso.", 3);
      props.fetchDataSource("item");
      setSpinning(false);
      // }
    }
    else {
      message.destroy();
      message.success("Item cancelado com sucesso.", 3);
      props.fetchDataSource("item");
      setSpinning(false);
    }
    // }
    // else {
    //   message.destroy();
    //   message.error("Ocorreu um erro ao cancelar o item.", 3);
    //   setSpinning(false);
    // }
    // }
  }

  const validateFields = (values) => {
    // if (!addrId) {
    //   message.error('Campo de endereço não preenchido.', 3);
    //   return false;
    // }
    if (!projectType && !props.data.projectType) {
      message.error('Campo tipo de projeto não preenchido.', 3);
      return false;
    }
    if (!model && !props.data.model) {
      message.error('Campo modelo não preenchido.', 3);
      return false;
    }

    if (!startDate && !props.data.startDate) {
      message.error('Campo data inicial não preenchido.', 3);
      return false;
    }
    if (!endDate && !props.data.endDate) {
      message.error('Campo data final não preenchido.', 3);
      return false;
    }
    if (!limitNotes && !props.data.limitNotes) {
      message.error('Campo limitar apontamento não preenchido.', 3);
      return false;
    }
    return true;
  }

  const validateFieldsItem = (values) => {
    if (!values.description) {
      message.error('Campo descrição não preenchido.', 3);
      return false;
    }
    if (!values.hours) {
      message.error('Campo horas não preenchido.', 3);
      return false;
    }
    if (!values.unitPrice) {
      message.error('Campo valor unitário preenchido.', 3);
      return false;
    }
    if (!values.unitTotal) {
      message.error('Campo valor total preenchido.', 3);
      return false;
    }
    if (!values.date) {
      message.error('Campo data de faturamento não preenchido.', 3);
      return false;
    }
    return true;
  }

  const dateFormat = 'YYYY-MM-DD';

  const ClosedModal = () => {
    // setAddresses([]);
    props.onClosed()
  }

  const CloseModalDocument = () => {
    setModalDoc(false)
    setSpinning(false)
    props.fetchDataSource("documents")
  }

  const OpenModalDocument = () => {
    setSpinning(true);
    setModalDoc(true);
  }

  // console.log('StartDate em cima props', props.data.startDate)

  return (
    <Modal
      title={"Editar Projeto | " + props.projectData.code + " - " + props.projectData.title ? props.projectData.title : props.projectData.description}
      destroyOnClose={() => ClosedModal()}
      width={"70%"}
      footer={null}
      visible={props.visible}
      onOk={props.onClosed}
      onCancel={props.onClosed} >

      <Spin spinning={spinning} delay={500}>
        <Form
          style={{ width: "100%" }}
          onFinish={onFinishEdit}>
          <Row style={{ borderBottom: "1px solid gray" }}>
            <div style={{ width: "100%" }}>
              <Input.Group compact style={{ margin: "1%" }}>
                <div style={{ width: "40%", paddingRight: "1%" }}>
                  <label>Título:</label>
                  {/* {props.data.title ? props.data.title : props.data.description} */
                    props.data.title}
                </div>
                <div style={{ width: "40%" }}>
                  <label>Cliente:</label>
                  {props.data.clientName}
                </div>

                <div style={{ width: "20%" }}>
                  <center>
                    <label style={{ paddingTop: "10%" }}> Status: {props.projectData.status}
                    </label>
                  </center>

                </div>
              </Input.Group>
            </div>
          </Row>
          <Row style={{ padding: "10px 0px 5px 0px" }}>
            <Tabs type="card" style={{ width: "100%" }} onTabClick={key => key == 2 ? setButtonDoc(true) : setButtonDoc(false)} >
              <TabPane tab="Dados Gerais" key="1">
                <Input.Group compact style={{ margin: "0px 1%" }}>
                  <div style={{ width: "15%", paddingRight: "1%" }}>
                    <label>Tipo de projeto:</label>
                    <Form.Item>
                      <Select options={options} defaultValue={[props.data.projectType]} onChange={(value) => setprojectType(value)} placeholder="Selecione" />
                    </Form.Item>
                  </div>
                  <div style={{ width: "15.8%" }}>
                    <label>Modelo:</label>
                    <Form.Item>
                      <Select options={models} defaultValue={[props.data.model] == 1 ? 'Time Material' : [props.data.model] == 2 ? 'TurnKey' : [props.data.model] == 3 ? 'Banco de Horas' : ''} onChange={(value) => setModel(value)} placeholder="Selecione" />
                    </Form.Item>
                  </div>
                  <div style={{ width: "19%", padding: "0px 1%" }}>
                    <label>Início Previsto:</label>
                    <Form.Item>
                      {/* <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder="Selecione uma data" defaultValue={startDate || props.data.startDate && props.data.startDate !== 'Invalid date' ? moment(props.data.startDate, 'DD/MM/YYYY') : null} onChange={(value) => setStartDate(value)} /> */}
                      <DatePicker
                        style={{ width: "100%" }}
                        format="DD/MM/YYYY"
                        placeholder="Selecione uma data"
                        defaultValue={moment(props.data.startDate, 'DD/MM/YYYY')}
                        onChange={(value) => setStartDate(value)}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ width: "17%" }}>
                    <label>Término Previsto:</label>
                    <Form.Item>
                      {/* <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder="Selecione uma data" defaultValue={endDate || props.data.endDate && props.data.endDate !== 'Invalid date' ? moment(props.data.endDate, 'DD/MM/YYYY') : null} onChange={(value) => setEndDate(value)} /> */}
                      <DatePicker
                        style={{ width: "100%" }}
                        format="DD/MM/YYYY"
                        placeholder="Selecione uma data"
                        defaultValue={moment(props.data.endDate, 'DD/MM/YYYY')}
                        onChange={(value) => setEndDate(value)}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ width: "16%", padding: "0px 1%" }}>
                    <label>Horas Previstas:</label>
                    <Form.Item name="budgetedHours" initialValue={props.data.totalTimeAmount}>
                      <InputNumber style={{ width: "100%" }} disabled={true} onChange={(value) => setBudgetedHours(value)} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "16%" }}>
                    <label>Aporte de Horas:</label>
                    <Form.Item>
                      <InputNumber style={{ width: "100%" }} disabled={true} defaultValue={props.data.timeChange} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "15%", paddingRight: "1%" }}>
                    <label>Custo do projeto:</label>
                    <Form.Item name="projectValue" initialValue={props.data.value}>
                      <InputNumber prefix="R$" style={{ width: "100%" }} />
                      {/* <MaskedInput mask="R$ ###.###.###,##" /> */}
                    </Form.Item>
                  </div>
                  <div style={{ width: "22%", paddingRight: "1%" }}>
                    <label>Workflow:</label>
                    <Form.Item>
                      <Select disabled={props.data.workflowPermission} options={props.data.dropdown} defaultValue={[props.data.workflow]} onChange={(value) => setWorkflow(value)} placeholder="Selecione" />
                    </Form.Item>
                  </div>
                  <div style={{ width: "22%", paddingRight: "1%" }}>
                    <label>Workflow Coord:</label>
                    <Form.Item>
                      <Select disabled={props.data.workflowPermission} options={props.data.dropdown} defaultValue={[props.data.workflow1]} onChange={(value) => setWorkflow1(value)} placeholder="Selecione" />
                    </Form.Item>
                  </div>
                  <div style={{ width: "22%", paddingRight: "1%" }}>
                    <label>Workflow Aporte:</label>
                    <Form.Item>
                      {console.log(props.data)}
                      <Select disabled={true} /*options={props.data.dropdown}*/ defaultValue={[props.data.workflow2]} onChange={(value) => setWorkflow2(value)} placeholder="Selecione" />
                    </Form.Item>
                  </div>
                  <div style={{ width: "18%" }}>
                    <label>Limitar apontamentos:</label>
                    <Select style={{ width: "100%" }} options={notes} defaultValue={[props.data.limitNotes]} onChange={e => setLimitNotes(e)} />
                  </div>
                </Input.Group>
                <div style={{ width: "100%", padding: "0px 1%" }}>
                  <label>Descrição:</label>
                  <Form.Item name="description" style={{ width: "100%", minWidth: "100%" }} initialValue={props.data.description}>
                    <TextArea style={{ minHeight: "100px", width: "100%" }} />
                  </Form.Item>
                </div>
              </TabPane>
              {/* <TabPane tab="Dados Cliente" key="2">
                <Input.Group compact style={{ margin: "0px 1%" }}>
                  <div style={{ width: "75%", paddingRight: "1%" }}>
                    <label>Fantasia:</label>
                    <Form.Item initialValue={props.data.fantasyName}>
                      <Input disabled={true} value={props.data.fantasyName || addresses.fantasyName || ''} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "25%" }}>
                    <label>Endereço:</label>
                    <Select placeholder={"Selecione"} style={{ width: "80%" }} defaultValue={props.data.CustomerAddress} options={props.unity} onChange={(value) => getAdressDetails(value)} />
                  </div>
                </Input.Group>
                <Input.Group compact style={{ margin: "0px 1%" }}>
                  <div style={{ width: "30%" }}>
                    <label>Cnpj:</label>
                    <Form.Item initialValue={props.data.CNPJ}>
                      <Input disabled={true} value={props.data.CNPJ || addresses.cnpj || ''} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "20%", padding: "0px 1%" }}>
                    <label>Cep:</label>
                    <Form.Item initialValue={props.data.CEP}>
                      <Input disabled={true} value={props.data.CEP || addresses.cep || ''} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "32%" }}>
                    <label>Logradouro:</label>
                    <Form.Item initialValue={props.data.street}>
                      <Input disabled={true} value={props.data.street || addresses.street || ''} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "15%", padding: "0px 1%" }}>
                    <label>Número:</label>
                    <Form.Item initialValue={props.data.number}>
                      <Input disabled={true} value={props.data.number || addresses.number || ''} />
                    </Form.Item>
                  </div>
                </Input.Group>
                <Input.Group compact style={{ margin: "0px 1%" }} >
                  <div style={{ width: "18%" }}>
                    <label>Complemento:</label>
                    <Form.Item initialValue={props.data.complement}>
                      <Input defaultValue={props.data.complement}/>
                    </Form.Item>
                  </div>
                  <div style={{ width: "40%", padding: "0px 1%" }}>
                    <label>Cidade:</label>
                    <Form.Item initialValue={props.data.city}>
                      <Input disabled={true} value={props.data.city || addresses.city || ''} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "30%", paddingRight: "1%" }}>
                    <label>Estado:</label>
                    <Form.Item initialValue={props.data.state}>
                      <Input disabled={true} value={props.data.state || addresses.state || ''} />
                    </Form.Item>
                  </div>
                </Input.Group>
                <Input.Group compact style={{ margin: "0px 1%" }}>
                  <div style={{ width: "10%" }}>
                    <label>Km:</label>
                    <Form.Item name="KM" initialValue={props.data.KM}>
                      <InputNumber defaultValue={props.data.KM}/>
                    </Form.Item>
                  </div>
                  <div style={{ width: "40%", paddingLeft: "2%" }}>
                    <label>Referência:</label>
                    <Form.Item name="reference" initialValue={props.data.reference}>
                      <Input defaultValue={props.data.reference} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "20%", paddingLeft: "2%" }}>
                    <label>Pedágio:</label>
                    <Radio.Group value={toll} onChange={e => setToll(e.target.value)}>
                      <Radio value={1}>SIM</Radio>
                      <Radio value={2}>NÃO</Radio>
                    </Radio.Group>
                  </div>
                </Input.Group>
              </TabPane>
              <TabPane tab="Recebimento" key="3">
                <Row style={{ paddingBottom: "2%" }}>
                  <Form style={{ width: "100%" }} onFinish={insertItemProject}>
                    <Row>
                      <div style={{ width: "40%", paddingRight: "1%", }}>
                        <label>Descrição:</label>
                        <Form.Item name="description">
                          <Input />
                        </Form.Item>
                      </div>
                      <div style={{ width: "10%", paddingRight: "1%" }}>
                        <label>Qnt. de hora:</label>
                        <Form.Item name="hours">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </div>
                      <div style={{ width: "10%", paddingRight: "1%" }}>
                        <label>Valor unitário:</label>
                        <Form.Item name="unitPrice">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </div>
                      <div style={{ width: "10%", paddingRight: "1%" }}>
                        <label>Valor total:</label>
                        <Form.Item name="unitTotal">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </div>
                      <div style={{ width: "25%", paddingRight: "1%" }}>
                        <label>Data de faturamento:</label>
                        <Form.Item name="date">
                          <DatePicker format="DD/MM/YYYY" placeholder="Selecione uma data" style={{ width: "100%" }} />
                        </Form.Item>
                      </div>
                      <div style={{ width: "5%", paddingTop: "35px" }}>
                        <Button htmlType="submit">
                          <PlusOutlined />
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </Row>
                <Row>
                  <Table columns={itens} dataSource={props.listItem} pagination={true} style={{ width: "100%" }} />
                </Row>
              </TabPane>
              <TabPane tab="Pagamento" key="4">
                <Row>
                  <Table columns={itens} dataSource={props.listItem} pagination={true} style={{ width: "100%" }} />
                </Row>
              </TabPane> */}
              <TabPane tab="Documentos" key="2">
                <Table columns={documents} dataSource={props.document} pagination={true} />
              </TabPane>
            </Tabs>
          </Row>
          <Row>
            <div style={{ float: "right", width: "100%" }}>
              <div style={{ float: "right" }}>
                <Button type="primary" htmlType="submit">
                  Salvar
              </Button>
              </div>
              <div style={{ padding: "0 1%", float: "right" }}>
                <Button style={{ color: "black" }} onClick={() => ClosedModal()}>
                  Fechar
              </Button>
              </div>
              <div style={{ float: "right" }}>
                {buttonDoc ?
                  <Button type="primary" onClick={() => OpenModalDocument()}>
                    + documento
                  </Button>
                  : null
                }
                <ModalDocumentEletronic
                  visible={modalDoc}
                  projectId={props.projectData.id}
                  closedModal={() => CloseModalDocument()} />
              </div>
            </div>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default ProjectEditModal;