import React, { useState } from "react";
import {
  Row,
  Table,
  Modal,
  Input,
  Spin,
  message,
  Checkbox,
  Form,
  Button,
  Popconfirm,
  Tooltip,
  InputNumber,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ProjectsService } from "../../services/projects";
import { workflowService } from "../../services/workflow";
import * as _ from 'lodash';

const ProjectChangeRequestModal = (props) => {

  const [spinning, setSpinning] = useState(false);
  const [visible, setVisible] = useState(true);
  const [checkbox, setCheckbox] = useState([]);

  const changeRequest = [
    {
      title: 'Motivo',
      dataIndex: 'Reason',
      key: 'Reason',
      align: 'center',
      width: 110,
    },
    {
      title: 'Impacto',
      dataIndex: 'Impact',
      key: 'Impact',
      align: 'center',
      width: 110,
    },
    {
      title: 'Qnt de horas',
      dataIndex: 'TimeAmount',
      key: 'TimeAmount',
      align: 'center',
      width: 110,
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      align: 'center',
      width: 110,
      render: (text, record) => <span>{record.Status == "N" ? "Negado" : record.Status == "Y" ? "Aprovado" : "Aguardando aprovação"}</span>
    },
    {
      title: 'Ação',
      key: 'Action',
      width: 50,
      align: 'center',
      render: (text, record) => (
        <Button.Group >
          {record.Status == "Y" ? null
            :
            <div style={{ padding: "7px" }}>
              <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                onConfirm={() => cancelExpense(record.Id)} okText="Sim" cancelText="Não">
                <Tooltip>
                  <a><DeleteOutlined /></a>
                </Tooltip>
              </Popconfirm>
            </div>
          }

        </Button.Group>
      ),
    }
  ];

  const onFinishChangeRequest = async (values) => {
    if (validateFields(values)) {
      setSpinning(true);
      let data = {};
      let workflow = [];
      if (props.projectData.WorkflowProject) {
        data = {
          ProjectId: props.projectData.id,
          Reason: values.reason,
          Impact: checkbox.join(', '),
          // alterado parseInt para parseFloat
          TimeAmount: parseFloat(values.quantityHours),
          Status: 'P'
        }
      }
      else {
        setSpinning(false);
        message.destroy();
        message.error('Ocorreu um erro ao criar uma solicitação de mudança, projeto sem workflow.', 3);
        return false
      }

      message.loading('Criando solicitação de mudança ...', 100);
      const r = await ProjectsService.postChangeRequest(data);
      if (r.data.error) {
        message.destroy();
        message.error('Ocorreu um erro ao criar uma solicitação de mudança.', 3);
      }
      else {
        const id = localStorage.getItem('userId');

        if (props.projectData.WorkflowProject) {
          const dataWorkflow = await workflowService.getByIdData(props.projectData.WorkflowProject);
          await workflowService.getApprovals(props.projectData.WorkflowProject).then((workflowApprovals) => {
            if (workflowApprovals.data.data.data) {
              //for (const flow of workflowApprovals.data.data.data) {

                const flow = workflowApprovals.data.data.data
                console.log(flow);

                workflow.push({
                  IdType: parseInt(r.data.data[0].Id),
                  Value: r.data.data[0].Value,
                  Type: "CR",
                  Status: "P",
                  WorkflowApprovalsId: parseInt(dataWorkflow.data.data.data[0].Id),
                  UserId: parseInt(id),
                  ProjectId: props.projectData.id,
                  ProjectName: props.projectData.title,
                  ResponsibleId: _.first(flow.UserId)
                });
             // }
             console.log(workflow);
            }
            else {
              message.destroy();
              message.error('Nenhum aprovador no workflow.', 3);
            }
          });
          await workflowService.insert(workflow).then((r) => {
            if (!r.data.error) {
              message.destroy();
              // console.log('first if values',values)
              message.success('Solicitação de mudança criada com sucesso', 3);
              props.onFecthing(props.projectData.id);
            }
            else {
              message.destroy();
              ProjectsService.deleteChangeProject(r.data.data[0].Id);
              message.error('Ocorreu um erro ao criar uma solicitação de mudança.', 3);
            }
          });
          setSpinning(false);
        }
        else {
          message.destroy();
          // console.log('last if values',values)
          message.success('Solicitação de mudança criada com sucesso.', 3);
          props.onFecthing(props.projectData.id);
          setSpinning(false);
        }
      }
    }
  }

  const validateFields = (values) => {
    if (!values.reason) {
      message.error('Campo motivo não preenchido.', 3);
      setVisible(true);
      return false;
    }
    if (!values.quantityHours) {
      message.error('Campo horas não preenchido.', 3);
      setVisible(true);
      return false;
    }
    if (values.quantityHours < 0) {
      message.error('Campo horas não pode ser menor que 0.', 3);
      setVisible(true);
      return false;
    }
    if (!values.impact) {
      message.error('Campo impacto não preenchido.', 3);
      setVisible(true);
      return false;
    }
    return true;
  }

  const cancelExpense = (id) => {
    props.onCancel(id);
  }

  function setCheck(value) {
    setCheckbox(value);
  }

  const checkOptions = ['Custo', 'Prazo', 'Qualidade', 'Escopo'];

  return (
    <Modal
      title={"Solicitação de Mudança | " + props.projectData.code + " - " + props.projectData.title}
      visible={props.visible}
      destroyOnClose={props.onClosed}
      width={props.width}
      footer={null}
      onCancel={props.onClosed}
      cancelButtonProps={{ style: { display: 'none' } }}>

      <Spin spinning={spinning} delay={500}>
        <Form style={{ width: "100%" }}>
          <Row>
            <Table columns={changeRequest} dataSource={props.changerequest} style={{ height: "100%" }} pagination={true} style={{ width: "100%", paddingTop: "10px" }} />
          </Row>
          <Row style={{ padding: "5px 0px", paddingBottom: "10px", width: "100%" }}>
            <div style={{ float: "right", width: "100%" }}>
              <div style={{ float: "right" }}>
                <Button style={{ backgroundColor: "#002E5B", color: "white" }} onClick={() => setVisible(!visible)}>+ Adicionar</Button>
              </div>
            </div>
            <Form style={{ width: "100%" }} onFinish={onFinishChangeRequest}>
              <Input.Group compact style={{ padding: "20px 0", width: "100%" }}>
                <div style={{ width: "25%" }}>
                  <Form.Item hidden={visible}><p>Motivo:</p></Form.Item>
                  <Form.Item hidden={visible} name="reason"><Input /></Form.Item>
                </div>
                <div style={{ width: "25%", padding: "0px 1%" }}>
                  <Form.Item hidden={visible}><p>Quantidade de Hora:</p></Form.Item>
                  <Form.Item hidden={visible} name="quantityHours"><InputNumber style={{ width: "100%" }} /></Form.Item>
                </div>
                <div style={{ width: "50%", padding: "0px 1%" }}>
                  <Form.Item hidden={visible}><p>Impacto:</p></Form.Item>
                  <Form.Item hidden={visible} name="impact">
                    <Checkbox.Group options={checkOptions} onChange={setCheck} />
                  </Form.Item>
                </div>
              </Input.Group>

              <Row style={{ width: "100%" }}>
                <div style={{ float: "right", width: "100%" }}>
                  <div style={{ float: "right" }}>
                    <Button type="primary" htmlType="submit" onClick={() => {visible ? setVisible(visible) : setVisible(!visible); props.onClosed()}}>Salvar</Button>
                  </div>
                  <div style={{ float: "right", paddingRight: "1%" }}>
                    <Button onClick={() => props.onClosed()}>Fechar</Button>
                  </div>
                </div>
              </Row>
            </Form>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default ProjectChangeRequestModal;