import { request } from '../http';

const permissionsService = {
  getProfiles: () => {
    return request.get('/permissions/profiles');
  },
  getPermissions: () => {
    return request.get('/permissions');
  },
  getProfileDetails: (id) => {
    return request.get('/permissions/profile/' + id);
  },
  updateProfile: (data) => {
    return request.put('/permissions/profile', data);
  },
  removeProfile: (id) => {
    return request.delete('/permissions/profile/' + id);
  },
  createProfile: (data) => {
    return request.post('/permissions/profile', data);
  }
}

export {
  permissionsService
}