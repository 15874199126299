import { Cookies } from 'react-cookie';
const cookies = new Cookies();

const authenticationReducer = (state, action) => {
  switch (action.type) {
    case 'login':
      cookies.set('authentication', action.value);
      return {
        ...state,
        ...action.value
      };
    case 'logout':
      cookies.remove('authentication');
      return null;
    default:
      return state;
  }
};

export default authenticationReducer;