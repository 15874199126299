import React from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Spin,
  Button,
  DatePicker,
  Checkbox,
  Drawer,
  message,
  Select
} from 'antd';
import { FilterFilled } from '@ant-design/icons';
import moment from "moment";
import * as _ from "lodash";
import { PaymentService } from "../../services/payments";
import { ProjectsService } from "../../services/projects";
import { tasksService } from "../../services/tasks";
import { cashbackService } from "../../services/cashback";
import { LogService } from "../../services/process-logs";
import { itemsService } from "../../services/items";
import { usersService } from "../../services/users";
moment.locale('pt-BR');

const { Search } = Input
class ProfessionalPayment extends React.Component {


  state = {
    dataSource: [],
    dataSourceBackup: [],
    dataSourceDate: [],
    checkedList: [],
    OBPLDataSource: [],
    fetching: false,
    obpl: 0,
    dateCompet: "",
    dimension1DataSource: [],
    dimension2DataSource: [],
    dimension3DataSource: [],
    dimension4DataSource: [],
    dimension5DataSource: [],
    dimension1Selected: [],
    dimension2Selected: [],
    dimension3Selected: [],
    dimension4Selected: [],
    dimension5Selected: [],
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ fetching: true });
    const professionalData = await PaymentService.getProfessionals();
    const professional = professionalData.data;

    const BP = await itemsService.getBusinessPlace();
    const BusinessPlace = BP.data.data.items;
    let options = BusinessPlace.map(find => {
      return { value: find.BPLId, label: find.BPLName }
    })

    const dimensionsData = await PaymentService.getDimensions();
    const dimensions = dimensionsData.data;
    if (!dimensions.error) {
      const dim = dimensions.data;

      for (let index = 1; index < 6; index++) {
        let dimension = dim.map(find => { return find.DimCode == index ? { label: find.PrcName, value: find.PrcCode } : null }).filter(find => find != null);
        if (index == 1) {
          this.setState({ dimension1DataSource: dimension });
        }
        if (index == 2) {
          this.setState({ dimension2DataSource: dimension });
        }
        if (index == 3) {
          this.setState({ dimension3DataSource: dimension });
        }
        if (index == 4) {
          this.setState({ dimension4DataSource: dimension });
        }
        if (index == 5) {
          this.setState({ dimension5DataSource: dimension });
        }
      }
    }

    if (!professional.error) {
      const data = await this.setId(professional.data)
      this.setState({ dataSource: data, dataSourceBackup: data, fetching: false, OBPLDataSource: options });
    }
  }

  setId = async (data) => {
    let key = 0;
    let dataSource = []
    for (let item of data) {
      dataSource.push(item = { ...item, Key: key++ })
    }
    return dataSource
  }

  searchTerm = (value) => {
    const date = this.state.dataSourceDate;
    const backup = this.state.dataSourceBackup
    if (value) {
      this.setState({ fetching: true });
      const filtrado = this.state.dataSource.filter(item => {
        return item.Name.toLowerCase().includes(value.toLowerCase())
      });
      this.setState({ dataSource: filtrado, fetching: false });
    }
    else {
      if (!date[0]) {
        this.setState({ dataSource: backup });
      }
      else {
        this.setState({ dataSource: date });
      }
    }
  };

  //date filter
  onChangeDate = (value, type) => {
    this.setState({ fetching: true });
    if (type == "max") {
      //data inicial
      if (!value) {
        if (this.state.dateMin) {
          const filtrado = this.state.dataSourceBackup.filter(x => {
            return moment(x.Date) <= moment(this.state.dateMin) ? x : null
          });
          this.setState({ dataSource: filtrado, fetching: false, dateMax: "", dataSourceDate: filtrado });

        }
        else {
          this.setState({ fetching: false, dateMax: "", dataSourceDate: this.state.dataSourceBackup });
          this.fetch();
        }
      }
      else {
        const filtrado = this.state.dataSourceBackup.filter(x => {
          return moment(x.Date) >= moment(value) ? x : null
        });
        this.setState({ dataSource: filtrado, fetching: false, dateMax: value, dataSourceDate: filtrado });
      }
    }
    else {
      //data final
      if (!value) {
        if (this.state.dateMax) {

          const filtrado = this.state.dataSourceBackup.filter(x => {
            return moment(x.Date) >= moment(this.state.dateMax) ? x : null
          });
          this.setState({ dataSource: filtrado, fetching: false, dateMin: "", dataSourceDate: filtrado });
        }
        else {
          this.setState({ fetching: false, dateMin: "", dataSourceDate: this.state.dataSourceBackup });
          this.fetch();
        }
      }
      else {
        const filtrado = this.state.dataSource.filter(x => {
          return moment(x.Date) <= moment(value) ? x : null
        });
        this.setState({ dataSource: filtrado, fetching: false, dateMin: value, dataSourceDate: filtrado });
      }
    }

  }

  //checklist
  onChange = (e, key) => {
    let code = e.target.value
    let checked = e.target.checked;
    if (checked) {
      this.state.checkedList.push({ id: key, value: code });
    }
    else {
      this.state.checkedList = this.state.checkedList.filter(r => r.id != key);
    }
  };

  createLog = async (paymentSending, paymentReturn, order, users) => {
    let data;
    if (paymentReturn.error) {
      for (const item of users) {
        data = {
          ProjectName: paymentSending.Project,
          ProjectId: paymentSending.ProjectId,
          Client: paymentSending.UserId,
          OrderType: paymentSending.Type.toUpperCase(),
          Status: "ERROR",
          LogDate: moment(new Date()).format("YYYY-MM-DD"),
          ErrorDetail: (paymentReturn.error.innerMessage.slice(paymentReturn.error.innerMessage.indexOf(", ") + 1)).trim().replace(/'/g, ''),
          Item: paymentSending.Id,
          Date: moment(paymentSending.Date).format("YYYY-MM-DD"),
          Value: parseInt(paymentSending.Value.replace("R$", '').replace("/h", '')),
          Quantity: 1,
          TotalValue: parseInt(paymentSending.Value.replace("R$", '').replace("/h", '')),
          BPLID: item.Business,
          ProjectDoc: order,
          CardCode: item.CardCode,
          DateCompet: item.Date,
          ClientName: paymentSending.Name,
          Salary: item.Salary,
          SalaryUnit: item.SalaryUnit,
          Dim1: item.CostingCode,
          Dim2: item.CostingCode2,
          Dim3: item.CostingCode3,
          Dim4: item.CostingCode4,
          Dim5: item.CostingCode5
        }
        const logData = await LogService.insertLog(data);
        let log = logData.data;
        if (log.error) {
          message.destroy();
          message.error("Ocorreu um erro ao salvar o log.", 3);
        }
      }
    }
    else {
      for (const item of users) {
        data = {
          ProjectName: paymentSending.Project,
          ProjectId: paymentSending.ProjectId,
          Client: paymentSending.UserId,
          OrderType: paymentSending.Type.toUpperCase(),
          Status: "OK",
          LogDate: moment(new Date()).format("YYYY-MM-DD"),
          ErrorDetail: "",
          Item: paymentSending.Id,
          Date: moment(paymentSending.Date).format("YYYY-MM-DD"),
          Value: parseInt(paymentSending.Value.replace("R$", '').replace("/h", '')),
          Quantity: 1,
          TotalValue: parseInt(paymentSending.Value.replace("R$", '').replace("/h", '')),
          BPLID: item.Business,
          ProjectDoc: order,
          CardCode: item.CardCode,
          DateCompet: item.Date,
          ClientName: paymentSending.Name,
          Salary: item.Salary,
          SalaryUnit: item.SalaryUnit,
          Dim1: item.CostingCode,
          Dim2: item.CostingCode2,
          Dim3: item.CostingCode3,
          Dim4: item.CostingCode4,
          Dim5: item.CostingCode5
        }
        const logData = await LogService.insertLog(data);
        let log = logData.data;
        if (log.error) {
          message.destroy();
          message.error("Ocorreu um erro ao salvar o log.", 3);
        }
      }
    }
  }

  sendingProfessionalPurchase = async () => {
    if (this.validateFields()) {
      message.loading("Inserindo faturamento ...", 100);
      this.setState({ fetching: true });
      const list = this.state.checkedList;
      const data = this.state.dataSource;
      let sendPrice = 0;
      let sendTime = 0;
      let filtered = [];
      let logList = [];
      let Users = []

      for (const item of list) {
        let filteredData = data.filter(find => find.Id == item.id);
        filtered = filteredData[0];
        logList.push(filteredData[0]);

        const userData = await usersService.getUserById(filtered.UserId);
        const user = userData.data.data;
        if (filtered.Type == "Reembolso") {
          sendPrice = sendPrice + parseInt(filtered.Value.replace("R$", ''));
          let User = user.map(find => { return { SalaryUnit: find.SalaryUnit, Salary: find.Salary, Billing: find.Billing, ProjectId: filtered.ProjectId, CardCode: find.BusinessPartner, Value: parseInt(filtered.Value.replace("R$", '')), Type: filtered.Type } });
          Users.push(User[0]);
        }
        else {
          sendTime = sendTime + parseInt(filtered.Value.replace("/h", ''));
          let User2 = user.map(find => { return { SalaryUnit: find.SalaryUnit, Salary: find.Salary, Billing: find.Billing, ProjectId: filtered.ProjectId, CardCode: find.BusinessPartner, Value: parseInt(filtered.Value.replace("/h", '')), Type: filtered.Type } });
          Users.push(User2[0]);
        }
      }

      const projectsData = await ProjectsService.getProjects();
      const project = projectsData.data.data;
      let id = project.filter(find => find.Id == filtered.ProjectId);
      let prj = id[0];

      if (prj) {
        if (prj.OrderDoc) {
          let sendData = [];

          for (const item of Users) {
            if (item.Billing == "Y") {

              let Dim1 = this.state.dimension1Selected.find(find => find.Id == item.Code);
              let Dim2 = this.state.dimension2Selected.find(find => find.Id == item.Code);
              let Dim3 = this.state.dimension3Selected.find(find => find.Id == item.Code);
              let Dim4 = this.state.dimension4Selected.find(find => find.Id == item.Code);
              let Dim5 = this.state.dimension5Selected.find(find => find.Id == item.Code);

              sendData.push({
                CardCode: item.CardCode,
                Price: item.Value,
                Type: item.Type,
                Date: this.state.dateCompet,
                Business: this.state.obpl,
                ProjectId: item.ProjectId,
                Salary: item.Salary,
                SalaryUnit: item.SalaryUnit,
                CostingCode: Dim1.Value,
                CostingCode2: Dim2.Value,
                CostingCode3: Dim3.Value,
                CostingCode4: Dim4.Value,
                CostingCode5: Dim5.Value
              });
            }
          }

          if (Users.length == sendData.length) {
            const paymentData = await PaymentService.subtractProjectDebit(prj.OrderDoc, sendPrice, sendTime, sendData);
            const payment = paymentData.data;

            if (payment.error) {
              for (const item of list) {
                let filteredData = data.filter(find => find.Id == item.id);
                let filter = filteredData[0]
                if (filter.Type == "Reembolso") {
                  await cashbackService.updateBilledCashback(filter.Id, "N");
                }
                else {
                  await tasksService.updateBilledTasks(filter.Id, "N");
                }
                await this.createLog(filter, payment, prj.OrderDoc, sendData)
              }
              message.destroy();
              message.error("Ocorreu um erro ao tentar faturar.", 3);
            }
            else {
              for (const item of list) {
                let filteredData = data.filter(find => find.Id == item.id);
                let filter = filteredData[0]
                if (filter.Type == "Reembolso") {
                  await cashbackService.updateBilledCashback(filter.Id, "Y");
                }
                else {
                  await tasksService.updateBilledTasks(filter.Id, "Y");
                }
                await this.createLog(filter, payment, prj.OrderDoc, sendData)
              }
              message.destroy();
              message.success("Faturamento realizado com sucesso.", 3);
            }
            this.fetch()
          }
          else {
            message.destroy();
            message.error("Ocorreu um erro ao faturar, algum usuário está com faturamento bloqueado.", 3);
          }
        }
        else {
          message.destroy();
          message.error("Ocorreu um erro ao faturar, o projeto está sem vinculo de pedido.", 3);
        }
      }
      else {
        message.destroy();
        message.error("Ocorreu um erro ao faturar, os dados do projeto está corrompido.", 3);
      }
      this.setState({ fetching: false });
    }
  }

  validateFields = () => {
    if (!this.state.checkedList[0]) {
      message.error("Nenhum pagamento selecionado.", 3);
      return false
    }
    let differentProject = this.state.checkedList.filter(find => find.value == this.state.checkedList[0].value);
    if (differentProject.length !== this.state.checkedList.length) {
      message.error("Selecione somente um projeto.", 3);
      return false
    }
    if (!this.state.obpl) {
      message.error("Campo empresa não selecionado.", 3);
      return false
    }
    if (!this.state.dateCompet) {
      message.error("Campo data de competência não preenchido.", 3);
      return false
    }
    if (this.state.checkedList.length !== this.state.dimension1Selected.length ||
      this.state.checkedList.length !== this.state.dimension2Selected.length ||
      this.state.checkedList.length !== this.state.dimension3Selected.length ||
      this.state.checkedList.length !== this.state.dimension4Selected.length ||
      this.state.checkedList.length !== this.state.dimension5Selected.length) {
      message.error("Falta algumas dimensões para selecionar.", 3);
      return false
    }
    return true;
  }

  onSelectDimensions = (value, id, type, recordId) => {
    let dim1 = this.state.dimension1Selected;
    let dim2 = this.state.dimension2Selected;
    let dim3 = this.state.dimension3Selected;
    let dim4 = this.state.dimension4Selected;
    let dim5 = this.state.dimension5Selected;

    if (type == "Dim1") {
      let dim = dim1.findIndex(find => find.Record == recordId);
      if (dim == -1) {
        dim1.push({ Id: id, Value: value, Record: recordId });
      }
      else {
        dim1.splice(dim, 1);
        dim1.push({ Id: id, Value: value, Record: recordId });
      }
    }
    if (type == "Dim2") {
      let dim = dim2.findIndex(find => find.Record == recordId);
      if (dim == -1) {
        dim2.push({ Id: id, Value: value, Record: recordId });
      }
      else {
        dim2.splice(dim, 1);
        dim2.push({ Id: id, Value: value, Record: recordId });
      }
    }
    if (type == "Dim3") {
      let dim = dim3.findIndex(find => find.Record == recordId);
      if (dim == -1) {
        dim3.push({ Id: id, Value: value, Record: recordId });
      }
      else {
        dim3.splice(dim, 1);
        dim3.push({ Id: id, Value: value, Record: recordId });
      }
    }
    if (type == "Dim4") {
      let dim = dim4.findIndex(find => find.Record == recordId);
      if (dim == -1) {
        dim4.push({ Id: id, Value: value, Record: recordId });
      }
      else {
        dim4.splice(dim, 1);
        dim4.push({ Id: id, Value: value, Record: recordId });
      }
    }
    if (type == "Dim5") {
      let dim = dim5.findIndex(find => find.Record == recordId);
      if (dim == -1) {
        dim5.push({ Id: id, Value: value, Record: recordId });
      }
      else {
        dim5.splice(dim, 1);
        dim5.push({ Id: id, Value: value, Record: recordId });
      }
    }
  }

  render() {

    const columns = [
      {
        title: "",
        key: 'Action',
        width: "5%",
        align: 'center',
        render: (text, record) => (
          <Button.Group >
            <div style={{ padding: "7px" }}>
              <Checkbox value={record.ProjectId} onChange={(e) => this.onChange(e, record.Id)} />
            </div>
          </Button.Group>
        ),
      },
      {
        title: 'Código',
        dataIndex: 'Id',
        key: 'Id',
        align: 'center',
        width: "5%",
      },
      {
        title: 'Profissional',
        dataIndex: 'Name',
        key: 'Name',
        align: 'center',
        width: "10%",
      },
      {
        title: 'Projeto',
        dataIndex: 'Project',
        key: 'Project',
        align: 'left',
        width: "10%",
        render: (text, record) => <span>{record.ProjectId ? record.ProjectId + " - " : null}{record.Project}</span>
      },
      {
        title: 'Item',
        dataIndex: 'Type',
        key: 'Type',
        align: 'center',
        width: "10s%",
      },
      {
        title: 'Data',
        key: 'date',
        dataIndex: 'date',
        align: 'center',
        width: "5%",
        render: (text, record) => <span>{record.Date ? moment(record.Date).format("DD/MM/YYYY") : null}</span>
      },
      {
        title: 'Horas / Valor',
        key: 'Value',
        dataIndex: 'Value',
        align: 'center',
        width: "5%",
      },
      {
        title: "Dimensão 1",
        dataIndex: "Dimension1",
        key: "Dimension1",
        align: "center",
        width: "10%",
        render: (text, record) => <span>{<Select options={this.state.dimension1DataSource} style={{ width: "100%" }} onSelect={(e) => this.onSelectDimensions(e, record.Code, "Dim1", record.Id)} />}</span>
      },
      {
        title: "Dimensão 2",
        dataIndex: "Dimension2",
        key: "Dimension2",
        align: "center",
        width: "10%",
        render: (text, record) => <span>{<Select options={this.state.dimension2DataSource} style={{ width: "100%" }} onSelect={(e) => this.onSelectDimensions(e, record.Code, "Dim2", record.Key)} />}</span>
      },
      {
        title: "Dimensão 3",
        dataIndex: "Dimension3",
        key: "Dimension3",
        align: "center",
        width: "10%",
        render: (text, record) => <span>{<Select options={this.state.dimension3DataSource} style={{ width: "100%" }} onSelect={(e) => this.onSelectDimensions(e, record.Code, "Dim3", record.Key)} />}</span>
      },
      {
        title: "Dimensão 4",
        dataIndex: "Dimension4",
        key: "Dimension4",
        align: "center",
        width: "10%",
        render: (text, record) => <span>{<Select options={this.state.dimension4DataSource} style={{ width: "100%" }} onSelect={(e) => this.onSelectDimensions(e, record.Code, "Dim4", record.Key)} />}</span>
      },
      {
        title: "Dimensão 5",
        dataIndex: "Dimension5",
        key: "Dimension5",
        align: "center",
        width: "10%",
        render: (text, record) => <span>{<Select options={this.state.dimension5DataSource} style={{ width: "100%" }} onSelect={(e) => this.onSelectDimensions(e, record.Code, "Dim5", record.Key)} />}</span>
      },
    ];

    const fetching = this.state.fetching;
    const business = this.state.OBPLDataSource

    return (
      <Spin spinning={fetching} delay={0}>

        <Row style={{ paddingBottom: "15px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "left", fontSize: "30px" }}>Pagamento para profissionais</b>
          </div>
          <div style={{ width: "35%" }}>
            <Search placeholder="Pesquisa por profissional" onSearch={value => this.searchTerm(value)} />
          </div>
          <div style={{ width: "5%", float: "right" }}>
            <center>
              <FilterFilled onClick={() => this.setState({ drawer: true })} style={{ fontSize: "20px", padding: "5px" }} />
            </center>
          </div>
        </Row>
        <Row style={{ paddingBottom: "10px" }}>
          <Select style={{ width: "30%" }} options={business} placeholder="Selecione uma empresa" onSelect={(e) => this.setState({ obpl: e })} />
          <div style={{ paddingLeft: "5px", width: "20%" }}>
            <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder="Data de competência" onSelect={(e) => this.setState({ dateCompet: e })} />
          </div>
        </Row>
        <Drawer
          title="Filtros"
          placement="right"
          closable={false}
          onClose={() => this.setState({ drawer: false })}
          visible={this.state.drawer} >
          <div style={{ paddingBottom: "3%" }}>
            <p>Selecione um periodo:</p>
            <div style={{ paddingBottom: "3%" }}>
              <DatePicker format="DD/MM/YYYY" placeholder="Data inicial" style={{ width: "100%" }} onChange={(value) => this.onChangeDate(value, "max")} />
            </div>
            <DatePicker format="DD/MM/YYYY" placeholder="Data final" style={{ width: "100%" }} onChange={(value) => this.onChangeDate(value, "min")} />
          </div>
        </Drawer>
        <Row style={{ width: "100%" }}>
          <Col style={{ border: "1px solid #e8e8e8" }} span={24} >
            <Table columns={columns} dataSource={this.state.dataSource} style={{ height: "100%" }} pagination={true} scroll={{ x: 2000 }} />
          </Col>
        </Row>
        <div style={{ float: "right", paddingTop: "1%" }}>
          <Button type="primary" onClick={() => this.sendingProfessionalPurchase()} >Gerar</Button>
        </div>
      </Spin>
    )
  }
}

export default ProfessionalPayment;