import React from "react";
import { Table, Row, Form, message, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import moment from "moment";
import { FloatingButton } from '../../components/floating-button';
import ProjectModelService from '../../services/project-model'
import ModalProjectModel from '../../components/project-model-modal';
moment.locale('pt-BR');

class ProjectModel extends React.Component {

    state = {
        fetching: false,
        dataSource: undefined,
        modalVisible: false,
        dataTable: [],
        currentRecord: []
    }



    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {

        await ProjectModelService.get().then((res) => {
            this.setState({ dataTable: res.data })
        }).catch(() => {

        });
    }

    del = record => {
        (async () => {
            message.loading("Excluindo");
            await ProjectModelService.delete(record.key).then((res) => {
                message.success("Excluido");
                this.fetch();
            });
            message.destroy();
        })();

    }

    edit = record => {

        this.setState({ currentRecord: record, modalVisible: true });

    }

    addNew = () => {
        this.setState({ currentRecord: [], modalVisible: true });
    }

    render() {

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '25%',
                editable: false,
            },
            {
                title: 'Descrição',
                dataIndex: 'description',
                width: '40%',
                editable: true,
            },
            {
                title: '',
                dataIndex: '',
                key: 'X',
                render: (_, record) =>
                    <Tooltip placement={"top"} title={"Editar"}>
                        <a>
                            <EditOutlined onClick={() => this.edit(record)} />
                        </a>
                    </Tooltip>,
            },
            {
                title: '',
                dataIndex: '',
                key: 'Y',
                render: (_, record) =>
                    <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                        onConfirm={() => this.del(record)} okText="Sim" cancelText="Não">
                        <Tooltip placement={"top"} title={"Excluir"}>
                            <a>
                                <CloseOutlined />
                            </a>
                        </Tooltip>
                    </Popconfirm>,
            },
        ];


        return (
            <Form /* style={{ minHeight: '100vh' }} */>
                <ModalProjectModel visible={this.state.modalVisible} data={this.state.currentRecord} onOk={() => this.setState({ modalVisible: false })} onCancel={() => this.setState({ modalVisible: false })} />
                <Row style={{ paddingBottom: "25px" }}>
                    <div style={{ width: "60%" }}>
                        <b style={{ textAlign: "left", fontSize: "30px" }}>Modelos de Projeto</b>
                    </div>
                </Row>
                <Table columns={columns} dataSource={this.state.dataTable} />
                <FloatingButton icon="+" onClick={this.addNew} />
            </Form >
        )
    }
}

export default ProjectModel;