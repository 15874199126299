import { request } from '../http';

const ProjectModelService = {
    get: () => {
        return request.get('/project-model');
    },
    patch: (data, id) => {
        return request.patch('/project-model/' + id, data);
    },
    delete: (id) => {
        return request.delete('/project-model/' + id);
    },
    post: (body) => {
        return request.post('/project-model', body);
    }
}

export default
    ProjectModelService
