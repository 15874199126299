import React, { useState } from "react";
import { Form, Modal, Input, Upload, Button, message, DatePicker } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import moment from "moment";
import { warningsService } from '../../services/warnings';
import conf from '../../environment.json';

const { TextArea } = Input;
const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const WarningAddModal = (props) => {

    const [path, setPath] = useState();

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const onFinish = async (values) => {
        if (validateFields(values)) {
            message.loading('Criando Alerta ...', 100);

            const data = {
                Title: values.data.Title,
                Description: values.data.Description,
                FileUrl: path ? path : '',
                StartDate: moment(values.data.Date[0]).format("YYYY-MM-DD"),
                EndDate: moment(values.data.Date[1]).format("YYYY-MM-DD"),
            }
            await warningsService.insertWarning(data).then((r) => {
                if (r.data.error) {
                    message.destroy();
                    props.onClose();
                    message.error('Erro ao criar alerta.', 3);
                }
                else {
                    props.onClose();
                    message.destroy();
                    message.success('Alerta criado com sucesso.', 3);
                }
            });
        }
    };

    const validateFields = (values) => {
        if (!values.data.Title) {
            message.error('Campo título não preenchido.', 3);
            return false;
        }
        if (!values.data.Description) {
            message.error('Campo descrição não preenchido.', 3);
            return false;
        }
        if (!values.data.Date) {
            message.error('Campo data não preenchido.', 3);
            return false;
        }
        return true;
    }

    const config = {
        name: 'file',
        multiple: false,
        action: conf['API_URL'] + '/warnings/file',
        //action: `${config['API_URL']}/warnings/file`,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                //setPath(info.fileList[0].response.path)
                setPath(info.file.name)
            } else if (status === 'error') {
            }
        },
    };

    return (
        <Modal
            title={props.title}
            visible={props.visible}
            footer={null}
            onCancel={props.onCancel}
            destroyOnClose={props.onCancel}>
            <Form name="nest-messages" onFinish={onFinish} style={{ width: "100%" }}>
                <p>Titulo:</p>
                <Form.Item name={['data', 'Title']} style={{ width: "100%" }}>
                    <Input placeholder="ex: Todos convocados" style={{ float: "left" }} />
                </Form.Item>
                <p>Descrição:</p>
                <Form.Item name={['data', 'Description']} style={{ width: "100%" }}>
                    <TextArea placeholder="ex: reunião" autoSize={{ minRows: 5, maxRows: 7 }}></TextArea>
                </Form.Item>
                <p>Data:</p>
                <Form.Item name={['data', 'Date']} style={{ width: "100%" }}>
                    <RangePicker format="DD/MM/YYYY" showToday={true} style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item>
                    <Dragger {...config}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Clique ou Arraste o arquivo para upload</p>
                    </Dragger>
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 20 }}>
                    <Button type="primary" htmlType="submit">
                        Criar
                </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default WarningAddModal;