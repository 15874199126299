import React, { useState } from "react";
import {
  Row,
  Table,
  Modal,
  Button,
  Col,
} from "antd";
import * as _ from 'lodash';
import UserTimeGoalModal from '../../components/user-time-goals-modal';

const UserTimeGoalAddModal = (props) => {

  const [modalTimeGoals, setModalTimeGoals] = useState(false);

  const userTimeGoalsColumns = [
    // {
    //   title: "Usuário",
    //   dataIndex: "UserId",
    //   key: "UserId",
    //   width: "3%",
    //   align: "center",
    //   render: (text, record) => <span>{record.UserId}</span>
    // },
    // {
    //   title: "Nome",
    //   dataIndex: "Name",
    //   key: "Name",
    //   width: "8%",
    //   align: "center",
    //   render: (text, record) => <span>{record.Name}</span>
    // },
    {
      title: "Ano",
      dataIndex: "Year",
      key: "Year",
      width: "5%",
      align: "center",
    },
    {
      title: "Janeiro",
      dataIndex: "Jan",
      key: "Jan",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Jan + " Hr"}</span>
    },
    {
      title: "Fevereiro",
      dataIndex: "Feb",
      key: "Feb",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Feb + " Hr"}</span>
    },
    {
      title: "Março",
      dataIndex: "Mar",
      key: "Mar",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Mar + " Hr"}</span>
    },
    {
      title: "Abril",
      dataIndex: "Apr",
      key: "Apr",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Apr + " Hr"}</span>
    },
    {
      title: "Maio",
      dataIndex: "May",
      key: "May",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.May + " Hr"}</span>
    },
    {
      title: "Junho",
      dataIndex: "Jun",
      key: "Jun",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Jun + " Hr"}</span>
    },
    {
      title: "Julho",
      dataIndex: "Jul",
      key: "Jul",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Jul + " Hr"}</span>
    },
    {
      title: "Agosto",
      dataIndex: "Aug",
      key: "Aug",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Aug + " Hr"}</span>
    },
    {
      title: "Setembro",
      dataIndex: "Sep",
      key: "Sep",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Sep + " Hr"}</span>
    },
    {
      title: "Outubro",
      dataIndex: "Oct",
      key: "Oct",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Oct + " Hr"}</span>
    },
    {
      title: "Novembro",
      dataIndex: "Nov",
      key: "Nov",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Nov + " Hr"}</span>
    },
    {
      title: "Dezembro",
      dataIndex: "Dec",
      key: "Dec",
      width: "7%",
      align: "center",
      render: (text, record) => <span>{record.Dec + " Hr"}</span>
    }
  ];

  return (
    <Modal
      title={"Cadastro de metas de horas"}
      width={"90%"}
      visible={props.visible}
      footer={null}
      onCancel={props.onCancel}
      destroyOnClose={props.onCancel}>
      <Row>
        <Col span={24}>
          <Table
            dataSource={props.data}
            columns={userTimeGoalsColumns}
            style={{ height: "80%", width: "100%" }}
            pagination={true}
          />
        </Col>
        <Col span={24} >
          <div style={{ float: "right" }}>
            <Button type="primary" onClick={() => setModalTimeGoals(true)}>
              Criar
            </Button>
          </div>
        </Col>
      </Row>
      <UserTimeGoalModal
        onCancel={() => setModalTimeGoals(false)}
        visible={modalTimeGoals}
        user={props.user}
        // dropdown={this.state.dropdownUserList}
        dataSource={props.data}
        onFeching={() => props.onFeching()}
      />
    </Modal>
  )
}

export default UserTimeGoalAddModal;