import { request } from '../http';

const itemsService = {
  getList: (docEntry) => {
    return request.get('/items/list/details/' + docEntry);
  },
  getListByDocEntry: (docEntry) => {
    return request.get('/items/list/' + docEntry);
  },
  updateStatusDraft: (code, type) => {
    return request.put('/items/' + code + '/' + type);
  },
  getItem: () => {
    return request.get('/items');
  },
  deleteItem: (id, docEntry) => {
    return request.delete('/items/' + id + '/' + docEntry);
  },
  insertItem: (data) => {
    return request.post('/items', data);
  },
  getBusinessPlace: () => {
    return request.get('/items/business/place');
  },
}

export {
  itemsService
}