import { request } from '../http';

const workflowService = {


  getData: (onlyActive) => {
    return request.get('/workflow/data?onlyActive=' + onlyActive);
  },
  getByIdData: (id) => {
    return request.get('/workflow/data/' + id)
  },
  insertData: (data) => {
    return request.post('/workflow/data', data);
  },
  deleteData: (id) => {
    return request.delete('/workflow/data/' + id);
  },
  updateData: (id, data) => {
    return request.put('/workflow/data/' + id, data);
  },
  getByFilter: (filter, id) => {
    return request.post('/workflow/filter/' + id, filter)
  },



  getApprovals: (id) => {
    return request.get('/workflow/approvals/' + id);
  },
  insertApprovals: (data) => {
    return request.post('/workflow/approvals', data);
  },
  deleteApprovals: (id) => {
    return request.delete('/workflow/approvals/' + id);
  },


  get: (id) => {
    return request.get('/workflow/' + id);
  },
  getWorkflowByCodeAndType: (projectCode, type) => {
    return request.get('/workflow/status/' + projectCode + '/' + type);
  },
  getWorkflowByCode: (projectCode) => {
    return request.get('/workflow/status/' + projectCode);
  },
  updateStatus: (id, status, responsibleId) => {
    return request.put('/workflow/' + id + "/" + status + "/" + responsibleId);
  },
  reverseStatus: (id, status) => {
    return request.put('/workflow/' + id + "/" + status);
  },
  insert: (data) => {
    return request.post('/workflow', data);
  },
  delete: (id) => {
    return request.delete('/workflow/' + id);
  },
  deleteByIdType: (id, type) => {
    return request.delete('/workflow/IdType/' + id + '/' + type);
  }
}

export {
  workflowService
}