import { request } from '../http';

const homeService = {
  getHomeCount: (id) => {
    return request.get('/home/details/' + id);
  },
  getChartsHome: () => {
    return request.get('/home/charts');
  },
  getUserTaskPj: (id, data) => {
    return request.post('/', id, data);
  },
}

export {
  homeService
}