import React, { useState } from "react";
import { Form, Row, Tooltip, Modal, message, Popconfirm, Table, Button, AutoComplete, Spin } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { workflowService } from '../../services/workflow';

const WorkFlowAssignmentModal = (props) => {
    const [spinning, setSpinning] = useState(false);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'UserId',
            key: 'UserId',
            align: 'center',
            width: 50,
        },
        {
            title: 'Nome de usuário',
            dataIndex: 'UserName',
            key: 'UserName',
            align: 'left',
            width: 150,
        },
        {
            title: 'Ação',
            key: 'Action',
            width: 50,
            align: 'center',
            render: (text, record) => (
                <Button.Group >
                    <div style={{ padding: "7px" }}>
                        <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                            onConfirm={() => onCancel(record.Id)} okText="Sim" cancelText="Não">
                            <Tooltip placement="top" title={"Excluir"}>
                                <a>
                                    <DeleteOutlined />
                                </a>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </Button.Group>
            ),
        },
    ];

    const onFinishWorkflow = async (values) => {

        if (validateFields(values)) {
            setSpinning(true);
            const data = {
                WorkFlowId: props.id,
                UserId: values.User.substring(0, values.User.indexOf(" -")),
                UserName: (values.User.slice(values.User.indexOf("- ") + 1)).trim(),
                Order: 0
            }
            await workflowService.insertApprovals(data).then((r) => {
                if(r.data.data.error){
                    message.destroy();
                    message.error('Ocorreu um erro ao adicionar o usuário.', 3);
                    setSpinning(false);
                }
                else {
                    message.destroy();
                    message.success('Usuário adicionado com sucesso.', 3);
                    props.onfeting(props.id);
                    setSpinning(false);
                }
            });
        }
    }

    const validateFields = (values) => {
        if (!values.User) {
            message.error('Campo usuário não preenchido.', 3);
            return false;
        }
        return true;
    }

    const onCancel = async (value) => {
        message.loading('Removendo usuário do workflow ...', 100);
        setSpinning(true);
        await workflowService.deleteApprovals(value).then((r) => {
            if (r.data.error) {
                message.destroy();
                setSpinning(false);
                message.error('Ocorreu um erro ao remover o usuário.', 3);
            }
            else {
                setSpinning(false);
                message.destroy();
                message.success('Usuário removido com sucesso.', 3);
                props.onfeting(props.id);
            }
        });
    }

    return (
        <Modal title="Gerenciador de usuário do workflow"
            visible={props.visible}
            footer={null}
            onCancel={props.destroyOnClose}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={"60%"}
            destroyOnClose={props.destroyOnClose}>
            <Spin spinning={spinning}>
                <Form onFinish={onFinishWorkflow}>
                    <Row>
                        <div style={{ width: "93%", paddingRight: "1%" }}>
                            <p>Usuários</p>
                            <Form.Item name="User">
                                <AutoComplete options={props.dropdown} placeholder="Selecione um usuario" filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                            </Form.Item>
                        </div>
                        <div style={{ width: "5%", paddingLeft: "1%", paddingTop: "35px" }}>
                            <Button htmlType="submit">
                                <PlusOutlined />
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        <Table columns={columns} dataSource={props.dataSource} style={{ height: "100%", width: "100%" }} pagination={true} />
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

export default WorkFlowAssignmentModal;