import { request } from '../http';

const authenticationService = {
  login: (username, password) => {
    return  request.post('/authentication/login', {username, password});
  },
  logout: () => {
    return  request.post('/authentication/logout');
  },
  // atualizar senha do usuário logado
  updatePass: (id, password) => {
    return  request.put('/authentication/update-pass', {id, password});
  }

}

export {
  authenticationService
}