/* eslint-disable no-undef */
import React from "react";
import { Table, Row, Form, message, Tooltip, Popconfirm, Button, Spin } from 'antd';
import moment from "moment";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { FloatingButton } from '../../components/floating-button';
import MeasureUnitCategoryModal from '../../components/category-unit-measure-modal';
import { outgoingService } from '../../services/outgoing';
moment.locale('pt-BR');

class CategoryMeasureUnit extends React.Component {

    state = {
        fetching: false,
        modal: false,
        dataSource: [],
    }

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        this.setState({ fetching: true, modal: false });
        const categoryData = await outgoingService.getOutgoingCategory();
        this.setState({ dataSource: categoryData.data.data, fetching: false });
    }

    delete = async (id) => {
        message.loading("Excluindo unidade de medida ...", 100);
        const categoryData = await outgoingService.deleteOutgoingCategory(id);
        if (!categoryData.data.error) {
            message.destroy();
            message.success("Unidade de medida excluido.", 3);
        }
        else {
            message.destroy();
            message.error("Ocorreu um erro ao tentar excluir a unidade de medida.", 3);
        }
        this.fetch();

    }
    add = () => {
        this.setState({ modal: true });
    }

    render() {


        const columns = [
            {
                title: 'Descrição',
                key: 'Description',
                dataIndex: 'Description',
                width: '90%',
                align: 'left',
            },
            {
                title: 'Ação',
                key: 'Action',
                width: '10%',
                align: 'center',
                render: (_, record) =>
                    <Button.Group >
                        <div style={{ padding: "7px" }}>
                            <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                                onConfirm={() => this.delete(record.Id)} okText="Sim" cancelText="Não">
                                <Tooltip placement={"top"} title={"Excluir"}>
                                    <a> < DeleteOutlined /></a>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </Button.Group>
                ,
            }
        ];


        return (
            <Form /* style={{ minHeight: '90vh' }} */>
                <Spin spinning={this.state.fetching}>
                    <MeasureUnitCategoryModal
                        visible={this.state.modal}
                        onClosed={() => this.setState({ modal: false, fetching: false })}
                        onFetching={() => this.fetch()} />

                    <Row style={{ paddingBottom: "25px" }}>
                        <div style={{ width: "60%" }}>
                            <b style={{ textAlign: "left", fontSize: "30px" }}>Cadastro de unidade de medida</b>
                        </div>
                    </Row>
                    <Table columns={columns} dataSource={this.state.dataSource} />
                    <FloatingButton icon="+" onClick={this.add} />
                </Spin>

            </Form >
        )
    }
}

export default CategoryMeasureUnit;