import React, { useEffect, useState } from 'react';
import { Table, message, Tooltip } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { tasksService } from '../../services/tasks';
import { workflowService } from '../../services/workflow';
import moment from 'moment';
moment.locale('pt-br');
const AssignmentList = (props) => {

    const [data, setData] = useState();

    const del = async value => {
        const workflowData = await workflowService.deleteByIdType(value.Id, "AT");
        const workflow = workflowData.data;
        if (!workflow.error) {
            message.loading('Excluindo atividade ...')
            await tasksService.deleteTask(value.Id).then((res) => {
                message.destroy();
                message.success('Atividade excluída com sucesso.')
                setData(data.filter(field => field.Id !== value.Id));
                props.onFeching();
            }).catch(() => {
                message.destroy();
                message.error('Ocorreu um erro ao excluir a atividade.')
            });

        }
        else {
            message.error('Ocorreu um erro ao excluir a atividade.')
        }

    }


    useEffect(() => {
        setData(props.data);
    }, [props.data])

    const columns =
        [
            {
                title: 'Projeto',
                dataIndex: 'Project',
                key: 'Project',
                width: 400,
                ellipsis: {
                    showTitle: false,
                },
                render: (text, record) => {
                    return (
                        record.Company ?
                            <Tooltip placement="topLeft" title={record.Company + " - " + record.ProjectId + " - " + record.Project}>
                                {record.Company + " - " + record.ProjectId + " - " + record.Project}
                            </Tooltip> : <span></span>
                    )
                },
            },
            {
                title: 'Atividade',
                dataIndex: 'Description',
                key: 'Description',
                ellipsis: {
                    showTitle: false,
                },
                render: (text, record) => {
                    return (
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip>
                    )
                },
            },
            {
                title: 'Data Prevista',
                dataIndex: 'EndDate',
                key: 'EndDate',
                align: 'center',
                width: 100,
                render: (text, record) => <span>{record.EndDate ? record.EndDate : ''}</span>,
                defaultSortOrder: 'ascend',
                sorter: (a, b) =>
                    moment(a.EndDate, 'DD/MM/YYYY').unix() -
                    moment(b.EndDate, 'DD/MM/YYYY').unix()

            },
            {
                title: 'Horas atribuídas',
                dataIndex: 'ApprovedTime',
                key: 'ApprovedTime',
                width: 100,
                align: 'center',
            },
            {
                title: 'Horas consumidas',
                dataIndex: 'ConsumedTime',
                key: 'ConsumedTime',
                width: 100,
                align: 'center',
            },

            {
                title: 'Ação',
                key: 'Action',
                align: 'center',
                width: 70,
                render: (_, record) =>
                    <a>{record.Id != 1 && record.ConsumedTime == 0 ?
                        <MinusCircleOutlined onClick={() => del(record)} />
                        :
                        null
                    }
                    </a>,
            },
        ];


    return (
        <Table pagination={{defaultPageSize: 50}} style={{ width: '100%' }} columns={columns} dataSource={data} loading={!data} size={"small"} />
    )
}

export default AssignmentList;