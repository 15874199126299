import { request } from '../http';

const CategoryDocumentService = {
    get: () => {
        return request.get('/category-document');
    },
    patch: (data, id) => {
        return request.patch('/category-document/' + id, data);
    },
    delete: (id) => {
        return request.delete('/category-document/' + id);
    },
    post: (body) => {
        return request.post('/category-document', body);
    }
}

export default
    CategoryDocumentService
