import React from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Spin,
  Tooltip,
  message,
  Button,
  Popconfirm
} from "antd";
import { FundProjectionScreenOutlined, EyeOutlined, FilterFilled, EditOutlined, ScheduleOutlined, FileTextOutlined, DeleteOutlined, ClockCircleFilled } from "@ant-design/icons";
import moment from "moment";
import { EletronicDocumentsService } from "../../services/eletronic-documents";
import { ProjectsService } from "../../services/projects";
import { outgoingService } from "../../services/outgoing";
import ProjectEditModal from '../../components/project-edit-modal';
import ProjectExpenseModal from '../../components/project-expenses-modal';
import ProjectChangeRequestModal from '../../components/project-changerequest-modal';
import { scheduleService } from "../../services/schedule";
import { workflowService } from "../../services/workflow";
import * as _ from 'lodash';
import { tasksService } from "../../services/tasks";
import { permissionsService } from '../../services/permissions';
import { usersService } from '../../services/users';
// import ProjectAddModal from '../../components/project-add-modal'

moment.locale("pt-BR");

const { Search } = Input;

class Projects extends React.Component {

  state = {
    show: false,

    fetching: false,
    fetchEdit: false,
    fetchExpense: false,
    fetchProjectChange: false,
    modalEditvis: false,
    modalFilter: false,
    modalEdit: false,
    modalExpenses: false,
    modalProjectChange: false,
    modalDetails: false,
    modalAdd: false,

    visible: true,

    Scheduler: false,

    dataSourceItem: [],
    item: [],
    unit: [],

    projectByID: [],
    projectData: [],

    dataSource: [],
    dataSourceExpenses: [],
    dataSourceProjectChange: [],
    dataSourceDocuments: [],
    dataSourceSchedule: [],
    dataSourceSetOutgoingValue: [],
    projectType: "",
    value: "",
    ProjectId: "",
    outgoingValue: "",
    projectValue: 0,
    projectAmount: 0,

    scheduledata: [],
    projectDetails: {},
    projectEdit: {},
    outgoing: []
  };

  setUnit = async (Customer, Code, Id, docEntry) => {
    // const res = await ProjectsService.getAdressesProject(Customer);
    // console.log(res)
    // if (res) {

    // this.setState({ unit: [] });
    // for (const value of res.data.data) {
    //   this.state.unit.push({ "value": value.value.toString(), "label": value.value.toString() })
    // }
    //get data documents, set in table
    const doc = await EletronicDocumentsService.getByIdProject(Id);
    console.log(doc.data);
    this.setState({ dataSourceDocuments: doc.data.data });

    //get data itens 
    // const listData = await itemsService.getListByDocEntry(docEntry);
    // const list = listData.data.data
    //get data itens to ItensOrder
    // const item = await itemsService.getItem();
    // if (item.data.data.items) {
    // const itens = item.data.data.items.map((res) => {
    //   return ({ "value": res.CODE + " - " + res.NAME });
    // });
    // for (const obj of list.items) {
    //   let value = this.state.projectValue + obj.Price
    //   let amount = this.state.projectAmount + obj.Quantity
    //   this.setState({ projectAmount: amount, projectValue: value })
    // }
    // this.setState({ dataSourceItem: list.items, item: itens });
    // }

    // }

  }

  // showProject = async (record) => {
  //   const prj = await ProjectsService.getProjectById(record.Id);
  //   const project = prj.data.data
  //   const dataDetails = await ProjectsService.getAdressesProjectDetails(record.CustomerId, project[0].CustomerAddressCode);
  //   const dataAddress = dataDetails.data.data[0];
  //   if (dataAddress) {
  //     const ProjectDetails = {
  //       id: project[0].Id,
  //       title: project[0].Title,
  //       status: project[0].Status,
  //       clientName: dataAddress.CardName,
  //       fantasyName: dataAddress.CardFName,
  //       street: dataAddress.AddrType + "." + dataAddress.Street,
  //       block: dataAddress.Block,
  //       zipCode: dataAddress.CEP,
  //       city: dataAddress.City,
  //       number: dataAddress.StreetNo,
  //       state: dataAddress.State,
  //       CNPJ: dataAddress.CNPJ,

  //       projectType: project[0].ProjectType,
  //       model: project[0].ProjectModelCode,
  //       budgetedHours: project[0].EstimatedTimeAmount,
  //       startDate: moment(project[0].StartDate).format("DD/MM/YYYY"),
  //       endDate: moment(project[0].EndDate).format("DD/MM/YYYY"),
  //       description: project[0].Description,
  //       KM: project[0].KM,
  //       value: project[0].Value,
  //       toll: project[0].Toll,
  //       reference: project[0].Reference,
  //       address: project[0].CustomerAddressCode
  //     }
  //     const pdata = { status: record.Status, title: record.Title, id: record.Id, code: record.Code, customer: record.CustomerId };
  //     this.setState({ projectDetails: ProjectDetails, modalDetails: true, projectData: pdata });
  //   }
  //   else {
  //     message.warning("Aprove o projeto para visualizar.", 3)
  //   }
  // }

  showEdit = async (record) => {
    //  alterada Id correto do cliente
    const r = await ProjectsService.getChangeProject(record.CustomerId, "Y");
    let hours = r.data.data.Total

    const actives = await workflowService.getData(true);
    // três chaves data em sequencia
    const workflow = actives.data.data.data.map((r) => {
      return ({ value: r.Id, label: r.Title });
    });

    const rProject = await ProjectsService.getProjectById(record.Id);
    const data = rProject.data.data;
    let rWorkflow = null;
    let workflowPermission = "";

    if (data.Workflow) {
      rWorkflow = await workflowService.getByIdData(data.Workflow);
      workflowPermission = localStorage.getItem('workflowPermission');
    }

    let rWorkflow1 = null;
    if (data.Workflow1) {
      rWorkflow1 = await workflowService.getByIdData(data.Workflow1);

    }

    let rWorkflow2 = null;
    if (data.Workflow2) {
      rWorkflow2 = await workflowService.getByIdData(data.Workflow2);

    }
    // let dataDetails = []
    // let dataAddress = {}
    // if (data.CustomerAddressCode) {
    //   dataDetails = await ProjectsService.getAdressesProjectDetails(record.CustomerId, data.CustomerAddressCode);
    //   dataAddress = dataDetails.data.data[0];
    // }
    await this.setUnit(record.CustomerId, record.Code, record.Id, data.OrderDoc);


    // debugger;
    const Project = {
      dropdown: workflow,
      id: data.Id,
      title: data.Title,
      status: data.Status,
      // CNPJ: data.CNPJ,
      // clientName: data.CustomerName,
      // fantasyName: data.FantasyName,
      // zipCode: data.Zipcode,
      // block: data.Block,
      // number: data.Number,
      complement: data.Complement,
      // city: data.City,
      // state: data.State,
      CustomerAdressId: data.CustomerAdressId,
      clientName: data.CustomerName,
      // fantasyName: dataAddress.CardFName,
      // street: dataAddress.AddrType + "." + dataAddress.Street,
      // block: dataAddress.Block,
      // zipCode: dataAddress.CEP,
      // city: dataAddress.City,
      // number: dataAddress.StreetNo,
      // state: dataAddress.State,
      // CNPJ: dataAddress.CNPJ,

      projectType: data.ProjectType,
      model: data.ProjectModelCode,
      budgetedHours: data.EstimatedTimeAmount,
      startDate: data.StartDate,
      endDate: data.EndDate,
      description: data.Description,
      totalTimeAmount: data.TotalTimeAmount,
      KM: data.KM,
      value: data.Value,
      toll: data.Toll,
      reference: data.Reference,
      workflow: rWorkflow ? rWorkflow.data.data.data[0] ? rWorkflow.data.data.data[0].Title : null : null,
      workflow1: rWorkflow1 ? rWorkflow1.data.data.data[0] ? rWorkflow1.data.data.data[0].Title : null : null,
      workflow2: rWorkflow2 ? rWorkflow2.data.data.data[0] ? rWorkflow2.data.data.data[0].Title : null : null,
      limitNotes: data.LimitNotes,
      hoursContribution: hours,
      workflowPermission: workflowPermission == true ? true : false,
      // adicionado aporte trazido no get projectdetails
      timeChange: data.Time_Change
    }
    const pdata = { status: record.Status, diretor: this.state.modalEditvis, title: record.Title, id: record.Id, code: record.Code, customer: record.CustomerId, docEntry: data.OrderDoc };
    this.setState({ projectEdit: Project, modalEdit: true, projectData: pdata });
  };

  showExpenses = async (record) => {
    this.setState({ fetching: true, visible: true });
    const cashArray = [];
    let ProjectType = [];

    let today = moment(new Date).format("YYYY-MM-DD");
    const prj = await ProjectsService.getProjectById(record.Id);
    let project = prj.data.data;
    const outgoing = await outgoingService.getOutgoing();
    console.log(outgoing);
    let cash = outgoing.data.data;
    if (project.ProjectType !== "ambos") {
      if (project.ProjectType == "externo") {
        ProjectType = cash.map((r) => {
          return r.Classification == "externo" ? r : null
        }).filter((r) => { return r != null });
        ProjectType = ProjectType.map(DateFilter => {
          console.log(DateFilter);
          return DateFilter.EndDate ? moment(today) <= moment(DateFilter.EndDate) ? DateFilter : null : moment(today) >= moment(DateFilter.StartDate) ? DateFilter : null
        }).filter(r => { return r != null });
      }
      else {
        ProjectType = cash.map((r) => {
          return r.Classification == "interno" ? r : null
        }).filter((r) => { return r != null });

        ProjectType = ProjectType.map(DateFilter => {
          return DateFilter.EndDate ? moment(today) <= moment(DateFilter.EndDate) ? DateFilter : null : moment(today) >= moment(DateFilter.StartDate) ? DateFilter : null
        }).filter(r => { return r != null });
      }
    }
    else {
      ProjectType = cash.map(DateFilter => {
        return DateFilter.EndDate ? moment(today) <= moment(DateFilter.EndDate) ? DateFilter : null : moment(today) >= moment(DateFilter.StartDate) ? DateFilter : null
      }).filter(r => { return r != null });
    }
    const expensePRJ = await ProjectsService.getExpensesProject(record.Id)
    const dataExpenses = expensePRJ.data.data;
    const pdata = {
      status: record.Status,
      title: record.Title,
      id: record.Id,
      code: record.Code,
      customer: record.CustomerId,
      WorkflowProject: project.Workflow
    };
    for (const c of ProjectType) {
      cashArray.push({ value: c.Title, label: c.Title });
    }
    this.setState({ dataSourceExpenses: dataExpenses, projectData: pdata, modalExpenses: true, outgoing: cashArray, dataSourceSetOutgoingValue: ProjectType });
  }

  showProjectChange = async (record) => {
    this.setState({ fetching: true, visible: true });
    const prj = await ProjectsService.getProjectById(record.Id);
    let project = prj.data.data;
    await ProjectsService.getChangeProject(record.Id, "N").then((r) => {
      const changeRequest = r.data.data.ChangeRequests;
      const pdata = { status: record.Status, title: record.Title, id: record.Id, code: record.Code, customer: record.CustomerId, WorkflowProject: project.Workflow };
      this.setState({ dataSourceProjectChange: changeRequest, modalProjectChange: true, projectData: pdata });
    });
  }

  showSchedule = async (record) => {
    this.props.history.push("/schedule/" + record.Id);
  }

  componentDidMount() {
    this.fetch("list");
  }

  fetch = async (type, code) => {
    let user = localStorage.getItem('userId');

    const r = await usersService.getProfileById(user);

    const profile = r.data.data[0];

    let permission = await permissionsService.getProfileDetails(profile.AccessProfileId);

    let value = permission.data.data.filter(value => value.Feature == '/aporte')

    this.setState({ modalEditvis: value.length > 0 ? true : false });

    if (type == "list") {
      this.setState({ fetching: true });
      const { data: dataProject, err: ErrorProject } = await ProjectsService.getProjects();

      if (!ErrorProject && dataProject) {
        this.setState({ fetching: false, dataSource: dataProject.data });
      }
    }
    else if (type == "expense") {
      const { data: dataExpenseProject, err: ErrorExpenseProject } = await ProjectsService.getExpensesProject(code);
      if (!ErrorExpenseProject) {
        this.setState({ fetchExpense: false, dataSourceExpenses: dataExpenseProject.data, visible: true });
      }
    }
    else if (type == "change") {
      const { data: dataChangeRequest, err: ErrorChangeRequest } = await ProjectsService.getChangeProject(code, "N");
      if (!ErrorChangeRequest) {
        this.setState({ fetchProjectChange: false, dataSourceProjectChange: dataChangeRequest.data.ChangeRequests, visible: true });
      }
    }
    // else if (type === "schedule") {
    //   const { data: dataSchedule, err: ErrorSchedule } = await scheduleService.getScheduleByProjectId(code);
    //   if (!ErrorSchedule) {
    //     this.setState({ dataSourceSchedule: dataSchedule.data });
    //   }
    // }
  };

  cancel = async (type, id) => {
    if (type === "expense") {
      this.setState({ fetchExpense: true });

      const workflowData = await workflowService.deleteByIdType(id, "E");
      const workflow = workflowData.data;
      if (!workflow.error) {
        message.loading('Deletando despesa de projeto ...', 100);
        await ProjectsService.deleteExpenseProject(id).then(r => {
          const expense = r.data;
          if (!expense.error) {
            message.destroy();
            message.success('Despesas deletada sucesso.', 3);
            this.fetch("expense", this.state.projectData.id);
          }
          else {
            message.destroy();
            message.error('Ocorreu um erro ao deletar a despesa.', 3);
          }

        });
      }
      else {
        message.destroy();
        message.error('Ocorreu um erro ao deletar a despesa.', 3);
      }
    }
    else if (type === "change") {
      this.setState({ fetchProjectChange: true });
      const workflowData = await workflowService.deleteByIdType(id, "CR");
      const workflow = workflowData.data;
      if (!workflow.error) {
        message.loading('Deletando solicitação de mudança ...', 100);
        await ProjectsService.deleteChangeProject(id).then(r => {
          const change = r.data;
          if (!change.error) {
            message.destroy();
            message.success('Solicitação de mudança deletada sucesso.', 3);
            this.fetch("change", this.state.projectData.id);
          }
          else {
            message.destroy();
            message.success('Ocorreu um erro ao deletar a solicitação de mudança.', 3);
          }

        });
      }
      else {
        message.destroy();
        message.success('Ocorreu um erro ao deletar a solicitação de mudança.', 3);

      }
    }
  }

  finalizeProject = async (record) => {
    message.loading("Finalizando projeto ...", 100);
    //verificar se na schedule tem algum item não faturado ou a % de conclusão diferente de 100
    const scheduleData = await scheduleService.getScheduleByProjectId(record.Id);
    let schedule = [];
    let verifySchedule;
    schedule = scheduleData.data.data;
    // console.log(scheduleData);
    if (schedule[0]) {
      verifySchedule = schedule.filter(filtered => {
        return filtered.ConclusionPercentage === 100
      });

      //verificar se alguma atividade está pendente ou se n está aprovado
      const taskData = await tasksService.getListByProjectCode(record.Id);
      const task = taskData.data.data;
      let verifyTask;
      if (task[0]) {
        verifyTask = task.filter(filtered => {
          return filtered.Status !== "P" && filtered.Status !== "PAT" && filtered.Approval === "Y"
        });


        if (schedule.length !== verifySchedule.length) {
          message.destroy();
          return message.error("Existem cronograma que precisa ser faturado ou concluido (%). ", 3);
        }
        else {
          if (task.length !== verifyTask.length) {
            message.destroy();
            return message.error("Existem tarefa do cronograma que precisam ser aprovadas. ", 3);
          }
          else {
            message.destroy();
            const projectStatusData = await ProjectsService.updateProjectStatus(record.Id, "Finalizado");
            const status = projectStatusData.data;
            if (!status.error) {
              this.fetch("list");
              message.success("Projeto finalizado com sucesso.", 3);
            }
            else {
              message.destroy();
              message.error("Ocorreu um erro ao tentar finalizar o projeto.", 3);
            }
          }
        }
      }
      else {
        message.destroy();
        message.error("Ocorreu um erro ao tentar finalizar o projeto, não existe nenhuma tarefa.", 3);
      }
    }
    else {
      message.destroy();
      message.error("Ocorreu um erro ao tentar finalizar o projeto, não existe nenhum cronograma.", 3);
    }
  }

  searchTerm = async (value) => {
    if (value) {

      this.setState({ fetching: true });
      await this.fetch('list');
      const filtrado = this.state.dataSource.filter((x) => {
        return (
          x.CustomerName.toLowerCase().includes(value.toLowerCase())
          || x.Code.toLowerCase().includes(value.toLowerCase())
          || x.Title.toLowerCase().includes(value.toLowerCase())
        );
      });
      this.setState({ dataSource: filtrado, fetching: false });
    }
    else {
      this.fetch('list');
    }
  };

  onChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  setOutgoingValue = (value) => {
    let outgoingValue = this.state.dataSourceSetOutgoingValue.map(item => {
      return item.Title == value ? item.Value : null
    }).filter(filtered => filtered != null);
    this.setState({ outgoingValue: outgoingValue[0] })
  }

  render() {

    const fetching = this.state.fetching;

    const columns = [
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        align: 'center',
        width: 30,
        defaultSortOrder: 'ascend',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.Status.length - b.Status.length,
      },
      {
        title: 'Cliente',
        key: 'Client',
        align: 'left',
        width: 250,
        render: (text, record) => <span>{record.CustomerName}</span>,
        defaultSortOrder: 'descend',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.CustomerName.length - b.CustomerName.length,
      },
      {
        title: 'Projeto',
        dataIndex: 'Title',
        key: 'Title',
        align: 'left',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.Title.length - b.Title.length,
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <span>{record.Code} - {record.Title ? record.Title : record.Description}</span>,
      },
      {
        title: 'Início',
        key: 'StartDate',
        align: 'center',
        width: 30,
        render: (text, record) => <span>{(record.StartDate ? moment(record.StartDate).format('DD/MM/YYYY') : '')}</span>,
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.StartDate,
      },
      {
        title: 'Término',
        key: 'EndDate',
        align: 'center',
        width: 30,
        render: (text, record) => <span>{(record.EndDate ? moment(record.EndDate).format('DD/MM/YYYY') : '')}</span>,
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.EndDate,
      },
      {
        title: 'Ação',
        key: 'Action',
        width: 50,
        align: 'center',
        render: (text, record) => (
          <Button.Group >
            {record.Status !== "Aprovado" ? null :
              <div style={{ padding: "7px" }}>
                <Tooltip placement="top" title={"Despesas de Projeto"}>
                  <a>
                    <FundProjectionScreenOutlined onClick={() => this.showExpenses(record)} />
                  </a>
                </Tooltip>
              </div>
            }
            {/* {record.Status === "Integrado" || record.Status === "Finalizado" ? null :
              <div style={{ padding: "7px" }}>
                <Tooltip placement="top" title={"Detalhes"}>
                  <a>
                    <EyeOutlined onClick={() => this.showProject(record)} />
                  </a>
                </Tooltip>
              </div>
            } */}
            {record.Status !== "Aprovado" ? null :
              <div style={{ padding: "7px" }}>
                <Tooltip placement="top" title={"Editar Projeto"}>
                  <a>
                    <EditOutlined onClick={() => this.showEdit(record)} />
                  </a>
                </Tooltip>
              </div>
            }
            {record.Status !== "Aprovado" ? null :
              <div style={{ padding: "7px" }}>
                <Tooltip placement="top" title={"Solicitação de Mudança"}>
                  <a>
                    <FileTextOutlined onClick={() => this.showProjectChange(record)} />
                  </a>
                </Tooltip>
              </div>
            }
            {record.Status !== "Aprovado" ? null :
              <div style={{ padding: "7px" }}>
                <Tooltip placement="top" title={"Cronograma"}>
                  <a>
                    <ScheduleOutlined onClick={() => this.showSchedule(record)} />
                  </a>
                </Tooltip>
              </div>
            }
            {record.Status === "Integrado" || record.Status === "Finalizado" ? null : null
              //  <div style={{ padding: "7px" }}>
              //    <Popconfirm placement="left" title={"Tem certeza que deseja finalizar o projeto?"}
              //      onConfirm={() => this.finalizeProject(record)} okText="Sim" cancelText="Não">
              //      <Tooltip placement="top" title={"Finalizar Projeto"}>
              //        <a>
              //         <DeleteOutlined />
              //        </a>
              //      </Tooltip>
              //    </Popconfirm>
              //  </div>
            }
          </Button.Group>
        ),
      }
    ];

    const fetchingModals = (type, code) => {
      if (type === "list") {
        this.fetch(type);
      }
      else if (type === "expense") {
        this.fetch(type, code)
      }
      else if (type === "change") {
        this.fetch(type, code)
      }
      else if (type === "schedule") {
        this.fetch(type, code)
      }
    }

    const dataSourceFetch = (type) => {
      if (type === "item") {
        this.setUnit(this.state.projectData.customer, this.state.projectData.code, this.state.projectData.id, this.state.projectData.docEntry);
      }
      if (type === "documents") {
        this.setUnit(this.state.projectData.customer, this.state.projectData.code, this.state.projectData.id, this.state.projectData.docEntry);
      }
    }

    return (
      <Spin spinning={fetching} delay={500}>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "left", fontSize: "30px" }}>Listagem de Projetos</b>
          </div>
          <div style={{ width: "35%" }}>
            <Search placeholder="Pesquisar" onSearch={value => this.searchTerm(value)} />
          </div>
          {/* <div style={{ width: "5%", float: "right" }}>
            <center>
              <FilterFilled onClick={() => this.setState({ modalFilter: true })} style={{ fontSize: "20px", padding: "5px" }} />
            </center>
          </div> */}
        </Row>
        <Row style={{ width: "100%"/* , minHeight: "90vh" */ }}>
          <Col style={{ border: "1px solid #e8e8e8" }} span={24} >
            <Table columns={columns} size={'middle'} dataSource={this.state.dataSource} style={{ height: "100%" }} pagination={true} />
          </Col>
        </Row>

        {/* Modal Detalhamento */}
        {/* <ProjectDetailsModal
          data={this.state.projectDetails}
          title={"Detalhamento Projeto | " + this.state.projectData.code + " - " + this.state.projectData.title}
          destroyOnClose={() => this.setState({ modalDetails: false, fetching: false })}
          width={"70%"}
          footer={null}
          visible={this.state.modalDetails}
          onCancel={() => this.setState({ modalDetails: false, fetching: false })} /> */}

        {/* Modal Editar */}
        <ProjectEditModal
          document={this.state.dataSourceDocuments}
          unity={this.state.unit}
          item={this.state.item}
          listItem={this.state.dataSourceItem}
          data={this.state.projectEdit}
          projectData={this.state.projectData}

          width={"70%"}
          footer={null}
          visible={this.state.modalEdit}
          onClosed={() => this.setState({ modalEdit: false, fetching: false })}

          fetchDataSource={(type) => dataSourceFetch(type)}
          onFecthing={() => fetchingModals("list")} />

        {/* Modal Despesas */}
        <ProjectExpenseModal
          outgoing={this.state.outgoing}
          expenses={this.state.dataSourceExpenses}
          projectData={this.state.projectData}
          visible={this.state.modalExpenses}
          outgoingValue={this.state.outgoingValue}
          sedingOutgoing={(value) => this.setOutgoingValue(value)}

          width={"70%"}
          footer={null}
          onCancel={(id) => this.cancel("expense", id)}
          onClosed={() => this.setState({ modalExpenses: false, fetching: false, fetchExpense: false })}
          onFecthing={(code) => fetchingModals("expense", code)} />

        {/* Modal Alterção de Projeto */}
        <ProjectChangeRequestModal
          projectData={this.state.projectData}
          changerequest={this.state.dataSourceProjectChange}
          visible={this.state.modalProjectChange}
          width={"70%"}
          footer={null}
          onCancel={(id) => this.cancel("change", id)}
          onClosed={() => this.setState({ modalProjectChange: false, fetchProjectChange: false, fetching: false })}
          onFecthing={(code) => fetchingModals("change", code)} />

        {/* <ProjectAddModal
          document={this.state.dataSourceDocuments}
          unity={this.state.unit}
          item={this.state.item}
          listItem={this.state.dataSourceItem}
          data={this.state.projectEdit}
          projectData={this.state.projectData}

          width={"70%"}
          footer={null}
          visible={this.state.modalEdit}
          onClosed={() => this.setState({ modalEdit: false, fetching: false })}

          fetchDataSource={(type) => dataSourceFetch(type)}
          onFecthing={() => fetchingModals("list")} />
        
        <FloatingButton icon="+" onClick={() => this.showmodaladd()} /> */}
      </Spin>
    );
  }
}

export default Projects;
