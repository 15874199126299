import { request } from '../http';

const outgoingService = {
  getOutgoing: () => {
    return request.get('/outgoing');
  },
  getOutgoingCategory: () => {
    return request.get('/outgoing/category');
  },
  createOutgoing: (data) => {
    return request.post('/outgoing', data);
  },
  createOutgoingCategory: (data) => {
    return request.post('/outgoing/category', data);
  },
  deleteOutgoing: (data) => {
    return request.delete('/outgoing/' + data.Id);
  },
  deleteOutgoingCategory: (Id) => {
    return request.delete('/outgoing/category/' + Id);
  },
  editOutgoing: (data) => {
    return request.put('/outgoing', data);
  }
}

export {
  outgoingService
}