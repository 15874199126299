import React from "react";
import {
  Form,
  Row,
  Col,
  Table,
  Input,
  Spin,
  Button,
  Tooltip,
  message,
  Popconfirm,
  Drawer,
  Select
} from 'antd';
import { FilterFilled, CheckCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { workflowService } from '../../services/workflow';
import { tasksService } from '../../services/tasks';
import { ProjectsService } from '../../services/projects';
import { cashbackService } from '../../services/cashback';
import { scheduleService } from '../../services/schedule';
import { usersService } from '../../services/users';
import moment from "moment";
import * as _ from "lodash";
moment.locale('pt-BR');

const { Search } = Input;

class WorkFlowApprovals extends React.Component {
  state = {
    fetching: true,
    dataSource: [],
    dataSourceBackup: [],
    dataVerifyTime: [],
    drawer: false,
    type: "1",
    status: ` wf.Status = 'P' `,
    userData: [],
    projectData: [],
    type: null,
    project: null,
    user: null,
    selectType: null,
    selectStatus: 'P',
    selectProject: null,
    selectUser: null
  }

  componentDidMount() {
    this.fetch();
  }


  fetch = async () => {

    this.setState({ fetching: true });
    const id = localStorage.getItem('userId');
    const { data: dataUsers } = await usersService.getUsers();
    const { data: dataProjects } = await ProjectsService.getProjects();

    let searchFilter = [];
    // searchFilter.push(` wf.UserId = '${id}'`)
    if (this.state.type) { searchFilter.push(this.state.type) }
    if (this.state.user) { searchFilter.push(this.state.user) }
    if (this.state.status) { searchFilter.push(this.state.status) }
    if (this.state.project) { searchFilter.push(this.state.project) }


    const { data: dataWorkflowApprovals, err: errorWorkflowApprovals } = await workflowService.getByFilter(searchFilter, id);
    if (!errorWorkflowApprovals) {
      let workflow = dataWorkflowApprovals.data.data;
      // ajustado filtro para já listar os pendentes
      let filteredList = workflow//.filter(find => find.Status == 'P');
      let data;
      if (filteredList[0]) {
        data = filteredList;
      }
      else {
        data = workflow;
      }
      workflow.map(find => find.ProjectId);
      this.setState({ fetching: false, dataSource: data, dataSourceBackup: workflow, userData: dataUsers.data, projectData: dataProjects.data });
    }
  }

  searchTerm = async (value) => {
    console.log(value);
    if (value) {
      this.setState({ fetching: true });
      await this.fetch();
      const backupData = this.state.dataSourceBackup;
      const filtrado = backupData.filter((x) => {
        return x.ProjectId.toLowerCase().includes(value.toLowerCase()) ||
          x.ProjectName.toLowerCase().includes(value.toLowerCase())
      });
      this.setState({ dataSource: filtrado, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  render() {

    const formatDuration = (duration) => {
      duration = duration || "0";
      const dur = moment.duration(parseFloat(duration), 'hours');
      var hours = Math.floor(dur.asHours());
      var mins = Math.floor(dur.asMinutes()) - hours * 60;
      return `${_.padStart(hours, 2, '0')}:${_.padStart(mins, 2, '0')}`;
    }

    const formatMoney = (value) => {
      //com R$
      var f = value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

      //sem R$
      var f2 = value.toLocaleString('pt-br', { minimumFractionDigits: 2 });
      return f2;
    }

    const optionsDrawer = [
      {
        value: null,
        label: "Todos",
      },
      {
        value: "CR",
        label: "Solicitação de mudança",
      },
      {
        value: "C",
        label: "Reembolso",
      },
      {
        value: "E",
        label: "Despesas de projeto",
      },
      {
        value: "T",
        label: "Horas de atividades",
      }
    ]

    const optionsStatus = [
      {
        value: null,
        label: "Todos",
      },
      {
        value: "Y",
        label: "Aprovado",
      },
      {
        value: "N",
        label: "Reprovado",
      },
      {
        value: "P",
        label: "Aguardando aprovação",
      },
      {
        value: "I",
        label: "Faturado",
      },
      {
        value: "R",
        label: "Estornado",
      }
    ]

    const columns = [
      {
        title: '#',
        key: 'Id',
        align: 'center',
        render: (text, record) => <span>{record.Id}</span>
      },
      {
        title: 'Data',
        key: 'DateRequest',
        align: 'center',
        render: (text, record) => <span>{record.Type == "AT" ? record.DateTasks : record.Type == "C" ? record.DateTasks : record.Type == "T" ? record.DateTasks : record.DateTasks}</span>,
        defaultSortOrder: 'ascend',
        sorter: (a, b) =>
          moment((a.Type == "AT" ? a.DateTasks : a.Type == "C" ? a.DateTasks : a.Type == "T" ? a.DateTasks : a.DateTasks), 'DD/MM/YYYY').unix() -
          moment((b.Type == "AT" ? b.DateTasks : b.Type == "C" ? b.DateTasks : b.Type == "T" ? b.DateTasks : b.DateTasks), 'DD/MM/YYYY').unix()
      },
      {
        title: 'Projeto',
        key: 'ProjectId',
        align: 'left',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.ProjectId + " - " + record.Company + " - " + record.ProjectName}>
            {record.ProjectId + " - " + record.Company + " - " + record.ProjectName}
          </Tooltip>
        )
      },
      {
        title: 'Atividade',
        key: 'Atividade',
        align: 'left',
        dataIndex: 'Atividade',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.DescriptionTasks}>
            {record.DescriptionTasks}
          </Tooltip>
        )
      },
      {
        title: 'Tipo',
        key: 'Type',
        align: 'left',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Type == "CR" ? "Aporte" : record.Type == "E" ? "Despesas" : record.Type == "T" ? "Apontamento" : record.Type == "C" ? "Reembolso" : record.Type == "AT" ? "Atribuição" : ""}>
            {record.Type == "CR" ? "Aporte" : record.Type == "E" ? "Despesas" : record.Type == "T" ? "Apontamento" : record.Type == "C" ? "Reembolso" : record.Type == "AT" ? "Atribuição" : ""}
          </Tooltip>
        )
      },
      {
        title: 'Atendimento',
        key: 'Type',
        align: 'left',
        render: (text, record) => <span>{record.TypeTaks == "1" ? "Remoto" : record.TypeTak == "3" ? "Cliente" : "Consultoria"}</span>
      },
      {
        title: 'Unidade',
        key: 'Value',
        align: 'center',
        render: (text, record) => <span>{record.Type == "CR" ? formatDuration(record.Value) + " Hrs" : record.Type == "E" ? "R$ " + formatMoney(record.Value) : record.Type == "T" ? formatDuration(record.Value) + " Hrs" : record.Type == "C" ? "R$ " + formatMoney(record.Value) : record.Type == "AT" ? formatDuration(record.Value) + " Hrs" : ""}</span>
      },
      {
        title: 'Justificativa',
        key: 'Reason',
        align: 'left',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Type == "CR" ? record.Reason : record.Type == "E" ? record.Title : record.Type == "T" ? record.DescriptionTasksHistory : record.Type == "C" ? record.DescriptionTasks : record.Type == "AT" ? record.DescriptionTasksHistory : ""}>
            {record.Type == "CR" ? record.Reason : record.Type == "E" ? record.Title : record.Type == "T" ? record.DescriptionTasksHistory : record.Type == "C" ? record.DescriptionTasks : record.Type == "AT" ? record.DescriptionTasksHistory : ""}
          </Tooltip>
        )
      },
      {
        title: 'Impacto',
        key: 'Impact',
        align: 'center',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Type == "CR" ? record.Impact : record.Type == "E" ? "R$ " + formatMoney(record.Value) : record.Type == "C" ? record.CashbackName : ""}>
            {record.Type == "CR" ? record.Impact : record.Type == "E" ? "R$ " + formatMoney(record.Value) : record.Type == "C" ? record.CashbackName : ""}
          </Tooltip>
        )
      },
      {
        title: 'Usuário',
        key: 'Users',
        align: 'center',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => <span>{record.Type == "C" ? record.UsersTasks : record.Type == "AT" ? record.UsersTasks : record.Type == "T" ? record.UsersTasks : record.UsersTasks} </span>
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        align: 'center',
        render: (text, record) => <span>{record.Status === "Y" ? "Aprovado" :record.Status === "R" ? "Estornado" : record.Status === "I" ? "Faturado" : record.Status === "N" ? "Reprovado" : "Pendente"} </span>
      },
      {
        title: 'Aprovador',
        key: 'ApprovalUsers',
        align: 'center',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => {
          // alterado de record.UsersApproval -> record.WorkflowApprovalsId || record.UserId
          let username = record.UserId;
          // username = _.first(username.split('@'))
          return (
            <Tooltip placement="topLeft" title={username}>
              {username}
            </Tooltip>
          )
        }
      },
      {
        title: 'Ação',
        key: 'Action',
        align: 'center',
        fixed: 'right',
        render: (text, record) => getButtonGroup(text, record),
      },
    ];

    const getButtonGroup = (text, record) => {
      return (
        <Button.Group >
          {record.Status === "Y" ?
            record.Status === "Y" && record.Type === "T" ?
              <div style={{ padding: "7px" }}>
                <Popconfirm
                  placement="left"
                  title={"Deseja estornar a aprovação de horas?"}
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={() => reverse(record, "R")}
                >
                  <Tooltip placement="top" title={"Estornar?"}>
                    <a>
                      <ExclamationCircleOutlined />
                    </a>
                  </Tooltip>
                </Popconfirm>
              </div>:
              <div style={{ padding: "7px" }}>
                <Tooltip placement="top" title={"Aprovado"}>
                  <CloseCircleOutlined />
                </Tooltip>
              </div>
            : record.Status === "R" ?
              <div style={{ padding: "7px" }}>
                <Tooltip placement="top" title={"Estornado"}>
                  <CloseCircleOutlined />
                </Tooltip>
              </div>
            : record.Status === "N" ?
              <div style={{ padding: "7px" }}>
                <Tooltip placement="top" title={"Reprovado"}>
                  <CloseCircleOutlined />
                </Tooltip>
              </div>
              : record.Status === "I" ?
                <div style={{ padding: "7px" }}>
                  <Tooltip placement="top" title={"Faturado"}>
                    <CloseCircleOutlined />
                  </Tooltip>
                </div>
                :
                <div style={{ padding: "7px" }}>
                  <Popconfirm
                    placement="left"
                    //title={record.Type == "T" ? parseFloat(record.ValueTotalTask) < parseFloat(record.ValueMaxTask) ? parseFloat(record.Value) + parseFloat(record.ValueTotalTask) > parseFloat(record.ValueMaxTask) ? "Essas horas irá ultrapassar o estipulado de horas, deseja aprovar ?" : "Deseja aprovar ?" : "Horas estipuladas para esta atividade já excedeu o limite, deseja aprovar  ?" : "Deseja aprovar ?" + record.ValueTotalTask}
                    title={"Deseja aprovar ?"}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={() => approving(record, "Y")}
                    onCancel={() => approving(record, "N")}>
                    <Tooltip placement="top" title={"Aprovação pendente"}>
                      <a>
                        <ExclamationCircleOutlined />
                      </a>
                    </Tooltip>
                  </Popconfirm>
                </div>
          }
        </Button.Group>
      )
    }

    const updateStatus = async (record, approving) => {

      if (record.Type == "CR") {
        if (approving == "Y") {
          await ProjectsService.updateChangeRequest(record.IdType, "Y");
          // erro ao atualizar aporte de horas
          const r = await ProjectsService.updateTotalTimeProject({ Time: record.Value, Id: record.ProjectId })
        }
        else {
          await ProjectsService.updateChangeRequest(record.IdType, "N");
        }
      }
      if (record.Type == "E") {
        if (approving == "Y") {
          await ProjectsService.updateExpenseProjectStatus(record.IdType, "Y");
        }
        else {
          await ProjectsService.updateExpenseProjectStatus(record.IdType, "N");
        }
      }
      if (record.Type == "T") {

        // const workflowData = await workflowService.getWorkflowByCodeAndType(record.ProjectId, 'T');
        // const workflow = workflowData.data.data
        // let filteredData = workflow.filter(find => record.IdType == find.IdType);

        // let getApproval = filteredData.filter(find => find.Status == 'Y');
        // let getRepproval = filteredData.filter(find => find.Status == 'N');
        // let getPeding = filteredData.filter(find => find.Status == 'P');

        if (approving == "Y") {
          await tasksService.updateTaskHistory(parseInt(record.IdType), "Y");
          const taskHistoryData = await tasksService.getTaskHistoryById(record.IdType);
          const taskHistory = taskHistoryData.data.data[0];
          console.log(taskHistory)

          await tasksService.updateTaskConsumed(taskHistory.TaskId, { time: record.Value });
          const taskData = await tasksService.getById(taskHistory.TaskId);
          const task = taskData.data.data[0];
          console.log(task);
          
          const data = {
            ConclusionPercentage: taskHistory.Percentage === 100 ? taskHistory.Percentage : task.Percentage,
            Id: task.ScheduleId,
          }
          console.log(data);

          await scheduleService.updateSchedule(data);
          if (task.percentage === 100 || taskHistory.Percentage === 100) {
            console.log(taskHistory.TaskId);
            await tasksService.updateStatus(taskHistory.TaskId, 'A', 'Y');
          }
          // }
          // else {
          //   if ((getApproval.length + getRepproval.length) == filteredData.length) {
          //     await tasksService.updateTaskHistory(parseInt(record.IdType), "N");
          //   }
          // }
          // }
        } else if (approving == "R") {
          await tasksService.updateTaskHistory(parseInt(record.IdType), "R");
        }
        else {
          // if (!getPeding[0]) {
          await tasksService.updateTaskHistory(parseInt(record.IdType), "N");
          // }
        }
      }
      if (record.Type == "C") {
        if (approving == "Y") {
          await cashbackService.editStatusCashback(record.IdType, "Y");
        }
        else {
          await cashbackService.editStatusCashback(record.IdType, "N")
        }
      }
    }

    const approving = async (record, status) => {
      let tipo = record.Type == "CR" ? "Solicitação de mudança" : record.Type == "E" ? "Despensas" : record.Type == "T" ? "Horas de atividades" : record.Type == "C" ? "Reembolso" : record.Type == "AT" ? "Atribuição de tarefa" : ""
      message.loading("Aprovando " + tipo + " ...", 3);
      this.setState({ fetching: true });
      // alterado record.ResponsibleId -> record.UserId
      const r = await workflowService.updateStatus(record.Id, status, record.UserId);
      const workflow = r.data;
      if (!workflow.error) {
        this.fetch();
        message.destroy();
        if (status == "Y") {
          await updateStatus(record, status);
          message.success(tipo + " aprovado.", 3);
        }
        else {
          await updateStatus(record, status);
          message.error(tipo + " reprovado.", 3);
        }
        this.setState({ fetching: false });
      }
      else {
        message.destroy();
        message.error("Ocorreu um erro ao tentar aprovar.", 3);
        this.setState({ fetching: false });
      }
    }

    const reverse = async (record, status) => {
      message.loading("Estornando aprovação ...", 3);
      this.setState({ fetching: true });
      try {
        const r = await workflowService.reverseStatus(record.Id, status);
        const workflow = r.data.data;
        if (!workflow.error) {
          this.fetch();
          message.destroy();
          if (status == "R") {
            await updateStatus(record, status);
            message.success("estornado.", 3);
          }
          this.setState({ fetching: false });
        }
        else {
          message.destroy();
          message.error("Ocorreu um erro ao tentar aprovar.", 3);
          this.setState({ fetching: false });
        }
      } catch (e) {
        console.log("Reverse Error: ", e);
      }
    }


    const approveAll = async () => {
      message.loading("Aprovando ...", 100);
      this.setState({ fetching: true });
      let listApprovals = this.state.dataSource
      let notApproval = 0;
      let itemForApprove = listApprovals.filter(find => find.Status == "P");
      if (itemForApprove[0]) {
        for (const item of itemForApprove) {
          if (item.ValueMaxTask && item.ValueTotalTask) {
            if (item.Type == "T" && item.ValueTotalTask < item.ValueMaxTask && item.ValueTotalTask + item.Value < item.ValueMaxTask) {
              const r = await workflowService.updateStatus(item.Id, "Y", item.ResponsibleId);
              const workflowApproval = r.data;
              if (!workflowApproval.error) {
                await updateStatus(item, "Y");
              }
            }
            else {
              notApproval++
            }
          }
          else {
            const r = await workflowService.updateStatus(item.Id, "Y", item.ResponsibleId);
            const workflowApproval = r.data;
            if (!workflowApproval.error) {
              await updateStatus(item, "Y");
            }
          }
        }
        if (notApproval == 0) {
          this.fetch()
          message.destroy();
          message.success('Todas as aprovações realizadas com sucesso', 3);
          this.setState({ fetching: false });
        }
        else {
          this.fetch()
          message.destroy();
          message.warning('Algumas atividades está com as horas estipuladas excedidas, insira manualmente. ', 3);
          this.setState({ fetching: false });
        }
      }
      else {
        this.fetch()
        message.destroy();
        message.success('Todas as aprovações selecionadas ja estão aprovadas.', 3);
        this.setState({ fetching: false });
        return false
      }
    }

    const onChangeFilter = async () => {

      this.setState({ fetching: true });

      const id = localStorage.getItem('userId');
      let searchFilter = [];
      // searchFilter.push(`wf.UserId = '${id}'`)
      if (this.state.type) { searchFilter.push(this.state.type) }
      if (this.state.user) { searchFilter.push(this.state.user) }
      if (this.state.status) { searchFilter.push(this.state.status) }
      if (this.state.project) { searchFilter.push(this.state.project) }
      const { data: dataWorkflowApprovals, err: errorWorkflowApprovals } = await workflowService.getByFilter(searchFilter, id);

      if (!errorWorkflowApprovals) {
        let workflow = dataWorkflowApprovals.data.data
        workflow && workflow.map(find => find.ProjectId)

        this.setState({ fetching: false, dataSource: workflow, dataSourceBackup: workflow, filter: searchFilter, drawer: false, });
      }

    }

    return (
      <Spin spinning={this.state.fetching} delay={500}>
        <Form /* style={{ minHeight: '90vh' }} */>
          <Row style={{ paddingBottom: "25px" }}>
            <div style={{ width: "60%" }}>
              <b style={{ textAlign: "left", fontSize: "30px" }}>Listagem de aprovações</b>
            </div>
            <div style={{ width: "35%" }}>
              <Search placeholder="Pesquisa por justificativa, impacto/custo e projeto." onSearch={value => this.searchTerm(value)} />
            </div>
            <div style={{ width: "5%", float: "right" }}>
              <center>
                <FilterFilled style={{ fontSize: "20px", padding: "5px" }} onClick={() => this.setState({ drawer: true })} />
              </center>
            </div>
          </Row>
          <Row style={{ width: "100%"/* , minHeight: "90vh" */ }}>
            <Col style={{ border: "1px solid #e8e8e8" }} span={24} >
              <Table scroll={{ x: 1300 }} size="small" columns={columns} dataSource={this.state.dataSource} style={{ height: "100%" }} pagination={{ pageSize: 100 }} />
            </Col>
          </Row>

          <Drawer
            title="Filtros"
            placement="right"
            width="400"
            closable={false}
            onClose={() => this.setState({ drawer: false })}
            visible={this.state.drawer} >
            <div style={{ paddingBottom: "3%" }}>
              <label>Tipo:</label>
              <div style={{ paddingBottom: "3%" }}>
                <Select value={this.state.selectType} options={optionsDrawer} style={{ width: "100%" }} onChange={(e) => this.setState({ type: e == null ? null : ` wf.Type = '${e}' `, selectType: e })} />
              </div>
              <div style={{ paddingBottom: "3%" }}>
                <label>Status:</label>
                <Select value={this.state.selectStatus} options={optionsStatus} style={{ width: "100%" }} onChange={(e) => this.setState({ status: e == null ? null : ` wf.Status = '${e}' `, selectStatus: e })} />
              </div>
              <div style={{ paddingBottom: "3%" }}>
                <label>Projeto:</label>
                <Select
                  filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch value={this.state.selectProject} style={{ width: "100%" }} onChange={(e, o) => this.setState({ project: o.key == 'null' ? null : ` wf.ProjectId = '${o.key}' `, selectProject: o.value })} >
                  <Select.Option key={null} value={"Todos"}>Todos</Select.Option>
                  {this.state.projectData && this.state.projectData.sort((a, b) => (a.CustomerName + " - " + a.Title).toLowerCase().localeCompare(b.CustomerName + " - " + b.Title)).map(projectD => (
                    <Select.Option key={projectD.Id} value={projectD.CustomerName + " - " + projectD.Title}>{projectD.CustomerName} - {projectD.Title}</Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{ paddingBottom: "3%" }}>
                <Button type="primary" style={{ width: "100%" }} onClick={() => onChangeFilter()}>Pesquisar</Button>
              </div>
              <div style={{ paddingBottom: "3%" }}>
                <Button type="danger" style={{ width: "100%" }} onClick={() => {
                  this.setState({
                    drawer: false,
                    type: null,
                    status: ` wf.Status = 'P' `,
                    project: null,
                    user: null,
                    selectType: null,
                    selectStatus: 'P',
                    selectProject: null,
                    selectUser: null
                  });
                  this.fetch();
                }
                }>Limpar</Button>
              </div>
              <div style={{ paddingBottom: "3%" }}>
                <Button type="dashed" style={{ width: "100%" }} onClick={() => approveAll()}>Aprovar todos</Button>
              </div>
            </div>
          </Drawer>

        </Form >
      </Spin>
    )
  }
}

export default WorkFlowApprovals;