import React from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Spin,
  Tooltip,
  message,
  Button,
  Popconfirm
} from "antd";
import { DeleteOutlined, EditOutlined, FilterFilled } from "@ant-design/icons";
import moment from "moment";
import { outgoingService } from "../../services/outgoing";
import { FloatingButton } from '../../components/floating-button';
import OutgoingAddModal from '../../components/outgoing-add-modal';
import OutgoingEditModal from '../../components/outgoing-edit-modal';

moment.locale("pt-BR");

const { Search } = Input;

class Outgoing extends React.Component {
  state = {
    fetching: false,
    modalAdd: false,
    modalEdit: false,
    visible: false,
    dataSource: [],
    dataEdit: [],
    dataCreate: [],
    unitMeasurement: []
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ fetching: true });
    const { data: dataOutgoing, err: ErrorOutgoing } = await outgoingService.getOutgoing();
    console.log(dataOutgoing);
    if (!ErrorOutgoing) {
      this.setState({ fetching: false, dataSource: dataOutgoing.data });
    }
  };

  searchTerm = async (value) => {
    if (value) {
      this.setState({ fetching: true });
      await this.fetch();
      const filtrado = this.state.dataSource.filter((x) => {
        return x.Title.toLowerCase().includes(value.toLowerCase());
      });
      
      this.setState({ dataSource: filtrado, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  render() {

    const fetching = this.state.fetching;

    const columns = [
      {
        title: 'Titulo',
        dataIndex: 'Title',
        key: 'Title',
        align: 'left',
        width: 110,
      },
      {
        title: 'Classificação',
        dataIndex: 'Classification',
        key: 'Classification',
        align: 'left',
        width: 10,
      },
      {
        title: 'Unidade de medida',
        dataIndex: 'Unit',
        key: 'Unit',
        align: 'center',
        width: 10,
      },
      {
        title: 'Valor',
        dataIndex: 'Value',
        key: 'Value',
        align: 'center',
        width: 10,
        // render: (text, record) => <span>{(record.Value ? 'R$ ' + record.Value : '')}</span>
        render: (text, record) => <span>{(record.Value ? parseFloat(record.Value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '')}</span>
      },
      {
        title: 'Início Vigencia',
        key: 'StartDate',
        align: 'center',
        width: 110,
        render: (text, record) => <span>{(record.StartDate ? moment(record.StartDate,'YYYY-MM-DD').format('DD/MM/YYYY') : '')}</span>
      },
      {
        title: 'Término Vigencia',
        key: 'EndDate',
        align: 'center',
        width: 110,
        render: (text, record) => <span>{(record.EndDate ? moment(record.EndDate,'YYYY-MM-DD').format('DD/MM/YYYY') : '')}</span>
      },
      {
        title: 'Ação',
        key: 'Action',
        width: 50,
        align: 'center',
        render: (text, record) => (
          <Button.Group >
            <div style={{ padding: "7px" }}>
              <Tooltip placement="top" title={"Editar"}>
                <a><EditOutlined onClick={() => showEdit(record)} /></a>
              </Tooltip>
            </div>
            <div style={{ padding: "7px" }}>
              <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                onConfirm={() => onCancel(record.Id)} okText="Sim" cancelText="Não">
                <Tooltip placement="top" title={"Excluir"}>
                  <a><DeleteOutlined /></a>
                </Tooltip>
              </Popconfirm>
            </div>
          </Button.Group>
        ),
      },
    ]

    const showEdit = async (record) => {

      let CategoryData = await outgoingService.getOutgoingCategory();
      let CategoryFiltered = CategoryData.data.data.map(item => {
        return { value: item.Description, label: item.Description }
      });

      console.log(CategoryData);
      console.log(CategoryFiltered);
      console.log(record);
      this.setState({ modalEdit: true, dataEdit: record, unitMeasurement: CategoryFiltered });
    }

    const showAdd = async () => {

      let CategoryData = await outgoingService.getOutgoingCategory();
      let CategoryFiltered = CategoryData.data.data.map(item => {
        return { value: item.Description, label: item.Description }
      });

      this.setState({ modalAdd: true, unitMeasurement: CategoryFiltered })
    }

    const onCancel = async (value) => {
      message.loading('Deletando despesa ...', 100);
      this.setState({ fetching: true });

      const data = {
        Id: value
      }
      await outgoingService.deleteOutgoing(data).then(r => {
        if (r.data.error) {
          message.destroy();
          message.error('Ocorreu um erro ao deletar despesa.', 3);
        }
        else {
          message.destroy();
          message.success('Despesa deletado com sucesso.', 3);
        }
      });
      this.setState({ fetching: false });
      this.fetch();
    }

    return (
      <Spin spinning={fetching} delay={500}>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "Right", fontSize: "30px" }}>Cadastro do tipos de despesas</b>
          </div>
          <div style={{ width: "35%" }}>
            <Search placeholder="Pesquisar" onSearch={value => this.searchTerm(value)} />
          </div>
          {/* <div style={{ width: "5%", float: "right" }}>
            <center>
              <FilterFilled style={{ fontSize: "20px", padding: "5px" }} />
            </center>
          </div> */}
        </Row>
        <Row style={{ width: "100%"/* , minHeight: "90vh" */ }}>
          <Col style={{ border: "1px solid #e8e8e8" }} span={24} >
            <Table columns={columns} dataSource={this.state.dataSource} style={{ height: "100%" }} pagination={true} />
          </Col>
        </Row>

        {/* Modal Adicionar */}
        <OutgoingAddModal
          visible={this.state.modalAdd}
          dropdown={this.state.unitMeasurement}
          onClosed={() => this.setState({ modalAdd: false, fetching: false })}
          onFetching={() => this.fetch()} />

        {/* Modal Editar */}
        <OutgoingEditModal
          visible={this.state.modalEdit}
          data={this.state.dataEdit}
          dropdown={this.state.unitMeasurement}
          onClosed={() => this.setState({ modalEdit: false, fetching: false })}
          onFetching={() => this.fetch()} />

        <FloatingButton icon="+" onClick={() => showAdd()} />
      </Spin>
    );
  }
}



export default Outgoing;
