import React, { useState } from "react";
import { Row, Input, Modal, Button, message } from 'antd';
import { outgoingService } from "../../services/outgoing";

const MeasureUnitCategoryModal = (props) => {

    const [description, setDescription] = useState();

    const insertMeasureUnit = async () => {
        if (validateFields()) {
            message.loading("Criando unidade de medida ...", 100)
            const data = {
                Description: description
            }
            const categoryData = await outgoingService.getOutgoingCategory();
            const category = categoryData.data;
            if (category.data.find(item => item.Description == description)) {
                message.destroy();
                message.error("Unidade de medida já existente.", 3)
            }
            else {
                const categoryData = await outgoingService.createOutgoingCategory(data);
                if (!categoryData.data.error) {
                    message.destroy();
                    message.success("Unidade de medida criado com sucesso.", 3)
                    props.onFetching()
                }
                else {
                    message.destroy();
                    message.success("Ocorreu um erro ao criar uma unidade de medida.", 3)
                }
            }
        }
    }

    const validateFields = () => {
        if (!description) {
            message.error('Campo unidade de medida não preenhido.', 3);
            return false;
        }
        return true;
    }

    return (
        <Modal
            title="Criar unidade de medida"
            visible={props.visible}
            onOk={props.onClosed}
            footer={null}
            onCancel={props.onClosed}
            destroyOnClose={props.onClosed}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={"30%"}>
            <div style={{ width: "100%" }}>
                <Row style={{ width: "100%" }}>
                    <div style={{ padding: "0 1%", width: "100%" }}>
                        <h3>Descrição</h3>
                        <Input style={{ width: "100%" }} onChange={(value) => setDescription(value.target.value)} />
                    </div>
                </Row>
                <Row style={{ width: "100%" }}>
                    <div style={{ float: "right", width: "100%", paddingTop: "5px" }}>
                        <div style={{ padding: "0 1%", float: "right" }}>
                            <Button type="primary" onClick={insertMeasureUnit}>Salvar</Button>
                        </div>
                    </div>

                </Row>
            </div >
        </Modal >
    )
}

export default MeasureUnitCategoryModal;