import { request } from '../http';

const EletronicDocumentsService = {
    get: () => {
        return request.get('/eletronic-documents');
    },
    getCategory: () => {
        return request.get('/eletronic-documents/category')
    },
    getProject: () => {
        return request.get('/eletronic-documents/project')
    },
    getByIdProject: (IdProject) => {
        return request.get('/eletronic-documents/project/' + IdProject)
    },
    post: (body) => {
        return request.post('/eletronic-documents', body);
    },
    delete: (id) => {
        return request.delete('/eletronic-documents/' + id);
    },
    downloadDocuments: (filePath) => {
        return request.get('/uploads/EletronicDocuments/' + filePath);
    },
}

export {
    EletronicDocumentsService
}