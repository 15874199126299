import React, { useState, useEffect } from "react";
// adicionado componente Modal e Form do ant
import { Layout, Menu, Dropdown, Row, Col, Card, message, Badge, Modal, Form, Input } from 'antd';
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { authenticationService } from '../../services/authentication';
import { useStateValue } from '../../state';
import { permissionsService } from '../../services/permissions';
import { usersService } from '../../services/users';
import * as _ from 'lodash';
import {
  ProjectOutlined,
  FolderOutlined,
  ProfileOutlined,
  CalendarOutlined,
  WarningOutlined,
  UserOutlined,
  HomeOutlined,
  BellFilled,
  MenuOutlined,
  BankOutlined,
  SettingOutlined,
  FilePdfOutlined,
  SnippetsOutlined,
  FileMarkdownOutlined,
  ClusterOutlined,
  TeamOutlined,
  FileAddOutlined,
  FolderOpenOutlined,
  FundProjectionScreenOutlined,
  DollarOutlined,
  ContactsOutlined,
  CheckCircleOutlined,
  HourglassOutlined,
  RiseOutlined,
  RadarChartOutlined
} from '@ant-design/icons';
import Logo from '../../assets/phdHeader.png';
import WarningPopup from '../warning-popup';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { tasksService } from "../../services/tasks";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const AppLayout = ({ permission: Permission, component: Component, ...rest }) => {

  const history = useHistory();
  const [{ authentication }, dispatch] = useStateValue();
  const [warningsOpened, setWarningsOpened] = useState(0);
  const [dataPermission, setDataPermission] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  // adicionado states para o modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [username, setUsername] = useState('');
  const [pass, setPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [rNewPass, setRNewPass] = useState('');
  const [profile, setProfile] = useState('');
  const [isError, setIsError] = useState(false);
  
  async function fetchPermission(pathname) {
    if (authentication) {

      localStorage.setItem('userId', authentication.userId);

      const r = await usersService.getProfileById(authentication.userId);
      const profileData = r.data.data[0];
      setProfile(profileData);

      localStorage.setItem('userName', profileData.Name);

      if (!profileData.error) {

        const profileId = r.data.data[0];

        let permissionsIds = await permissionsService.getProfileDetails(profileId.AccessProfileId);

        if (permissionsIds) {
          // Chamado permissionIds de Object.values para o filter funcionar
          let taskView = Object.values(permissionsIds.data.data).filter(data => data.Feature == "/taskAssignment")
          localStorage.setItem('task_view', taskView.length > 0);

          setDataPermission(permissionsIds.data.data);
          // validarAcesso(pathname, permissionsIds.data.data)
        }
      }
      else {
        message.error("Ocorreu um erro ao tentar se conectar na base de dados.", 3);
      }
    }
  }

  async function verifyPermission(perm) {
    let response = _.find(dataPermission, function (res) { return res.Id === perm; });
    return response;
  }

  const validarAcesso = (pathname, data) => {
    if (data) {
      const inx = data.findIndex(obj => obj.Feature === pathname);
      const permission = data.findIndex(obj => obj.Feature === "/workflow");
      localStorage.setItem('workflowPermission', permission !== -1 ? false : true);
      if (inx === -1 && pathname != '/' && !pathname.includes("/taskAssignment") && !pathname.includes("/schedule")) {
        history.push('error')
      }
    }
  }

  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    async function loadPermissions() {
      await fetchPermission(pathname);

      if (dataPermission.length > 0) {
        validarAcesso(pathname, dataPermission);
      }
    }

    if (pathname === "/" || pathname.includes("/schedule") || pathname.includes("/taskAssignment")) {
      // fetchPermission(pathname);
      loadPermissions();

    }
    else if (pathname !== "/login") {

      // fetchPermission(pathname);
      loadPermissions();

    }
    //validar permissão de acesso ou diretionar para nao permitido
  }, [location.pathname]);

  const onCollapse = collapsed => {

    setCollapsed(collapsed);
  };

  const logout = async () => {

    const hide = message.loading('Saindo...', 0);
    authenticationService.logout();
    hide();
    localStorage.clear();
    dispatch({ type: 'logout' });
  }

  const showModalChangePass = async () => {
    setIsModalVisible(true);
  }

  const onFinishFailed = (errorInfo) => {
    new Error(errorInfo);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangePass = async (values) => {
    // valida os campos
    setIsError(false);
    if (pass !== profile.Password){
      setIsError(true);
      return message.error("Senha atual incorreta", 5);
    }
    message.destroy(); 
    if (newPass === '' || rNewPass === ''){
      setIsError(true);
      return message.error("Campos 'Nova Senha' e 'Repetir Nova Senha' devem ser preenchidos.", 6);
    }
    message.destroy(); 
    if (newPass !== rNewPass){
      setIsError(true);
      return message.error("Campos 'Repetir Nova Senha' e 'Nova Senha' devem ser iguais.", 6);
    } 

    if (isError) return;
    // atualiza a senha no bd
    message.destroy();
    const { data: response } = await authenticationService.updatePass(authentication.userId, newPass);

    if (response) {
      if (response.error) {
        message.destroy();
        message.error(response.error.message, 5);
      }
      else {
        message.destroy();
        message.success('Senha alterada com sucesso!');
        setIsModalVisible(false);
      }
    } else {
      message.destroy();
      message.error("Falha na comunicação com o servidor, tente novamente.");
    }


    
    
  }
  const userMenu = (
    <Menu>
      <Menu.Item onClick={showModalChangePass}>
        Alterar Senha
      </Menu.Item>
      <Menu.Item onClick={() => logout()}>
        Sair
      </Menu.Item>
    </Menu>
  );

  const menu = [

    { path: "/", icon: <HomeOutlined style={{ color: "#474747" }} />, title: "Inicio", visible: true, permission: 0 },
    { path: "/myNotes", icon: <ProfileOutlined style={{ color: "#474747" }} />, title: "Minhas Tarefas", visible: true, permission: 9 }, /* 15 */
    { path: "/calendar", icon: <CalendarOutlined style={{ color: "#474747" }} />, title: "Agenda", visible: true, permission: 24 }, /* 27 */
    { path: "/cashback", icon: <HourglassOutlined style={{ color: "#474747" }} />, title: "Reembolso", visible: true, permission: 14 }, /* 7 */

    { path: "/register/", icon: <FolderOpenOutlined style={{ color: "#474747" }} />, title: "Cadastros", visible: true, permission: 29, hasChildren: true }, /* 16 */
    { path: "/permissions", icon: <FolderOutlined style={{ color: "#474747" }} />, title: "Acessos", visible: true, permission: 5, group: "/register/" }, /* 2 */
    { path: "/users", icon: <TeamOutlined style={{ color: "#474747" }} />, title: "Usuários", visible: true, permission: 4, group: "/register/" }, /* 5 */
    { path: "/customers", icon: <UserOutlined style={{ color: "#474747" }} />, title: "Clientes", visible: true, permission: 2, group: "/register/" }, /* 3 */
    { path: "/new-project", icon: <FundProjectionScreenOutlined style={{ color: "#474747" }} />, title: "Projetos", visible: true, permission: 35, group: "/register/" },
    { path: "/warnings", icon: <WarningOutlined style={{ color: "#474747" }} />, title: "Avisos", visible: true, permission: 1, group: "/register/" },
    { path: "/eletronicDocuments", icon: <FilePdfOutlined style={{ color: "#474747" }} />, title: "Documentos", visible: true, permission: 6, group: "/register/" },
    { path: "/outgoing", icon: <BankOutlined style={{ color: "#474747" }} />, title: "Despesas", visible: true, permission: 7, group: "/register/" }, /* 17 */
    { path: "/configPage", icon: <SettingOutlined style={{ color: "#474747" }} />, title: "Configurações", visible: true, permission: 10, group: "/register/" },
    { path: "/logs", icon: <ProfileOutlined style={{ color: "#474747" }} />, title: "Log", visible: true, permission: 16, group: "/register/" },

    { path: "/category/", icon: <FileAddOutlined style={{ color: "#474747" }} />, title: "Categorias", visible: true, permission: 30, hasChildren: true }, /* 18 */
    { path: "/categoryDocument", icon: <FilePdfOutlined style={{ color: "#474747" }} />, title: "Categoria de Documentos", visible: true, permission: 11, group: "/category/" }, /* 9 */
    { path: "/categoryUnit", icon: <FileMarkdownOutlined style={{ color: "#474747" }} />, title: "Unidades de Medida", visible: true, permission: 20, group: "/category/" }, /* 19 */
    { path: "/timeCourse", icon: <FileMarkdownOutlined style={{ color: "#474747" }} />, title: "Encerramento de Período", visible: true, permission: 28, group: "/category/" }, /* 26 */

    { path: "/projects/", icon: <FundProjectionScreenOutlined style={{ color: "#474747" }} />, title: "Projetos", visible: true, permission: 31, hasChildren: true }, /* 20 */
    { path: "/taskAssignment", icon: <SnippetsOutlined style={{ color: "#474747" }} />, title: "Atribuir Tarefas", visible: true, permission: 8, group: "/projects/" }, /* 11 */
    { path: "/project", icon: <ProjectOutlined style={{ color: "#474747" }} />, title: "Projetos", visible: true, permission: 3, group: "/projects/" }, /* 4 */
    { path: "/projectModel", icon: <ProjectOutlined style={{ color: "#474747" }} />, title: "Modelo de Projeto", visible: true, permission: 25, group: "/projects/" }, /* 4 */
    { path: "/workflow", icon: <ClusterOutlined style={{ color: "#474747" }} />, title: "Cadastro de Workflow", visible: true, permission: 13, group: "/projects/" }, /* 21 */
    { path: "/approvals", icon: <CheckCircleOutlined style={{ color: "#474747" }} />, title: "Aprovações", visible: true, permission: 17, group: "/projects/" }, /* 22 */

    { path: "/payments/", icon: <DollarOutlined style={{ color: "#474747" }} />, title: "Pagamentos", visible: true, permission: 32, hasChildren: true }, /* 23 */
    { path: "/report", icon: <RadarChartOutlined style={{ color: "#474747" }} />, title: "Relatórios", visible: true, permission: 21, group: "/payments/" }, /* 24 */
    { path: "/powerbi", icon: <RadarChartOutlined style={{ color: "#474747" }} />, title: "Power BI", visible: true, permission: 27, group: "/payments/" }, /* 25 */
    { path: "/supplierPayment", icon: <DollarOutlined style={{ color: "#474747" }} />, title: "Pagamento de Fornecedores", visible: true, permission: 18, group: "/payments/" },
    { path: "/professionalPayments", icon: <ContactsOutlined   style={{ color: "#474747" }} />, title: "Pagamento de Profissionais", visible: true, permission: 22 , group: "/payments/"}, /* 21 */

    { path: "/receipts/", icon: <DollarOutlined style={{ color: "#474747" }} />, title: "Recebimentos", visible: true, permission: 33, hasChildren: true }, /* 23 */
    { path: "/revenues", icon: <RiseOutlined style={{ color: "#474747" }} />, title: "Faturamento", visible: true, permission: 19, group: "/receipts/" },
  ]

  const onSelect = ({ key, history }) => {
    history.push(key);
  }

  return (
    <Route {...rest} render={matchProps => (
      authentication ?

        <Layout style={{height:"100vh"}}>

          <Header style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            alignItems: 'center'
          }}>
            <div className="ant-pro-global-header">
              <Row>
                <Col span={3}>
                  <div style={{ width: "50%", float: "left", height: "100%", paddingLeft: "10px" }}>
                    <img src={Logo} alt="logo" style={{ width: "130px", height: "83,2px" }}></img>
                  </div>
                </Col>
                <Col span={5}>
                  {/* <div style={{ display: "inline-block" }}>
                    <center>
                    <h1 style={{ float: 'center', color: "#f00" }}>{'PORTAL EM MANUTENÇÃO'}</h1>
                    </center>
                  </div> */}
                </Col>
                <Col span={16}>
                  <div style={{ float: "right", paddingRight: "10px" }}>
                    <div style={{ padding: 1, display: "inline-block" }}>
                      <center>
                        <Badge>
                          <BellFilled
                            onClick={() => setWarningsOpened(warningsOpened + 1)}
                            style={{ color: "#fff", fontSize: '20px' }} />
                        </Badge>
                      </center>
                    </div>
                    <div style={{ marginLeft: 15, display: "inline-block" }}>
                      <center>
                        <p style={{ color: "#fff" }}>{authentication.user}</p>
                      </center>
                    </div>
                    <div style={{ marginLeft: 15, display: "inline-block" }}>
                      <center>
                        <Dropdown overlay={userMenu}>
                          <MenuOutlined style={{ color: "#fff", fontSize: '20px', marginRight: "70%" }} />
                        </Dropdown>
                      </center>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Header>

          <Layout>
            <Sider
              collapsible
              collapsed={collapsed}
              onCollapse={onCollapse}
              width={250}
              style={{
                overflow: 'auto',
                borderRight: "1px solid #1e1e1e",
                backgroundColor: "#eee"
              }}
            >
              {/* <div className="logo" /> */}

              <Menu
                mode="inline"
                selectedKeys={[matchProps.match.path]}
                onSelect={({ ...args }) => onSelect({ ...args, history: matchProps.history })}
                style={{ backgroundColor: "#EEEEEE" }}
              >
                {Object.keys(menu).filter((f) => menu[f].visible && !menu[f].group).map((k) => {
                  const item = menu[k];
                  let showMenu = !(item.permission > 0);

                  if (item.hasChildren) {
                    if (!(showMenu) && (dataPermission.find(permission => permission.Id == item.permission))) {
                      showMenu = true;
                    }

                    if (showMenu) {
                      return <SubMenu
                        style={{ backgroundColor: "#EEEEEE" }}
                        key={k}
                        icon={item.icon}
                        title={
                          <span>
                            <span style={{ color: "#474747", fontWeight: "bold" }}>{item.title}</span>
                          </span>
                        }>
                        {menu.filter(menu => dataPermission
                          .find(permission => permission.Id == menu.permission && menu.group == item.path && !menu.hasChildren))
                          .map(menuItem => (
                            <Menu.Item key={menuItem.path} style={{ backgroundColor: "#EEEEEE" }} icon={menuItem.icon}>
                              <span style={{ color: "#474747", fontWeight: "bold", backgroundColor: "#EEEEEE" }}>{menuItem.title}</span>
                            </Menu.Item>
                          ))}
                      </SubMenu>
                    }
                  }
                  else {
                    if (!(showMenu) && (dataPermission.find(permission => permission.Id == item.permission))) {
                      showMenu = true;
                    }

                    if (showMenu) {
                      return <Menu.Item key={item.path} style={{ backgroundColor: "#EEEEEE" }} icon={item.icon} >
                        <span style={{ color: `${collapsed ? '#EEEEEE' : '#474747'}`, fontWeight: "bold" }}> {item.title}</span>
                      </Menu.Item>
                    }
                  }
                })}
              </Menu>
            </Sider>

            <Layout style={{ padding: '0 24px 24px' }}>
              <Content
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280
                }}
              >
                {/* modal */}
                <Modal title={"Alterar Senha"}
                  width={"50%"}
                  visible={isModalVisible}
                  onOk={onChangePass}
                  onCancel={handleCancel}
                  cancelButtonProps={{ style: { display: 'none' } }}
                  okButtonProps={{
                    form: "changepass-form",
                    key: "submit",
                    htmlType: "submit",
                  }}
                  okText={"Salvar"} >
                  <Form style={{ width: "120%" }} onFinish={onChangePass} onFinishFailed={onFinishFailed} id="changepass-form">
                    <Row>
                      <div style={{ width: "100%" }}>
                        <Input.Group compact>
                          <div style={{ display: "block", width: "42%", padding: "0 1%" }}>
                            <p>Usuário:</p>
                            <Form.Item name='username'>
                              <Input style={{ width: "100%" }} disabled={true} defaultValue={authentication.user} value={setUsername(authentication.user)} />
                            </Form.Item>
                          </div>
                          <div style={{ display: "block", width: "42%", padding: "0 1%" }}>
                            <p>Senha Atual:</p>
                            <Form.Item 
                              name="pass"
                              rules={[
                                {
                                  required: true,
                                  message: 'Digite sua senha atual',
                                },
                              ]}>
                              <Input.Password style={{ width: "100%" }} value={pass} onChange={(e) => setPass(e.target.value)} />
                            </Form.Item>
                          </div>
                          <div style={{ display: "block", width: "42%", padding: "0 1%" }}>
                            <p>Nova Senha:</p>
                            <Form.Item 
                              name="newpass"
                              rules={[
                                {
                                  required: true,
                                  message: 'Digite sua nova senha',
                                },
                              ]}>
                              <Input.Password style={{ width: "100%" }} value={newPass} onChange={(e) => setNewPass(e.target.value)} />
                            </Form.Item>
                          </div>
                          <div style={{ display: "block", width: "42%", padding: "0 1%" }}>
                            <p>Repetir Nova Senha:</p>
                            <Form.Item 
                              name="rnewpass"
                              rules={[
                                {
                                  required: true,
                                  message: 'Repita sua nova senha',
                                },
                              ]}>
                              <Input.Password style={{ width: "100%" }} value={rNewPass} onChange={(e) => setRNewPass(e.target.value)} />
                            </Form.Item>
                          </div>


                        </Input.Group>
                      </div>
                    </Row>
                  </Form>
                </Modal >

                <WarningPopup warningsOpened={warningsOpened} />

                <Row>
                  <Col span={24}>
                    <Card bordered={false} style={{ maxHeight:"85vh", overflow: 'auto' }}>
                      {Permission !== 0 ? !verifyPermission(Permission) ? <Redirect to='/error' /> : <Component {...matchProps} authentication={authentication} /> : <Component {...matchProps} authentication={authentication} />}
                    </Card>
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Layout>
        </Layout >

        :
        <Redirect to='/login' />
    )} />
  )
}
export default AppLayout;