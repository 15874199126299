import React from "react";
import {
  Form,
  Row,
  Col,
  Table,
  Input,
  Spin,
  Button,
  Tooltip,
  message,
  Popconfirm
} from 'antd';
import { FilterFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import WarningAddModal from '../../components/warnings-add-modal';
import WarningEditModal from '../../components/warning-edit-modal';
import { warningsService } from '../../services/warnings';
import { FloatingButton } from '../../components/floating-button';
import moment from "moment";
moment.locale('pt-BR');

const { Search } = Input;

class Warning extends React.Component {


  state = {
    loading: false,
    fetching: true,
    modal: false,
    addwarning: false,
    editwarning: false,
    Id: undefined,
    dataWarning: [],
    dataSource: [],
    path: "não encontrado"
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ fetching: true });
    const { data: DataGetWarning, error: ErrorGetWaring } = await warningsService.getWarnings(false);
    if (!ErrorGetWaring && !DataGetWarning.error) {
      console.log(DataGetWarning.data);
      this.setState({ fetching: false, dataSource: DataGetWarning.data });
    }
    // para fechar o loading, mesmo que tenha erro, encerrei-o. é isso mesmo?
    this.setState({ fetching: false });

  }

  searchTerm = (value) => {
    if (value) {
      this.setState({ fetching: true });
      const filtrado = this.state.dataSource.filter((x) => {
        return x.Title.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ dataSource: filtrado, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  render() {

    const columns = [
      {
        title: 'Título',
        dataIndex: 'Title',
        key: 'Title',
        align: 'left',
        width: 110,
      },
      {
        title: 'Descrição',
        dataIndex: 'Description',
        key: 'Description',
        align: 'left',
        width: 150,
      },
      {
        title: 'Inicio',
        key: 'StartDate',
        align: 'center',
        width: 50,
        defaultSortOrder: 'descend',
        //render: (text, record) => <span>{moment(record.StartDate).format("MM/DD/YYYY")}</span>
        render: (text, record) => <span>{record.StartDate}</span>
      },
      {
        title: 'Fim',
        key: 'EndDate',
        align: 'center',
        width: 50,
        //render: (text, record) => <span>{moment(record.EndDate).format("MM/DD/YYYY")}</span>
        render: (text, record) => <span>{record.EndDate}</span>
      },
      {
        title: 'Ação',
        key: 'Action',
        width: 50,
        align: 'center',
        render: (text, record) => (
          <Button.Group >
            <div style={{ padding: "7px" }}>
              <Tooltip placement="top" title={"Editar"}>
                <a>
                  <EditOutlined onClick={() => this.setState({ editwarning: true, Id: record.Id, dataWarning: record })} />
                </a>
              </Tooltip>
            </div>
            <div style={{ padding: "7px" }}>
              <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                onConfirm={() => onCancel(record.Id)} okText="Sim" cancelText="Não">
                <Tooltip placement="top" title={"Excluir"}>
                  <a>
                    <DeleteOutlined />
                  </a>
                </Tooltip>
              </Popconfirm>
            </div>
          </Button.Group>
        ),
      },
    ];

    const onCancel = async (value) => {
      message.loading('Deletando Alerta..', 100);
      this.setState({ addwarning: false, fetching: true });

      const data = {
        Id: value
      }
      await warningsService.deleteWarning(data).then(() => {
      }).catch(err => {
        message.destroy();
        this.setState({ fetching: false });
        message.error('Erro ao deletar', 3);
      }).finally(() => {
        this.fetch();
        message.destroy();
        message.success('Alerta deletado com sucesso', 3);
      });
    }

    const onClose = async () => {
      this.fetch();
      this.setState({ addwarning: false, fetching: false, editwarning: false });
    }

    return (
      <Spin spinning={this.state.fetching} delay={500}>
        <Form /* style={{ minHeight: '90vh' }} */>

          <WarningEditModal
            title={"Editar Aviso: " + this.state.Id}
            id={this.state.Id}
            data={this.state.dataWarning}
            visible={this.state.editwarning}
            onClose={() => onClose()}
            destroyOnClose={() => onClose()}
            onCancel={() => this.setState({ editwarning: false })} />

          <WarningAddModal
            title="Adicionar Aviso"
            visible={this.state.addwarning}
            destroyOnClose={() => onClose()}
            onClose={() => onClose()}
            onCancel={() => this.setState({ addwarning: false })} />

          <Row style={{ paddingBottom: "25px" }}>
            <div style={{ width: "60%" }}>
              <b style={{ textAlign: "left", fontSize: "30px" }}>Cadastro de avisos</b>
            </div>
            {/* <div style={{ width: "35%" }}>
              <Search placeholder="Pesquisar" onSearch={value => this.searchTerm(value)} />
            </div> */}
            {/* <div style={{ width: "5%", float: "right" }}>
              <center>
                <FilterFilled onClick={() => this.setState({ modal: true })} style={{ fontSize: "20px", padding: "5px" }} />
              </center>
            </div> */}
          </Row>
          <Row style={{ width: "100%"/* , minHeight: "90vh" */ }}>
            <Col style={{ border: "1px solid #e8e8e8" }} span={24} >
              <Table columns={columns} dataSource={this.state.dataSource} style={{ height: "100%" }} pagination={false} />
            </Col>
          </Row>
        </Form >
        <FloatingButton icon="+" onClick={() => this.setState({ addwarning: true })} />
      </Spin>
    )
  }
}

export default Warning;