import React from "react";
import {
  Row,
  Col,
  Table,
  Drawer,
  Input,
  Spin,
  Checkbox,
  message,
  Button,
  DatePicker,
  Select,
} from "antd";
import moment from "moment";
import { FilterFilled } from "@ant-design/icons";
import { itemsService } from "../../services/items";
import { PaymentService } from "../../services/payments";
import { LogService } from "../../services/process-logs";
import { scheduleService } from '../../services/schedule';
import * as _ from "lodash";
import Schedule from "../schedule";
moment.locale('pt-BR');


const { Search } = Input
class Revenue extends React.Component {

  state = {
    fetching: false,
    dataSource: [],
    drawer: false,
    checkedList: [],
    checked: false,
    filteredDataSource: [],
    dateMax: "",
    dateMin: "",
    OBPLDataSource: [],
    obpl: 0,
    dateCompet: "",
    dimension1DataSource: [],
    dimension2DataSource: [],
    dimension3DataSource: [],
    dimension4DataSource: [],
    dimension5DataSource: [],
    dimension1Selected: [],
    dimension2Selected: [],
    dimension3Selected: [],
    dimension4Selected: [],
    dimension5Selected: [],
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ fetching: true });
    let revenueDataSource = []
    const schedulerData = await scheduleService.getSchedule();
    const schedule = schedulerData.data.data;

    //cliente, verificar se vai precisar mesmo desta tela
    const ListRevenue = await itemsService.getList(17);
    const revenueData = ListRevenue.data.data;
    if (revenueData) {
      let revenueList = await this.setId(revenueData.items)
      for (const revenue of revenueList) {
        let getScheduler = schedule.find(item => item.DraftOrder == revenue.Code ? item : null);
        if (getScheduler) {
          if (getScheduler.Billing !== "N" && getScheduler.ConclusionPercentage === 100) {
            revenueDataSource.push(revenue);
          }
        }
      }
    }

    const dimensionsData = await PaymentService.getDimensions();
    const dimensions = dimensionsData.data;
    if (!dimensions.error) {
      const dim = dimensions.data;

      for (let index = 1; index < 6; index++) {
        let dimension = dim.map(find => { return find.DimCode == index ? { label: find.PrcName, value: find.PrcCode } : null }).filter(find => find != null);
        if (index == 1) {
          this.setState({ dimension1DataSource: dimension });
        }
        if (index == 2) {
          this.setState({ dimension2DataSource: dimension });
        }
        if (index == 3) {
          this.setState({ dimension3DataSource: dimension });
        }
        if (index == 4) {
          this.setState({ dimension4DataSource: dimension });
        }
        if (index == 5) {
          this.setState({ dimension5DataSource: dimension });
        }
      }
    }

    const BP = await itemsService.getBusinessPlace();
    const BusinessPlace = BP.data.data.items;
    let options = BusinessPlace.map(find => {
      return { value: find.BPLId, label: find.BPLName }
    })

    this.setState({
      dataSource: revenueDataSource,
      fetching: false,
      filteredDataSource: revenueDataSource,
      OBPLDataSource: options,
      dimension1Selected: [],
      dimension2Selected: [],
      dimension3Selected: [],
      dimension4Selected: [],
      dimension5Selected: [],
    });
  };

  setId = async (data) => {
    let key = 0;
    let dataSource = []
    for (let item of data) {
      dataSource.push(item = { ...item, Id: key++ })
    }
    return dataSource
  }

  //date filter
  onChangeDate = (value, type) => {
    this.setState({ fetching: true });
    if (type == "max") {
      //data inicial
      if (!value) {
        if (this.state.dateMin) {
          const filtered = this.state.dataSource.filter(x => {
            return moment(x.Date) <= moment(this.state.dateMin) ? x : null
          });
          this.setState({ filteredDataSource: filtered, fetching: false, dateMax: "" });

        }
        else {
          this.setState({ fetching: false, dateMax: "" });
          this.fetch();
        }
      }
      else {
        const filtered = this.state.dataSource.filter(x => {
          return moment(x.Date) >= moment(value) ? x : null
        });
        this.setState({ filteredDataSource: filtered, fetching: false, dateMax: value });
      }
    }
    else {
      //data final
      if (!value) {
        if (this.state.dateMax) {

          const filtered = this.state.dataSource.filter(x => {
            return moment(x.Date) >= moment(this.state.dateMax) ? x : null
          });
          this.setState({ filteredDataSource: filtered, fetching: false, dateMin: "" });
        }
        else {
          this.setState({ fetching: false, dateMin: "" });
          this.fetch();
        }
      }
      else {
        const filtered = this.state.filteredDataSource.filter(x => {
          return moment(x.Date) <= moment(value) ? x : null
        });
        this.setState({ filteredDataSource: filtered, fetching: false, dateMin: value });
      }
    }

  }

  //checklist
  onChange = (e, key) => {
    let code = e.target.value
    let checked = e.target.checked;
    if (checked) {
      this.state.checkedList.push({ id: key, value: code });
    }
    else {
      this.state.checkedList = this.state.checkedList.filter(r => r.id != key);
    }
  };

  searchTerm = (value) => {
    if (value) {
      this.setState({ fetching: true });
      const filtrado = this.state.dataSource.filter(item => {
        return item.PartnerName.toLowerCase().includes(value.toLowerCase()) ||
          item.PartnerCode.toLowerCase().includes(value.toLowerCase()) ||
          item.ProjectName.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ filteredDataSource: filtrado, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  createLog = async (saleSending, saleReturn, items) => {
    let data;
    let item = items.DocumentLines;
    if (saleReturn.error) {
      for (const obj of item) {
        data = {
          ProjectName: saleSending.ProjectName,
          ProjectId: saleSending.ProjectId,
          Client: saleSending.PartnerCode,
          OrderType: "VENDA",
          Status: "ERROR",
          LogDate: moment(new Date()).format("YYYY-MM-DD"),
          ErrorDetail: (saleReturn.error.innerMessage.slice(saleReturn.error.innerMessage.indexOf(", ") + 1)).trim().replace(/'/g, ''),
          Item: obj.ItemCode,
          Date: moment(saleSending.Date).format("YYYY-MM-DD"),
          Value: obj.UnitPrice,
          Quantity: obj.Quantity,
          TotalValue: parseInt(obj.UnitPrice) * parseInt(obj.Quantity),
          BPLID: this.state.obpl,
          ProjectDoc: "",
          CardCode: "",
          DateCompet: moment(this.state.dateCompet).format("YYYY-MM-DD"),
          ClientName: saleSending.PartnerName,
          Dim1: obj.CostingCode,
          Dim2: obj.CostingCode2,
          Dim3: obj.CostingCode3,
          Dim4: obj.CostingCode4,
          Dim5: obj.CostingCode5,
        }
        const logData = await LogService.insertLog(data);
        let log = logData.data;
        if (log.error) {
          message.destroy();
          message.error("Ocorreu um erro ao salvar o log.", 3);
        }
      }
    }
    else {
      for (const obj of item) {
        data = {
          ProjectName: saleSending.ProjectName,
          ProjectId: saleSending.ProjectId,
          Client: saleSending.PartnerCode,
          OrderType: "VENDA",
          Status: "OK",
          LogDate: moment(new Date()).format("YYYY-MM-DD"),
          ErrorDetail: "",
          Item: obj.ItemCode,
          Date: moment(saleSending.Date).format("YYYY-MM-DD"),
          Value: obj.UnitPrice,
          Quantity: obj.Quantity,
          TotalValue: parseInt(obj.UnitPrice) * parseInt(obj.Quantity),
          BPLID: this.state.obpl,
          ProjectDoc: "",
          CardCode: "",
          DateCompet: moment(this.state.dateCompet).format("YYYY-MM-DD"),
          ClientName: saleSending.PartnerName,
          Dim1: obj.CostingCode,
          Dim2: obj.CostingCode2,
          Dim3: obj.CostingCode3,
          Dim4: obj.CostingCode4,
          Dim5: obj.CostingCode5,
        }
        const logData = await LogService.insertLog(data);
        let log = logData.data;
        if (log.error) {
          message.destroy();
          message.error("Ocorreu um erro ao salvar o log.", 3);
        }
      }
    }
  }

  sendingSaleOrder = async () => {
    if (this.validateFields()) {
      let documents = [];
      message.loading("Inserindo faturamento ...", 100);
      this.setState({ fetching: true });
      const checklist = this.state.checkedList;
      const dataFiltered = this.state.filteredDataSource;


      let customerSelected = dataFiltered.find(r => r.Code == checklist[0].id);
      let customerData = [];

      for (const item of checklist) {
        let customerSelectedData = dataFiltered.find(find => find.Code == item.id);
        if (customerSelectedData) {
          customerData.push(customerSelectedData);
        }
      }
      for (const item of customerData) {

        let Dim1 = this.state.dimension1Selected.find(find => find.Id == item.Code);
        let Dim2 = this.state.dimension2Selected.find(find => find.Id == item.Code);
        let Dim3 = this.state.dimension3Selected.find(find => find.Id == item.Code);
        let Dim4 = this.state.dimension4Selected.find(find => find.Id == item.Code);
        let Dim5 = this.state.dimension5Selected.find(find => find.Id == item.Code);

        documents.push({
          ItemCode: item.ItemCode,
          Quantity: item.Quantity,
          TaxCode: "",
          UnitPrice: item.Price,
          CostingCode: Dim1.Value,
          CostingCode2: Dim2.Value,
          CostingCode3: Dim3.Value,
          CostingCode4: Dim4.Value,
          CostingCode5: Dim5.Value,
        })
      }

      let data = {
        CardCode: customerSelected.PartnerCode,
        BPL_IDAssignedToInvoice: this.state.obpl,
        U_ALFA_DT_Compet: moment(this.state.dateCompet).format("YYYY-MM-DD"),
        DocDueDate: moment(customerSelected.Date).format("YYYY-MM-DD"),
        DocumentLines: documents
      }

      const saleOrder = await PaymentService.insertSaleOrder(data);
      const sale = saleOrder.data
      if (sale.error) {
        message.destroy();
        message.error("Ocorreu um erro ao inserir o faturamento.", 3);
        for (const item of customerData) {
          await itemsService.updateStatusDraft(item.Code, "E");
        }
      }
      else {
        message.destroy();
        message.success("Faturamento realizado com sucesso.", 3);
        for (const item of customerData) {
          await itemsService.updateStatusDraft(item.Code, "S");
        }
      }
      await this.createLog(customerSelected, sale, data);
      await this.fetch();
      this.setState({ fetching: false, checkedList: [] });
    }
  }

  onSelectDimensions = (value, id, type, recordId) => {
    let dim1 = this.state.dimension1Selected;
    let dim2 = this.state.dimension2Selected;
    let dim3 = this.state.dimension3Selected;
    let dim4 = this.state.dimension4Selected;
    let dim5 = this.state.dimension5Selected;

    if (type == "Dim1") {
      let dim = dim1.findIndex(find => find.Record == recordId);
      if (dim == -1) {
        dim1.push({ Id: id, Value: value, Record: recordId });
      }
      else {
        dim1.splice(dim, 1);
        dim1.push({ Id: id, Value: value, Record: recordId });
      }
    }
    if (type == "Dim2") {
      let dim = dim2.findIndex(find => find.Record == recordId);
      if (dim == -1) {
        dim2.push({ Id: id, Value: value, Record: recordId });
      }
      else {
        dim2.splice(dim, 1);
        dim2.push({ Id: id, Value: value, Record: recordId });
      }
    }
    if (type == "Dim3") {
      let dim = dim3.findIndex(find => find.Record == recordId);
      if (dim == -1) {
        dim3.push({ Id: id, Value: value, Record: recordId });
      }
      else {
        dim3.splice(dim, 1);
        dim3.push({ Id: id, Value: value, Record: recordId });
      }
    }
    if (type == "Dim4") {
      let dim = dim4.findIndex(find => find.Record == recordId);
      if (dim == -1) {
        dim4.push({ Id: id, Value: value, Record: recordId });
      }
      else {
        dim4.splice(dim, 1);
        dim4.push({ Id: id, Value: value, Record: recordId });
      }
    }
    if (type == "Dim5") {
      let dim = dim5.findIndex(find => find.Record == recordId);
      if (dim == -1) {
        dim5.push({ Id: id, Value: value, Record: recordId });
      }
      else {
        dim5.splice(dim, 1);
        dim5.push({ Id: id, Value: value, Record: recordId });
      }
    }
  }

  validateFields = () => {
    if (!this.state.checkedList[0]) {
      message.error("Nenhum pagamento selecionado.", 3);
      return false
    }
    let differentProject = this.state.checkedList.filter(find => find.value == this.state.checkedList[0].value);
    if (differentProject.length !== this.state.checkedList.length) {
      message.error("Selecione somente um projeto.", 3);
      return false
    }
    if (!this.state.obpl) {
      message.error("Nenhuma empresa selecionado.", 3);
      return false
    }
    if (!this.state.dateCompet) {
      message.error("Selecione uma data de competência.", 3);
      return false
    }
    if (this.state.checkedList.length !== this.state.dimension1Selected.length ||
      this.state.checkedList.length !== this.state.dimension2Selected.length ||
      this.state.checkedList.length !== this.state.dimension3Selected.length ||
      this.state.checkedList.length !== this.state.dimension4Selected.length ||
      this.state.checkedList.length !== this.state.dimension5Selected.length) {
      message.error("Falta algumas dimensões para selecionar.", 3);
      return false
    }
    return true;
  }

  render() {

    const columns = [
      {
        title: "",
        key: 'Action',
        width: "5%",
        align: 'center',
        render: (text, record) => (
          <Button.Group >
            <div style={{ padding: "7px" }}>
              <Checkbox value={record.PartnerCode} onChange={(e) => this.onChange(e, record.Code)} />
            </div>
          </Button.Group>
        ),
      },
      {
        title: 'Projeto',
        dataIndex: 'ProjectName',
        key: 'ProjectName',
        align: 'left',
        width: "10%",
      },
      {
        title: "Clientes",
        dataIndex: "partner",
        key: "Partner",
        align: "left",
        width: "5%",
        render: (text, record) => <span>{record.PartnerCode + " - " + record.PartnerName}</span>
      },
      {
        title: "Pedidos de venda",
        dataIndex: "Code",
        key: "Code",
        align: "center",
        width: "5%",
      },
      {
        title: "Item do pedido",
        dataIndex: "Name",
        key: "Name",
        align: "center",
        width: "5%",
      },
      {
        title: "Data",
        dataIndex: "Date",
        key: "Date",
        align: "center",
        width: "5%",
        render: (text, record) => <span>{(record.Date ? moment(record.Date).format('DD/MM/YYYY') : '')}</span>
      },
      {
        title: "Quantidade",
        dataIndex: "Quantity",
        key: "Quantity",
        align: "center",
        width: "5%",
        render: (text, record) => <span>{_.round(record.Quantity, 2)}</span>
      },
      {
        title: "Dimensão 1",
        dataIndex: "Dimension1",
        key: "Dimension1",
        align: "center",
        width: "10%",
        render: (text, record) => <span>{<Select options={this.state.dimension1DataSource} style={{ width: "100%" }} onSelect={(e) => this.onSelectDimensions(e, record.Code, "Dim1", record.Id)} />}</span>
      },
      {
        title: "Dimensão 2",
        dataIndex: "Dimension2",
        key: "Dimension2",
        align: "center",
        width: "10%",
        render: (text, record) => <span>{<Select options={this.state.dimension2DataSource} style={{ width: "100%" }} onSelect={(e) => this.onSelectDimensions(e, record.Code, "Dim2", record.Id)} />}</span>
      },
      {
        title: "Dimensão 3",
        dataIndex: "Dimension3",
        key: "Dimension3",
        align: "center",
        width: "10%",
        render: (text, record) => <span>{<Select options={this.state.dimension3DataSource} style={{ width: "100%" }} onSelect={(e) => this.onSelectDimensions(e, record.Code, "Dim3", record.Id)} />}</span>
      },
      {
        title: "Dimensão 4",
        dataIndex: "Dimension4",
        key: "Dimension4",
        align: "center",
        width: "10%",
        render: (text, record) => <span>{<Select options={this.state.dimension4DataSource} style={{ width: "100%" }} onSelect={(e) => this.onSelectDimensions(e, record.Code, "Dim4", record.Id)} />}</span>
      },
      {
        title: "Dimensão 5",
        dataIndex: "Dimension5",
        key: "Dimension5",
        align: "center",
        width: "10%",
        render: (text, record) => <span>{<Select options={this.state.dimension5DataSource} style={{ width: "100%" }} onSelect={(e) => this.onSelectDimensions(e, record.Code, "Dim5", record.Id)} />}</span>
      },
      {
        title: "Valor unitário",
        dataIndex: "Price",
        key: "Price",
        align: "center",
        width: "5%",
        render: (text, record) => <span>{"R$ " + parseFloat(record.Price).toFixed(2)}</span>
      },
      {
        title: "Valor total",
        dataIndex: "Total",
        key: "Total",
        align: "center",
        width: "5%",
        render: (text, record) => <span>{"R$ " + parseFloat(record.Total).toFixed(2)}</span>
      },
    ];

    const fetching = this.state.fetching;
    const business = this.state.OBPLDataSource;

    return (
      <Spin spinning={fetching} delay={500}>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "Right", fontSize: "30px" }}>Faturamento para clientes</b>
          </div>
          <div style={{ width: "35%" }}>
            <Search placeholder="Pesquisar" onSearch={value => this.searchTerm(value)} />
          </div>
          <div style={{ width: "5%", float: "right" }}>
            <center>
              <FilterFilled onClick={() => this.setState({ drawer: true })} style={{ fontSize: "20px", padding: "5px" }} />
            </center>
          </div>
        </Row>
        <Row style={{ paddingBottom: "10px" }}>
          <Select style={{ width: "30%" }} options={business} placeholder="Selecione uma empresa" onSelect={(e) => this.setState({ obpl: e })} />
          <div style={{ paddingLeft: "5px", width: "20%" }}>
            <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder="Competência" onSelect={(e) => this.setState({ dateCompet: e })} />
          </div>
        </Row>
        <Row>
          <Drawer
            title="Filtros"
            placement="right"
            closable={false}
            onClose={() => this.setState({ drawer: false })}
            visible={this.state.drawer} >
            <div style={{ paddingBottom: "3%" }}>
              <p>Selecione um periodo:</p>
              <div style={{ paddingBottom: "3%" }}>
                <DatePicker format="DD/MM/YYYY" placeholder="Data inicial" style={{ width: "100%" }} onChange={(value) => this.onChangeDate(value, "max")} />
              </div>
              <DatePicker format="DD/MM/YYYY" placeholder="Data final" style={{ width: "100%" }} onChange={(value) => this.onChangeDate(value, "min")} />
            </div>
          </Drawer>
        </Row>
        <Col>
          <Table columns={columns} dataSource={this.state.filteredDataSource} style={{ height: "100%" }} pagination={true} scroll={{ x: 2000 }} />
        </Col>
        <div style={{ float: "right", paddingTop: "1%" }}>
          <Button type="primary" onClick={() => this.sendingSaleOrder()}>Gerar</Button>
        </div>
      </Spin>
    )
  }
}

export default Revenue;
