import React from "react";
import {
  Row,
  Col,
  Table,
  Modal,
  Input,
  Spin,
  Tooltip,
  message,
  Checkbox,
  Form,
} from "antd";
import { DeleteOutlined, FilterFilled, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import * as _ from 'lodash';
import { permissionsService } from "../../services/permissions";
import { FloatingButton } from "../../components/floating-button";
moment.locale("pt-BR");

const { Search } = Input;

class Permissions extends React.Component {
  state = {
    show: false,
    fetching: false,
    profileList: [],
    profileDetails: [],
    permissionsList: [],
    edit: false,
    profileBackup: [],
    selectedList: [],
  };

  formRef = React.createRef();

  showModal = (code) => {
    this.setState({ fetching: true, edit: false });
    permissionsService.getPermissions().then((r) => {
      const { data } = r.data;
      var options = data.map(function (val) {
        return {
          label: val.Description,
          value: val.Id,
          checked: false,
          style: {
            marginRight: '20%'
          },
        };
      });
      this.formRef.current.resetFields();
      if (code && code.Id) {
        this.fetchDetails(code);
      }

      options.sort((a, b) => {
        const aValue = a.label.toLowerCase()
        const bValue = b.label.toLowerCase()

        if (aValue < bValue) {
          return -1;
        }

        if (aValue > bValue) {
          return 1;
        }

        return 0;
      })

      this.setState({
        permissionsList: options,
        fetching: false,
      });
    });
    // modal
    this.setState({
      visible: true,
    });
  };

  fetchDetails = async (code) => {
    this.setState({ fetching: true, edit: true });
    await permissionsService
      .getProfileDetails(code.Id)
      .then((r) => {
        const { data } = r.data;
        const permissoes = data.map((registro) => {
          return registro.Id;
        });
        this.setState({ selectedList: permissoes })
        this.formRef.current.setFieldsValue({
          Id: code.Id,
          Title: code.Name,
          Description: code.Description,
          Permissions: permissoes,
        });
      });
    this.setState({ fetching: false });
  };

  handleOk = (e) => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.formRef.current.resetFields();
    // console.log(e);
    this.setState({
      visible: false,
      selectedList: [],
    });
  };

  componentDidMount() {
    if (this.props.match.params.type !== ":type") {
      this.fetch(this.props.match.params.type);
    }
  }

  fetch = async (type) => {
    this.setState({ fetching: true });
    await permissionsService.getProfiles().then((r) => {
      const { data } = r.data;
      this.setState({
        profileList: data,
        profileBackup: data,
        fetching: false,
      });
    });
  };

  deleteProfile = async (id, quantidade) => {
    if (quantidade > 0) {
      message.error("Não é possivel remover pois o grupo está em utilização.");
    } else {
      permissionsService.removeProfile(id).then((r) => {
        //const { data } = r.data;
        message.success("Grupo de acesso removido com sucesso.");
        this.fetch();
      });
    }
  };

  onChange = (type) => {
    this.props.history.push(type);
    this.setState({ logList: undefined });
    this.fetch(type);
  };

  searchTerm = (value) => {
    const filtrado = this.state.profileBackup.filter((x) => {
      return x.Name.toLowerCase().includes(value.toLowerCase());
    });
    this.setState({ profileList: filtrado });
  };

  onGroupChange = (checkedList) => {
    this.setState({ selectedList: checkedList })
  }

  render() {
    const columns = [
      {
        title: "Nome",
        dataIndex: "Name",
        key: "Name",
        //sorter: (a, b) => a.Name - b.Name,
        width: 150,
      },
      {
        title: "Descrição",
        dataIndex: "Description",
        key: "Description",
        width: 500,
        defaultSortOrder: "descend",
        //sorter: (a, b) => a.Description - b.Description,
        onFilter: (value, record) => record.name.indexOf(value) === 0,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "",
        id: "details-button",
        render: (text, record) => (
          <span>
            <Tooltip placement="top" title="Editar">
              <a
                style={{ padding: "15px" }}
                onClick={() => this.showModal(record)}
              >
                <EditOutlined />
              </a>
            </Tooltip>
            <Tooltip placement="top" title="Excluir">
              <a onClick={() => this.deleteProfile(record.Id, record.Quantity)}>
                <DeleteOutlined />
              </a>
            </Tooltip>
          </span>
        ),
        width: 70,
      },
    ];

    const { TextArea } = Input;
    const fetching = this.state.fetching;
    const profileList = this.state.profileList;
    const permissionList = this.state.permissionsList;
    const editar = this.state.edit;

    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 18 },
    };

    const onUpdate = async (values) => {
      if (validateFields(values)) {
        message.loading("Atualizando grupo de acesso..", 100);
        const data = {
          Id: values.Id,
          Name: values.Title,
          Description: values.Description,
          Permissions: this.state.selectedList
        };
        this.setState({ addwarning: false });
        await permissionsService
          .updateProfile(data)
          .then(() => {
            message.destroy();
            message.success("Grupo de acesso atualizado com sucesso.");
            this.fetch();
          })
          .catch((err) => {
            message.destroy();
            message.error("Ocorreu um erro ao inserir o grupo de acesso", 3);
          })
          .finally(() => {
            message.destroy();
          });
        this.formRef.current.resetFields();
        this.setState({ visible: false });
      }
    };

    const onFinish = async (values) => {
      if (validateFields(values)) {
        message.loading("Criando grupo de acesso..", 100);
        const data = {
          Name: values.Title,
          Description: values.Description,
          Permissions: this.state.selectedList,
        };
        this.setState({ addwarning: false });
        await permissionsService
          .createProfile(data)
          .then(() => {
            message.destroy();
            message.success("Grupo de acesso criado com sucesso.");
            this.fetch();
          })
          .catch((err) => {
            message.destroy();
            message.error("Ocorreu um erro ao inserir o grupo de acesso.", 3);
          })
          .finally(() => {
            this.fetch();
            message.destroy();
          });
        this.formRef.current.resetFields();
        this.setState({ visible: false });
      }
    };

    const validateFields = (values) => {
      if (!values.Title) {
        message.error('Campo título não preenchido.', 3);
        return false;
      }
      if (!values.Description) {
        message.error('Campo descrição não preenchido.', 3);
        return false;
      }
      if (!this.state.selectedList) {
        message.error('Necessário informar ao menos uma permissão.', 3);
        return false;
      }
      return true;
    }

    return ( 
      // <Spin spinning={fetching} delay={0}>
      //   <Row style={{ paddingBottom: "25px" }}>
      //     <div style={{ width: "60%" }}>
      //       <b style={{ textAlign: "left", fontSize: "30px" }}>
      //         Cadastro do direitos de acesso
      //       </b>
      //     </div>
      //     <div style={{ width: "35%" }}>
      //       <Search
      //         placeholder="Pesquisar"
      //         onSearch={(value) => this.searchTerm(value)}
      //       />
      //     </div>
      //     <div style={{ width: "5%", float: "right" }}>
      //       <center>
      //         <FilterFilled style={{ fontSize: "20px", padding: "5px" }}/>
      //       </center>
      //     </div>
      //   </Row>
      <Spin spinning={fetching} delay={0}>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "left", fontSize: "30px" }}>
              Cadastro do direitos de acesso
            </b>
          </div>
        </Row>
        <Row>
          <div style={{ width: "100%" }}>
            <Table
              dataSource={profileList}
              columns={columns}
              style={{ height: "100%" }}
              pagination={false}
            />
          </div>
        </Row>
        <Row>
          <FloatingButton icon="+" onClick={() => this.showModal(null)} />
        </Row>
        <Modal
          title={
            editar === true ? "Editar grupo de acesso" : "Novo grupo de acesso"
          }
          visible={this.state.visible}
          onOk={editar === true ? this.onUpdate : this.onFinish}
          onCancel={this.handleCancel}
          width="40%"
          centered={true}
          okButtonProps={{
            form: "add-form",
            key: "submit",
            htmlType: "submit",
          }}
          cancelText={"Voltar"}
          okText={editar === true ? "Salvar" : "Criar"}
        >
          <div class="card">
            <div class="card-body">
              <Form
                {...layout}
                layout="vertical"
                id="add-form"
                name="nest-messages"
                onFinish={editar === true ? onUpdate : onFinish}
                style={{ width: "100%" }}
                ref={this.formRef}
                textAlign="center"
              >
                <Row style={{ padding: "8px" }}>
                  <Col span={24}>
                    <Form.Item
                      name="Title"
                      label="Nome do grupo"
                      style={{ width: "135%" }}
                    >
                      <Input
                        placeholder="ex: Grupo de Coordenadores"
                        style={{ float: "left" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="Description"
                      label="Descrição"
                      style={{ width: "135%" }}
                    >
                      <TextArea
                        placeholder="ex: Grupo de acesso para os usuários do tipo coordenador."
                        style={{ float: "left" }}
                      />
                    </Form.Item>
                    <Form.Item name="Id" hidden="true">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ padding: "8px" }}>
                  <Form.Item
                    name="Permissions"
                    label="Direitos de Acesso"
                    style={{ width: "70%" }}
                  >
                    <Checkbox.Group options={permissionList} value={this.state.selectedList} onChange={this.onGroupChange} />
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </div>
        </Modal>
      </Spin>
    );
  }
}

export default Permissions;
