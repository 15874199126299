import React, { useState, useEffect } from 'react';
import { Menu, Modal, Spin, Carousel, Input, Icon, Card } from 'antd';
import { warningsService } from '../../services/warnings';
import moment from 'moment';
import config from '../../environment.json';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import "./index.css";


const WarningPopup = (props) => {

    const [dataWarning, setDataWarning] = useState();
    const [visible, setVisible] = useState(false);
    const { TextArea } = Input;

    useEffect(() => {
        fetchData();
    }, [props.warningsOpened])

    const fetchData = async () => {
        await warningsService.getWarnings(true).then(response => {
            let value = response.data.data;
            
            if (value) {
                const data_atu = moment();
                const wn = Object.values(value)//.filter(warn => {
                   // return warn.EndDate >= (data_atu).format('YYYY-MM-DD')
                //})
                setDataWarning(wn)
                if (value.length > 0) {
                    setVisible(true);
                }
            }
        });
    }



    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        nextArrow: <RightOutlined />,
        prevArrow: <LeftOutlined />,
        centerMode: true,
        arrows: true,
    }

    const Arrow = ({ type, style, className, onClick }) => (
        <Icon type={type} style={style} className={className} onClick={onClick} />
    );

    return (
        <Modal title="Avisos"
            visible={visible}
            onOk={() => setVisible(false)}
            width={650}
            style={{ top: 20 }}
            onCancel={() => setVisible(false)}
            footer={null}
            cancelButtonProps={{ style: { display: 'none' } }}  >
            {dataWarning ?
                <Carousel
                    prevArrow={<Arrow type="left" />}
                    nextArrow={<Arrow type="right" />}
                    {...settings}
                    autoplay
                >
                    {Object.keys(dataWarning).map((k) =>
                        <div>
                            {dataWarning[k].FileUrl === "undefined" || dataWarning[k].FileUrl === ""?
                                <Card>
                                    <h1> {dataWarning[k].Title}</h1>
                                    <p> Descrição: {dataWarning[k].Description}</p>
                                    <p>{moment(k.StartDate).format("DD/MM/YYYY")} </p>
                                </Card>
                                :
                                <Card

                                    cover={<img alt="example" src={config['API_URL'] + '/uploads/Warnings/' + dataWarning[k].FileUrl} />}>
                                    <h1> {dataWarning[k].Title}</h1>
                                    <p> Descrição: {dataWarning[k].Description}</p>
                                    <p>{moment(k.StartDate).format("DD/MM/YYYY")} </p>
                                </Card>}
                        </div>
                    )}
                </Carousel>
                : <Spin spinning={true} delay={0} />
            }
        </Modal>
    );
}

export default WarningPopup;