import { request } from '../http';

const LogService = {
  getLogs: () => {
    return request.get('/processLogs');
  },
  insertLog: (data) => {
    return request.post('/processLogs', data);
  },
  updateLog: (data, id) => {
    return request.patch('/processLogs/' + id, data);
  }
}

export {
  LogService
}