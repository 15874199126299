import React from "react";
import { Table, Row, Form, Input } from 'antd';
import moment from "moment";
import { OrderedListOutlined, FilterFilled } from '@ant-design/icons';
import { tasksService } from '../../services/tasks';
moment.locale('pt-BR');

class TaskAssignment extends React.Component {
    state = {
        fetching: false,
        dataSource: undefined,
        modalVisible: false,
        dataTable: [],
        currentRecord: [],
        load: true
    }

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        await tasksService.getAssignmentList().then((res) => {
            const { data } = res.data;
            let value = data.map(fields => ({
                Id: fields.Id,
                Status: fields.Status === 'N' ? 'Inativo' : 'Ativo',
                Analyst: fields.Analyst,
                QuantityTasks: fields.QuantityTasks,
                LastUpdate: fields.LastUpdate,
            }));

            value = value.filter((user) => user.Status === 'Ativo');

            // desativado o loading
            this.setState({ dataTable: value, load: false, fetching: true });
        }).catch((e) => {
            // console.log('task-assignment ERROR: ',e);           
        });
    }


    searchTerm = async (value) => {
        if (value) {
            this.setState({ fetching: true });
            await this.fetch();
            const list = this.state.dataTable.filter((x) => {
                return x.Analyst != null
            });
            const filtrado = list.filter((x) => {
                return x.Analyst.toLowerCase().includes(value.toLowerCase());
            });
            this.setState({ dataTable: filtrado, fetching: false });
        }
        else {
            this.fetch();
        }
    };


    render() {

        const { Search } = Input;

        const columns = [
            {
                title: 'Analista',
                dataIndex: 'analyst',
                width: '40%',
                editable: false,
                align: 'left',
                //sorter: true,
                render: (text, record) => <span>{record.Analyst}</span>
            },
            {
                title: 'Qtd. Tarefas',
                dataIndex: 'quantityTasks',
                width: '15%',
                editable: true,
                align: 'center',
                //sorter: true,
                render: (text, record) => <span>{record.QuantityTasks}</span>
            },
            {
                title: 'Status',
                dataIndex: 'status',
                width: '15%',
                editable: true,
                align: 'center',
                //sorter: true,
                render: (text, record) => <span>{record.Status}</span>
            },
            {
                title: 'Ação',
                dataIndex: '',
                width: '15%',
                align: 'center',
                key: 'X',
                render: (_, record) => <a><OrderedListOutlined onClick={() => this.props.history.push('/taskAssignment/' + record.Id)} /> </a>,
            },

        ];

        return (

            <Form /* style={{ minHeight: '100vh' }} */>
                <Row style={{ paddingBottom: "25px" }}>
                    <div style={{ width: "60%" }}>
                        <b style={{ textAlign: "left", fontSize: "30px" }}>Atribuição de tarefas</b>
                    </div>
                    <div style={{ width: "35%" }}>
                        <Search placeholder="Pesquisar" onSearch={(value) => this.searchTerm(value)} />
                    </div>
                    {/* <div style={{ width: "5%", float: "right" }}>
                        <center>
                            <FilterFilled onClick={() => this.setState({ modal: true })} style={{ fontSize: "20px", padding: "5px" }} />
                        </center>
                    </div> */}
                </Row>
                <Table columns={columns} dataSource={this.state.dataTable} loading={this.state.load} />
            </Form >

        )
    }
}

export default TaskAssignment;