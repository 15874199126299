import React from 'react';
import './style.css';

const EventCaption = (props) => {
  const captionItems = [
	{ label: 'Atrasado', color: '#ad3434' },
	{ label: 'Aguardando Aprovação', color: '#de7504' },
	{ label: 'Aprovado', color: '#3bbf5e' },
	{ label: 'Estornado', color: '#7701fd' },
	{ label: 'Reprovado', color: '#04061c' },
	{ label: 'Previsto', color: '#00B0F0' }
];

  return (
    <div className="event-caption" style={ props.visible ? { display: 'hidden'} : { display: 'none'} }>
      {captionItems.map((item, index) => (
        <div key={index} className="caption-item">
          <span style={{ backgroundColor: item.color }} className="caption-color"></span>
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default EventCaption;
