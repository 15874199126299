import React from "react";
import {
  Form,
  Row,
  Col,
  Table,
  Input,
  Spin,
  Button,
  Tooltip,
  message,
  Popconfirm
} from 'antd';
import { FilterFilled, EditOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import WorkFlowModal from '../../components/workflow-modal';
import WorkFlowAssignmentModal from '../../components/workflow-assignment-modal';
import { workflowService } from '../../services/workflow';
import { usersService } from '../../services/users';
import { ProjectsService } from '../../services/projects';
import { FloatingButton } from '../../components/floating-button';
import moment from "moment";
moment.locale('pt-BR');

const { Search } = Input;

class WorkFlow extends React.Component {


  state = {
    fetching: true,
    dataSource: [],

    workflowAssignmentId: 0,
    dataWorkflowAssignment: [],
    modalWorkflowAssignment: false,
    dataDropdownWorkflow: [],

    modalWorkflow: false,
    dataEditWorkflow: [],
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async (type, Id) => {
    if (type == "assignment") {
      await workflowService.getApprovals(Id).then((r) => {
        if (!r.data.data.error) {
          this.setState({ workflowAssignmentId: Id, modalWorkflowAssignment: true, dataWorkflowAssignment: r.data.data.data });
        }
      });
    }
    else {
      this.setState({ fetching: true });
      const { data: dataWorkflow, err: errorWorkflow } = await workflowService.getData(false);
      if (!errorWorkflow) {
        this.setState({ fetching: false, dataSource: dataWorkflow.data.data });
      }
    }
  }

  searchTerm = (value) => {
    if (value) {
      this.setState({ fetching: true });
      const filtrado = this.state.dataSource.filter((x) => {
        return x.Title.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ dataSource: filtrado, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  resetData = () => {
    this.setState({ dataEditWorkflow: [], modalWorkflow: false });
  }

  render() {

    const columns = [
      {
        title: 'Título',
        dataIndex: 'Title',
        key: 'Title',
        align: 'left',
        width: 50,
      },
      {
        title: 'Descrição',
        dataIndex: 'Description',
        key: 'Description',
        align: 'left',
        width: 150,
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        align: 'center',
        width: 50,
        render: (text, record) => <span>{record.Status == "Y" ? "ativo" : "desativado"}</span>
      },
      {
        title: 'Ação',
        key: 'Action',
        width: 50,
        align: 'center',
        render: (text, record) => (
          <Button.Group >
            <div style={{ padding: "7px" }}>
              <Tooltip placement="top" title={"Editar"}>
                <a>
                  <EditOutlined onClick={() => showModalWorkFlow(record)} />
                </a>
              </Tooltip>
            </div>
            <div style={{ padding: "7px" }}>
              <Tooltip placement="top" title={"Atribuição"}>
                <a>
                  <UserOutlined onClick={() => showModalWorkFlowAssignment(record.Id)} />
                </a>
              </Tooltip>
            </div>
            <div style={{ padding: "7px" }}>
              <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                onConfirm={() => onCancel(record.Id)} okText="Sim" cancelText="Não">
                <Tooltip placement="top" title={"Excluir"}>
                  <a>
                    <DeleteOutlined />
                  </a>
                </Tooltip>
              </Popconfirm>
            </div>
          </Button.Group>
        ),
      },
    ];

    const showModalWorkFlow = (record) => {
      this.setState({ dataEditWorkflow: record, modalWorkflow: true });
    }

    const showModalWorkFlowAssignment = async (Id) => {
      this.fetch("assignment", Id);
      await usersService.getUsers().then((r) => {
        const data = r.data.data.map((m) => {
          return ({ value: m.ID + " - " + m.NAME });
        });
        this.setState({ dataDropdownWorkflow: data, modalWorkflowAssignment: true });
      });
    }

    const onCancel = async (value) => {
      message.loading('Excluindo workflow ...', 100);
      this.setState({ fetching: true });
      const verify = await workflowService.getApprovals(value);
      const projectData = await ProjectsService.getProjects();
      const project = projectData.data.data;

      let verifyUsedWorkflow = project.map(item => {
        return item.Workflow == value ? item : null
      }).filter(filtered => filtered != null);
      if (verifyUsedWorkflow[0]){
        message.destroy();
        this.setState({ fetching: false });
        return message.error('O workflow está sendo usado.', 3);
      }
      if (!verify.data.error && verify.data.data[0]) {
        message.destroy();
        this.setState({ fetching: false });
        message.error('Exclua os usuários atribuidos no workflow.', 3);
      }
      else {
        await workflowService.deleteData(value).then((r) => {
          if (r.data.error) {
            message.destroy();
            this.setState({ fetching: false });
            message.error('Ocorreu um erro ao excluir o workflow.', 3);
          }
          else {
            this.fetch();
            message.destroy();
            message.success('Workflow excluido com sucesso.', 3);
          }
        });
      }
    }

    const onClose = async (type, id) => {
      if (type == "assignment") {
        this.fetch("assignment", id);
      }
      else {
        this.fetch();
        this.setState({ modalWorkflow: false });
      }
    }

    return (
      <Spin spinning={this.state.fetching} delay={500}>
        <Form /* style={{ minHeight: '90vh' }} */>
          <Row style={{ paddingBottom: "25px" }}>
            <div style={{ width: "60%" }}>
              <b style={{ textAlign: "left", fontSize: "30px" }}>Listagem do workflow</b>
            </div>
            <div style={{ width: "35%" }}>
              <Search placeholder="Pesquisar" onSearch={value => this.searchTerm(value)} />
            </div>
            {/* <div style={{ width: "5%", float: "right" }}>
              <center>
                <FilterFilled disabled style={{ fontSize: "20px", padding: "5px" }} />
              </center>
            </div> */}
          </Row>
          <Row style={{ width: "100%"/* , minHeight: "90vh" */ }}>
            <Col style={{ border: "1px solid #e8e8e8" }} span={24} >
              <Table columns={columns} dataSource={this.state.dataSource} style={{ height: "100%" }} pagination={true} />
            </Col>
          </Row>
          <FloatingButton icon="+" onClick={() => this.setState({ modalWorkflow: true })} />

          <WorkFlowModal
            visible={this.state.modalWorkflow}
            destroyOnClose={() => this.resetData()}
            onfeting={() => onClose()}
            data={this.state.dataEditWorkflow} />

          <WorkFlowAssignmentModal
            visible={this.state.modalWorkflowAssignment}
            dropdown={this.state.dataDropdownWorkflow}
            dataSource={this.state.dataWorkflowAssignment}
            id={this.state.workflowAssignmentId}
            onfeting={(id) => onClose("assignment", id)}
            destroyOnClose={() => this.setState({ modalWorkflowAssignment: false })}
          />
        </Form >
      </Spin>
    )
  }
}

export default WorkFlow;