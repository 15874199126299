import { request } from '../http';

const warningsService = {
  getWarnings: (onlyActive) => {
    return request.get('/warnings/list/onlyActive=' + onlyActive);
  },
  insertWarning: (data) => {
    return request.post('/warnings/insert', data);
  },
  deleteWarning: (data) => {
    return request.delete('/warnings/delete/' + data.Id);
  },
  editWarning: (data, id) => {
    return request.put('/warnings/edit/' + id, data);
  },
}

export {
  warningsService
}