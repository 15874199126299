import React from "react";
import moment from "moment";
import * as _ from 'lodash';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { reportsService } from "../../services/reports";
import { usersService } from "../../services/users";
import { Select, Row, Button, Col, DatePicker, message, Table, Tooltip } from "antd";
import { permissionsService } from '../../services/permissions';
import { ProjectsService } from '../../services/projects';
// import ReactExport from "react-export-excel"; // FIXME: PHD
import { ConsoleSqlOutlined } from "@ant-design/icons";
import './style.css';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

moment.locale("pt-BR");

class Reports extends React.Component {

  state = {
    dataReport: [],
    dataDropdown: [],
    dataDropdownProject: [],
    selected: +localStorage.getItem('userId'),
    periodStart: '',
    periodEnd: '',
    project: 0,
    planned: 'N'
  };

  componentDidMount() {
    this.fetch()
  }

  formatDuration = (duration) => {
    duration = duration || "0";
    const dur = moment.duration(parseFloat(duration), 'hours');
    var hours = Math.floor(dur.asHours());
    var mins = Math.floor(dur.asMinutes()) - hours * 60;
    return `${_.padStart(hours, 2, '0')}:${_.padStart(mins, 2, '0')}`;
  }

  fetch = async () => {

    const usersData = await usersService.getUsers();
    // setaop users como array para poder realizar map
    const users = usersData.data.data;
    const id = localStorage.getItem('userId');
    const userByIdData = await usersService.getUserById(id);
    const userById = userByIdData.data;
    // alterado o parametro userById.data[0].USERID -> userById.data[0].PROFILE
    const permissionData = await permissionsService.getProfileDetails(userById.data[0].PROFILE);
    const permission = permissionData.data.data;
    let { data: projectData } = await ProjectsService.getProjects();
    let permissionListAll = Object.values(permission).filter(find => find.Feature == '/taskAssignment')
    let list = []
    let listProjects = []

    listProjects.push({ value: 0, label: "Todos" });
    projectData.data.map(item => {
      listProjects.push({ value: item.Id, label: `${item.CustomerName.trim()}  -  ${item.Title ? item.Title.trim() : item.Description.trim()}` })

    });
    if (!users.error) {

      if (permissionListAll[0]) {
        list.push({ value: 0, label: "Todos" });

        users.map(item => {
          return list.push({ value: item.ID, label: item.NAME })
        });
      }
      else {
        users.map(item => {
          return item.ID == id ? list.push({ value: item.ID, label: item.NAME }) : null
        })
      }

    }

    this.setState({ dataDropdown: list, dataDropdownProject: listProjects });
  }

  onSelect = e => {
    this.setState({ selected: e })
  }

  onSelectProject = e => {
    this.setState({ project: e })
  }

  onSelectPlanned = e => {
    this.setState({ planned: e })
  }

  render() {
    const columns = [
      {
        title: "Tipo",
        dataIndex: "Type",
        key: "Type",
        sorter: (a, b) => a.Type > b.Type,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Type}>
            <span style={{fontWeight: record.Type === 'TOTAL' ? "bolder" : "normal"}}>{record.Type}</span>
          </Tooltip>
        )
      },
      {
        title: "Colaborador",
        dataIndex: "Collaborator",
        key: "Collaborator",
        sorter: (a, b) => a.Collaborator > b.Collaborator,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Collaborator}>
            <span style={{fontWeight: record.Type === 'TOTAL' ? "bolder" : "normal"}}>{record.Collaborator}</span>
          </Tooltip>
        )
      },
      {
        title: "Cliente",
        dataIndex: "Customer",
        key: "Customer",
        sorter: (a, b) => a.Customer > b.Customer,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Customer}>
            {record.Customer}
          </Tooltip>
        )
      },
      {
        title: "Projeto",
        dataIndex: "ProjectCode",
        key: "ProjectCode",
        sorter: (a, b) => a.ProjectCode > b.ProjectCode,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.ProjectCode}>
            {record.ProjectCode}
          </Tooltip>
        )
      },
      {
        title: "Atividade",
        dataIndex: "Description",
        key: "Description",
        sorter: (a, b) => a.Description > b.Description,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Description}>
            {record.Description}
          </Tooltip>
        )
      },
      {
        title: "Data",
        dataIndex: "Date",
        key: "Date",
        sorter: (a, b) => a.Date > b.Date,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Date}>
            {record.Date}
          </Tooltip>
        )
      },
      {
        title: "Apontamento",
        dataIndex: "TaskDescription",
        key: "TaskDescription",
        sorter: (a, b) => a.TaskDescription > b.TaskDescription,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.TaskDescription}>
            {record.TaskDescription}
          </Tooltip>
        )
      },
      {
        title: "Horas",
        dataIndex: "Value",
        key: "Value",
        sorter: (a, b) => a.Value > b.Value,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Value}>
            <span style={{fontWeight: record.Type === 'TOTAL' ? "bolder" : "normal"}}>{record.Value}</span>
          </Tooltip>
        )
      },
      {
        title: "Local",
        dataIndex: "ServiceType",
        key: "ServiceType",
        sorter: (a, b) => a.ServiceType > b.ServiceType,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.ServiceType}>
            {record.ServiceType}
          </Tooltip>
        )
      },
      {
        title: "Status",
        dataIndex: "Approval",
        key: "Approval",
        sorter: (a, b) => a.Approval > b.Approval,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Approval}>
            {record.Description === 'TOTAL' ? '' :
              <div className="caption-item">
                <span style={{ backgroundColor: (
                  record.Approval === 'Aprovado' ? '#3bbf5e' :
                  record.Approval === 'Reprovado' ? '#04061c' :
                  record.Approval === 'Aprovação Pendente' ? '#de7504' :
                  record.Approval === 'Estornado' ? '#7701fd' :
                  record.Approval === 'Previsto' ? '#00B0F0' : '#fff'
                  ) }} className="caption-color"></span>
                {record.Approval}
              </div>
            }
          </Tooltip>
        )
      }
    ];
    // const ExcelFile = ReactExport.ExcelFile; // FIXME: PHD
    // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet; // FIXME: PHD
    // const ExcelColumn = ReactExport.ExcelFile.ExcelColumn; // FIXME: PHD

    const CriaCorpoDocumento = () => {
      const header = [
        { text: 'Tipo', bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Cliente', bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Cod. Projeto', bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Atividade', bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Data', bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Desc. Atividade', bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Horas', bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Tipo Atendimento', bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Status', bold: true, fontSize: 9, margin: [0, 4, 0, 0] }
      ];
      const body = this.state.dataReport.map((prod) => {
        return [
          { text: prod.Type, fontSize: 8 },
          { text: prod.Customer, fontSize: 8 },
          { text: prod.ProjectCode, fontSize: 8 },
          { text: prod.Description, fontSize: 8 },
          { text: prod.Date, fontSize: 8 },
          { text: prod.TaskDescription, fontSize: 8 },
          { text: prod.Value, fontSize: 8 },
          { text: prod.ServiceType, fontSize: 8 },
          { text: prod.Approval, fontSize: 8 }
        ];
      });

      const lineHeader = [
        { text:'', alignment: 'center', fontSize: 5, colSpan: 3},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {}
      ];

      let content = [header, lineHeader];
      content = [...content, ...body];
      return content;
    }

    const GerarDocumento = (corpoDocumento) => {
      const documento = {
        //pageSize: 'A4',
        PageOrientation: 'landscape',
        pageSize: {
          width: 630,
          height: 891
        },
        pageMargins: [14, 53, 14, 48],
        header: function () {
          return {
            margin: [14, 12, 14, 0],
            layout: 'noBorders',
            table: {
              widths: ['*'],
              body: [
                [
                  { text: 'Extrato de horas', style: 'reportName' }
                ]
              ],
            },
          };
        },
       // watermark: { text: 'PHD Erp Sistemas', color: 'grey', opacity: 0.2, bold: true },
        content: [
          {
            layout: 'headerLineOnly',
            table: {
              headerRows: 1,
              widths: [60, 60, 60, 60, 60, 40, 20, 40, 40],
              body: corpoDocumento
            }
          },
        ],
        footer(currentPage, pageCount) {
          return {
            layout: 'noBorders',
            margin: [14, 0, 14, 22],
            table: {
              widths: ['auto'],
              body: [
                [
                  {
                    text:
                      '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                    alignment: 'center',
                    fontSize: 5,
                  },
                ],
                [
                  [
                    {
                      text: `Página ${currentPage.toString()} de ${pageCount}`,
                      fontSize: 7,
                      alignment: 'right',
                      margin: [3, 0],
                    },
                    {
                      text: '©PHD Gestão',
                      fontSize: 7,
                      alignment: 'center',
                    },
                  ],
                ],
              ],
            },
          };
        },
        styles: {
          reportName: {
            fontSize: 9,
            bold: true,
            alignment: 'center',
            margin: [0, 4, 0, 0],
          }
        },

      };
      return documento;
    }

    const visualizarImpressao = async () => {
      this.setState({ dataReport: [] })
      if ( validateFields() ) {

        let total = 0;
        let quantidade = 0;
        let startDate = '';
        let endDate = '';
        
        if (this.state.periodStart==='' || !this.state.periodStart ){
          startDate = 'none';
        } else {
          startDate = moment(this.state.periodStart,'YYYY-MM-DD').format('YYYY-MM-DD');
        }

        if (this.state.periodStart==='' || !this.state.periodEnd ){
          endDate = 'none';
        } else {
          endDate = moment(this.state.periodEnd,'YYYY-MM-DD').format('YYYY-MM-DD');
        }

        const da = await reportsService.userReport( this.state.selected, startDate, endDate, this.state.project);
        const reportsData = da.data.data;
        console.log(da);
        console.log(reportsData);
        if (reportsData != undefined) {
          for (const item of reportsData) {
            total += parseFloat(item.Value);
            quantidade++;
            item.Value = this.formatDuration(item.Value);
          }
        }
        if (!reportsData) {
          message.info("Não Foram Encontrado dados")
          return;
        }
        
        total = this.formatDuration(total);

        reportsData.push({ Type: 'TOTAL', Value: total, Collaborator: quantidade })
        this.setState({ dataReport: reportsData })
        console.log(reportsData)

        const corpo = CriaCorpoDocumento();
        const documento = GerarDocumento(corpo);
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
      }
    }

    const showData = async () => {
      this.setState({ dataReport: [] })

      if ( validateFields() ) {

        let total = 0;
        let quantidade = 0;
        let startDate = '';
        let endDate = '';
        
        if (this.state.periodStart==='' || !this.state.periodStart ){
          startDate = 'none';
        } else {
          startDate = moment(this.state.periodStart,'YYYY-MM-DD').format('YYYY-MM-DD');
        }

        if (this.state.periodStart==='' || !this.state.periodEnd ){
          endDate = 'none';
        } else {
          endDate = moment(this.state.periodEnd,'YYYY-MM-DD').format('YYYY-MM-DD');
        }

        try {
          const da = await reportsService.userReport( this.state.selected, startDate, endDate, this.state.project, this.state.planned);
          const reportsData = da.data.data;
          console.log(da);
          console.log(reportsData);
          if (reportsData != undefined) {
            for (const item of reportsData) {
              total += parseFloat(item.Value)
              quantidade++;
              item.Value = this.formatDuration(item.Value);
            }
          }
          if (!reportsData) {
            message.info("Não Foram Encontrado dados")
            return;
          }
          
          total = this.formatDuration(total);

          reportsData.push({ Type: 'TOTAL', Value: total, Collaborator: quantidade })
          this.setState({ dataReport: reportsData })
        } catch (e) {
          console.log("Catch Error: ", e);
        }
      }
    }
    
    const validateFields = () => {
    
      if (this.state.periodStart && !this.state.periodEnd || !this.state.periodStart && this.state.periodEnd) {
        message.error('Ocorreu um erro, informar o Inicio  e o Fim do Periodo');
        return false;
      }  

      if (this.state.project < 0) {
        message.error('Escolha um projeto.');
        return false;
      }

      if (this.state.selected < 0) {
        message.error('Escolha um colaborador.');
        return false;
      }

      return true;
    }
  
    const setDate = (target, value) => {
      if (target === 'start') {
        this.setState({ periodStart: value })
      } else {
        this.setState({ periodEnd: value })
      }
    }

    const { Option } = Select;
    const dropdown = this.state.dataDropdown;
    const dropdownProject = this.state.dataDropdownProject;

    return (
      <div>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "left", fontSize: "30px" }}>Relatórios</b>
          </div>
        </Row>

        <Row gutter={[8, 8]}>
          <Col span={6}>
            <p>Colaborador ou Todos:*</p>
            <Select defaultValue={this.state.selected} optionFilterProp="children" options={dropdown} showSearch onChange={(e) => this.onSelect(e)} style={{ width: "100%" }}
              filterOption={(input, option) =>

                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }  >

              {this.state.projects && this.state.projects.map(d => (
                <Option value={d.Id}>{`${d.CustomerName} - ${d.Title}`}</Option>
              ))}

            </Select>
          </Col>
          <Col span={6}>
            <p>Projetos ou Todos:*</p>
            <Select defaultValue={0} options={dropdownProject} showSearch onChange={(e) => this.onSelectProject(e)} style={{ width: "100%" }} 
            filterOption={(input, option) =>

              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }  >

              {this.state.projects && this.state.projects.map(d => (
                <Option value={d.Id}>{`${d.CustomerName} - ${d.Title}`}</Option>
              ))}

            </Select>
          </Col>
          <Col span={4}>
            <p>Filtra Atividades Previstas:*</p>
            <Select defaultValue={"N"} style={{ width: "100%" }} onChange={(e) => this.onSelectPlanned(e)}>
              <Select.Option value={"N"}>Não</Select.Option>
              <Select.Option value={"S"}>Sim</Select.Option>
            </Select>
          </Col>
          <Col span={4}>
            <p>Início Periodo:</p>
            <DatePicker style={{ width: "100%" }} placeholder="Selecione uma data" format={"DD/MM/YYYY"} onChange={(value) => setDate('start', value)} />
          </Col>
          <Col span={4}>
            <p>Fim Periodo:</p>
            <DatePicker style={{ width: "100%" }} placeholder="Selecione uma data" format={"DD/MM/YYYY"} onChange={(value) => setDate('end', value)} />
          </Col>
        </Row>

        <Row style={{ paddingTop: "2%", paddingBottom: "1%" }}>
          <Col style={{ paddingRight: "1%"}}>
            <Button type="primary" onClick={showData} >Visualizar</Button>
          </Col>
          <Col>
            <Button type="secondary" onClick={visualizarImpressao} >Gerar Relatório</Button>
            {/* <Button type="primary" onClick={visualizarImpressao} >Download Excel </Button>*/}
            {/* <ExcelFile element={<Button style={{ marginLeft: 15 }} type="primary" // FIXME: PHD
              onClick={visualizarImpressao}>Download Excel</Button>} filename="RelatorioHoras">
              <ExcelSheet data={this.state.dataReport} name="Relatorio">
                <ExcelColumn label="Tipo" value="Type" />
                <ExcelColumn label="Cliente" value="Customer" />
                <ExcelColumn label="Cod. Projeto" value="ProjectCode" />
                <ExcelColumn label="Atividade" value="Description" />
                <ExcelColumn label="Data" value="Date" />
                <ExcelColumn label="Descrição atividade" value="TaskDescription" />
                <ExcelColumn label="Horas" value="Value" />
                <ExcelColumn label="Tipo Atendimento" value="ServiceType" />
                <ExcelColumn label="Status" value="Approval" />
              </ExcelSheet>
            </ExcelFile> // FIXME: PHD */}
          </Col>
        </Row>

        <Row>
          <Table
            columns={columns}
            dataSource={this.state.dataReport}
            style={{ height: "100%", width: "100%" }}
            scroll={{ x: 1300 }}
            pagination={true}
            size={"small"}
          />
        </Row>
      </div>

    );
  }
}

export default Reports;