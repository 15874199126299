import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col, Input, Button, Card, message } from 'antd';
import { Form } from '@ant-design/compatible';
import { useStateValue } from '../../state';
import { authenticationService } from '../../services/authentication';
import './index.css';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import Logo from '../../assets/phdLogin.png';

const LoginForm = (props) => {

  const [loading, setLoading] = useState(false);
  const [{ authentication }, dispatch] = useStateValue();
  const { getFieldDecorator } = props.form;

  const handleLogin = async e => {
    message.loading('Logando..', 100);
    e.preventDefault();

    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        const { data: response } = await authenticationService.login(values.username, values.password);

        if (response) {
          if (response.error) {
            setLoading(false);
            message.destroy();
            message.error(response.error.message, 5);
          }
          else {
            setLoading(false);
            message.destroy();
            localStorage.setItem('userId', response.data.userId);
            dispatch({ type: 'login', value: response.data });
          }
        } else {
          setLoading(false);
          message.destroy();
          message.error("Falha na comunicação com o servidor, tente novamente.");
        }
      }
      else {
        message.error("Preencha corretamente os campos usuário e senha.");
      }
    });
  };
  const [visible, setVisible] = useState('password')
  const [eye, setEye] = useState(true)
  const changeView = () => {
    if (visible !== 'password') {
      setVisible('password');
      setEye(true);
    } else {
      setVisible('');
      setEye(false);
    }

  }

  return (
    !authentication ?
      <Row type="flex" justify="center" align="middle" className="login">
        <Col>
          <Card bordered={false} cover={<img alt="PHD Gestão" style={{ opacity: 25 }}
            src={Logo} className="logo" />} >
            <Form onSubmit={handleLogin} class="ant-legacy-form" >
              {getFieldDecorator('username', { rules: [{ required: true }] })(
                <Input autoFocus placeholder="Usuário" />
              )}
              {getFieldDecorator('password', {
                rules: [{ required: true }],
              })(
                <div>
                  <Row>
                    <Col span={20}>
                      <Input type={visible} placeholder="Senha" style={{ marginTop: '20px' }} />
                    </Col>
                    <Col span={1} offset={1} style={{ display: eye !== true ? "none" : "" }}>
                      <EyeOutlined onClick={changeView} style={{ fontSize: '25px', color: '#08c', marginTop: '25px' }} />
                    </Col>
                    <Col span={1} offset={1} style={{ display: eye === true ? "none" : "" }}>
                      <EyeInvisibleOutlined onClick={changeView} style={{ fontSize: '25px', color: '#08c', marginTop: '25px' }} />
                    </Col>
                  </Row>
                </div>
              )}
              <Button loading={loading} type="primary" size="large" block htmlType="submit" style={{ marginTop: '25px' }}>
                Acessar
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
      :
      <Redirect to='/' />
  );
}

const Login = Form.create({ name: 'login' })(LoginForm);
export default Login;