import React from "react";
import { Form } from 'antd';
import moment from "moment";
moment.locale('pt-BR');

class NotPermission extends React.Component {
    render() {
        return (
            <Form /* style={{ minHeight: '100vh' }} */>
                {/* <img src={Logo} style={{ width: "130px", height: "83,2px" }}></img> */}
                <center>
                    <p><b style={{ fontSize: "40px", color: "black" }}>Acesso Restrito</b></p>
                    <p style={{ fontSize: "30px", color: "black" }}>Parece que você não tem permissão para acessar este recurso.</p>
                    <p style={{ fontSize: "30px", color: "black" }}>Em caso de dúvidas contactar o administrador.</p>
                </center>
            </Form >
        )
    }
}

export default NotPermission;