import React from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Button,
  Form,
  Card,
  Tooltip
} from "antd";
import moment from "moment";
import './index.css';
import { WarningOutlined, EyeOutlined, CheckOutlined, EditOutlined, InfoOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { scheduleService } from '../../services/schedule';
import { tasksService } from "../../services/tasks";
import MyTasksModal from "../../components/my-tasks-modal";
import { homeService } from "../../services/home";
import TaskChart from '../../components/task-chart';
import * as _ from 'lodash'
moment.locale('pt-BR');

const { Meta } = Card;

class Home extends React.Component {
  state = {
    fetching: false,
    dataSource: [],
    HomeData: [],
    dataCounter: [],
    dataNoteModal: [],
    dataFieldsModal: [],
    dataModal: [],
    modal: false,
    adm: false
  };

  componentDidMount() {
    this.fetch();
    this.showHomeCount();
  }

  fetch = () => {
    (async () => {
      this.setState({ fetching: true });
      // let r = await homeService.getChartsHome();
      // let chartData = r.data.data;

      const id = localStorage.getItem('userId');
      let view = localStorage.getItem('task_view');

      let data = [];
      await tasksService.getListById(id).then((r) => {
        const data = r.data.data;
        this.setState({ fetching: false, dataSource: data, adm: localStorage.getItem('task_view') });

        // let tasks = r.data.data;
        // // ajustado filtro para já listar os pendentes
        // let filteredList = tasks.filter(find => find.Status != 'A');
        // let data;
        // if (filteredList[0]) {
        //   data = filteredList;
        // }
        // else {
        //   data = tasks;
        // }
        // // tasks.map(find => find.ProjectId);
        // console.log(filteredList)   
        // console.log(tasks)   
        // this.setState({ fetching: false, dataSource: data, adm: localStorage.getItem('task_view'), dataSourceBackup: tasks });
      });

      this.setState({ fetching: false, chart: view === true ? true : false });
    })();

  };

  componentWillUnmount() {
    //
  }

  showHomeCount = async () => {
    this.setState({ fetching: true, visible: true });
    const id = localStorage.getItem('userId');
    let r = await homeService.getHomeCount(id);
    const { data } = r.data;
    this.setState({ dataCounter: _.first(data), HomeData: data, fetching: false });

  }

  openModal = async (value) => {
    const dataFields = await scheduleService.getScheduleById(value.ScheduleId);
    const dataModal = this.state.dataSource.map((r) => { return r.Id === value.Id ? r : null }).filter((r) => { return r != null });
    this.setState({ modal: true, dataNoteModal: value, dataFieldsModal: dataFields.data.data[0], dataModal: dataModal[0] });
  }

  formatDuration = (duration) => {
    duration = duration || "0";
    const dur = moment.duration(parseFloat(duration), 'hours');
    var hours = Math.floor(dur.asHours());
    var mins = Math.floor(dur.asMinutes()) - hours * 60;
    return `${_.padStart(hours, 2, '0')}:${_.padStart(mins, 2, '0')}`;
  }

  render() {

    const columns = [
      {
        title: "Cliente",
        dataIndex: "Company",
        key: "Company",
        width: "15%",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Company}>
            <span>{record.Company}</span>
          </Tooltip>
        )
      },
      {
        title: "Projeto",
        dataIndex: "Project",
        key: "Project",
        width: "15%",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Project ? record.Project : record.Description}>
            <span>{record.Project ? record.Project : record.Description}</span>
          </Tooltip>
        )
      },
      {
        title: "Atividade",
        dataIndex: "TasksDescription",
        key: "TasksDescription",
        width: "20%",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.TasksDescription}>
            {record.TasksDescription}
          </Tooltip>
        )
      },
      {
        title: "Atribuidas",
        key: "ApprovedTime",
        align: "center",
        width: "90px",
        render: (text, record) => <span>{this.formatDuration(record.ApprovedTime)}</span>
      },
      {
        title: "Aprovadas",
        key: "ConsumedTime",
        align: "center",
        width: "90px",
        render: (text, record) => <span>{this.formatDuration(record.ConsumedTime)}</span>
      },
      {
        title: "Apontadas",
        key: "Note",
        align: "center",
        width: "90px",
        render: (text, record) => <span>{this.formatDuration(record.Note)}</span>
      },
      {
        title: "Saldo",
        key: "debitHours",
        align: "center",
        width: "90px",
        render: (text, record) => {
          const duration = parseFloat(_.get(record, 'ApprovedTime', 0) || 0) - parseFloat(_.get(record, 'Note', 0) || 0);
          return (<span>{this.formatDuration(duration)}</span>)
        }
      },
      {
        title: "Início",
        key: "ExpectedStartDate",
        align: "center",
        width: 100,
        render: (text, record) => <span>{(record.ExpectedStartDate ? record.ExpectedStartDate : '')}</span>
      },
      {
        title: "Término",
        key: "ExpectedEndDate",
        align: "center",
        width: 100,
        render: (text, record) => <span
          className={(moment(record.ExpectedEndDate, 'DD/MM/YYYY').format() < moment().format() ? 'expiredDate' : '')}>
          {(record.ExpectedEndDate ? record.ExpectedEndDate : '')}
        </span>,
        //sorter: (a, b) => moment(a.ExpectedEndDate) - moment(b.ExpectedEndDate),
        defaultSortOrder: 'ascend',
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      {
        title: "% concluída",
        dataIndex: "Percentage",
        key: "Percentage",
        align: "center",
        width: "100px",
        render: (text, record) => <span>{record.Percentage ? `${record.Percentage}%` : '0%'}</span>
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        align: "center",
        width: "100px",
        filters: [
          {
            text: 'Pendente',
            value: 'P',
          },
          {
            text: 'Parcialmente',
            value: 'W',
          },
          {
            text: 'Finalizado',
            value: 'A',
          },
        ],
        defaultFilteredValue : ["P","W"],
        filterMultiple: true,
        onFilter: (value, record) => record.Status.indexOf(value) === 0,
        sorter: (a, b) => a.Status.charCodeAt(0) - b.Status.charCodeAt(0),
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record) => <span>{record.Status == "P" ? "Pendente" : record.Status == "W" && record.Percentage != 100 ? "Apontado anteriomente" : "Finalizado"}</span>
      },
      {
        title: 'Ação',
        key: 'Action',
        width: "50px",
        align: 'center',
        fixed: 'right',
        render: (text, record) => (
          <Button.Group>
            {record.Percentage == 100 || record.Status === "A" ? null :
              <Tooltip placement="top" title={"Apontar"}>
                <a><EditOutlined onClick={() => this.openModal(record)} /></a>
              </Tooltip>
            }
          </Button.Group>
        )
      }
    ];

    const fetching = this.state.fetching;
    return (
      <Spin spinning={fetching} delay={500}>
        <Form /* style={{ minHeight: '100vh' }} */>
          {/* {this.state.adm ? 
          <Row>
            <Col>
              <TaskChart dataSource={this.state.dataChart} />
            </Col>
          </Row> : null}  */}
          {/* <Row style={{ paddingBottom: "10px" }}>
            <div style={{ width: "60%" }}>
              <b style={{ textAlign: "Right", fontSize: "30px" }}>Dashboard</b>
            </div>
          </Row> */}

          {/* <Row style={{ width: "100%" }}>
            <div className="site-card-wrapper" style={{ width: "100%" }}>
              <Row gutter={[8, 8]} >
                <Col span={8}>
                  <Card className="a" style={{ width: "100%", height: "70%" }}>
                    <Meta
                      avatar={<EyeOutlined />} description={_.get(this.state.dataCounter, 'ctn_tasksTotal', '').toString()} Input disabled={true}
                    />
                    <Meta style={{ textAlign: "center", paddingBottom: "15%" }}
                      title="Total de atividades" />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card className="b" style={{ width: "100%", height: "70%" }}>
                    <Meta
                      avatar={<InfoCircleOutlined />} description={_.get(this.state.dataCounter, 'ctn_tasksPending', '').toString()} Input disabled={true}
                    />
                    <Meta style={{ textAlign: "center", paddingBottom: "15%" }}
                      title="Ativ. pendentes" />
                  </Card>
                </Col>
                {/* <Col span={6}>
                    <Card className="c" style={{ width: "100%", height: "100%" }}>
                      <Meta
                        avatar={<CheckOutlined />} description={_.get(this.state.dataCounter, 'ctn_tasksConcluded', '').toString()} Input disabled={true}
                      />
                      <Meta style={{ textAlign: "center", paddingTop: "15%" }}
                        title="Ativ. completas" />
                    </Card>
                  </Col> * /}
                <Col span={8}>
                  <Card className="d" style={{ width: "100%", height: "70%" }}>
                    <Meta
                      avatar={<WarningOutlined />} description={_.get(this.state.dataCounter, 'ctn_tasksLate', '').toString()} Input disabled={true}
                    />
                    <Meta style={{ textAlign: "center", paddingBottom: "15%" }}
                      title="Ativ. atrasadas" />
                  </Card>
                </Col>
              </Row>
            </div>
          </Row> */}

          <Row style={{ paddingBottom: "10px" }}>
            <div style={{ width: "60%" }}>
              <b style={{ textAlign: "Right", fontSize: "30px" }}>Minhas Atividades</b>
            </div>
          </Row>

          <Row>
            <Table columns={columns} dataSource={this.state.dataSource} style={{ height: "100%", width: "100%" }} scroll={{ x: 1300 }} pagination={true} size={"small"} />

          </Row>
          <MyTasksModal
            fetch={() => this.fetch()}
            visible={this.state.modal}
            project={this.state.dataNoteModal}
            tasksList={this.state.dataModal}
            dataFields={this.state.dataFieldsModal}
            onClosed={() => this.setState({ modal: false, fetching: false })}
            destroyOnClose={() => this.setState({ modal: false, fetching: false })}
          />
        </Form >
      </Spin>
    )
  }
}

export default Home;
