import React, { useEffect, useState } from "react";
import { Form, Row, Input, message, Modal, Button, Spin, DatePicker, Select, InputNumber } from 'antd';
import { scheduleService } from '../../services/schedule';
import { tasksService } from '../../services/tasks';
import * as _ from "lodash";
import moment from "moment";


const ScheduleEditModal = (props) => {
  const [dropFather, setDropFather] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [predecessor, setPredecessor] = useState(0);
  const [dropPred, setDropPred] = useState([]);
  const [fetching, setfetching] = useState(false);

  const { TextArea } = Input;

  const onEdit = async (value) => {
    // se tarefa já foi atribuída, retornar erro informando que já tem atribuição para essa tarefa
    const taskData = await tasksService.getListByProjectCode(props.id);
    const task = taskData.data.data;
    let usedScheduleByTask = task.filter(find => find.ScheduleId === parseInt(props.data.key));
    if (usedScheduleByTask[0]) {
      message.destroy();
      message.error('Ocorreu um erro ao editar, tarefa já está sendo apontada.', 3);
      setfetching(false);
    } else {

      if (await validadeFields(value)) {
        message.loading('Editando cronograma ...', 100);
        setfetching(true);

        const input = {
          Id: props.data.key,
          Description: value.description ? value.description : props.data.Description,
          Predecessor: predecessor,
          ExpectedTime: value.previousHours ? value.previousHours : props.data.ExpectedTime,
          ExpectedStartDate: moment(startDate).format("YYYY-MM-DD"),
          ExpectedEndDate: moment(endDate).format("YYYY-MM-DD")
        }

        const recursive = {
          // alterado parseInt -> parseFloat
          previousHours: value.previousHours == 0 || value.previousHours ? parseFloat(value.previousHours) : parseFloat(props.data.ExpectedTime),
          previousStartDate: startDate ? moment(startDate).format("YYYY-MM-DD") : props.data.ExpectedStartDate ? props.data.ExpectedStartDate : '',
          previousEndDate: endDate ? moment(endDate).format("YYYY-MM-DD") : props.data.ExpectedEndDate ? props.data.ExpectedEndDate : ''
        }

        try {
          let schedule = await scheduleService.updateSomeFieldsSchedule(input);
          if (schedule.data.error) {
            message.destroy();
            setfetching(false);
            message.error('Ocorreu um erro ao editar cronograma.', 3);
          }
          else {

            await scheduleService.updateTotalTime(props.id, {});

            // Coleta a soma dos ExpectedTime dos parents tasks
            let parentTasksTime = await scheduleService.getParentTasksTime(props.id);
            // se retornar filho(s) do início do cronogrma
            if (parentTasksTime.data.data.length > 0) {
              // itera cada resistro
              for (let parent of parentTasksTime.data.data) {
                // monta o objeto com a menor e maior data dos filhos e o id do parentTask pai
                const parentData = {
                  minDate: moment(parent.MinDate).format('YYYY-MM-DD').toString(),
                  maxDate: moment(parent.MaxDate).format('YYYY-MM-DD').toString(),
                  id: parent.ParentTask
                };
                // atualiza o ExpectedTime e as menores e maiores datas do pai
                await scheduleService.updateParentTaskTime(parentData);
              }
            }


            // await recursiveMountData(props.data.key, recursive);
            props.onUpdateData()
            message.destroy();
            message.success('Cronograma editado com sucesso.', 3);
            setfetching(false);
          }
        } catch (e) {
            console.log("Catch Error: ", e);
            message.destroy();
            setfetching(false);
            message.error('Ocorreu um erro ao editar cronograma.', 3);
        }

        console.log("Aqui");
      }
    }
  }

  const getFatherId = async (fatherId) => {
    const child = props.dataSource.filter(item => { return fatherId == item.Id && item.ParentTask != 1 })
    if (child.length > 0) {
      const father = props.dataSource.map(item => {
        return child[0].ParentTask == item.Id ? item : null
      }).filter(r => { return r !== null });

      return father;
    } else {
      return child;
    }
  }

  const validateDate = (inputStartDate, previousStartDate) => {

    let startDate
    if (inputStartDate) {
      if (previousStartDate) {
        startDate = moment(inputStartDate).isSameOrAfter(previousStartDate) ? moment(inputStartDate).format("YYYY-MM-DD") : previousStartDate
      } else {
        startDate = moment(inputStartDate).format("YYYY-MM-DD")
      }
    } else {
      startDate = previousStartDate ? previousStartDate : ''
    }
    return startDate;
  }

  const recursiveMountData = async (id, value) => {

    const scheduleData = await scheduleService.getScheduleByProjectId(props.id);
    const schedule = scheduleData.data.data

    const father = schedule.filter(item => { return id == item.Id });
    const children = schedule.filter(item => { return father[0].Id == item.ParentTask });
    let input;
    if (children[0]) {
      let amountChildren = 0;
      let startDate;
      let endDate;

      for (const item of children) {
        // alterado parseInt -> parseFloat
        amountChildren = amountChildren + parseFloat(item.ExpectedTime);
        if (!startDate) {
          startDate = item.ExpectedStartDate
        }
        else {
          if (moment(item.ExpectedStartDate).format("YYYY-MM-DD") < moment(startDate).format("YYYY-MM-DD")) {
            startDate = item.ExpectedStartDate
          }
        }
        if (!endDate) {
          endDate = item.ExpectedEndDate
        }
        else {
          if (moment(item.ExpectedEndDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
            endDate = item.ExpectedEndDate
          }
        }
      }
      input = {
        ExpectedTime: amountChildren,
        ExpectedStartDate: validateDate(value.previousStartDate, startDate),
        ExpectedEndDate: validateDate(value.previousEndDate, endDate),
      }
    }
    else {
      input = {
        // alterado parseInt -> parseFloat
        ExpectedTime: value.previousHours == 0 || value.previousHours ? parseFloat(value.previousHours) : _.first(father).ExpectedTime,
        ExpectedStartDate: validateDate(value.previousStartDate, _.first(father).ExpectedStartDate),
        ExpectedEndDate: validateDate(value.previousEndDate, _.first(father).ExpectedEndDate),
      }

    }
    await scheduleService.updateTotalTime(id, input);
    const fatherList = await getFatherId(id);
    if (fatherList.length > 0) {
      await recursiveMountData(fatherList[0].Id, value);
    }
  }

  const validadeFields = async (value) => {

    try {
      if (startDate.isAfter(endDate)) {
        message.error("Previsão de início deve ser menor ou igual previsão de término.", 3);
        return false
      }

      let scheduleList = props.dataSource.filter(find => find.ParentTask == 1);

      if (((scheduleList[0].Balance + props.data.ExpectedTime) - value.previousHours) < 0) {
        message.error("Atividade excede a quantidade de saldo de horas do projeto", 3);
        return false
      }

    }
    catch (ex) {

    }

    if (value.previousHours <= 0) {
      message.error("Campo horas não pode ser menor que 0.", 3);
      return false
    }

    /*-let verifyDescription = props.dataSource.filter(find => { return find.Description == value.description });
    if (verifyDescription.length > 1) {
      message.error("Campo descrição já existe.", 3);
      return false
    }*/

    if ((props.project.TotalTimeAmount + props.project.Time_Change) - props.project.ConsumedTime < value.previousHours) {
      message.error('Campo horas maior que o saldo liberado.', 3);
      return false;
    }
    // if (props.data.ParentTask == 1) {
    //   if (props.project.TotalTimeAmount - props.project.ConsumedTime < value.previousHours) {
    //     message.error('Campo horas maior que o saldo liberado.', 3);
    //     return false;
    //   }
    // }


    return true
  }

  useEffect(() => {
    dropDown(props.id);
    setStartDate(moment(props.data.ExpectedStartDate, 'YYYY-MM-DD'));
    setEndDate(moment(props.data.ExpectedEndDate, 'YYYY-MM-DD'));
  }, [props]);

  const dropDown = async (id) => {
    const dataDropFather = [];
    const dataDropPred = [];
    const dataSchedule = await scheduleService.getScheduleByProjectId(parseInt(id));
    let schedule = [];
    schedule = dataSchedule.data;
    if (schedule[0]) {
      if (props.parentTask == "new") {
        let father = schedule.map((r) => {
          return r.ParentTask === 1 ? r : null
        }).filter((r) => { return r !== null });

        for (const item of father) {
          dataDropFather.push({ value: item.Id, label: item.Description });
        }
        setDropFather(dataDropFather);
      }
      else if (props.parentTask != 0) {
        dataDropFather.push({ value: props.parentTask, label: props.description });
        setDropFather(dataDropFather);
      }


      let predecessor = schedule.filter(r => { return r != null });
      for (const item of predecessor) {
        dataDropPred.push({ value: item.Id, label: item.Description });
      }
      setDropPred(dataDropPred);
    }
  }

  const dateFormat = 'YYYY-MM-DD';

  return (
    <Modal title={"Edição cronograma: " + props.data.Description}
      visible={props.visible}
      id="view-form"
      width={"60%"}
      footer={null}
      onOk={props.onClose}
      onCancel={props.onClose}
      destroyOnClose={props.onClose}
      cancelButtonProps={{ style: { display: 'none' } }}>
      <Spin spinning={fetching}>
        <Form style={{ width: "100%" }} onFinish={onEdit}>
          <Input.Group compact>
            <div style={{ width: "25%", paddingRight: "1%" }}>
              <p>Dependência:</p>
              <Form.Item name="Predecessor" initialValue={props.data.Predecessor ? props.data.Predecessor : null}>
                <Select options={dropPred} placeholder="Selecione" onSelect={(e) => setPredecessor(e)} />
              </Form.Item>
            </div>
            <div style={{ width: "25%" }}>
              <p>Data Prévio Inicio:</p>
              <DatePicker placeholder="Selecione uma data" defaultValue={startDate} value={startDate} format="DD/MM/YYYY" style={{ width: "100%" }} onChange={(date, dateString) => {
                setStartDate(moment(dateString, 'DD/MM/YYYY'));
              }} />
            </div>
            <div style={{ width: "25%", padding: "0px 1%" }}>
              <p>Data Prévio Termino:</p>
              <DatePicker placeholder="Selecione uma data" defaultValue={endDate} value={endDate} format="DD/MM/YYYY" style={{ width: "100%" }} onChange={(date, dateString) => {
                setEndDate(moment(dateString, 'DD/MM/YYYY'))
              }} />
            </div>
            <div style={{ width: "25%" }}>
              <p>Hora Previstas:</p>
              <Form.Item name="previousHours"  initialValue={props.data.ExpectedTime ? props.data.ExpectedTime : 0}>
                <InputNumber disabled={props.data.ParentTask !== 1 && !props.data.children ? false : true} style={{ width: "100%" }} />
              </Form.Item>
            </div>
          </Input.Group>
          <div style={{ width: "100%" }} >
            <p>Descrição:</p>
            <Form.Item name="description" initialValue={props.data.Description ? props.data.Description : null}>
              <TextArea placeholder="Digite a descrição" autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
          </div>
          <Row>
            <div style={{ float: "right", width: "100%", paddingTop: "10px" }}>
              <div style={{ float: "right" }}>
                <Button type="primary" htmlType="submit">
                  Salvar
                  </Button>
              </div>
              <div style={{ paddingRight: "1%", float: "right" }}>
                <Button style={{ color: "black" }} onClick={() => props.onClose()}>
                  Fechar
                  </Button>
              </div>
            </div>
          </Row>
        </Form>
      </Spin>
    </Modal>

  )
}

export default ScheduleEditModal;