import React, { useState } from "react";
import {Row,Modal,Input,Spin,message,Button,DatePicker  } from "antd";
import moment from "moment";
import { TimeCourseService } from "../../services/time-course";


const ModalTimeCourse = (props) => {

    const [spinning, setSpinning] = useState(false);
    const [courseDate, setCourseDate] = useState();

    const AddNew = async () => {
        if (courseDate) {
            message.loading("Inserindo nova data de fechamento...", 100);
            setSpinning(true);

            const id = localStorage.getItem('userId');

            const data = {
                ReferenceDate: courseDate ? moment(courseDate).format("YYYY-MM-DD") : '',
                Year: courseDate ? (moment(courseDate).format("YYYY")).toString() : '',
                Month: courseDate ? moment(courseDate).format("MM") : '',
                CreateDate: moment(new Date).format('YYYY-MM-DD'),
                UserId: id
            }

            console.log(data);
            const r = '';

            await TimeCourseService.createTimeCourse(data).then((r) => {
                if (!r.data.error) { 
                    setSpinning(false);                  
                    message.destroy();
                    props.onfetching();
                    message.success("Fechamento inserido com sucesso.", 3);
                }
                else {
                    setSpinning(false);                    
                    message.destroy();
                    message.error("Ocorreu um erro inserir a data de fechamento.", 3);
                }
                props.onCancel();

            });

        }
    }

    const setDate = e => {
        setCourseDate(e);
    }

    const dateFormat = 'YYYY/MM/DD';

    return (

        <Modal
            title="Fechamento de período"
            visible={props.visible}
            onOk={props.onOk}
            footer={null}
            onCancel={props.onCancel}
            destroyOnClose={props.onCancel}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={"40%"}>

            <Spin spinning={spinning} delay={500}></Spin>
            <div style={{ width: "100%" }}>
                <Row style={{ width: "100%" }}>
                    <div style={{ width: "30%" }}>
                        <p>Data de fechamento:</p>
                        <DatePicker style={{ width: "100%" }} /* panelRender={() => moment.locale(['br'])} */ placeholder="Selecione uma data" defaultValue={moment(new Date, dateFormat)} format={"DD/MM/YYYY"} onChange={(value) => setDate(value)} />
                    </div>
                </Row>
                <Row>
                    <div style={{ float: "right", width: "100%" }}>
                        <div style={{ paddingTop: "5px", float: "right" }}>
                            <Button type="primary" onClick={AddNew}>Salvar</Button>
                        </div>
                    </div>
                </Row>
            </div >
        </Modal >
    )
}


export default ModalTimeCourse;