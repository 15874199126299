import { request } from '../http';

const UserTimeGoalsService = {
  getUserTimeList: () => {
    return request.get('/userTimeGoals')
  },
  insertUserTimeGoals: (data) => {
    return request.post('/userTimeGoals', data);
  },
  deleteUserTimeGoals: (id) => {
    return request.delete('/userTimeGoals/' + id);
  },
  updateUserTimeGoals: (data) => {
    return request.put('/userTimeGoals', data);
  }
}

export {
  UserTimeGoalsService
}