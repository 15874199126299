import React from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Spin,
  Checkbox,
  message,
  Tooltip,
  Button
} from "antd";
import { FilterFilled, EyeOutlined } from "@ant-design/icons";
import { LogService } from '../../services/process-logs';
import LogModal from '../../components/log-modal';
import { PaymentService } from "../../services/payments";
import { itemsService } from "../../services/items";
import moment from "moment";
moment.locale("pt-BR");

const { Search } = Input;

class Logs extends React.Component {
  state = {
    fetching: false,
    dataSource: [],
    checkedList: [],
    modal: false,
    dataModal: {}
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ fetching: true });
    const { data: logsData, err: logsError } = await LogService.getLogs();
    if (!logsError) {
      this.setState({ fetching: false, dataSource: logsData.data });
    }
  };

  searchTerm = (value) => {
    if (value) {
      this.setState({ fetching: true });
      const filtered = this.state.dataSource.filter((x) => {
        return x.ProjectName.toLowerCase().includes(value.toLowerCase()) || x.Client.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ dataSource: filtered, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  onChange = (e, key) => {
    let code = e.target.value
    let checked = e.target.checked;
    if (checked) {
      this.state.checkedList.push({ id: key, value: code });
    }
    else {
      this.state.checkedList = this.state.checkedList.filter(r => r.id != key);
    }
  };

  openModal = (id) => {
    const filtered = this.state.dataSource.filter(x => {
      return x.Id == id ? x : null
    }).filter(f => f != null);
    this.setState({ modal: true, dataModal: filtered[0] });
  }

  updateLog = async (orderReturn, items) => {
    let data;
    if (orderReturn.error) {
      for (const obj of items) {
        data = {
          Status: "ERROR",
          LogDate: moment(new Date()).format("YYYY-MM-DD"),
          ErrorDetail: (orderReturn.error.innerMessage.slice(orderReturn.error.innerMessage.indexOf(", ") + 1)).trim().replace(/'/g, ''),
        }
        const logService = await LogService.updateLog(data, obj.Id);
        let log = logService.data;
        if (log.error) {
          message.destroy();
          message.error("Ocorreu um erro ao salvar o log.", 3);
        }
      }
    }
    else {
      for (const obj of items) {
        data = {
          Status: "OK",
          LogDate: moment(new Date()).format("YYYY-MM-DD"),
          ErrorDetail: "",
        }
        const logService = await LogService.updateLog(data, obj.Id);
        let log = logService.data;
        if (log.error) {
          message.destroy();
          message.error("Ocorreu um erro ao salvar o log.", 3);
        }
      }
    }
  }

  resendingOrders = async () => {
    if (this.validateFields()) {
      let documents = [];
      let input = [];
      let price = 0;
      let time = 0;
      message.loading("Reenviando faturamento ...", 100);
      this.setState({ fetching: true });
      const checklist = this.state.checkedList;
      const dataSource = this.state.dataSource;

      let customerSelected = dataSource.find(r => r.Id == checklist[0].id);
      let customerData = [];

      for (const item of checklist) {
        let customerSelectedData = dataSource.find(find => find.Id == item.id);
        if (customerSelectedData) {
          customerData.push(customerSelectedData);
        }
      }

      for (const item of customerData) {
        if (item.OrderType == "REEMBOLSO") {
          price = price + parseInt(item.TotalValue);
          input.push({ 
            CardCode: item.CardCode,
            Price: item.TotalValue,
            Type: "Reembolso",
            Date: item.Date,
            Salary: item.Salary,
            SalaryUnit: item.SalaryUnit,
            ProjectId: item.ProjectId,
            Business: item.BPLID,
            CostingCode: item.Dim1,
            CostingCode2: item.Dim2,
            CostingCode3: item.Dim3,
            CostingCode4: item.Dim4,
            CostingCode5: item.Dim5
          })
        }
        else if (item.OrderType == "HORAS TRABALHADAS") {
          time = time + parseInt(item.TotalValue);
          input.push({ 
            CardCode: item.CardCode,
            Price: item.TotalValue,
            Type: "Horas Trabalhadas",
            Date: item.Date,
            Salary: item.Salary,
            SalaryUnit: item.SalaryUnit,
            ProjectId: item.ProjectId,
            Business: item.BPLID,
            CostingCode: item.Dim1,
            CostingCode2: item.Dim2,
            CostingCode3: item.Dim3,
            CostingCode4: item.Dim4,
            CostingCode5: item.Dim5
          })
        }
        else {
          documents.push({
            ItemCode: item.Item,
            Quantity: item.Quantity,
            TaxCode: "",
            UnitPrice: item.Value,
            CostingCode: item.Dim1,
            CostingCode2: item.Dim2,
            CostingCode3: item.Dim3,
            CostingCode4: item.Dim4,
            CostingCode5: item.Dim5
          });
        }
      }

      let data = {
        CardCode: customerSelected.Client,
        BPL_IDAssignedToInvoice: customerSelected.BPLID,
        U_ALFA_DT_Compet: customerSelected.DateCompet,
        DocDueDate: customerSelected.Date,
        DocumentLines: documents
      }

      let insertedOrder;
      let inserted;

      if (customerSelected.OrderType == 'COMPRA') {
        insertedOrder = await PaymentService.insertPurchaseOrder(data);
      }
      else if (customerSelected.OrderType == 'VENDA') {
        insertedOrder = await PaymentService.insertSaleOrder(data);
      }
      else {
        inserted = await PaymentService.subtractProjectDebit(parseInt(customerData[0].ProjectDoc), price, time, input);
      }

      if (insertedOrder) {
        const sale = insertedOrder.data
        if (sale.error) {
          message.destroy();
          message.error("Ocorreu um erro ao reenviar o faturamento.", 3);
          for (const item of customerData) {
            await itemsService.updateStatusDraft(item.Code, "E");
          }
        }
        else {
          message.destroy();
          message.success("Faturamento realizado com sucesso.", 3);
          for (const item of customerData) {
            await itemsService.updateStatusDraft(item.Code, "S");
          }
        }
        await this.updateLog(sale, customerData);
        await this.fetch();
      }
      else if (inserted) {
        const professionaPayments = inserted.data;
        if (professionaPayments.error) {
          message.destroy();
          message.error("Ocorreu um erro ao reenviar o faturamento.", 3);
        }
        else {
          message.destroy();
          message.success("Faturamento realizado com sucesso.", 3);
        }
        await this.updateLog(professionaPayments, customerData);
        await this.fetch();
      }
      this.setState({ fetching: false, checkedList: [] });
    }
  }

  validateFields = () => {
    let checklist = this.state.checkedList;
    let data = this.state.dataSource;
    let verifyTypes = [];
    let type = false;

    let differentProject = checklist.filter(find => find.value == checklist[0].value);
    if (differentProject.length !== checklist.length) {
      message.error("Selecione somente um tipo de projeto.", 3);
      return false
    }

    for (const item of checklist) {
      let listData = data.filter(find => find.Id == item.id);
      verifyTypes.push({ type: listData[0].OrderType });
    }
    for (const item of verifyTypes) {
      if (item.type == verifyTypes[0].type) {
        type = true
      }
      else {
        type = false
      }
    }
    if (!type) {
      message.error("Selecione somente um tipo de pedido.", 3);
      return false
    }
    return true
  }

  render() {
    const fetching = this.state.fetching;

    const columns = [
      {
        title: "",
        key: 'combobox',
        width: "5%",
        align: 'center',
        render: (text, record) => (
          record.Status == 'ERROR' ?
            <Button.Group >
              <div style={{ padding: "7px" }}>
                <Checkbox dafaultChecked={false} value={record.ProjectName} onChange={(e) => this.onChange(e, record.Id)} />
              </div>
            </Button.Group>
            : null),
      },
      {
        title: 'Nome do projeto',
        dataIndex: 'ProjectName',
        key: 'ProjectName',
        align: 'left',
        width: "25%",
      },
      {
        title: 'Código do cliente',
        dataIndex: 'Client',
        key: 'Client',
        align: 'left',
        width: "10%"
      },
      {
        title: 'Cliente',
        dataIndex: 'ClientName',
        key: 'ClientName',
        align: 'left',
        width: "20%"
      },
      {
        title: 'Tipo pedido',
        dataIndex: 'OrderType',
        key: 'OrderType',
        align: 'center',
        width: "10%"
      },
      {
        title: 'Item',
        dataIndex: 'Item',
        key: 'Item',
        align: 'center',
        width: "10%"
      },
      {
        title: 'Data do log',
        dataIndex: 'LogDate',
        key: 'LogDate',
        align: 'center',
        width: "10%",
        render: (text, record) => <span>{(record.LogDate ? moment(record.LogDate).format('DD/MM/YYYY') : '')}</span>
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        align: 'center',
        width: "5%"
      },
      {
        title: 'Ação',
        key: 'action',
        width: "5%",
        align: 'center',
        render: (text, record) => (
          <Button.Group >
            <div style={{ padding: "7px" }}>
              <Tooltip placement="top" title={"Detalhes do item"}>
                <a><EyeOutlined onClick={() => this.openModal(record.Id)} /></a>
              </Tooltip>
            </div>
          </Button.Group>
        ),
      },
    ];

    return (
      <Spin spinning={fetching} delay={500}>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "Right", fontSize: "30px" }}>Listagem de logs</b>
          </div>
          <div style={{ width: "35%" }}>
            <Search placeholder="Pesquisar" onSearch={value => this.searchTerm(value)} />
          </div>
          <div style={{ width: "5%", float: "right" }}>
            <center>
              <FilterFilled style={{ fontSize: "20px", padding: "5px" }} />
            </center>
          </div>
        </Row>
        <Row style={{ width: "100%"/* , minHeight: "90vh" */ }}>
          <Col style={{ border: "1px solid #e8e8e8" }} span={24} >
            <Table columns={columns} dataSource={this.state.dataSource} style={{ height: "100%" }} pagination={true} />
          </Col>
        </Row>
        <div style={{ float: "right", paddingTop: "1%" }}>
          <Button type="primary" onClick={() => this.resendingOrders()}>Reenviar</Button>
        </div>
        <LogModal visible={this.state.modal} onClosed={() => this.setState({ modal: false })} data={this.state.dataModal} />
      </Spin>
    );
  }
}
export default Logs;
