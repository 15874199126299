import React, { useEffect, useState } from "react";
import { Form, Row, Input, message, Modal, Button, Spin, DatePicker, Select, InputNumber } from 'antd';
import { scheduleService } from '../../services/schedule';
import * as _ from "lodash";
import moment from "moment";
import { tasksService } from "../../services/tasks";


const ScheduleAddModal = (props) => {
  const [dropFather, setDropFather] = useState([]);
  const [dropPred, setDropPred] = useState([]);
  const [fetching, setfetching] = useState(false);

  const { TextArea } = Input;

  // Soma dos ExcpetedTime de um msm parenttask

  const onCreate = async (value) => {

    let scheduleTasks = await tasksService.getScheduleTask(props.parentTask === 'new' ? 1 : props.parentTask)
    // console.log('sch-add-modal ScheduleAddModal() - (scheduleTasks)', scheduleTasks);

    if (scheduleTasks.data.error) {
      console.log('ERROR',scheduleTasks.data.error);
      message.destroy();
      setfetching(false);
      message.error('Ocorreu um erro ao criar cronograma.', 3);
    }
    else {
      if (scheduleTasks.data.data.length === 0) {
        if (await validateFields(value)) {
        message.loading('Criando cronograma ...', 100);
        setfetching(true);

        let predecessor = 0;
        if (value.Predecessor) {
          predecessor = value.Predecessor;
        }
        
        const inputdata = {
          ProjectId: props.id,
          Description: value.description,
          ConclusionPercentage: 0,
          ExpectedTime: value.previousHours ? parseFloat(value.previousHours) : 0,
          ExpectedStartDate: value.previousStartDate ? moment(value.previousStartDate).format("YYYY-MM-DD") : '',
          ExpectedEndDate: value.previousEndDate ? moment(value.previousEndDate).format("YYYY-MM-DD") : '',
          StartDate: value.previousStartDate ? moment(value.previousStartDate).format("YYYY-MM-DD") : '',
          EndDate: value.previousEndDate ? moment(value.previousEndDate).format("YYYY-MM-DD") : '',
          ParentTask: props.parentTask != "new" && props.parentTask != 0 ? props.parentTask : 1,
          Predecessor: predecessor
        }
        console.log(inputdata);
        let schedule = await scheduleService.createSchedule(inputdata);
        // console.log('sch-add-modal ScheduleAddModal - schedule',schedule);
        if (schedule.data.error) {
          console.log('ERROR',schedule.data.error);
          message.destroy();
          setfetching(false);
          message.error('Ocorreu um erro ao criar cronograma.', 3);
        }
        else {
          // Coleta a soma dos ExpectedTime dos parents tasks
          let parentTasksTime = await scheduleService.getParentTasksTime(props.id);
          // se retornar filho(s) do início do cronogrma
          if (parentTasksTime.data.data.length > 0){
            // itera cada resistro
            for(let parent of parentTasksTime.data.data){
              // monta o objeto com a menor e maior data dos filhos e o id do parentTask pai
              const parentData = { 
                minDate: moment(parent.MinDate).format('YYYY-MM-DD').toString(),
                maxDate: moment(parent.MaxDate).format('YYYY-MM-DD').toString(),
                id: parent.ParentTask
              };
              // atualiza o ExpectedTime e as menores e maiores datas do pai
              await scheduleService.updateParentTaskTime(parentData);
            }
          // se não tem pai (primeiro ítem do cronograma)
          }else{
            // monta e envia o ProjectId e o Expected zerado
            let objfirst = {Id: props.id, ExpectedTime: 0}
            // atualiza o ExpectedTime do pai para zero
            await scheduleService.updateFirstParentTask(objfirst)
          }

          message.destroy();
          message.success('Cronograma criado com sucesso.', 3);
          recursiveMountData(props.parentTask === 'new' ? -1 : props.parentTask, value);
          // if (props.parentTask !== 'new') {

          //   await scheduleService.updateTotalTime(props.id, null);
          // }

          setfetching(false);
          props.onUpdateData()
        }
      }

    }
      else {
        message.destroy();
        setfetching(false);
        message.error('Já existe um tarefa atribuida a este cronograma. Remova as tarefas vinculadas antes.', 3);
      }
    }
  }

  const getFatherId = (fatherId) => {
    const child = props.dataSource.filter(item => { return fatherId == item.Id && item.ParentTask != -1 })
    if (child.length > 0) {
      const father = props.dataSource.map(item => {
        return child[0].ParentTask == item.Id ? item : null
      }).filter(r => { return r !== null });

      return father;
    } else {
      return child;
    }
  }

  const validateDate = (inputStartDate, previousStartDate) => {

    let startDate
    if (inputStartDate) {
      if (previousStartDate) {
        startDate = moment(inputStartDate).isSameOrAfter(previousStartDate) ? moment(inputStartDate).format("YYYY-MM-DD") : previousStartDate
      } else {
        startDate = moment(inputStartDate).format("YYYY-MM-DD")
      }
    } else {
      startDate = previousStartDate ? previousStartDate : ''
    }
    return startDate;
  }

  const recursiveMountData = async (id, value) => {

    const child = props.dataSource.filter(item => { return id == item.Id });
    // console.log('sch-add-modal recursiveMountData - props',props);
    if (child.ExpectedTime > 0){
      const inputdata = {
        // alterado parseInt para parseFloat
        ExpectedTime: value.previousHours ? parseFloat(value.previousHours) + _.first(child).ExpectedTime : _.first(child).ExpectedTime,
        ExpectedStartDate: validateDate(value.previousStartDate, _.first(child).ExpectedStartDate),
        ExpectedEndDate: validateDate(value.previousEndDate, _.first(child).ExpectedEndDate),
        // ConclusionPercentage: 
      }

      await scheduleService.updateTotalTime(id, inputdata);
    }
    // else{
    //   const inputdata = {
    //     ExpectedTime: value.previousHours ? parseInt(value.previousHours) + _.first(child).ExpectedTime : _.first(child).ExpectedTime,
    //     ExpectedStartDate: validateDate(value.previousStartDate, _.first(child).ExpectedStartDate),
    //     ExpectedEndDate: validateDate(value.previousEndDate, _.first(child).ExpectedEndDate),
    //     // ConclusionPercentage: 
    //   }

    //   await scheduleService.updateTotalTime(id, inputdata);
    // }

    const father = getFatherId(id);
    if (father.length > 0) {
      recursiveMountData(father[0].Id, value);
    }
  }

  const validateFields = (values) => {
    let scheduleList = props.dataSource.filter(find => find.ParentTask == props.parentTask);

    // if (!scheduleList[0]) {
    //   if (props.time != 0 || props.time) {
    //     message.error('Você não pode criar uma tarefa dentro de uma tarefas com horas.', 3);
    //     return false;
    //   }
    // }
    
    try {
      
      if (moment(values.previousStartDate).isAfter(moment(values.previousEndDate))) {
        message.error("Previsão de início deve ser menor ou igual previsão de término.", 3);
        return false;
      }

      // debugger
      let firstTask = props.dataSource[0];
      if (firstTask) {
        if ((firstTask.Balance - values.previousHours) < 0) {
          message.error("Atividade excede a quantidade de saldo de horas do projeto", 3);
          return false;
        } 
      }

    }
    catch (ex) {
    }

    if (!values.previousEndDate) {
      message.error('Campo data final previsto não preenchido.', 3);
      return false;
    }
    if (!values.description) {
      message.error('Campo descrição não preenchido.', 3);
      return false;
    }
   
    /*let verifyDescription = props.dataSource.filter(find => { return find.Description == values.description });
    if (verifyDescription[0]) {
      message.error("Campo descrição já existe.", 3);
      return false
    }*/

    if ((props.project.TotalTimeAmount+props.project.Time_Change) - props.project.ConsumedTime < values.previousHours) {
      message.error('Campo horas maior que o saldo liberado.', 3);
      return false;
    }


    return true;
  }

  useEffect(() => {
    dropDown(props.id);
  }, [props]);

  const dropDown = async (id) => {
    const dataDropFather = [];
    const dataDropPred = [];
    const { data: dataSchedule } = await scheduleService.getScheduleByProjectId(parseInt(id));
    let schedule = dataSchedule.data;
    // if (schedule[0]) {
      if (props.parentTask == "new") {
        let father = schedule.map((r) => {
          return r.ParentTask === 1 ? r : null
        }).filter((r) => { return r !== null });

        for (const item of father) {
          dataDropFather.push({ value: item.Id, label: item.Description });
        }
        setDropFather(dataDropFather);
      }
      else if (props.parentTask != 0) {
        dataDropFather.push({ value: props.parentTask, label: props.description });
        setDropFather(dataDropFather);
      }

      let predecessor = [];

      if (schedule) {
        predecessor = schedule.filter((r) => { return r != null });
      };

      for (const item of predecessor) {
        dataDropPred.push({ value: item.Id, label: item.Description });
      }
      setDropPred(dataDropPred);
    }

  // }

  return (
    <Modal title={"Criar cronograma " + props.description}
      visible={props.visible}
      id="view-form"
      width={"60%"}
      footer={null}
      onOk={props.onClose}
      onCancel={props.onClose}
      destroyOnClose={props.onClose}
      cancelButtonProps={{ style: { display: 'none' } }}>
      <Spin spinning={fetching}>
        <Form style={{ width: "100%" }} onFinish={onCreate}>
          <Input.Group compact>
            <div style={{ width: "25%", paddingRight: "1%" }}>
              <p>Dependência:</p>
              <Form.Item name="Predecessor">
                <Select options={dropPred} placeholder="Selecione" />
              </Form.Item>
            </div>
            <div style={{ width: "25%" }}>
              <p>Data Prévio Inicio:</p>
              <Form.Item name="previousStartDate"><DatePicker placeholder="Selecione uma data" format="DD/MM/YYYY" style={{ width: "100%" }} /></Form.Item>
            </div>
            <div style={{ width: "25%", padding: "0px 1%" }}>
              <p>Data Prévio Termino:</p>
              <Form.Item name="previousEndDate"><DatePicker placeholder="Selecione uma data" format="DD/MM/YYYY" style={{ width: "100%" }} /></Form.Item>
            </div>
            <div style={{ width: "25%" }}>
              <p>Hora Previstas:</p>
              <Form.Item name="previousHours"><InputNumber style={{ width: "100%" }} decimalPlaces="2"/></Form.Item>
            </div>
          </Input.Group>
          <div style={{ width: "100%" }} >
            <p>Descrição:</p>
            <Form.Item name="description">
              <TextArea placeholder="Digite a descrição" autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
          </div>
          <Row>
            <div style={{ float: "right", width: "100%" }}>
              <div style={{ float: "right" }}>
                <Button type="primary" htmlType="submit">
                  Salvar
                  </Button>
              </div>
              <div style={{ paddingRight: "1%", float: "right" }}>
                <Button style={{ color: "black" }} onClick={() => props.onClose()}>
                  Fechar
                  </Button>
              </div>
            </div>
          </Row>
        </Form>
      </Spin>
    </Modal>

  )
}

export default ScheduleAddModal;