import React, { useEffect, useState } from "react";
import { Form, Row, Col, Modal, Input, message, Select, Upload, Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { EletronicDocumentsService } from '../../services/eletronic-documents';
import { useStateValue } from '../../state';
import { usersService } from '../../services/users';
import conf from '../../environment.json';

const { TextArea } = Input;

const { Dragger } = Upload;

const ModalDocuments = (props) => {

    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [project, setProject] = useState([]);
    const [file, setFile] = useState([]);
    const [{ authentication }, dispatch] = useStateValue();

    //config pra subir arquivos
    const config = {
        name: 'file',
        multiple: false,
        action: conf['API_URL'] + '/eletronic-documents/file',
        onChange(info) {
            console.log(info);
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
                console.log(info);
                // message.success(`Arquivo ${info.file.name} subiu com sucesso.`);
                // console.log('info.file', info.file);
                // console.log('info.fileList',info.fileList);
                setFile([{ path: info.file.response.imagePath, fileName: info.file.response.filename }]);
            } else if (status === 'error') {
                // message.error(`Arquivo ${info.file.name} falhou ao subir.`);
            }
        },
    };

    async function fetch() {
        let responseCategory = await EletronicDocumentsService.getCategory();
        if (responseCategory.data.data) {
            var CategoryMenu = [];
            for (const category of responseCategory.data.data) {
                CategoryMenu.push({ value: category.Id, label: category.Description });
            }
            setCategory(CategoryMenu);
        }
        let responseProject = await EletronicDocumentsService.getProject();
        if (responseProject.data.data) {
            var projectMenu = [];
            for (const project of responseProject.data.data) {
                projectMenu.push({ value: project.Code, label: `${project.Code}  -  ${project.Title ? project.Title : project.Description}` });
            }
            setProject(projectMenu);
        }
    }

    useEffect(() => {
        fetch();
    }, []);

    const onFinishDocuments = async (values) => {
        if (validateFields(values)) {
            const userId = await usersService.verifyByUsername(authentication.user);
            const data = {
                Title: values.title,
                Description: values.description,
                ProjectId: props.projectId ? props.projectId : !values.project ? "" : values.project,
                Relation: '',
                FileName: file[0].fileName,
                FileCategoryId: selectedCategory, // troquei pra select ai ele ta vindo string, 
                FilePath: file[0].path,
                UserId: userId.data.data[0].Id
            }
            message.loading('Criando documento eletrônico ...', 100);
            const response = await EletronicDocumentsService.post(data);
            if (!response.data.error) {
                message.destroy();
                message.success('Documento criado com sucesso.', 3);
                props.closedModal();
            }
            else {
                message.destroy();
                message.error('Ocorreu um erro ao criar o documento.', 3);
            }
        }
    }

    const validateFields = (values) => {
        if (!values.title) {
            message.error('Campo título não preenchido.', 3);
            return false;
        }
        if (!selectedCategory) {
            message.error('Campo categoria de documento não preenchido.', 3);
            return false;
        }
        if (!values.description) {
            message.error('Campo descrição de horas não preenchido.', 3);
            return false;
        }
        if (!values.file) {
            message.error('Nenhum arquivo foi anexado.', 3);
            return false;
        }
        return true;
    }

    const onChangeCategory = (value, obj) => {
        //trocado obj.label por obj.value para trazer o id da categoria do documento
        setSelectedCategory(obj.value);
    }

    return (
        <Modal title="Gerenciador de Arquivos"
            visible={props.visible}
            footer={null}
            onCancel={props.closedModal}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={900}
            destroyOnClose={props.closedModal}>
            <Form onFinish={onFinishDocuments}>
                <Row gutter={[8, 8]}>
                    <Col span={17}>
                        <h4>Título</h4>
                        <Form.Item name="title">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <h4>Categoria do Documento</h4>
                        <Select style={{ width: "100%" }} options={category} placeholder="Selecione" onChange={(e, label) => onChangeCategory(e, label)} />
                    </Col>
                    {/* <Col span={7}>
                        <h4>Projeto</h4>
                        {!props.projectId ?
                            <Form.Item name="project">
                                <Select options={project} placeholder="Selecione" />
                            </Form.Item>
                            :
                            <Form.Item rules={[{ required: true }]}>
                                <Input disabled value={props.projectId} />
                            </Form.Item>
                        }
                    </Col> */}
                </Row>
                <Row style={{ marginTop: 25 }}>
                    <Col span={24}>
                        <h4>Descrição</h4>
                        <Form.Item name="description">
                            <TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: 25, marginBottom: 60 }}>
                    <Row style={{ width: "100%" }}>
                        <h4>Arquivo</h4>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col span={24}>
                            <Form.Item name="file">
                                <Dragger {...config}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Clique ou Arraste o arquivo para upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <Col offset={20} span={2}>
                        <Button style={{ width: 120, marginTop: 10, marginLeft: 22 }} type="primary" htmlType="submit">Enviar</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default ModalDocuments;