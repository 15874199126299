import React, { useState } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Spin,
  Input,
  Button,
  Tabs,
  Modal,
  message,
  Select,
  Form,
  DatePicker
} from "antd";
import * as _ from 'lodash';
import { usersService } from "../../services/users"

const { TabPane } = Tabs;
const UserModal = (props) => {
  const [spinning, setSpinning] = useState(false);
  const onEdit = async (value) => {
    
    // console.log('value',value);
    // console.log('props',props);
    if (value) {
      message.loading(props.editForm ? 'Editando usuário ...' : 'Cadastrando usuário ...', 100);
      setSpinning(true);
      const inputdata = {
        // O que é o campo HierarchyId ?
        Id: props.data.ID,
        Name: value.NAME ? value.NAME : props.data.NAME,
        Username: value.Username ? value.Username : props.data.Username,
        Address: value.Address ? value.Address : props.data.Address ? props.data.Address : '',
        Number: value.Number ? value.Number : props.data.Number ? props.data.Number : 0,
        Complement: value.Complement ? value.Complement : props.data.Complement ? props.data.Complement : '',
        ZipCode: value.ZipCode ? value.ZipCode : props.data.ZipCode ? props.data.ZipCode : '',
        City: value.City ? value.City : props.data.City ? props.data.City : '',
        State: value.State ? value.State : props.data.State ? props.data.State : '',
        Phone: value.Phone ? value.Phone : props.data.Phone ? props.data.Phone : '',
        Mobile: value.Mobile ? value.Mobile : props.data.Mobile ? props.data.Mobile : '',
        CPF: value.CPF ? value.CPF : props.data.CPF ? props.data.CPF : '',
        RG: value.RG ? value.RG : props.data.RG ? props.data.RG : '',
        CivilStatus: value.CivilStatus ? value.CivilStatus : props.data.CivilStatus ? props.data.CivilStatus : '',
        Birthdate: value.Birthdate ? moment(value.Birthdate).format("YYYY-MM-DD") : props.data.Birthdate ? moment(props.data.Birthdate).format("YYYY-MM-DD") : '',
        CompanyAdmission: value.CompanyAdmission ? moment(value.CompanyAdmission).format("YYYY-MM-DD") : props.data.CompanyAdmission ? moment(props.data.CompanyAdmission).format("YYYY-MM-DD") : '',
        Email: value.EMAIL ? value.EMAIL : props.data.EMAIL,
        ContractType: value.ContractType ? value.ContractType : props.data.ContractType ? props.data.ContractType : '',
        Status: value.Status ? value.Status : props.data.Status ? props.data.Status : '',
        Cost: value.Cost ? value.Cost : props.data.Cost ? props.data.Cost : 0,
        Position: value.Position ? value.Position : props.data.Position ? props.data.Position: '',
        BusinessPartner: value.BusinessPartner ? value.BusinessPartner : props.data.BusinessPartner ? props.data.BusinessPartner : 0,
        AccessProfileId: value.AccessProfileId ? value.AccessProfileId : props.data.PROFILE,
        Password: value.Password,
        // SAPId: value.SAPId ? value.SAPId : 0,
        // Billing: value.Billing ? value.Birthdate : 0
      };
      // se apresentar erro na tentativa de atualizar os dados, logá-lo para entendimento
      try{
        if (props.editForm) {
          await usersService.updateUser(inputdata).then(r => {
            if (!r.data.error) {
              message.destroy();
              message.success('Perfil Alterado.');
            }
            else {
              message.destroy();
              console.log('inputdata',inputdata);
              console.log('ERROR',r.data.error);
              message.error('Falha ao atualizar perfil.')
            }
          });
        } else {
          await usersService.createUser(inputdata).then(r => {
            if (!r.data.error) {
              message.destroy();
              message.success('Usuário Cadastrado.');
            }
            else {
              message.destroy();
              console.log('inputdata',inputdata);
              console.log('ERROR',r.data.error);
              message.error('Falha ao cadastrar usuário.')
            }
          });
        }

      }catch(e){
        // console.log('user-modal update ERROR: ',e);
      }
      setSpinning(false);
    }
  }

  const dateFormat = 'YYYY/MM/DD';

  return (

    <Modal
      title={props.title}
      width={"70%"}
      visible={props.visible}
      footer={null}
      onOk={props.onCancel}
      onCancel={props.onCancel}
      destroyOnClose={props.onCancel}>

      <Spin spinning={spinning}>
        <Form style={{ width: "100%" }} onFinish={onEdit} >
          <Row>
            <Input.Group compact style={{ margin: "0px 1%" }}>
              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>ID:</p>
                <Form.Item>
                  <Input disabled={true} value={props.data.ID && props.data.ID != "null" && props.data.ID != "undefined" && props.data.ID != "0" ? props.data.ID : ""} />
                </Form.Item>
              </div>
              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Nome:</p>
                <Form.Item name={['NAME']} initialValue={props.data.NAME && props.data.NAME != "null" && props.data.NAME != "undefined" && props.data.NAME != "0" ? props.data.NAME : ""}>
                  <Input disabled={false} />
                </Form.Item>
              </div>
              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Nome de usuário:</p>
                <Form.Item name={['Username']} initialValue={props.data.Username && props.data.Username != "null" && props.data.Username != "undefined" && props.data.Username != "0" ? props.data.Username : ""}>
                  <Input disabled={props.editForm} />
                </Form.Item>
              </div>
              <div style={{ width: "25%", padding: "0px 1%" }}>
                <p>Email:</p>
                <Form.Item name={['EMAIL']} initialValue={props.data.EMAIL && props.data.EMAIL != "null" && props.data.EMAIL != "undefined" && props.data.EMAIL != "0" ? props.data.EMAIL : ""}>
                  <Input disabled={false} />
                </Form.Item>
              </div>
            </Input.Group>
          </Row>
          <Row style={{ padding: "10px 0px 5px 0px" }}>
            <Tabs type="card" style={{ width: "100%" }} onTabClick={key => key == 4} >
              <TabPane tab="Usuário" key="1">
                <Input.Group compact style={{ margin: "0px 1%" }}>
                  <div style={{ width: "20%", padding: "0px 1%" }}>
                    <p>Status:</p>
                    <Form.Item maxLength={"7"} name={['Status']} initialValue={props.data.STATUS && props.data.STATUS != "null" && props.data.STATUS != "undefined" && props.data.STATUS != "0" ? props.data.STATUS : ""} >
                      {/* <Input /> */}
                      <Select disabled={false} defaultValue={props.data.STATUS}>
                        <Select.Option value={"A"}>Ativo</Select.Option>
                        <Select.Option value={"I"}>Inativo</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  {/* <div style={{ width: "20%", padding: "0px 1%" }}>
                    <p>Faturar:</p>
                    <Form.Item maxLength={"7"} name={['Billing']} initialValue={props.data.Billing && props.data.Billing != "null" && props.data.Billing != "undefined" && props.data.Billing != "0" ? props.data.Billing : ""} >
                      {/* <Input /> * /}
                      <Select disabled={true } defaultValue={props.data.AccessProfileId}>
                        <Select.Option value={"Y"}>Sim</Select.Option>
                        <Select.Option value={"N"}>Não</Select.Option>
                      </Select>
                    </Form.Item>
                  </div> */}
                  {/* <div style={{ width: "20%", padding: "0px 1%" }}>
                    <p>Código:</p>
                    <Form.Item name={['SAPId']} initialValue={props.data.SAPId && props.data.SAPId != "null" && props.data.SAPId != "undefined" && props.data.SAPId != "0" ? props.data.SAPId : ""}>
                      <Input disabled={ true } />
                    </Form.Item>
                  </div> */}
                  <div style={{ width: "20%", padding: "0px 1%" }}>
                    <p>Password:</p>
                    <Form.Item type={"password"} name={['Password']} initialValue={props.data.Password && props.data.Password != "null" && props.data.Password != "undefined" && props.data.Password != "0" ? props.data.Password : ""}  >
                      <Input disabled={false} type={"password"}/>
                    </Form.Item>
                  </div>
                  <div style={{ width: "20%", padding: "0px 1%" }}>
                    <p>Perfil:</p>
                    <Form.Item name={['AccessProfileId']}>
                      <Select disabled={false} defaultValue={props.data.PROFILE}>
                        {props.dropdown && props.dropdown.map(data => (
                          <Select.Option value={data.Id}>{data.Name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Input.Group>
              </TabPane>
              <TabPane tab="Endereço" key="2"> 
                <Input.Group compact style={{ margin: "0px 1%" }}>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>Logradouro:</p>
                    <Form.Item name={['Address']} initialValue={props.data.Address && props.data.City != "null" && props.data.City != "undefined" && props.data.City != "0" ? props.data.Address : ""} >
                      <Input disabled={props.fields ? true : false} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>Número:</p>
                    <Form.Item name={['Number']} initialValue={props.data.Number && props.data.Number != "null" && props.data.Number != "undefined" && props.data.Number != "0" ? props.data.Number : ""} >
                      <Input disabled={props.fields ? true : false} maxLength={"6"} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>Complemento:</p>
                    <Form.Item name={['Complement']} initialValue={props.data.Complement && props.data.Complement != "null" && props.data.Complement != "undefined" && props.data.Complement != "0" ? props.data.Complement : ""} >
                      <Input disabled={props.fields ? true : false} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>CEP:</p>
                    <Form.Item name={['ZipCode']} initialValue={props.data.ZipCode && props.data.ZipCode != "null" && props.data.ZipCode != "undefined" && props.data.ZipCode != "0" ? props.data.ZipCode : ""} >
                      <Input disabled={props.fields ? true : false} maxLength={"9"} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>Cidade:</p>
                    <Form.Item name={['City']} initialValue={props.data.City && props.data.City != "null" && props.data.City != "undefined" && props.data.City != "0" ? props.data.City : ""} >
                      <Input disabled={props.fields ? true : false} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>Estado:</p>
                    <Form.Item name={['State']} initialValue={props.data.State && props.data.State != "null" && props.data.State != "undefined" && props.data.State != "0" ? props.data.State : ""}  >
                      <Input disabled={props.fields ? true : false} />
                    </Form.Item>
                  </div>
                </Input.Group>
              </TabPane>
              <TabPane tab="Informações" key="3">
                <Input.Group compact style={{ margin: "0px 1%" }}>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>Telefone:</p>
                    <Form.Item name={['Phone']} initialValue={props.data.Phone && props.data.Phone != "undefined" && props.data.Phone != "null" && props.data.Phone != "0" ? props.data.Phone : ""}  >
                      <Input disabled={props.fields ? true : false} maxLength={"11"} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>Celular:</p>
                    <Form.Item name={['Mobile']} initialValue={props.data.Mobile && props.data.Mobile != "undefined" && props.data.Mobile != "null" && props.data.Mobile != "0" ? props.data.Mobile : ""} >
                      <Input disabled={props.fields ? true : false} maxLength={"12"} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>CPF:</p>
                    <Form.Item name={['CPF']} initialValue={props.data.CPF && props.data.CPF != "undefined" && props.data.CPF != "null" && props.data.CPF != "0" ? props.data.CPF : ""}>
                      <Input disabled={props.fields ? true : false} maxLength={"14"} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>RG:</p>
                    <Form.Item name={['RG']} initialValue={props.data.RG && props.data.RG != "undefined" && props.data.RG != "null" && props.data.RG != "0" ? props.data.RG : ""} >
                      <Input disabled={props.fields ? true : false} maxLength={"12"} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>Estatus Civil:</p>
                    <Form.Item name={['CivilStatus']} initialValue={props.data.CivilStatus && props.data.CivilStatus != "undefined" && props.data.CivilStatus != "null" && props.data.CivilStatus != "0" ? props.data.CivilStatus : ""} >
                      <Input disabled={props.fields ? true : false} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "33%", padding: "0px 1%" }}>
                    <p>Data de Nascimento:</p>
                    <Form.Item name={'Birthdate'}>
                      <DatePicker disabled={props.fields ? true : false} defaultValue={props.data.Birthdate && props.data.Birthdate !== 'Invalid date' ? moment(props.data.Birthdate, dateFormat) : null} format={"DD/MM/YYYY"} placeholder="Selecione uma data" style={{ width: "100%" }} />
                    </Form.Item>
                  </div>
                </Input.Group>
              </TabPane>
              <TabPane tab="Contrato" key="4">
                <Input.Group compact style={{ margin: "0px 1%" }}>
                  <div style={{ width: "20%", padding: "0px 1%" }}>
                    <p>Data de Admissão:</p>
                    <Form.Item name={'CompanyAdmission'} >
                      <DatePicker disabled={props.fields ? true : false} defaultValue={props.data.CompanyAdmission && props.data.CompanyAdmission !== 'Invalid date' ? moment(props.data.CompanyAdmission, dateFormat) : null} format={"DD/MM/YYYY"} placeholder="Selecione uma data" style={{ width: "100%" }} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "20%", padding: "0px 1%" }}>
                    <p>Tipo de Contrato:</p>
                    <Form.Item name={['ContractType']} initialValue={props.data.ContractType && props.data.ContractType != "undefined" && props.data.ContractType != "null" && props.data.ContractType != "0" ? props.data.ContractType : ""} >
                      <Input disabled={props.fields ? true : false} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "20%", padding: "0px 1%" }}>
                    <p>Custo:</p>
                    <Form.Item name={['Cost']} initialValue={props.data.Cost && props.data.Cost != "undefined" && props.data.Cost != "null" && props.data.Cost != "0" ? props.data.Cost : ""} >
                      <Input disabled={props.fields ? true : false} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "20%", padding: "0px 1%" }}>
                    <p>Função:</p>
                    <Form.Item name={['Position']} initialValue={props.data.Position && props.data.Position != "undefined" && props.data.Position != "null" && props.data.Position != "0" ? props.data.Position : ""} >
                      <Input disabled={props.fields ? true : false} />
                    </Form.Item>
                  </div>
                  {/* <div style={{ width: "20%", padding: "0px 1%" }}>
                    <p>Parceiro de Negócios:</p>
                    <Form.Item name={['BusinessPartner']} initialValue={props.data.BusinessPartner ? props.data.BusinessPartner : ""} >
                      <Input disabled={props.fields ? true : false} />
                    </Form.Item>
                  </div> */}
                </Input.Group>
              </TabPane>
            </Tabs>
            <Row style={{ width: "100%" }}>
              <div style={{ float: "right", width: "100%" }}>
                <div style={{ float: "right" }}>
                  <Button type="primary" htmlType="submit" onClick={() => props.onCancel()}>Salvar</Button>
                </div>
                <div style={{ float: "right", paddingRight: "1%" }}>
                  <Button onClick={() => props.onCancel()}>Fechar</Button>
                </div>
              </div>
            </Row>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default UserModal;