import React from "react";
import { Form, Row, Col, Table, Input, Spin, Button, Tooltip, message, Popconfirm } from 'antd';
import { FilterFilled, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from "moment";
import config from '../../environment.json';
import ModalDocuments from '../../components/modal-documents';
import { FloatingButton } from '../../components/floating-button';
import { EletronicDocumentsService } from '../../services/eletronic-documents';
moment.locale('pt-BR');


const { Search } = Input;

class EletronicDocuments extends React.Component {


    state = {
        fetching: false,
        dataSource: [],
        modalVisible: false
    }

    componentDidMount() {
        this.fetch();
    }

    searchTerm = (value) => {
        if (value) {
            this.setState({ fetching: true });
            const filtrado = this.state.dataSource.filter((x) => {
                return x.Title.toLowerCase().includes(value.toLowerCase()) ||
                    x.Description.toLowerCase().includes(value.toLowerCase());
            });
            this.setState({ dataSource: filtrado, fetching: false });
        }
        else {
            this.fetch();
        }
    };

    fetch = async () => {
        this.setState({ fetching: true });
        const data = await EletronicDocumentsService.get();
        if (data.data.data) {
            this.setState({ dataSource: data.data.data, modalVisible: false, fetching: false });
        }
        // mesmo sem dados, encerrado o loading
        this.setState({fetching: false});
    }

    cancel = async (id) => {
        this.setState({ fetching: true });
        message.loading('Excluindo documento eletrônico..', 100);
        const response = await EletronicDocumentsService.delete(id);
        if (response.status === 200) {
            message.destroy();
            message.success('Documento excluido com sucesso.', 3);
            this.fetch();
            this.setState({ fetching: false });
        }
    }

    onClosedModal = async () => {
        this.fetch();
        this.setState({ modalVisible: false });
    }

    render() {

        const columns = [
            {
                title: 'Titulo',
                dataIndex: 'Title',
                key: 'Title',
                align: 'left',
                width: "30%",
            },
            {
                title: 'Descrição',
                dataIndex: 'Description',
                key: 'Description',
                align: 'left',
                width: "40%",
            },
            // {
            //     title: 'Nome do arquivo',
            //     dataIndex: 'FileName',
            //     key: 'FileName',
            //     align: 'center',
            //     width: 10,
            // },
            // {
            //     title: 'Relação',
            //     dataIndex: 'Relation',
            //     key: 'Relation',
            //     align: 'center',
            //     width: 10,
            // },
            // {
            //     title: 'Projeto',
            //     key: 'ProjectId',
            //     align: 'center',
            //     width: 10,
            //     render: (text, record) => <span>{!record.ProjectId ? "- -" : record.ProjectId}</span>
            // },
            {
                title: 'Categoria',
                dataIndex: 'FileCategoryId',
                key: 'FileCategoryId',
                align: 'center',
                width: "10%",
            },
            {
                title: 'Ação',
                key: 'Action',
                width: "10%",
                align: 'center',
                render: (text, record) => (
                    <Button.Group >
                        <div style={{ padding: "7px" }}>
                            <Tooltip placement="top" title={"Baixar"}>
                                <a href={config['API_URL'] + '/uploads/EletronicDocuments/' + record.FileName} download target="_blank"><DownloadOutlined /></a>
                            </Tooltip>
                        </div>
                        <div style={{ padding: "7px" }}>
                            <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                                onConfirm={() => this.cancel(record.Id)} okText="Sim" cancelText="Não">
                                <Tooltip placement="top" title={"Excluir"}>
                                    <a><DeleteOutlined /></a>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </Button.Group>
                ),
            },
        ];


        return (
            <Spin spinning={this.state.fetching} delay={500}>
                <Form /* style={{ minHeight: '90vh' }} */>
                    <ModalDocuments
                        visible={this.state.modalVisible}
                        closedModal={() => this.onClosedModal()}
                        onOk={() => this.setState({ modalVisible: false })}
                        onCancel={() => this.setState({ modalVisible: false })} />
                    <Row style={{ paddingBottom: "25px" }}>
                        <div style={{ width: "60%" }}>
                            <b style={{ textAlign: "left", fontSize: "30px" }}>Cadastro de documentos</b>
                        </div>
                        <div style={{ width: "35%" }}>
                            <Search placeholder="Pesquisar de título ou descrição" onSearch={(value) => this.searchTerm(value)} />
                        </div>
                        {/* <div style={{ width: "5%", float: "right" }}>
                            <center>
                                <FilterFilled onClick={() => this.setState({ modal: true })} style={{ fontSize: "20px", padding: "5px" }} />
                            </center>
                        </div> */}
                    </Row>
                    <Row style={{ width: "100%"/* , minHeight: "90vh" */ }}>
                        <Col style={{ border: "1px solid #e8e8e8" }} span={24} >
                            <Table columns={columns} dataSource={this.state.dataSource} style={{ height: "100%" }} pagination={true} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FloatingButton icon="+" onClick={() => this.setState({ modalVisible: true })} />
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}
export default EletronicDocuments;