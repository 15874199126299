import React from "react";
import { Form, Row, Tooltip, message, Table, Popconfirm, Button, Spin, Upload, Col, InputNumber } from 'antd';
import { scheduleService } from '../../services/schedule'
import moment from "moment";
import * as _ from 'lodash';
import { DeleteOutlined, PlusOutlined, EditOutlined, UploadOutlined, CheckCircleOutlined } from "@ant-design/icons";
import ScheduleAddModal from "../../components/schedule-add-modal";
import ScheduleEditModal from '../../components/schedule-edit-modal';
import { tasksService } from "../../services/tasks";
import config from '../../environment.json';

// import readXlsxFile from 'read-excel-file' // FIXME: PHD


moment.locale("pt-BR");

class Schedule extends React.Component {
  state = {
    dropFather: [],
    dropPred: [],
    fetching: false,
    projectid: [],
    visible: false,
    dataSource: [],
    schedules: [],
    projectSchedule: [],
    key: 0,
    description: "",
    time: 0,
    modalEdit: false,
    dataSourceEdit: []
  };


  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.onFetch(this.props.match.params.id)
  }
  
  onFetch = async (id) => {
    this.setState({ fetching: true });
    const projectData = await scheduleService.getSchPjCus(id);
    let prj = [];
    prj = projectData.data.data[0];

    if (prj) {
      const data = {
        Title: prj.Title ? prj.Title : prj.ProjectDescription,
        Code: prj.Code,
        CompanyName: prj.Company.trim(),
        Id: prj.Id,
        EstimatedTimeAmount: prj.HoursContribution ? prj.HoursContribution : 0,
        TotalTimeAmount: prj.TotalTimeAmount ? prj.TotalTimeAmount : 0,
        ConsumedTime: (prj.Consumed_Time ? prj.Consumed_Time : 0),
        ExpectedTime: prj.ExpectedTime,
        ExpectedStartDate: prj.StartDate,
        ExpectedEndDate: prj.EndDate,
        Status: prj.ProjectModelCode,
        // adiionado aporte de horas
        Time_Change: prj.Time_Change
      }
      const { data: dataSchedule } = await scheduleService.getScheduleByProjectId(id);
      // console.log(dataSchedule.data);
      // let schedule = []

      // schedule = dataSchedule.data;
      this.setState({ projectSchedule: data, schedules: dataSchedule.data });

      const getChildrenByFatherId = (fatherId) => {
        const children = dataSchedule.data.map(item => {
          return fatherId == item.ParentTask ? item : null
        }).filter(r => { return r !== null });
        return children;
      }

      const recursiveMountData = (list) => {
        const resultList = [];

        let ordinationList = list.sort((a, b) => a.Id - b.Id);
        for (const item of ordinationList) {
          
          let pred = dataSchedule.data.map(data => {
            return data.Id == item.Predecessor ? data.Description : null
          }).filter(f => f != null);

          const childrens = getChildrenByFatherId(item.Id);

          const result = {
            key: item.Id,
            ProjectId: item.ProjectId,
            Description: item.Description,
            Predecessor: pred ? pred[0] : 1,
            ExpectedStartDate: item.ExpectedStartDate,
            ExpectedEndDate: item.ExpectedEndDate,
            ExpectedTime: item.ExpectedTime,
            ConclusionPercentage: item.ConclusionPercentage,
            ParentTask: item.ParentTask,
            children: []
          }
          if (childrens.length > 0) {
            let child = recursiveMountData(childrens);

            let percentageChild = child.map(c => {
              let percentage = (c.ExpectedTime / item.ExpectedTime) * c.ConclusionPercentage
              return percentage
            });
            let conclusionPercentageChild = _.sum(percentageChild)
            result.children = child;
            result.ConclusionPercentage = conclusionPercentageChild;
          }
          else {
            delete result.children
          }
          resultList.push(result);
        }
        return resultList;
      }
      const dataSourceSchedule = recursiveMountData(dataSchedule.data.filter(item => item.ParentTask == 1));
      // console.log('schedule - dataSourceSchedule',dataSourceSchedule);
      this.setState({ dataSource: dataSourceSchedule, fetching: false });
    }
    else {
      this.setState({ fetching: false });
    }
  }

  onUpdateDataModal = () => {
    this.setState({ visible: false, modalEdit: false });
    this.onFetch(this.props.match.params.id);
  }

  onClosedModal = () => {
    this.setState({ visible: false, modalEdit: false });
  }

  onModalAdd = (value, description, time) => {
    this.setState({ key: value, visible: true, description: description, time: time });
  }

  onModalEdit = async (value) => {
    this.setState({ modalEdit: true, dataSourceEdit: value });
  }

  render() {

    const props = {
      name: 'file',
      action: `${config['API_URL']}/schedule/file`,
      // action: 'http://177.80.244.148:8059/eletronic-documents/file',
      headers: {
        authorization: 'authorization-text',
      },
      async onChange(info) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {

            // const readXlsxFile = require('read-excel-file/node');// FIXME: PHD

            // File path.
            // readXlsxFile(info.file.response.imagePath).then((rows) => { // FIXME: PHD
              
            // console.log(rows) // FIXME: PHD
              // `rows` is an array of rows
              // each row being an array of cells.
            // }) // FIXME: PHD
            // `rows` is an array of rows
            // each row being an array of cells.
          //setFile([{ path: info.file.response.imagePath, fileName: info.file.response.filename }]);
          //await this.onFetch(this.props.match.params.id)
          message.success(`${info.file.response.filename} arquivo importado com sucesso`);

        } else if (info.file.status === 'error') {
          message.error(`${info.file.response.filename} falha ao importar.`);
        }
      },
    };

    const columns = [
      {
        title: 'Descrição',
        dataIndex: 'Description',
        key: 'Description',
        align: 'left',
        width: '50%',
        render: (text, record) => <span>{(record.children || []).length > 0 ? <b>{record.Description}</b> : record.Description}</span>,
      },
      {
        title: 'Dependência',
        dataIndex: 'Predecessor',
        key: 'Predecessor',
        align: 'left',
        width: '20%',
      },
      {
        title: 'Início',
        key: 'ExpectedStartDate',
        align: 'center',
        width: '10%',
        render: (text, record) => <span>{record.key == "new" ? "" : record.ExpectedStartDate ? moment(record.ExpectedStartDate, 'YYYY-MM-DD').format("DD/MM/YYYY") : ""}</span>,
        sorter: (a, b) => moment(a.ExpectedStartDate) - moment(b.ExpectedStartDate),
        defaultSortOrder: 'ascend',
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      {
        title: 'Término',
        key: 'ExpectedEndDate',
        align: 'center',
        width: '10%',
        render: (text, record) => <span>{record.key == "new" ? "" : record.ExpectedEndDate ? moment(record.ExpectedEndDate, 'YYYY-MM-DD').format("DD/MM/YYYY") : ""}</span>
      },
      {
        title: 'Horas',
        key: 'ExpectedTime',
        align: 'center',
        width: '5%',
        render: (text, record) => <span>{record.key == "new" ? "" : record.ExpectedTime}</span>
      },
      {
        title: '% de conclusão',
        dataIndex: 'conclusionPercentage',
        key: 'conclusionPercentage',
        align: 'center',
        width: '5%',
        render: (text, record) => <span>{record.ConclusionPercentage ? parseFloat(record.ConclusionPercentage).toFixed(2) + "%" : '0.00%'}</span>
      },
      {
        title: 'Ação',
        key: 'Action',
        width: '50',
        align: 'center',
        render: (text, record) => (
          <Button.Group >
            <div style={{ padding: "7px" }}>
              <Tooltip placement="topLeft" title="Adicionar" arrowPointAtCenter>
                <a><PlusOutlined onClick={() => this.onModalAdd(record.key, record.Description, record.ExpectedTime)} /></a>
              </Tooltip>
            </div>
            {/* {record.ParentTask !== 1 && !record.children ?
            <div style={{ padding: "7px" }}>
              <Tooltip placement="topLeft" title="Editar" arrowPointAtCenter>
                <a><EditOutlined onClick={() => this.onModalEdit(record)} /></a>
              </Tooltip>
            </div>
            :
            null
            } */}
            <div style={{ padding: "7px" }}>
              <Tooltip placement="topLeft" title="Editar" arrowPointAtCenter>
                <a><EditOutlined onClick={() => this.onModalEdit(record)} /></a>
              </Tooltip>
            </div>
            {record.ParentTask === 1 ? null :
              <div style={{ padding: "7px" }}>
                <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                  onConfirm={() => onCancel(record)} okText="Sim" cancelText="Não">
                  <Tooltip placement="topLeft" title="Excluir" arrowPointAtCenter>
                    <a><DeleteOutlined /></a>
                  </Tooltip>
                </Popconfirm>
              </div>}
          </Button.Group>
        ),
      },
    ]

    const fetching = this.state.fetching;

    // const changePercentage = async (value, record) => {
    //   if (value >= 0) {
    //     if (value <= 100) {
    //       const data = {
    //         ConclusionPercentage: value,
    //         Id: record.key,
    //       }
    //       await scheduleService.updateSchedule(data);
    //       this.onFetch(this.props.match.params.id);
    //     }
    //     else {
    //       message.error("As horas são maiores que 100%.", 3);
    //     }
    //   }
    //   else {
    //     message.error("As horas não pode ser menor que 0.", 3);
    //   }
    // }

    const velidateDate = async (fatherDate, childDate) => {
      let date = ''
      if (fatherDate && childDate) {
        if (fatherDate) {
          if (childDate) {
            if (moment(fatherDate) > moment(childDate)) {
              return fatherDate
            }
            else {
              return childDate
            }
          }
          else {
            return fatherDate
          }
        }
        else {
          return childDate
        }
      }
      else {
        return date
      }
    }

    const getFatherId = async (fatherId) => {
      // alterado const data = props.dataSource -> abaixo
      const data = this.state.schedules;
      const child = Object.values(data).filter(item => { return fatherId == item.Id });
      if (child.length > 0) {

        if (child[0].ParentTask == 1) {
          return child;
        }
        const father = data.map(item => {
          return child[0].ParentTask == item.Id ? item : null
        }).filter(r => { return r !== null });
        return father;
      }
      else {
        return child;
      }
    }

    const recursiveSubtractData = async (record, subtract) => {
      const data = this.state.schedules;
      let verifyfather = Object.values(data).filter(find => find.Id == record.ParentTask);
      const input = {
        // alterado parseInt -> parseFloat
        ExpectedTime: parseFloat(verifyfather[0].ExpectedTime) - parseFloat(subtract),
        ExpectedStartDate: await velidateDate(verifyfather[0].ExpectedStartDate, record.ExpectedStartDate),
        ExpectedEndDate: await velidateDate(verifyfather[0].ExpectedStartDate, record.ExpectedStartDate)
      }
      await scheduleService.updateTotalTime(verifyfather[0].Id, input);

      const father = await getFatherId(verifyfather[0].ParentTask);
      if (father.length > 0) {
        recursiveSubtractData(...verifyfather, subtract);
      }
    }

    const onCancel = async (value) => {
      message.loading('Deletando Cronograma ...', 100);
      this.setState({ fetching: true });
      const taskData = await tasksService.getListByProjectCode(value.ProjectId);
      const task = taskData.data.data;
      let usedScheduleByTask = task.filter(find => find.ScheduleId == value.key);
      
      if (!value.children) {
        // if(!value.ConclusionPercentage){
        if (!usedScheduleByTask.length > 0) {
          await recursiveSubtractData(value, value.ExpectedTime);

          await scheduleService.deleteSchedule(value.key).then(r => {
            
            if (!r.data.error) {
                scheduleService.getParentTasksTime(value.ProjectId).then(async (r) => {
                  // se retornar filho(s) do início do cronograma
                  if (r.data.data.length > 0){
                    // itera cada resistro
                    for(let parent of r.data.data){
                      // monta o objeto com a menor e maior data dos filhos e o id do parentTask pai
                      const parentData = { 
                        minDate: moment(parent.MinDate).format('YYYY-MM-DD').toString(),
                        maxDate: moment(parent.MaxDate).format('YYYY-MM-DD').toString(),
                        id: parent.ParentTask
                      };
                      // atualiza o ExpectedTime e as menores e maiores datas do pai
                      await scheduleService.updateParentTaskTime(parentData);
                    }
                  }

                  message.destroy();
                  message.success('Cronograma deletado com sucesso.', 3);
                this.onFetch(this.props.match.params.id);
                });
            }
            
            else {
              message.destroy();

              message.error('Ocorreu um erro ao deletar cronograma.', 3);
            }
          });
        }
        else {

          message.destroy();
          message.error('Ocorreu um erro ao deletar, tarefa já está sendo apontada.', 3);
        }
        // }
        // else {
        //   message.destroy();
        //   message.error('Ocorreu um erro ao deletar, tarefa já está sendo apontada.', 3);
        // }
        this.setState({ fetching: false });
      }
      else {

        let obj = JSON.stringify(value);
        let listOfKeys = [];
        await JSON.parse(obj, (key, value) => {
          if (key === 'key') {
            listOfKeys.push(value.toUpperCase());
          }
        });

        let usedScheduleByTask = [];

        listOfKeys.forEach(r => {
          let taskAssig = task.find(find => find.ScheduleId == r.key && find.Status == 'W');
          if (taskAssig) {
            usedScheduleByTask.push(taskAssig)
          }
        });

        message.destroy();
        this.setState({ fetching: false });
        message.error('Ocorreu um erro ao deletar cronograma, exclua os dependentes primeiro.', 3);


        if(!value.ConclusionPercentage){
        if (usedScheduleByTask.length <= 0) {
          
        
          // listOfKeys.forEach(data => {
          //   await recursiveSubtractData(value, value.ExpectedTime);
          //   await scheduleService.updateTotalTime(this.props.match.params.id, null);
          //    scheduleService.deleteSchedule(value.key).then(r => {
          //     if (!r.data.error) {

          //       message.destroy();
          //       message.success('Cronograma deletado com sucesso.', 3);
          //       this.onFetch(this.props.match.params.id);
          //     }
          //     else {
          //       message.destroy();

          //       message.error('Ocorreu um erro ao deletar cronograma.', 3);
          //     }
          //   });
          // });
        }
        else {

          message.destroy();
          message.error('Erro ao deletar, existem tarefas sendo apontadas.', 3);
        }
      }
        this.setState({ fetching: false });

      }
    }

    return (
      <Spin spinning={fetching}>
        <Row>
          <Form style={{ width: "100%" }}>
            <div style={{ width: "100%", borderBottom: "1px solid gray", padding: "1%" }}>
              <Row style={{ margin: "1%" }}>
                <div style={{ width: "25%", paddingRight: "1%" }}>
                  <b>Projeto:</b><br />
                  {this.state.projectSchedule.Title ? this.state.projectSchedule.Title : this.state.projectSchedule.ProjectDescription}
                </div>
                <div style={{ width: "25%", paddingRight: "1%" }}>
                  <b>Cliente:</b><br />
                  {this.state.projectSchedule.CompanyName}
                </div>
                <div style={{ width: "25%", paddingRight: "1%" }}>
                  <b>Modelo de projeto:</b><br />
                  {this.state.projectSchedule.Status == 1 ? 'Time Material' : this.state.projectSchedule.Status == 2 ? 'TurnKey' : this.state.projectSchedule.Status == 3 ? 'Banco de Horas' : ''}
                </div>
                <div style={{ width: "12%", paddingRight: "1%" }}>
                  <b>Início previsto:</b><br />
                  {this.state.projectSchedule.ExpectedStartDate}
                </div>
                <div style={{ width: "12%" }}>
                  <b>Término previsto:</b><br />
                  {this.state.projectSchedule.ExpectedEndDate}
                </div>
              </Row>
              <Row style={{ margin: "1%" }}>
                <div style={{ width: "25%", paddingRight: "1%" }}>
                  <b>Horas utilizadas:</b><br />
                  {this.state.projectSchedule.ConsumedTime}
                </div>
                <div style={{ width: "25%", paddingRight: "1%" }}>
                  <b>Horas sem atividade:</b><br />
                  {this.state.projectSchedule.Balance ? this.state.projectSchedule.Balance : 0}
                </div>
                <div style={{ width: "25%", paddingRight: "1%" }}>
                  <b>Total de horas:</b><br />
                  {this.state.projectSchedule.TotalTimeAmount ? this.state.projectSchedule.TotalTimeAmount : 0}
                </div>
                <div style={{ width: "25%" }}>
                  <b>Aporte de horas:</b><br />
                  {/* {this.state.projectSchedule.EstimatedTimeAmount ? this.state.projectSchedule.EstimatedTimeAmount : 0} */}
                  {this.state.projectSchedule.Time_Change ? this.state.projectSchedule.Time_Change : 0}
                </div>
              </Row>
              <Row>
                <Col>
                  <Upload {...props}>
                    <Button disabled={this.state.dataSource.length <= 0 ? false : true} icon={<UploadOutlined />} type="primary">Importar</Button>
                  </Upload>
                </Col>
                <Col>
                  <Button disabled={this.state.dataSource.length <= 0 ? false : true} style={{ marginLeft: 15 }} onClick={() => this.onModalAdd('new', `Cronograma Inicial - ${this.state.projectSchedule.TotalTimeAmount ? this.state.projectSchedule.TotalTimeAmount : 0}h`, this.state.projectSchedule.TotalTimeAmount ? this.state.projectSchedule.TotalTimeAmount : 0)} icon={<CheckCircleOutlined />} type="primary">Iniciar Cronograma</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Row>
        <Row>
          <Table
            columns={columns}
            dataSource={this.state.dataSource}
            style={{ width: "100%", padding: "0 1%" }}
            pagination={false}
            size={"small"}
          />
        </Row>
        <Row style={{ paddingTop: "1%" }}>
          <ScheduleAddModal
            visible={this.state.visible}
            id={this.props.match.params.id}
            parentTask={this.state.key}
            description={this.state.description}
            project={this.state.projectSchedule}
            onUpdateData={() => this.onUpdateDataModal()}
            onClose={() => this.onClosedModal()}
            dataSource={this.state.schedules}
            time={this.state.time}
          />

          <ScheduleEditModal
            id={this.props.match.params.id}
            visible={this.state.modalEdit}
            data={this.state.dataSourceEdit}
            project={this.state.projectSchedule}
            onClose={() => this.onClosedModal()}
            onUpdateData={() => this.onUpdateDataModal()}
            dataSource={this.state.schedules}
          />
        </Row>
      </Spin>
    );
  }
}

export default Schedule;
