import { request } from '../http';

const cashbackService = {
  getCashback: (userId) => {
    return request.get('/cashback/' + userId);
  },
  getCashbacksProjects: (userId) => {
    return request.get('/cashback/projects/' + userId);
  },
  createCashback: (data) => {
    return request.post('/cashback', data);
  },
  deleteCashback: (data) => {
    return request.delete('/cashback/' + data.Id);
  },
  editCashback: (data) => {
    return request.put('/cashback', data);
  },
  editStatusCashback: (id, status) => {
    return request.put('/cashback/' + id + "/" + status);
  },
  updateBilledCashback: (id, status) => {
    return request.put('/cashback/billed/' + id + "/" + status);
  },
}

export {
  cashbackService
}