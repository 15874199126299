import { request } from '../http';

const PaymentService = {
  insertPurchaseOrder: (data) => {
    return request.post('/payments/purchase', data);
  },
  insertSaleOrder: (data) => {
    return request.post('/payments/sale', data);
  },
  subtractProjectDebit: (id, price, time, data) => {
    return request.post('/payments/purchase/projectDebit/' + id + '/' + price + "/" + time, data);
  },
  getProfessionals: () => {
    return request.get('/payments');
  },
  getDimensions: () => {
    return request.get('/payments/dimensions');
  },
}



export {
  PaymentService
}