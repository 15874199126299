import React from "react";
import { Form } from 'antd';
import moment from "moment";
moment.locale('pt-BR');

class NotFound extends React.Component {
    render() {
        return (
            <Form style={{ minHeight: '100vh' }}>
                {/* <img src={Logo} style={{ width: "130px", height: "83,2px" }}></img> */}
                <center>
                    <p><b style={{ fontSize: "40px", color: "black" }}>Página não encontrada 404</b></p>
                </center>
            </Form >
        )
    }
}

export default NotFound;