import React from "react";
import conf from '../../environment.json';
import {
  Row,
  Col,
  Table,
  Modal,
  Input,
  Spin,
  Tooltip,
  message,
  Form,
  Button,
  DatePicker,
  Select,
  Popconfirm, InputNumber, Upload
} from "antd";
import { DeleteOutlined, UploadOutlined, FilterFilled } from "@ant-design/icons";
import moment from "moment";
import { cashbackService } from "../../services/cashback";
import { FloatingButton } from '../../components/floating-button';
import { ProjectsService } from "../../services/projects";
import { workflowService } from '../../services/workflow';
import * as _ from 'lodash'

moment.locale("pt-BR");

const { Search } = Input;

class Cashback extends React.Component {
  state = {
    show: false,
    fetching: false,
    modalAdd: false,
    modalEdit: false,
    visible: false,
    cashbackdata: [],
    dataSource: [],
    ProjectNames: [],
    project: "",
    cashback: "",
    cashbackValue: 0,
    cashbackDataDropdown: [],
    dropDownProject: [],
    dropDownCashBack: [],
    expensesData: [],
    Attachment: "",
    AttError: false,
    filename: "",
    filepath: ""
  };

  showEdit = async (Object) => {
    this.setState({ fetching: true, edit: false });
    await this.projectLoad();
    setTimeout(function () {
      this.FormCashbackEdit.current.resetFields();
      const project = this.getProjectName(Object.ProjectId);
      this.FormCashbackEdit.current.setFieldsValue({
        Title: Object.Title,
        Classification: [Object.Classification],
        ProjectName: [project],
        Unit: Object.Unit,
        Value: Object.Value,
        startDate: moment(Object.StartDate),
        endDate: Object.EndDate ? moment(Object.EndDate) : null,
      });
    }.bind(this), 1000)
    // modal
    const pdata = {};
    this.setState({
      modalEdit: true,
      cashbackdata: pdata
    });
  }

  projectLoad = async () => {
    this.setState({ fetching: true });
    await ProjectsService.getProjects().then((r) => {
      const projectNames = r.data.data;
      var options = projectNames.map(function (val) {
        return {
          label: val.Title ? val.Title : val.Description,
          value: val.Id,
        };
      });
      this.setState({ fetching: false, ProjectNames: options })
    })
  }

  getProjectName = (projectId) => {
    const data = (this.state.ProjectNames.filter(y => {
      return y.value === Number(projectId)
    }))
    return data[0].label
  }

  componentDidMount() {
    this.fetch();
  }

  showmodaladd = async () => {
    this.projectLoad();
    this.setState({ modalAdd: true })
  }

  fetch = async () => {
    
    this.setState({ fetching: true, cashbackValue: 0 });
    const id = localStorage.getItem('userId');
    const r = await cashbackService.getCashbacksProjects(id);
    const cashData = r.data.data
    let cashbackDrop = cashData.map((r) => {
      return ({ value: r.Id, label: `${r.Company} - ${r.Title ? r.Title : r.Description}` });
    });
    const { data: cashbackData } = await cashbackService.getCashback(id);

    this.setState({ fetching: false, dataSource: cashbackData.data, dropDownProject: cashbackDrop });
  };

  enableInput = async () => {
    this.setState({ visible: !this.state.visible });
  }

  searchTerm = async (value) => {
    if (value) {
      this.setState({ fetching: true });
      await this.fetch();
      const filtrado = this.state.dataSource.filter((x) => {
        return x.Title.toLowerCase().includes(value.toLowerCase());
      });
      console.log(filtrado);
      this.setState({ dataSource: filtrado, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  render() {

    const { TextArea } = Input;
    const fetching = this.state.fetching;

    const columns = [
      {
        title: 'Tipo de reembolso',
        dataIndex: 'CashbackName',
        key: 'CashbackName',
        align: 'center',
        width: 110
      },
      {
        title: 'Projeto',
        dataIndex: 'ProjectName',
        key: 'ProjectName',
        align: 'center',
        width: 110
      },
      {
        title: 'Data do reembolso',
        key: 'CashbackDate',
        align: 'center',
        width: 110,
        render: (text, record) => <span>{(record.CashbackDate ? moment(record.CashbackDate).format('DD/MM/YYYY') : '')}</span>
      },
      {
        title: 'Data da solicitação',
        key: 'CashbackDate',
        align: 'center',
        width: 110,
        render: (text, record) => <span>{(record.CreateDate ? moment(record.CreateDate).format('DD/MM/YYYY') : '')}</span>
        // render: (text, record) => <span>{(record.CreateDate ? record.CreateDate : '')}</span>
      },
      {
        title: 'Valor do reembolso',
        dataIndex: 'Value',
        key: 'Value',
        align: 'center',
        width: 110,
        render: (text, record) => <span>{parseFloat(record.Value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>

      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        align: 'center',
        width: 70,
        render: (text, record) => <span>{(record.Status === "Y" ? "Aprovado" : record.Status === "N" ? "Negado" : "Aguardando aprovação")}</span>
      },
      {
        title: 'Ação',
        key: 'Action',
        width: 50,
        align: 'center',
        render: (text, record) => (
          <Button.Group >
            {record.Status == "P" ?
              <div style={{ padding: "7px" }}>
                <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                  onConfirm={() => onCancel(record.Id)} okText="Sim" cancelText="Não">
                  <Tooltip placement="top" title={"Deletar"}>
                    <a>
                      <DeleteOutlined />
                    </a>
                  </Tooltip>
                </Popconfirm>
              </div>
              :
              null
            }
          </Button.Group>
        ),
      },
    ];
    
    // const Uploader = () => {
    //   console.log('teste state');
    //   const [file, setFile] = useState([]);
      
      //console.log('teste config');
      const config = {
        name: 'file',
        multiple: false,
        action: conf['API_URL'] + '/eletronic-documents/file',
        //action: `${config['API_URL']}/eletronic-documents/file`,
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
          }
          if (status === 'done') {
            message.success(`Arquivo ${info.file.response.filename} subiu com sucesso.`);
            //setFile([{ path: info.file.response.imagePath, fileName: info.file.response.filename }]);
            //console.log(this.state.filename);
          } else if (status === 'error') {
            // this.setState({ AttError: true });
            message.error(`Arquivo ${info.file.name} falhou ao subir.`);
          }
        }
      }
    //}

    const onRemove = (info) => {
      this.setState({ AttError: false });
    }

    const onChan = (info) => {
      console.log('Entrou no on Change');
      console.log(this.state.project.label);

      // let millisecond = new Date();
      // let nameFile = `${millisecond.getMilliseconds()}_${localStorage.getItem('userId')}_${millisecond.getDate()}_${millisecond.getFullYear()}_${info.file.name}`;
      // info.file.name = nameFile
      // this.setState({ Attachment: nameFile });
      // console.log('testes teste stestes ',nameFile);

      // if (info.file.status !== 'uploading') {
      //   this.setState({ AttError: true });
      // }
      // if (info.file.status === 'done') {
      //   this.setState({ AttError: false });
      //   message.success(`${info.file.response.filename} Arquivo Enviado.`);

      // } else if (info.file.status === 'error') {
      //   this.setState({ AttError: true });
      //   message.error(`${info.file.response.filename} Erro ao Enviar Arquivo.`);
      // }
    }

    const onCreate = async (value) => {
      console.log('Entrou no onCreate');
      if (validateFields(value)) {
        const id = localStorage.getItem('userId');
        if (this.state.dropDownProject) {
          message.loading("Inserindo um reembolso ...", 3);
          this.setState({ modalAdd: false, fetching: true });
          let data = {
            UserId: id,
            ProjectId: this.state.project.value,
            CashbackDate: moment(value.ExpenseDate).format("YYYY-MM-DD"),
            //Attachment: file[0].fileName,
            Title: this.state.cashback,
            Description: value.Description,
            Value: value.Value,
            ProjectName: this.state.project.label,
            CashbackId: this.state.cashback.value,
            CashbackName: this.state.cashback.label
          }
          console.log(data);
          const r = await cashbackService.createCashback(data);
          const cashbackRes = r.data.data;

          if (r.data.error) {
            this.setState({ fetching: false });
            message.destroy();
            message.error("Ocorreu um erro ao inserir um reembolso.", 3);
          }
          else {
            const id = localStorage.getItem('userId');
            const workflowCode = await workflowService.getWorkflowByCode(this.state.project.value);
            const workflowRes = workflowCode.data.data.data[0];
            let workflow = [];
            await workflowService.getApprovals(workflowRes.WorkflowApprovalsId).then((workflowApprovals) => {
              //for (const flow of workflowApprovals.data.data.data) {
              const flow = workflowApprovals.data.data.data
              console.log(flow);

              workflow.push({
                IdType: cashbackRes[0].Id,
                Value: cashbackRes[0].Value,
                Type: "C",
                Status: "P",
                WorkflowApprovalsId: workflowRes.WorkflowApprovalsId,
                UserId: parseInt(id),
                ProjectId: workflowRes.ProjectId,
                ProjectName: workflowRes.ProjectName,
                ResponsibleId: _.first(flow.UserId)
              });
              console.log(workflow);
            });
            await workflowService.insert(workflow).then(r => {
              if (!r.data.error) {
                this.fetch();
                this.setState({ fetching: false });
                message.destroy();
                message.success("Reembolso inserido com sucesso.", 3);
              }
              else {
                this.setState({ fetching: false });
                message.destroy();
                message.error("Ocorreu um erro ao inserir um reembolso.", 3);
              }
            });
          }
        }
        else {
          message.error("Nenhum workflow definido, contate um supervisor.", 3);
        }
      }
    }

    const onChangeProject = async (label) => {
      if (label) {
        const r = await ProjectsService.getExpensesProject(label.value);
        let today = moment(new Date).format("YYYY-MM-DD");
        const expenses = r.data.data;
        let expense = expenses.map(r => {
          return r.Status == "P" ? null :
            r.EffectiveEnd ? moment(today) <= moment(r.EffectiveEnd) ? ({ label: r.OutgoingType, value: r.Id }) : null :
              moment(today) >= moment(r.EffectiveStart) ? ({ label: r.OutgoingType, value: r.Id }) : null
        }).filter(filtered => filtered != null);
        this.setState({ dropDownCashBack: expense, project: label, expensesData: expenses });
      }
    }

    const onChangeValueCashBack = async (label) => {
      if (!label) {
        this.setState({ cashbackValue: 0 });
      }
      else {
        let cashbackValue = this.state.expensesData.filter(filtered => filtered.Id == label.value)
        this.setState({ cashback: label, cashbackValue: cashbackValue[0].BilledAmount, cashbackDataDropdown: cashbackValue });
      }
    }

    const onCancel = async (value) => {
      message.loading('Deletando reembolso ...', 100);
      this.setState({ fetching: true });

      const data = {
        Id: value
      }
      const workflowData = await workflowService.deleteByIdType(value, "C");
      const workflow = workflowData.data;
      if (!workflow.error) {
        await cashbackService.deleteCashback(data).then(r => {
          if (r.data.error) {
            message.destroy();
            this.setState({ fetching: false });
            message.error('Ocorreu um erro ao deletar o reembolso.', 3);
          }
          else {
            message.destroy();
            message.success('Reembolso deletado com sucesso.', 3);
          }
        });
      }
      else {
        message.destroy();
        this.setState({ fetching: false });
        message.error('Ocorreu um erro ao deletar o reembolso.', 3);
      }

      this.fetch();
      this.setState({ fetching: false });
    }

    const validateFields = (values) => {
      if (!values.Project) {
        message.error('Campo projeto não preenchido.', 3);
        return false;
      }
      if (this.state.AttError) {
        message.error('Reembolso enviado com Falha, envie novamente!.', 3);
        return false;
      }
      if (!values.Cashback) {
        message.error('Campo tipo de reembolso não preenchido.', 3);
        return false;
      }
      if (!values.ExpenseDate) {
        message.error('Campo data da despesa não preenchido.', 3);
        return false;
      }
      if (!values.Description) {
        message.error('Campo descrição não preenchido.', 3);
        return false;
      }
      if (!values.Value) {
        message.error('Campo valor não preenchido.', 3);
        return false;
      }
      if (values.Value <= 0) {
        message.error('Campo valor não pode ser menor que 0.', 3);
        return false;
      }
      if (values.Value > this.state.cashbackValue) {
        message.error('Campo valor maior do que o esperado.', 3);
        return false;
      }

      if (moment(values.ExpenseDate).format("YYYY-MM-DD") < moment(this.state.cashbackDataDropdown[0].EffectiveStart).format("YYYY-MM-DD")) {
        message.error('Campo de data menor do que ' + moment(this.state.cashbackDataDropdown[0].EffectiveStart).format("DD/MM/YYYY") + '.', 3);
        return false;
      }
      else {
        if (moment(values.ExpenseDate).format("YYYY-MM-DD") > moment(this.state.cashbackDataDropdown[0].EffectiveEnd).format("YYYY-MM-DD")) {
          message.error('Campo de data maior do que o ' + moment(this.state.cashbackDataDropdown[0].EffectiveEnd).format("DD/MM/YYYY") + '.', 3);
          return false;
        }
      }
      return true;
    }

    return (
      <Spin spinning={fetching} delay={500} >
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "Right", fontSize: "30px" }}>Solicitações de reembolso</b>
          </div>
          <div style={{ width: "35%" }}>
            <Search placeholder="Pesquisar" onSearch={value => this.searchTerm(value)} />
          </div>
          {/* <div style={{ width: "5%", float: "right" }}>
            <center>
              <FilterFilled style={{ fontSize: "20px", padding: "5px" }} />
            </center>
          </div> */}
        </Row>
        <Row style={{ width: "100%"/* , minHeight: "90vh" */ }}>
          <Col style={{ border: "1px solid #e8e8e8" }} span={24} >
            <Table columns={columns} dataSource={this.state.dataSource} style={{ height: "100%" }} pagination={true} />
          </Col>
        </Row>

        {/* Modal Adicionar */}
        <Modal title={"Incluir solicitação de reembolso"}

          destroyOnClose={() => this.setState({ modalAdd: false, fetching: false })
          }
          width={"50%"}
          visible={this.state.modalAdd}
          onOk={this.onCreate}
          onCancel={() => this.setState({ modalAdd: false, fetching: false })}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{
            form: "add-form",
            key: "submit",
            htmlType: "submit",
          }}
          okText={"Salvar"} >
          <Form style={{ width: "120%" }} ref={this.addForm} onFinish={onCreate} id="add-form">
            <Row>
              <div style={{ width: "100%" }}>
                <Input.Group compact>
                  <div style={{ width: "42%", padding: "0 1%" }}>
                    <p>Projeto:</p>
                    <Form.Item name='Project'>
                      <Select options={this.state.dropDownProject} onChange={(value, label) => onChangeProject(label)} placeholder="Selecione" />
                    </Form.Item>
                  </div>
                  <div style={{ width: "32%", padding: "0 1%" }}>
                    <p>Tipo de Reembolso:</p>
                    <Form.Item name='Cashback'>
                      <Select options={this.state.dropDownCashBack} onChange={(value, label) => onChangeValueCashBack(label)} placeholder="Selecione" />
                    </Form.Item>
                  </div>
                  <div style={{ width: "10%", padding: "0 1%" }}>
                    <p>Valor:</p>
                    <Form.Item>
                      <Input disabled={true} value={this.state.cashbackValue} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "42%", padding: "0 1%" }}>
                    <p>Data da Despesa:</p>
                    <Form.Item name='ExpenseDate'>
                      <DatePicker format="DD/MM/YYYY" placeholder="Selecione uma data" style={{ width: "100%" }} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "42%", padding: "0 1%" }}>
                    <p>Valor do reembolso:</p>
                    <Form.Item name="Value">
                      <InputNumber decimalPlaces="3" decimalSeparator="," thousandSeparator="." style={{ width: "100%" }} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "84%", padding: "0 1%" }}>
                    <p>Descrição:</p>
                    <Form.Item name="Description">
                      <TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item name="file">
                      <Upload onRemove={onRemove} {...config}>
                        <Button icon={<UploadOutlined />}>Comprovante</Button>
                      </Upload>
                    </Form.Item>
                  </div>
                </Input.Group>
              </div>
            </Row>
          </Form>
        </Modal >
        <FloatingButton icon="+" onClick={() => this.showmodaladd()} />
      </Spin >
    );
  }
}



export default Cashback;