/* eslint-disable no-undef */
import React from "react";
import { Table, Row, Form, message, Tooltip, Popconfirm, Button } from 'antd';
import moment from "moment";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { FloatingButton } from '../../components/floating-button';
import ModalCategoryDocuments from '../../components/category-document-modal';
import CategoryDocumentService from '../../services/category-document';
import { EletronicDocumentsService } from '../../services/eletronic-documents';
moment.locale('pt-BR');

class CategoryDocument extends React.Component {

    state = {
        fetching: false,
        dataSource: undefined,
        modalVisible: false,
        dataTable: [],
        currentRecord: []
    }

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        await CategoryDocumentService.get().then((res) => {
            this.setState({ dataTable: res.data.data });
        });
    }

    edit = record => {
        console.log(record);
        this.setState({ currentRecord: record, modalVisible: true })
    }

    del = async (record) => {
        message.loading("Excluindo categoria de documento ...", 100);
        const eletronicData = await EletronicDocumentsService.get();
        const eletronic = eletronicData.data.data;
        let verifyCategoryUsed = eletronic.find(item => item.FileCategoryId == record.Category)

        if (verifyCategoryUsed) {
            message.destroy();
            message.error("Categoria de documento está sendo usado.", 3);
        }
        else {
            message.loading("Excluindo categoria de documento ...", 100);
            await CategoryDocumentService.delete(record.Id).then(() => {
                message.destroy();
                message.success("Categoria de documento excluido.", 3);
                this.fetch();
            });
        }
    }
    addNew = () => {

        this.setState({ modalVisible: true, currentRecord: [] });
    }

    render() {


        const columns = [
            {
                title: 'Categoria',
                key: 'Category',
                dataIndex: 'Category',
                width: '20%',
                align: 'center',
            },
            {
                title: 'Descrição',
                key: 'Description',
                dataIndex: 'Description',
                width: '60%',
                align: 'left',
            },
            {
                title: 'Ação',
                key: 'Action',
                width: '20%',
                align: 'center',
                render: (_, record) =>
                    <Button.Group >
                        <div style={{ padding: "7px" }}>
                            <Tooltip placement={"top"} title={"Editar"}>
                                <a>
                                    <EditOutlined onClick={() => this.edit(record)} />
                                </a>
                            </Tooltip>
                        </div>
                        <div style={{ padding: "7px" }}>
                            <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                                onConfirm={() => this.del(record)} okText="Sim" cancelText="Não">
                                <Tooltip placement={"top"} title={"Excluir"}>
                                    <a> < DeleteOutlined /></a>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </Button.Group>
                ,
            }
        ];


        return (
            <Form /* style={{ minHeight: '100vh' }} */>
                <ModalCategoryDocuments
                    data={this.state.currentRecord}
                    visible={this.state.modalVisible}
                    onOk={() => this.setState({ modalVisible: false })}
                    onCancel={() => this.setState({ modalVisible: false })}
                    onfetching={() => this.fetch()} />
                <Row style={{ paddingBottom: "25px" }}>
                    <div style={{ width: "60%" }}>
                        <b style={{ textAlign: "left", fontSize: "30px" }}>Cadastro da categoria de documentos</b>
                    </div>
                </Row>
                <Table columns={columns} dataSource={this.state.dataTable} />
                <FloatingButton icon="+" onClick={this.addNew} />
            </Form >
        )
    }
}

export default CategoryDocument;