import React, { useState, useEffect  } from "react";
import {
  Row,
  Modal,
  Input,
  InputNumber,
  Spin,
  message,
  Form,
  Button,
  DatePicker,
  Select
} from "antd";
import { ProjectsService } from "../../services/projects";
import moment from "moment";

import { itemsService } from '../../services/items';
import * as _ from 'lodash';
import { customerService } from "../../services/customer"
import { usersService } from "../../services/users"
import { workflowService } from "../../services/workflow"

const { TextArea } = Input;

const ProjectAddModal = (props) => {
  const [clients, setClients] = useState([]);
  const [wf, setWf] = useState([]);
  const [wf2, setWf2] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedWF, setSelectedWF] = useState('');
  const [selectedWF2, setSelectedWF2] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [allAddresses, setAllAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [responsibles, setResponsibles] = useState([]);
  const [selectedResponsible, setSelectedResponsible] = useState('');

  const [spinning, setSpinning] = useState(false);

  const [model, setModel] = useState();
  const [projectType, setprojectType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [addrId, setAddrId] = useState();
  const [toll, setToll] = useState(2);
  const [limitNotes, setLimitNotes] = useState();
  const [budgetedHours, setBudgetedHours] = useState();

  const notes = [
    {
      value: 'N',
      label: 'Não',
    },
    {
      value: 'Y',
      label: 'Sim',
    }
  ]

  const options = [
    {
      value: 'externo',
      label: 'Externo',
    },
    {
      value: 'interno',
      label: 'Interno',
    },
    {
      value: 'ambos',
      label: 'Ambos',
    }
  ]

  const models = [
    {
      value: '1',
      label: 'Time Material',
    },
    {
      value: '2',
      label: 'TurnKey',
    },
    {
      value: '3',
      label: 'Banco de Horas',
    }
  ]

  useEffect(() => {
    customerService.getCustomers().then((r) => {
      if (r) {
        const customerData = r.data.data;
  
        const list = customerData.map(customer => ({
          value: customer.Id,
          label: customer.Company
        }));
  
        setClients(list);
      }
    });

    customerService.getCustomerAllAddress().then((r) => {
      if (r) {
        const customerData = r.data.data;
  
        const list = customerData.map(customer => ({
          value: customer.Id,
          label: `${customer.Address}, ${customer.Number} (${customer.City})`
        }));

        setAllAddresses(list);
      }
    });

    usersService.getUsers().then((r) => {
      if (r) {
        const userData = r.data.data;
  
        const list = userData.map(user => ({
          value: user.USERID,
          label: user.NAME
        }));

        setResponsibles(list);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedClient) {
      setSelectedAddress(null);
      setAddresses([]);

      customerService.getCustomerAddressById(selectedClient).then((r) => {
        if (r) {
          const customerData = r.data.data;
    
          const list = customerData.map(customer => ({
            value: customer.Id,
            label: `${customer.Address}, ${customer.Number} (${customer.City})`
          }));
    
          setAddresses(list);
        }
      });
    }
  }, [selectedClient]);

  useEffect(() => {
    workflowService.getData(true).then((r) => {
      if (r) {
        const workflowData = r.data.data.data;
  
        const list = workflowData.map(workflow => ({
          value: workflow.Id,
          label: workflow.Title
        }));
  
        setWf(list);
        setWf2(list);
      }

    });
  }, []);

  const getSelectLabel = (value, select) => {
    let options = []

    if (select === 'customer') {
      options = clients;
    }

    if (select === 'address') {
      options = allAddresses;
    }

    if (select === 'responsible') {
      options = responsibles;
    }

    if (select === 'model') {
      options = models;
    }

    if (select === 'workflow') {
      options = wf;
    }

    for (let index = 0; index < options.length; index++) {
      const element = options[index];
      if (element.value.toString() === value.toString()) {
        return element.label;
      };
    };

    return "";
  };

  const getSelectId = (value, select) => {
    let options = []

    if (select === 'customer') {
      options = clients;
    }

    if (select === 'address') {
      options = allAddresses;
    }

    if (select === 'responsible') {
      options = responsibles;
    }

    if (select === 'model') {
      options = models;
    }

    if (select === 'workflow') {
      options = wf;
    }

    for (let index = 0; index < options.length; index++) {
      const element = options[index];
      if (element.label.toString() === value.toString()) {
        return element.value;
      };
    };

    return "";
  };

  const getFormattedDateToSelect = (dateValue) => {
    const newDateValue = (moment(`${dateValue.substr(8,2)}/${dateValue.substr(5,2)}/${dateValue.substr(0,4)}`, dateFormat));

    return newDateValue;
  }

  const onEdit = async (value) => {
    let success = true;

    if (value) {
      message.loading(props.type === 'edit' ? 'Editando projeto ...' : 'Cadastrando projeto ...', 100);
      setSpinning(true);

      const inputdata = {
        Id: props.data.Id,
        Code: value.Code ? value.Code : props.data.Code ? props.data.Code : '',
        Title: value.Title ? value.Title : props.data.Title ? props.data.Title : '',
        Status: value.Status ? value.Status : props.data.Status ? props.data.Status : '',
        CustomerId: value.CustomerId ? value.CustomerId : props.data.CustomerId ? props.data.CustomerId : 0,
        CustomerAdressId: value.CustomerAdressId ? value.CustomerAdressId : props.data.CustomerAdressId ? props.data.CustomerAdressId : 0,
        ResponsibleId: value.ResponsibleId ? value.ResponsibleId : props.data.ResponsibleId ? props.data.ResponsibleId : 0,
        ProjectType: value.ProjectType ? value.ProjectType : props.data.ProjectType ? props.data.ProjectType : '',
        ProjectModelCode: value.ProjectModelCode ? value.ProjectModelCode : props.data.ProjectModelCode ? props.data.ProjectModelCode : 0,
        EstimatedTimeAmount: value.EstimatedTimeAmount ? value.EstimatedTimeAmount : props.data.EstimatedTimeAmount ? props.data.EstimatedTimeAmount : 0,
        Value: value.Value ? value.Value : props.data.Value ? props.data.Value : 0,
        StartDate: value.StartDate ? moment(value.StartDate).format("YYYY-MM-DD") : props.data.StartDate ? moment(props.data.StartDate).format("YYYY-MM-DD") : '',
        EndDate: value.EndDate ? moment(value.EndDate).format("YYYY-MM-DD") : props.data.EndDate ? moment(props.data.EndDate).format("YYYY-MM-DD") : '',
        Workflow: value.Workflow ? value.Workflow : props.data.Workflow ? props.data.Workflow : 0,
        Workflow2: value.Workflow2 ? value.Workflow2 : props.data.Workflow2 ? props.data.Workflow2 : 0,
        LimitNotes: value.LimitNotes ? value.LimitNotes : props.data.LimitNotes ? props.data.LimitNotes : '',
        Description: value.Description ? value.Description : props.data.Description ? props.data.Description : '',
      };

      try{
        if (props.type === 'edit') {
          inputdata.CustomerId = getSelectId(inputdata.CustomerId, "customer") ? getSelectId(inputdata.CustomerId, "customer") : inputdata.CustomerId;
          inputdata.CustomerAdressId = getSelectId(inputdata.CustomerAdressId, "address") ? getSelectId(inputdata.CustomerAdressId, "address") : inputdata.CustomerAdressId;
          inputdata.ResponsibleId = getSelectId(inputdata.ResponsibleId, "responsible") ? getSelectId(inputdata.ResponsibleId, "responsible") : inputdata.ResponsibleId;
          inputdata.ProjectModelCode = getSelectId(inputdata.ProjectModelCode, "model") ? getSelectId(inputdata.ProjectModelCode, "model") : inputdata.ProjectModelCode;
          inputdata.Workflow = getSelectId(inputdata.Workflow, "workflow") ? getSelectId(inputdata.Workflow, "workflow") : inputdata.Workflow;
          inputdata.Workflow2 = getSelectId(inputdata.Workflow2, "workflow") ? getSelectId(inputdata.Workflow2, "workflow") : inputdata.Workflow2;

          await ProjectsService.registerProjectUpdate(inputdata).then(r => {
            if (r.data.error) {
              success = false;
              console.log('inputdata',inputdata);
              console.log('ERROR',r.data.error);
            }
          });

          if (success) {
            message.destroy();
            message.success('Projeto Alterado.');
          } else {
            message.destroy();
            message.error('Falha ao atualizar projeto.')
          }
        } else {
          await ProjectsService.registerProjectCreate(inputdata).then(r => {
            if (r.data.error) {
              success = false;
              console.log('inputdata',inputdata);
              console.log('ERROR',r.data.error);
            }
          });

          if (success) {
            message.destroy();
            message.success('Projeto Cadastrado.');
          } else {
            message.destroy();
            message.error('Falha ao cadastrar projeto.')
          }
        }
      }catch(e){
        console.log(e);
        // console.log('user-modal update ERROR: ',e);
      }
      setSpinning(false);
    }
  };


  const validateFields = (values) => {
    // if (!addrId) {
    //   message.error('Campo de endereço não preenchido.', 3);
    //   return false;
    // }
    if (!projectType && !props.data.projectType) {
      message.error('Campo tipo de projeto não preenchido.', 3);
      return false;
    }
    if (!model && !props.data.model) {
      message.error('Campo modelo não preenchido.', 3);
      return false;
    }

    if (!startDate && !props.data.startDate) {
      message.error('Campo data inicial não preenchido.', 3);
      return false;
    }
    if (!endDate && !props.data.endDate) {
      message.error('Campo data final não preenchido.', 3);
      return false;
    }
    if (!limitNotes && !props.data.limitNotes) {
      message.error('Campo limitar apontamento não preenchido.', 3);
      return false;
    }
    return true;
  }

  const validateFieldsItem = (values) => {
    if (!values.description) {
      message.error('Campo descrição não preenchido.', 3);
      return false;
    }
    if (!values.hours) {
      message.error('Campo horas não preenchido.', 3);
      return false;
    }
    if (!values.unitPrice) {
      message.error('Campo valor unitário preenchido.', 3);
      return false;
    }
    if (!values.unitTotal) {
      message.error('Campo valor total preenchido.', 3);
      return false;
    }
    if (!values.date) {
      message.error('Campo data de faturamento não preenchido.', 3);
      return false;
    }
    return true;
  }

  // const dateFormat = 'YYYY-MM-DD';
  const dateFormat = 'DD/MM/YYYY';

  const ClosedModal = () => {
    // setAddresses([]);
    props.onClosed()
  }

  return (
    <Modal
      title={props.title}
      width={props.width}
      visible={props.visible}
      footer={props.footer}
      onOk={props.onCancel}
      onCancel={props.onCancel}
      destroyOnClose={props.onCancel}
    >
      <Spin spinning={spinning} /* delay={500} */>
        <Form style={{ width: "100%" }} onFinish={onEdit}>
          <Row style={{ borderBottom: "1px solid gray", margin: "0px 1%"  }}>
            <Input.Group compact>
              <div style={{ width: "20%", paddingRight: "1%" }}>
                <label>Código:</label>
                <Form.Item name={['Code']} initialValue={props.data.Code && props.data.Code != "null" && props.data.Code != "undefined" && props.data.Code != "0" ? props.data.Code : ""}>
                  <Input disabled={props.type !== 'add'} style={{ width: "100%" }} />
                </Form.Item>
              </div>

              <div style={{ width: "40%", paddingRight: "1%" }}>
                <label>Título:</label>
                <Form.Item name={['Title']} initialValue={props.data.Title && props.data.Title != "null" && props.data.Title != "undefined" && props.data.Title != "0" ? props.data.Title : ""}>
                  <Input disabled={props.type === 'view'} style={{ width: "100%" }} />
                </Form.Item>
              </div>

              <div style={{ width: "20%", paddingRight: "1%" }}>
                <label>Coordenador:</label>
                <Form.Item name={['ResponsibleId']} initialValue={props.data.ResponsibleId && props.data.ResponsibleId != "null" && props.data.ResponsibleId != "undefined" && props.data.ResponsibleId != "0" ? getSelectLabel(props.data.ResponsibleId, "responsible") : ""}>
                  <Select disabled={props.type === 'view'} onChange={(e) => setSelectedResponsible(e)} value={selectedResponsible} placeholder="Selecione">
                    {responsibles.map((responsible) => (
                      <option key={responsible.value} value={responsible.value}>
                        {responsible.label}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div style={{ width: "20%" }}>
                <label>Status:</label>
                <Form.Item name={['Status']} initialValue={props.data.Status && props.data.Status != "null" && props.data.Status != "undefined" && props.data.Status != "0" ? props.data.Status : ""} >
                  <Select disabled={props.type === 'view'} defaultValue={props.data.Status}>
                    <Select.Option value={"Previsto"}>Previsto</Select.Option>
                    <Select.Option value={"Aprovado"}>Aprovado</Select.Option>
                    <Select.Option value={"Finalizado"}>Finalizado</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Input.Group>
          </Row>

          <Row style={{ padding: "40px 0px 5px 0px" }}>
            <Input.Group compact style={{ margin: "0px 1%" }}>
              <div style={{ width: "20%", paddingRight: "1%" }}>
                <label>Cliente:</label>
                <Form.Item name={['CustomerId']} initialValue={props.data.CustomerId && props.data.CustomerId != "null" && props.data.CustomerId != "undefined" && props.data.CustomerId != "0" ? getSelectLabel(props.data.CustomerId, "customer") : ""}>
                  <Select disabled={props.type !== 'add'} onChange={(e) => setSelectedClient(e) } value={selectedClient} placeholder="Selecione">
                    {clients.map((client) => (
                      <option key={client.value} value={client.value}>
                        {client.label}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div style={{ width: "20%", paddingRight: "1%" }}>
                <label>Local:</label>
                <Form.Item name={['CustomerAdressId']} initialValue={props.data.CustomerAdressId && props.data.CustomerAdressId != "null" && props.data.CustomerAdressId != "undefined" && props.data.CustomerAdressId != "0" ? getSelectLabel(props.data.CustomerAdressId, "address") : ""}>
                  <Select disabled={props.type !== 'add'} onChange={(e) => setSelectedAddress(e)} value={selectedAddress} placeholder="Selecione">
                    {addresses.map((address) => (
                      <option key={address.value} value={address.value}>
                        {address.label}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div style={{ width: "20%", paddingRight: "1%" }}>
                <label>Tipo:</label>
                <Form.Item name={['ProjectType']} initialValue={props.data.ProjectType && props.data.ProjectType != "null" && props.data.ProjectType != "undefined" && props.data.ProjectType != "0" ? props.data.ProjectType : ""}>
                  <Select disabled={props.type === 'view'} options={options} onChange={(value) => setprojectType(value)} placeholder="Selecione" />
                </Form.Item>
              </div>
              <div style={{ width: "20%", paddingRight: "1%" }}>
                <label>Modelo:</label>
                <Form.Item name={['ProjectModelCode']} initialValue={props.data.ProjectModelCode && props.data.ProjectModelCode != "null" && props.data.ProjectModelCode != "undefined" && props.data.ProjectModelCode != "0" ? getSelectLabel(props.data.ProjectModelCode, "model") : ""}>
                  <Select disabled={props.type === 'view'} options={models} onChange={(value) => setModel(value)} placeholder="Selecione" />
                </Form.Item>
              </div>
              <div style={{ width: "10%", paddingRight: "1%" }}>
                <label>Horas Previstas:</label>
                <Form.Item name={['EstimatedTimeAmount']} initialValue={props.data.EstimatedTimeAmount && props.data.EstimatedTimeAmount != "null" && props.data.EstimatedTimeAmount != "undefined" && props.data.EstimatedTimeAmount != "0" ? props.data.EstimatedTimeAmount : 0}>
                  <InputNumber disabled={props.type !== 'add'} style={{ width: "100%" }} onChange={(value) => setBudgetedHours(value)} />
                </Form.Item>
              </div>
              <div style={{ width: "10%"}}>
                <label>Custo do projeto:</label>
                <Form.Item name={['Value']} initialValue={props.data.Value && props.data.Value != "null" && props.data.Value != "undefined" && props.data.Value != "0" ? props.data.Value : 0}>
                  <InputNumber disabled={props.type !== 'add'} prefix="R$" style={{ width: "100%" }} />
                  {/* <MaskedInput mask="R$ ###.###.###,##" /> */}
                </Form.Item>
              </div>
            </Input.Group>

            <Input.Group compact style={{ margin: "0px 1%" }}>
              <div style={{ width: "20%", paddingRight: "1%" }}>
                <label>Início Previsto:</label>
                <Form.Item name={['StartDate']}>
                  <DatePicker
                    disabled={props.type === 'view'}
                    defaultValue={props.data.StartDate && props.data.StartDate !== 'Invalid date' ? getFormattedDateToSelect(props.data.StartDate) : null}
                    format="DD/MM/YYYY"
                    placeholder="Selecione uma data"
                    /* onChange={(value) => setStartDate(value)} */
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
              <div style={{ width: "20%", paddingRight: "1%" }}>
                <label>Término Previsto:</label>
                <Form.Item name={['EndDate']}>
                  <DatePicker
                    disabled={props.type === 'view'}
                    defaultValue={props.data.EndDate && props.data.EndDate !== 'Invalid date' ? getFormattedDateToSelect(props.data.EndDate) : null}
                    format="DD/MM/YYYY"
                    placeholder="Selecione uma data"
                    /* onChange={(value) => setEndDate(value)} */
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
              <div style={{ width: "20%", paddingRight: "1%" }}>
                <label>Workflow Apontamentos:</label>
                <Form.Item name={['Workflow']} initialValue={props.data.Workflow && props.data.Workflow != "null" && props.data.Workflow != "undefined" && props.data.Workflow != "0" ? getSelectLabel(props.data.Workflow, "workflow") : ""}>
                  <Select disabled={props.type === 'view'} onChange={(e) => setSelectedWF(e) } value={selectedWF} placeholder="Selecione">
                    {wf.map((workfow) => (
                      <option key={workfow.value} value={workfow.value}>
                        {workfow.label}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div style={{ width: "20%", paddingRight: "1%" }}>
                <label>Workflow Aporte:</label>
                <Form.Item name={['Workflow2']} initialValue={props.data.Workflow2 && props.data.Workflow2 != "null" && props.data.Workflow2 != "undefined" && props.data.Workflow2 != "0" ? getSelectLabel(props.data.Workflow2, "workflow") : ""}>
                  <Select disabled={props.type === 'view'} onChange={(e) => setSelectedWF2(e) } value={selectedWF2} placeholder="Selecione">
                    {wf2.map((workfow) => (
                      <option key={workfow.value} value={workfow.value}>
                        {workfow.label}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div style={{ width: "20%" }}>
                <label>Limitar apontamentos:</label>
                <Form.Item name={['LimitNotes']} initialValue={props.data.LimitNotes && props.data.LimitNotes != "null" && props.data.LimitNotes != "undefined" && props.data.LimitNotes != "0" ? props.data.LimitNotes : ""}>
                  <Select disabled={props.type === 'view'} style={{ width: "100%" }} options={notes} defaultValue={[props.data.limitNotes]} onChange={e => setLimitNotes(e)} />
                </Form.Item>
              </div>
            </Input.Group>

            <Input.Group compact style={{ margin: "0px 1%" }}>
              <div style={{ width: "100%" }}>
                <label>Descrição:</label>
                <Form.Item name={['Description']} initialValue={props.data.Description && props.data.Description != "null" && props.data.Description != "undefined" && props.data.Description != "0" ? props.data.Description : ""}>
                  <TextArea disabled={props.type === 'view'} style={{ minHeight: "100px", width: "100%" }} />
                </Form.Item>
              </div>
            </Input.Group>
          </Row>

          <Row>
            <div style={{ margin: "0px 1%", float: "right", width: "100%" }}>
              { props.type !== 'view' &&
                <div style={{ float: "right" }}>
                  <Button type="primary" htmlType="submit" onClick={() => props.onCancel()}>Salvar</Button>
                </div>
              }
              <div style={{ float: "right", paddingRight: "1%" }}>
                <Button onClick={() => props.onCancel()}>Fechar</Button>
              </div>
            </div>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default ProjectAddModal;