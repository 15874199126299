import React from "react";
import { Form, Row, Card, Modal, Input, Col } from 'antd';
import moment from "moment";

const LogModal = (props) => {
    return (
        <Modal title="Detalhes do log"
            visible={props.visible}
            footer={null}
            onCancel={props.onClosed}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={"60%"}
            destroyOnClose={props.onClosed}>
            <Form>
                <Row>
                    <Col span={12}>
                        <Card title="Informação do projeto:" style={{ width: "100%", height: "auto" }} bordered={false}>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>Projeto: </b> {props.data.ProjectName}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>Código do cliente: </b> {props.data.Client}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>Cliente: </b> {props.data.ClientName}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>Data da competência: </b> {props.data.DateCompet ? moment(props.data.DateCompet).format("DD/MM/YYYY") : ""}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>Código da filial: </b> {props.data.BPLID}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>{props.data.OrderType == "REEMBOLSO" || props.data.OrderType == "HORAS TRABALHADAS" ? "Código do projeto:" : null} </b> {props.data.ProjectDoc}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>{props.data.OrderType == "REEMBOLSO" || props.data.OrderType == "HORAS TRABALHADAS" ? "Código do parceiro:" : null} </b> {props.data.CardCode}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>Dimensão 1: </b> {props.data.Dim1}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>Dimensão 2: </b> {props.data.Dim2}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>Dimensão 3: </b> {props.data.Dim3}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>Dimensão 4: </b> {props.data.Dim4}
                            </Row>
                            <Row>
                                <b style={{ paddingRight: "2%" }}>Dimensão 5: </b> {props.data.Dim5}
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title="Informação do item:" style={{ width: "100%", height: "auto" }} bordered={false}>
                            {props.data.OrderType == "REEMBOLSO" || props.data.OrderType == "HORAS TRABALHADAS" ?
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Data:</b>{moment(props.data.Date).format("DD/MM/YYYY")}
                                        </Row>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Código do documento:</b>{props.data.Item}
                                        </Row>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Quantidade:</b> {props.data.Quantity}
                                        </Row>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Valor unitário:</b> {props.data.OrderType == "HORAS TRABALHADAS" ? null : 'R$'} {props.data.Value} {props.data.OrderType == "HORAS TRABALHADAS" ? "Hrs" : null}
                                        </Row>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Tipo:</b> {props.data.OrderType}
                                        </Row>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Data:</b>{moment(props.data.Date).format("DD/MM/YYYY")}
                                        </Row>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Código do item:</b>{props.data.Item}
                                        </Row>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Quantidade:</b> {props.data.Quantity}
                                        </Row>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Valor unitário:</b> R$ {props.data.Value}
                                        </Row>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Valor total:</b> R$ {props.data.TotalValue}
                                        </Row>
                                        <Row>
                                            <b style={{ paddingRight: "2%" }} >Tipo:</b> {props.data.OrderType}
                                        </Row>
                                    </Col>
                                </Row>
                            }
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Card title="Informação do log:" style={{ width: "100%", height: "auto" }} bordered={false}>
                        <Row>
                            <b style={{ paddingRight: "1%" }}>Data do log: </b> {moment(props.data.LogDate).format("DD/MM/YYYY")}
                        </Row>
                        <Row>
                            <b style={{ paddingRight: "1%" }}>Erro do log: </b> {props.data.ErrorDetail ? props.data.ErrorDetail : "Nenhum"}
                        </Row>
                        <Row>
                            <b style={{ paddingRight: "1%" }}>Status: </b> {props.data.Status}
                        </Row>
                    </Card>
                </Row>
            </Form>
        </Modal >
    )
}

export default LogModal;