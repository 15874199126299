/* eslint-disable no-undef */
import React from "react";
import { Table, Row, Form, message, Tooltip, Popconfirm, Button, Spin } from 'antd';
import moment from "moment";
import { EditOutlined } from '@ant-design/icons';
import { TimeCourseService } from "../../services/time-course";
import ModalTimeCourse from "../../components/time-course-modal";
moment.locale('pt-BR');

class TimeCourse extends React.Component {

    state = {
        fetching: false,
        dataSource: undefined,
        modalVisible: false,
        dataTable: [],
        currentRecord: []
    }

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        await TimeCourseService.getTimeCourse().then((timeData) => {
            this.setState({ dataTable: timeData.data.data });
        });
    }

    edit = record => {
        console.log(record);
        this.setState({ currentRecord: record, modalVisible: true })
    }

    addNew = () => {

        this.setState({ modalVisible: true, currentRecord: [] });
    }

    render() {


        const columns = [            
            {
                title: 'Data de fechamento',
                key: 'ReferenceDate',
                dataIndex: 'ReferenceDate',
                width: '90%',
                align: 'left',
                render: (text, record) => <span>{(record.ReferenceDate ? moment(record.ReferenceDate,'YYYY-MM-DD').format('DD/MM/YYYY') : '')}</span>
            },
            {
                title: 'Ação',
                key: 'Action',
                width: '20%',
                align: 'center',
                render: (_, record) =>
                    <Button.Group >
                        <div style={{ padding: "7px" }}>
                            <Tooltip placement={"top"} title={"Editar"}>
                                <a>
                                    <EditOutlined onClick={() => this.edit(record)} />
                                </a>
                            </Tooltip>
                        </div>
                    </Button.Group>
                ,
            }
        ];


        return (
            <Form /* style={{ minHeight: '100vh' }} */>
                <ModalTimeCourse
                    data={this.state.currentRecord}
                    visible={this.state.modalVisible}
                    onOk={() => this.setState({ modalVisible: false })}
                    onCancel={() => this.setState({ modalVisible: false })}
                    onfetching={() => this.fetch()} />
                <Row style={{ paddingBottom: "25px" }}>
                    <div style={{ width: "60%" }}>
                        <b style={{ textAlign: "left", fontSize: "30px" }}>Cadastro de fechamento de período</b>
                    </div>
                </Row>
                <Table columns={columns} dataSource={this.state.dataTable} />
            </Form >
        )
    }
}

export default TimeCourse;