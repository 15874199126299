import { request } from '../http';

const scheduleService = {
  getSchedule: () => {
    return request.get('/schedule');
  },
  getScheduleByProjectId: (id) => {
    return request.get('/schedule/details/' + id);
  },
  getScheduleById: (id) => {
    return request.get('/schedule/' + id);
  },
  getSchPjCus: (id) => {
    return request.get('/schedule/projectdata/' + id);
  },
  createSchedule: (data) => {
    return request.post('/schedule', data);
  },
  // rota para coleta dos ExpectedTime dos parents tasks
  getParentTasksTime: (id) => {
    return request.get('/schedule/parent-tasks-time/' + id);
  },
  deleteSchedule: (id) => {
    return request.delete('/schedule/' + id);
  },
  updateSchedule: (data) => {
    return request.put('/schedule', data);
  },
  updateTotalTime: (id, data) => {
    return request.put('/schedule/total-time/' + id, data);
  },
  updateSomeFieldsSchedule: (data) => {
    return request.put('/schedule/update', data);
  },
  //atualiza ExcpetedTime do ParentTask
  updateParentTaskTime: (data) => {
    return request.put('/schedule/update-parent-task-time', data);
  },
  //atualiza ExcpetedTime do primeiro ParentTask do cronograma
  updateFirstParentTask: (data) => {
    return request.put('/schedule/update-first-pt-time/', data);
  },

}

export {
  scheduleService
}