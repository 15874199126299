import React, { useState } from "react";
import { Form, Row, Col, Modal, Input, message, Select, Button } from 'antd';
import { workflowService } from '../../services/workflow';

const { TextArea } = Input;

const WorkFlowModal = (props) => {
    const [status, setStatus] = useState();

    const stats = [{
        value: "Y",
        label: "Ativar"
    },
    {
        value: "N",
        label: "Desativar"
    }]

    const onFinishWorkflow = async (values) => {

        if (validateFields(values)) {
            if (!props.data.Title) {
                const data = {
                    Title: values.Title,
                    Description: values.Description,
                    Status: "Y",
                }
                message.loading('Criando um workflow ...', 100);
                await workflowService.insertData(data).then((r) => {
                    if (!r.data.error) {
                        message.destroy();
                        message.success('Workflow criado com sucesso.', 3);
                        props.onfeting();
                    }
                    else {
                        message.destroy();
                        message.error('Ocorreu um erro ao criar o workflow.', 3);
                    }
                });
            }
            else {
                const data = {
                    Title: values.Title,
                    Description: values.Description,
                    Status: !status ? props.data.Status : status[0],
                    // WorkFlowType: !type ? props.data.WorkFlowType : type[0]
                }

                message.loading('Editando workflow ...', 100);
                await workflowService.updateData(props.data.Id, data).then((r) => {
                    if (!r.data.error) {
                        message.destroy();
                        message.success('Workflow editado com sucesso.', 3);
                        props.onfeting();
                    }
                    else {
                        message.destroy();
                        message.error('Ocorreu um erro ao editar o workflow.', 3);
                    }
                });
            }
        }
    }

    const validateFields = (values) => {
        if (!values.Title) {
            message.error('Campo título não preenchido.', 3);
            return false;
        }
        if (!values.Description) {
            message.error('Campo descrição não preenchido.', 3);
            return false;
        }
        return true;
    }

    return (
        <Modal title="Gerenciador de workflow"
            visible={props.visible}
            footer={null}
            onCancel={props.destroyOnClose}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={"60%"}
            destroyOnClose={props.destroyOnClose}>
            <Form onFinish={onFinishWorkflow}>
                {!props.data.Title ?
                    <div>
                        <Row>
                            <div style={{ width: "100%" }}>
                                <p>Título</p>
                                <Form.Item name="Title">
                                    <Input />
                                </Form.Item>
                            </div>
                        </Row>
                        <Row>
                            <div style={{ width: "100%" }}>
                                <p>Descrição</p>
                                <Form.Item name="Description">
                                    <TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                                </Form.Item>
                            </div>
                        </Row>
                        <Row>
                            <Col offset={22} span={2}>
                                <Button type="primary" htmlType="submit">Criar</Button>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <Row>
                            <div style={{ width: "86%", paddingRight: "1%" }}>
                                <p>Título</p>
                                <Form.Item name="Title" initialValue={props.data.Title}>
                                    <Input />
                                </Form.Item>
                            </div>
                            <div style={{ width: "14%" }}>
                                <p>Status</p>
                                <Form.Item>
                                    <Select options={stats} defaultValue={[props.data.Status]} onChange={(value) => setStatus(value)} placeholder="Selecione" />
                                </Form.Item>
                            </div>
                        </Row>
                        <Row>
                            <div style={{ width: "100%" }}>
                                <p>Descrição</p>
                                <Form.Item name="Description" initialValue={props.data.Description}>
                                    <TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                                </Form.Item>
                            </div>
                        </Row>
                        <Row>
                            <Col offset={22} span={2}>
                                <Button type="primary" htmlType="submit">Salvar</Button>
                            </Col>
                        </Row>
                    </div>
                }
            </Form>
        </Modal>
    )
}

export default WorkFlowModal;