import { request } from '../http';

const tasksService = {
  getTasks: () => {
    return request.get('/tasks');
  },
  getListById: (id) => {
    return request.get('/tasks/list/' + id);
  },
  getById: (id) => {
    return request.get('/tasks/list/byId/' + id);
  },
  getListByProjectCode: (id) => {
    return request.get('/tasks/list-project/' + id);
  },
  getTaskHistoryByUserFilter: (id, filter) => {
    return request.post('/tasks/filter/' + id, filter)
  },
  updateTasks: (data) => {
    return request.patch('/tasks/update', data);
  },
  updateTaskConsumed: (id, data) => {
    return request.patch('/tasks/update/' + id, data);
  },
  updateStatus: (id, status, approval) => {
    return request.put('/tasks/updateStatus/' + id + "/" + status + "/" + approval);
  },
  getAssignmentList: () => {
    return request.get('/tasks/assignment-list');
  },
  getFilterProject: (id) => {
    return request.get('/tasks/project-manager/' + id);
  },
  getProjectActivities: (id) => {
    return request.get('/tasks/project-activity/' + id);
  },
  getAssignmentListById: (id) => {
    return request.get('/tasks/assignment-list/' + id);
  },
  postNewTask: (data) => {
    return request.post('/tasks/new-task/', data);
  },

  assignAllTasks: (data) => {
    return request.post('/tasks/assign-all-tasks/', data);
  },
  deleteTask: (id) => {
    return request.delete('/tasks/' + id);
  },
  getScheduleTask: (id) => {
    return request.get('/tasks/schedule-task/' + id)
  },
  insertTaskHistory: (data) => {
    return request.post('/tasks/new-task-history', data)
  },
  getTaskHistoryById: (id) => {
    return request.get('/tasks/list-task-history/' + id)
  },
  getTaskHistoryByTaskId: (id) => {
    return request.get('/tasks/list-task-history/task/' + id)
  },
  getTaskHistoryByUserId: (id) => {
    return request.get('/tasks/list-task-history/user/' + id)
  },
  deleteTaskHistory: (id) => {
    return request.delete('/tasks/task-history/' + id)
  },
  updateTaskHistory: (id, approval) => {
    return request.put('/tasks/list-task-history/status/' + id + "/" + approval);
  },
  updatePercentageTaskHistory: (id, data) => {
    return request.patch('/tasks/task-history/' + id, data);
  },
  updateBilledTasks: (id, status) => {
    return request.put('/tasks/billed/' + id + "/" + status);
  },
}

export {
  tasksService
}