import React from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Input,
  Spin,
  message,
  Tooltip,
  Popconfirm,
  Drawer,
  Select
} from "antd";
import { DeleteOutlined,FormOutlined } from "@ant-design/icons";
import moment from "moment";
import { tasksService } from "../../services/tasks";
import { workflowService } from '../../services/workflow';
import { usersService } from '../../services/users';
import { eventsService } from '../../services/events';
import { permissionsService } from '../../services/permissions';
import MyTasksViewModal from "../../components/my-tasks-view-modal";
import EventCaption from "../../components/event-caption";
import './index.css';
import * as _ from 'lodash';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { formatDate } from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
// import timelinePlugin from '@fullcalendar/timeline';
import listPlugin from '@fullcalendar/list';
import locales from '@fullcalendar/core/locales-all.js'

moment.locale("pt-BR");

/* let str = formatDate(new Date(), {
  month: 'long',
  year: 'numeric',
  day: 'numeric'
}); */

// console.log(str);

const { Search } = Input;

class calendar extends React.Component {
  state = {
    fetching: false,
    dataSource: [],
    dataSourceBackup: [],
    calendarPage: false,
    openNoteModal: false,
    dataFieldsModal: [],
    drawer: false,
    dataNoteModal: [],
    dataModal: [],
    userData: [],
    projectData: [],
    taskView: false,
    status: null,
    project: null,
    user: null,
    userID: null,
    selectStatus: null,
    selectProject: null,
    selectUser: null,
    captionModal: true
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    (async () => {

      this.setState({ fetching: true });
      const id = localStorage.getItem('userId');
      const taskData = await tasksService.getTaskHistoryByUserId(id);
      let view = localStorage.getItem('task_view');
      let teste = view == 'true' ? true : false
      let list = []
      this.setState({ taskView: teste })
      const usersData = await usersService.getUsers();
      const users = usersData.data.data;
      const userByIdData = await usersService.getUserById(id);
      const userById = userByIdData.data;
      const { data: dataUsers } = await usersService.getUsers();
      const permissionData = await permissionsService.getProfileDetails(userById.data[0].PROFILE);
      const permission = permissionData.data.data;
      let permissionListAll = Object.values(permission).filter(find => find.Feature == '/taskAssignment');

      const data = taskData.data.data;
      const fathers = [...new Set(data.map(x => x.ProjectId))]
      let dataSource = [];
      fathers.forEach(father => {
        console.log(data)
        const childrens = data.filter(item => item.ProjectId == father);
        console.log(childrens)
        const childrensWithKeys = childrens.map(children => ({ ...children, key: children.TaskId }));
        let ordinationList = childrensWithKeys.sort((a, b) => a.Id - b.Id);
        let entity = {
          Title: childrens[0].Title ? childrens[0].Title : childrens[0].ProjectDescription,
          Company: childrens[0].Company,
          key: childrens[0].ProjectId,
          DescriptionTask: childrens[0].DescriptionTask,
          Description: childrens[0].DescriptionTask,
          ConsumedTime: childrens.reduce((total, item) => item.Approval != 'N' ? total + parseFloat(item.ConsumedTime) : total, 0),
          Percentage: childrens[0].Percentage,
          DateTask: childrens[0].DateTask,
          Approval: childrens[0].Approval,
          children: ordinationList
        }

        dataSource.push(entity);
      });

      if (!users.error) {
        if (permissionListAll[0]) {
          list.push({ value: 0, label: "Todos" });
  
          users.map(item => {
            return list.push({ value: item.ID, label: item.NAME })
          });
        }
        else {
          users.map(item => {
            return item.ID == id ? list.push({ value: item.ID, label: item.NAME }) : null
          })
        }
      }

      let eventsData = await eventsService.getEventsHoliday();
      let events = eventsData.data.data;
      let userEvents = []

      if (!events.error) {
        events.map(item => {

          return userEvents.push({
            groupId: item.groupId,
            title: item.title,
            description: item.description,
            date: item.date.substring(0,10),
            start: item.start.substring(0,16),
            end: item.end.substring(0,16),
            backgroundColor: item.backgroundColor,
            borderColor: item.borderColor,
            textColor: item.textColor,
            display: item.display,
            allDay: item.allDay==='Y'
          })
        })
      }

      eventsData = await eventsService.getEventsByUser(id);
      events = eventsData.data.data;

      if (!events.error) {
        events.map(item => {
          return userEvents.push({
            groupId: item.groupId,
            title: item.title,
            description: item.description,
            date: item.date.substring(0,10),
            start: item.start,
            end: item.end,
            backgroundColor: item.backgroundColor,
            borderColor: item.borderColor,
            textColor: item.textColor,
            display: item.display,
            allDay: item.allDay==='Y'
          })
        })
      }

      this.setState({ userEvents: userEvents, dataDropdown: list, fetching: false, dataSource: dataSource, userData: dataUsers.data, projectData: dataSource, userID: id, dataSourceBackup: data });
    })();
  };

  onSelect = e => {
    (async () => {
      let eventsData = null;
      let events = null;
      let userEvents = [];

      if (e === 0) {
        eventsData = await eventsService.getEvents();
        events = eventsData.data.data;

        if (!events.error) {
          events.map(item => {
            return userEvents.push({
              groupId: item.groupId,
              title: item.title,
              description: item.description,
              date: item.date.substring(0,10),
              start: item.start,
              end: item.end,
              backgroundColor: item.backgroundColor,
              borderColor: item.borderColor,
              textColor: item.textColor,
              display: item.display,
              allDay: item.allDay==='Y'
            })
          })
        }
      } else {
        eventsData = await eventsService.getEventsHoliday();
        events = eventsData.data.data;

        if (!events.error) {
          events.map(item => {
            return userEvents.push({
              groupId: item.groupId,
              title: item.title,
              description: item.description,
              date: item.date.substring(0,10),
              start: item.start,
              end: item.end,
              backgroundColor: item.backgroundColor,
              borderColor: item.borderColor,
              textColor: item.textColor,
              display: item.display,
              allDay: item.allDay==='Y'
            })
          })
        }
  
        eventsData = await eventsService.getEventsByUser(e);
        events = eventsData.data.data;
  
        if (!events.error) {
          events.map(item => {
            return userEvents.push({
              groupId: item.groupId,
              title: item.title,
              description: item.description,
              date: item.date.substring(0,10),
              start: item.start,
              end: item.end,
              backgroundColor: item.backgroundColor,
              borderColor: item.borderColor,
              textColor: item.textColor,
              display: item.display,
              allDay: item.allDay==='Y'
            })
          })
        }
      }

      this.setState({ selected: e, userEvents: userEvents })
    })();
  };

  render() {

    const fetching = this.state.fetching;
    const { Option } = Select;
    const dropdown = this.state.dataDropdown;
    const userDefault = localStorage.getItem('userName')

    return (
      <Spin spinning={fetching} delay={500}>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "58%" }}>
            <b style={{ textAlign: "left", fontSize: "30px" }}>Minha Agenda</b>
          </div>
        </Row>

        <Row gutter={[8, 8]} style={ {paddingBottom: '2%'}}>
          <Col span={6}>
            <Select
              optionFilterProp="children"
              options={dropdown}
              defaultValue={userDefault}
              showSearch
              onChange={(e) => this.onSelect(e)}
              style={{ width: "90%" }}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >

              {
                this.state.projects && this.state.projects.map(d => (
                  <Option
                    value={d.Id}
                  >
                    {
                      `${d.CustomerName} - ${d.Title}`
                    }
                  </Option>
                ))
              }
            </Select>
          </Col>
        </Row>

        <Row>
          <div style={{ width: "100%" }}>
            <FullCalendar
              locale={ 'pt-Br' }
              timeZone={ 'America/Sao_Paulo'}
              plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin/* , timelinePlugin */ ]}
              initialView="dayGridMonth"
              height={"auto"}
              contentHeight={"auto"}
              // weekends={false}
              // eventContent={renderEventContent}
              // dateClick={this.handleDateClick}
              // eventClick={this.handleEventClick}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'listWeek timeGridWeek dayGridMonth'
                // right: 'listWeek timelineDay timeGridWeek dayGridMonth'
              }}
              /* customButtons={{
                myCaption: {
                    text: 'Legenda',
                    click: function() { this.setState({ captionModal: !(this.state.captionModal) }) }
                }
              }} */
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                // second: '2-digit',
                hour12: false
              }}
              dayHeaderFormat={{
                weekday: 'long',
                // month: 'numeric',
                // day: 'numeric',
                omitCommas: true
              }}
              titleFormat={{
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              }}
              buttonText={{
                today: 'Hoje',
                month: 'Mês',
                week: 'Semana',
                day: 'Dia',
                list: 'Lista'
              }}
              displayEventTime={false}
              displayEventEnd={false}
              // nextDayThreshold={'08:00:00'}
              /* eventDidMount= {
                function(info) {
                  let tooltip = new Tooltip(info.el, {
                    title: info.event.extendedProps.description,
                    placement: 'top',
                    trigger: 'hover',
                    container: 'body'
                  });
                }
              } */
              events={this.state.userEvents}

              businessHours={{
                // days of week. an array of zero-based day of week integers (0=Sunday)
                daysOfWeek: [ 1, 2, 3, 4, 5 ], // Monday - Friday
                startTime: '08:00', // a start time (8am in this example)
                endTime: '18:00', // an end time (6pm in this example)
              }}
            />
          </div>
          <Col span={24} style={{ alignContent: 'center'}}>
            <EventCaption visible={this.state.captionModal}/>
          </Col>
        </Row>
      </Spin>
    );
  }

  /* handleDateClick = (arg) => { // bind with an arrow function
    alert(arg.dateStr)
  } */
  /* handleEventClick = (arg) => { // bind with an arrow function
    console.log(arg)
    // alert(arg.dateStr)
  } */
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

export default calendar;
