import React, { useState } from "react";
import {
  Row,
  Table,
  Modal,
  Input,
  Spin,
  message,
  Form,
  Button,
  DatePicker,
  Select,
  Popconfirm,
  Tooltip,
  InputNumber,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { ProjectsService } from "../../services/projects";
import { workflowService } from "../../services/workflow";
import * as _ from 'lodash';

const ProjectExpenseModal = (props) => {
  const [spinning, setSpinning] = useState(false);
  const [visible, setVisible] = useState(true);
  const expenses = [
    {
      title: 'Tipo de despesa',
      dataIndex: 'OutgoingType',
      key: 'OutgoingType',
      align: 'center',
      width: 10,
    },
    {
      title: 'Valor Reembolso',
      dataIndex: 'OutgoingCost',
      key: 'OutgoingCost',
      align: 'center',
      width: 110,
      render: (text, record) => <span>{'R$ ' + record.OutgoingCost}</span>
    },
    {
      title: 'Valor Faturado',
      dataIndex: 'BilledAmount',
      key: 'BilledAmount',
      align: 'center',
      width: 110,
      render: (text, record) => <span>{'R$ ' + record.BilledAmount}</span>
    },
    {
      title: 'Início Vigencia',
      key: 'EffectiveStart',
      align: 'center',
      width: 110,
      render: (text, record) => <span>{moment(record.EffectiveStart).format("DD/MM/YYYY")}</span>
    },
    {
      title: 'Término Vigencia',
      key: 'EffectiveEnd',
      align: 'center',
      width: 110,
      render: (text, record) => <span>{record.EffectiveEnd && moment(record.EffectiveEnd).format("YYYY-MM-DD") != moment("1899-12-31").format("YYYY-MM-DD") ? moment(record.EffectiveEnd).format("DD/MM/YYYY") : ""}</span>
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      align: 'center',
      width: 110,
      render: (text, record) => <span>{record.Status == "N" ? "Negado" : record.Status == "Y" ? "Aprovado" : "Aguardando aprovação"}</span>
    },
    {
      title: 'Ação',
      key: 'Action',
      width: 50,
      align: 'center',
      render: (text, record) => (
        <Button.Group >
          <div style={{ padding: "7px" }}>
            <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
              onConfirm={() => cancelExpense(record.Id)} okText="Sim" cancelText="Não">
              <Tooltip placement="top" title={"Excluir"}>
                <a><DeleteOutlined /></a>
              </Tooltip>
            </Popconfirm>
          </div>
        </Button.Group>
      ),
    }
  ];

  const onFinishExpenses = async (values) => {
    if (validateFields(values)) {
      setSpinning(true);
      let workflow = [];
      let data = {};
      if (props.projectData.WorkflowProject) {
        data = {
          ProjectId: props.projectData.id,
          OutgoingType: values.expensesType,
          OutgoingCost: props.outgoingValue,
          BilledAmount: parseFloat(values.valueBilling),
          EffectiveStart: moment(values.startDate).format("YYYY-MM-DD"),
          EffectiveEnd: values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : "",
          Status: "P"
        }
        console.log(parseFloat(values.valueBilling));
      }
      else {
        message.destroy();
        message.error('Ocorreu um erro ao adicionar nova despesa, projeto sem workflow.', 3);
        return false
      }
      message.loading('Adicionando despesa de projeto ...', 100);
      const r = await ProjectsService.postExpenseProject(data);
      if (r.data.error) {
        message.destroy();
        message.error('Ocorreu um erro ao adicionar nova despesa.', 3);
      }
      else {
        console.log(r.data.data);
        const id = localStorage.getItem('userId');
        if (props.projectData.WorkflowProject) {
          const dataWorkflow = await workflowService.getByIdData(props.projectData.WorkflowProject);
          await workflowService.getApprovals(props.projectData.WorkflowProject).then((workflowApprovals) => {
            if(workflowApprovals.data.data.data){
              //for (const flow of workflowApprovals.data.data.data) {
                const flow = workflowApprovals.data.data.data
                console.log(flow);

                workflow.push({
                  IdType: parseInt(r.data.data[0].Id),
                  Value: r.data.data[0].Value,
                  Type: "E",
                  Status: "P",
                  WorkflowApprovalsId: parseInt(dataWorkflow.data.data.data[0].Id),
                  UserId: parseInt(id),
                  ProjectId: props.projectData.id,
                  ProjectName: props.projectData.title,
                  ResponsibleId: _.first(flow.UserId)
                });
                console.log(workflow);
            }
            else {
              message.destroy();
              message.error('Nenhum aprovador no workflow.', 3);
            }
          });
          await workflowService.insert(workflow).then((r) => {
            if (!r.data.error) {
              message.destroy();
              message.success('Despesas adicionado com sucesso', 3);
              props.onFecthing(props.projectData.id);
              setSpinning(false);
              props.onClosed();
            }
            else {
              message.destroy();
              ProjectsService.deleteExpenseProject(r.data.data.data[0].Id);
              message.error('Ocorreu um erro ao adicionar nova despesa.', 3);
            }
          });
        }
        else {
          message.destroy();
          message.success('Despesas adicionado com sucesso', 3);
          props.onFecthing(props.projectData.id);
          setSpinning(false);
        }
      }
    }
  }

  const validateFields = (values) => {
    if (!values.expensesType) {
      message.error('Campo tipo de despesa não preenchido.', 3);
      return false;
    }
    if (!values.valueBilling) {
      message.error('Campo valor faturado não preenchido.', 3);
      return false;
    }
    if (!values.startDate) {
      message.error('Campo data inicial não preenchido.', 3);
      return false;
    }
    if (parseInt(props.outgoingValue) < parseInt(values.valueBilling)) {
      message.error('Campo valor faturado maior que o esperado.', 3);
      return false;
    }
    if (parseInt(values.valueBilling) < 0) {
      message.error('Campo horas não pode ser menor que 0.', 3);
      return false;
    }
    return true;
  }

  const onChangeOutgoing = e => {
    props.sedingOutgoing(e);
  }

  const cancelExpense = (id) => {
    props.onCancel(id);
  }

  return (
    <Modal
      title={"Despesas Projeto | " + props.projectData.code + " - " + props.projectData.title}
      visible={props.visible}
      width={props.width}
      footer={null}
      onOk={props.onClosed}
      onCancel={props.onClosed}
      destroyOnClose={props.onClosed}
      cancelButtonProps={{ style: { display: 'none' } }}>

      <Spin spinning={spinning} delay={500}>
        <Form style={{ width: "100%" }}>
          <Row>
            <Table columns={expenses} dataSource={props.expenses} style={{ height: "100%" }} pagination={true} style={{ width: "100%", paddingTop: "10px" }} />
          </Row>
          <Row style={{ padding: "5px 0px", paddingBottom: "10px", width: "100%" }}>
            <div style={{ float: "right", width: "100%" }}>
              <div style={{ float: "right" }}>
                <Button style={{ backgroundColor: "#002E5B", color: "white" }} onClick={() => setVisible(!visible)}>+ Adicionar</Button>
              </div>
            </div>

            <Form style={{ width: "100%" }} onFinish={onFinishExpenses}>
              <Input.Group compact style={{ padding: "20px 0", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <Form.Item hidden={visible}><p>Tipo de despesa:</p></Form.Item>
                  <Form.Item hidden={visible} name="expensesType"><Select options={props.outgoing} placeholder={"Selecione"} onChange={e => onChangeOutgoing(e)} /></Form.Item>
                </div>
                <div style={{ width: "20%", padding: "0px 1%" }}>
                  <Form.Item hidden={visible}><p>Valor Reembolso:</p></Form.Item>
                  <Form.Item hidden={visible}><Input disabled={true} value={props.outgoingValue} /></Form.Item>
                </div>
                <div style={{ width: "20%" }}>
                  <Form.Item hidden={visible}><p>Valor Faturado:</p></Form.Item>
                  <Form.Item hidden={visible} name="valueBilling">
                    <InputNumber decimalPlaces="3" decimalSeparator="," thousandSeparator="." style={{ width: "100%" }} />
                  </Form.Item>
                </div>
                <div style={{ width: "20%", padding: "0px 1%" }}>
                  <Form.Item hidden={visible}><p>Início Vigencia:</p></Form.Item>
                  <Form.Item hidden={visible} name="startDate"><DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} placeholder="Selecione uma data" /></Form.Item>
                </div>
                <div style={{ width: "20%" }}>
                  <Form.Item hidden={visible}><p>Término Vigencia:</p></Form.Item>
                  <Form.Item hidden={visible} name="endDate"><DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} placeholder="Selecione uma data" /></Form.Item>
                </div>
              </Input.Group>

              <Row style={{ width: "100%" }}>
                <div style={{ float: "right", width: "100%" }}>
                  <div style={{ float: "right" }}>
                    <Button type="primary" htmlType="submit" >Salvar</Button>
                  </div>
                  <div style={{ float: "right", paddingRight: "1%" }}>
                    <Button onClick={() => props.onClosed()}>Fechar</Button>
                  </div>
                </div>
              </Row>
            </Form>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default ProjectExpenseModal;