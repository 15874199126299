import React, { useState } from "react";
import { Form, Modal, Input, Upload, Button, message, DatePicker, Row } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import moment from "moment";
import { warningsService } from '../../services/warnings';
import conf from '../../environment.json';

const { TextArea } = Input;
const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const WarningEditModal = (props) => {

    const [path, setPath] = useState();

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const onEdit = async (value) => {
        if (validateFields(value)) {
            message.loading('Editando Alerta..', 100);

            const startDateProps = moment(props.data.StartDate, "DD/MM/YYYY");
            const endDateProps = moment(props.data.EndDate, "DD/MM/YYYY");
            const data = {
                Title: value.data.Title,
                Description: value.data.Description,
                FileUrl: path ? path : props.data.FileUrl,
                StartDate: value.data.Date ? moment(value.data.Date[0]).format("YYYY-MM-DD") : startDateProps.format("YYYY-MM-DD"),
                EndDate: value.data.Date ? moment(value.data.Date[1]).format("YYYY-MM-DD") : endDateProps.format("YYYY-MM-DD"),
            }
            await warningsService.editWarning(data, props.id).then((r) => {
                if (r.data.error) {
                    console.log(r.data.error);
                    message.destroy();
                    props.onClose();
                    message.error('Erro ao editar', 3);
                }
                else {
                    props.onClose();
                    message.destroy();
                    message.success('Alerta editado com sucesso', 3);
                }
            });
        }
    }

    const validateFields = (values) => {
        if (!values.data.Title) {
            message.error('Campo título não preenchido.', 3);
            return false;
        }
        if (!values.data.Description) {
            message.error('Campo descrição não preenchido.', 3);
            return false;
        }
        if (!props.data.StartDate && props.data.EndDate) {
            message.error('Campo data não preenchido.', 3);
            return false;
        }
        return true;
    }

    const config = {
        name: 'file',
        multiple: false,
        action: conf['API_URL'] + '/warnings/file',
        //action: `${config['API_URL']}/warnings/file`,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                setPath(info.fileList[0].response.path)
            } else if (status === 'error') {
            }
        },
    };

    const dateFormat = 'DD/MM/YYYY';

    return (
        <Modal
            title={props.title}
            visible={props.visible}
            footer={null}
            onCancel={props.onCancel}
            destroyOnClose={props.onCancel}>
            <Form name="nest-messages" onFinish={onEdit} style={{ width: "100%" }}>
                <Row>
                    <p>Titulo:</p>
                    <Form.Item name={['data', 'Title']} style={{ width: "100%" }} initialValue={props.data.Title}>
                        <Input />
                    </Form.Item>
                    <p>Data:</p>
                    <Form.Item name={['data', 'Date']} style={{ width: "100%" }}>
                        <RangePicker format ={'DD/MM/YYYY'} style={{ width: "100%" }} defaultValue={[moment(props.data.StartDate, dateFormat), moment(props.data.EndDate, dateFormat)]} />
                    </Form.Item>
                </Row>
                <Row>
                    <p>Descrição:</p>
                    <Form.Item name={['data', 'Description']} style={{ width: "100%" }} initialValue={props.data.Description}>
                        <TextArea style={{ minHeight: "100px", width: "100%" }} />
                    </Form.Item>
                </Row>
                <Form.Item >
                    <Dragger {...config}>
                        <p className="ant-upload-drag-icon"> <InboxOutlined /> </p>
                        <p className="ant-upload-text">Clique ou Arraste o arquivo para upload</p>
                    </Dragger>
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 20 }}>
                    <Button type="primary" htmlType="submit">
                        Editar
                </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default WarningEditModal;