import { request } from '../http';

const usersService = {
  getUsers: () => {
    return request.get('/Users');
  },
  getUserById: (id) => {
    return request.get('/Users/' + id);
  },
  verifyByUsername: (name) => {
    return request.get('/Users/verify/' + name);
  },
  getProfileById: (id) => {
    return request.get('/Users/profileId/' + id);
  },
  // atualizar dados do usuário
  updateUser: (userData) => {
    return request.put('/Users/profile-permission/', userData);
  },
  // Novo usuário
  createUser: (userData) => {
    return request.post('/Users/', userData);
  }
}

export {
  usersService
}