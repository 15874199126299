import React from "react";
import {
  Row,
  Button,
  Table,
  Input,
  Spin,
  message,
  Tooltip,
  Popconfirm,
  Drawer,
  Select
} from "antd";
import { FilterFilled, DeleteOutlined,FormOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { tasksService } from "../../services/tasks";
import { workflowService } from '../../services/workflow';
import { projectsService } from '../../services/projects';
import { scheduleService } from '../../services/schedule';
import { usersService } from '../../services/users';
import MyTasksViewModal from "../../components/my-tasks-view-modal";
import * as _ from 'lodash';

moment.locale("pt-BR");

const { Search } = Input;

class myNotes extends React.Component {
  state = {
    fetching: false,
    dataSource: [],
    dataSourceBackup: [],
    calendarPage: false,
    openNoteModal: false,
    dataFieldsModal: [],
    drawer: false,
    dataNoteModal: [],
    dataModal: [],
    userData: [],
    projectData: [],
    taskView: false,
    status: null,
    project: null,
    user: null,
    userID: null,
    selectStatus: null,
    selectProject: null,
    selectUser: null
  };

  componentDidMount() {
    this.fetch();
  }

  openModal = async (value) => {
    this.setState({ modal: true, dataNoteModal: value});
  }

  formatDuration = (duration) => {
    duration = duration || "0";
    const dur = moment.duration(parseFloat(duration), 'hours');
    var hours = Math.floor(dur.asHours());
    var mins = Math.floor(dur.asMinutes()) - hours * 60;
    return `${_.padStart(hours, 2, '0')}:${_.padStart(mins, 2, '0')}`;
  }

  fetch = () => {
    (async () => {

      this.setState({ fetching: true });
      const id = localStorage.getItem('userId');
      const taskData = await tasksService.getTaskHistoryByUserId(id);
      let view = localStorage.getItem('task_view');
      let teste = view == 'true' ? true : false
      this.setState({ taskView: teste })
      const { data: dataUsers } = await usersService.getUsers();

      const data = taskData.data.data;
      const fathers = [...new Set(data.map(x => x.ProjectId))]
      let dataSource = [];
      fathers.forEach(father => {
        console.log(data)
        const childrens = data.filter(item => item.ProjectId == father);
        console.log(childrens)
        const childrensWithKeys = childrens.map(children => ({ ...children, key: children.TaskId }));
        let ordinationList = childrensWithKeys.sort((a, b) => a.Id - b.Id);
        let entity = {
          Title: childrens[0].Title ? childrens[0].Title : childrens[0].ProjectDescription,
          Company: childrens[0].Company,
          key: childrens[0].ProjectId,
          DescriptionTask: childrens[0].DescriptionTask,
          Description: childrens[0].DescriptionTask,
          ConsumedTime: childrens.reduce((total, item) => item.Approval != 'N' ? total + parseFloat(item.ConsumedTime) : total, 0),
          Percentage: childrens[0].Percentage,
          DateTask: childrens[0].DateTask,
          Approval: childrens[0].Approval,
          children: ordinationList
        }

        dataSource.push(entity);
      });
      console.log(dataSource)
      this.setState({ fetching: false, dataSource: dataSource, userData: dataUsers.data, projectData: dataSource, userID: id, dataSourceBackup: data });
    })();
  };

  deleteTask = async (value) => {
    message.loading("Excluindo apontamento ...", 100);
    const workflowData = await workflowService.deleteByIdType(value.Id, 'T');
    const workflow = workflowData.data;
    let notesList = this.state.dataSourceBackup;

    if (!workflow.error) {
      const taskData = await tasksService.deleteTaskHistory(value.Id);
      const task = taskData.data;
      message.destroy();
      if (!task.error) {
        let list = notesList.filter(find => find.TaskId == value.TaskId);
        console.log(notesList);
        console.log(list[0]);
        console.log(value.TaskId);

        if (list[1]) {
          await tasksService.updateStatus(value.TaskId, 'W', 'Y');

          let concluidos = list.filter(find => parseFloat(find.Percentage) === 100);

          for (let index = 0; index < concluidos.length; index++) {
            const element = concluidos[index];
            await tasksService.updatePercentageTaskHistory(element.Id, {Percentage: 99});
          }
        }
        else {
          await tasksService.updateStatus(value.TaskId, 'P', 'N');
        }
        await this.fetch();
        message.success("Apontamento excluído com sucesso.", 3);
      }
      else {
        message.error("Ocorreu um erro ao excluir o apontamento.", 3);
      }
    }
  }

  searchTerm = (value) => {
    if (value && this.state.dataSourceBackup) {
      this.setState({ fetching: true });
      const filtrado = this.state.dataSourceBackup.filter((x) => x.Title)
      const filtered = filtrado.filter((x) => {
        return x.Title.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ dataSource: filtered, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  RedirectPage = () => {
    this.setState({ calendarPage: !this.state.calendarPage });
  }



  render() {

    const optionsStatus = [
      {
        value: null,
        label: "Todos",
      },
      {
        value: "Y",
        label: "Aprovado",
      },
      {
        value: "N",
        label: "Reprovado",
      },
      {
        value: "W",
        label: "Aguardando aprovação",
      }
    ]

    const columns = [
      {
        title: "Cliente",
        key: "Company",
        align: "left",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => record.children ? <span>{record.Company ? record.Company : ""}</span> : ''
      },
      {
        title: "Projeto",
        key: "Title",
        align: "left",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => record.children ? <span>{record.Title ? record.Title : ""}</span> : ''
      },
      {
        title: "Tarefa",
        key: "DescriptionTask",
        align: "left",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => record.children ? '' : (
          <Tooltip placement="topLeft" title={record.DescriptionTask ? record.DescriptionTask : ""}>
            {record.DescriptionTask ? record.DescriptionTask : ""}
          </Tooltip>
        )
      },
      {
        title: "Local de Atendimento",
        key: "ServiceType",
        align: "left",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => record.children ? '' : <span>{record.ServiceType ? record.ServiceType === '1' ? 'Remoto' : record.ServiceType === '2' ? 'Presencial na Consultoria' : record.ServiceType === '3' ? 'Presencial no Cliente' : '' : ''}</span>
      },
      {
        title: "Atividade Realizada",
        key: "Description",
        align: "left",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip placement="topLeft" title={record.Description ? record.Description : ""}>
            {record.Description ? record.Description : ""}
          </Tooltip>
        )
      },
      {
        title: "Horas Utilizadas",
        key: "ConsumedTime",
        align: "left",
        render: (text, record) => <span>{this.formatDuration(record.ConsumedTime)}</span>
      },
      {
        title: "Conclusão (%)",
        key: "Percentage",
        align: "left",
        render: (text, record) => {
          if (record.children) {
            return '';
            // return (<span>{_.get(_.maxBy(record.children, 'Percentage'),'Percentage',0) + '%'}</span>)
          } else
            return (<span>{record.Percentage ? record.Percentage + '%' : '0%'}</span>)
        }
      },
      {
        title: "Dia do Atendimento",
        key: "DateTask",
        align: "left",
        render: (text, record) => record.children ? '' : <span>{record.DateTask ? record.DateTask : 'sem data'}</span>
      },
      {
        title: "Status",
        key: "Approval",
        align: "left",
        render: (text, record) => record.children ? '' : <span
          className={record.Approval == 'P' ? "pending" : record.Approval == 'R' ? "reversal" : record.Approval == 'N' ? "denied" : 'approved'}>
          {record.Approval == 'P' ? "Aguardando aprovação" : record.Approval == 'I' ? "Faturado" : record.Approval == 'R' ? "Estornado" : record.Approval == 'N' ? "Negado" : 'Aprovado'}</span>
      },
      {
        title: 'Ação',
        key: 'Action',
        align: 'left  ',
        render: (text, record) => record.children ? '' : (
          <Button.Group >
            <Tooltip placement="top" title={"Visualizar"}>
              <a><EyeOutlined onClick={() => this.openModal(record)} /></a>
            </Tooltip>
            {record.Approval == 'P' || record.Approval == 'R' ?
              <Popconfirm placement="left" title={"Tem certeza que deseja excluir?"}
                onConfirm={() => this.deleteTask(record)} okText="Sim" cancelText="Não">
                <Tooltip placement="top" title={"Excluir"}>
                  <a style={{paddingLeft: '20%'}}><DeleteOutlined /></a>
                </Tooltip>
              </Popconfirm>
              :
              null}
          </Button.Group>
        )
      }
    ];

    const fetching = this.state.fetching;

    const onChangeFilter = async () => {

      this.setState({ fetching: true });

      const id = localStorage.getItem('userId');
      let searchFilter = [];

      if (this.state.taskView) { searchFilter.push(this.state.user) }
      if (this.state.status) { searchFilter.push(this.state.status) }
      if (this.state.project) { searchFilter.push(this.state.project) }

      console.log(searchFilter);

      const { data: dataTask, err: errorTask } = await tasksService.getTaskHistoryByUserFilter(id, searchFilter);

      if (!errorTask) {
        let task = dataTask.data.data
        task && task.map(find => find.ProjectId)

        this.setState({ fetching: false, dataSource: task, dataSourceBackup: task, filter: searchFilter, drawer: false, });
      }
    }

    return (
      // // <div>
      // //   {verifyCalendar === false ?
      <Spin spinning={fetching} delay={500}>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "58%" }}>
            <b style={{ textAlign: "left", fontSize: "30px" }}>Minhas Tarefas</b>
          </div>
          <div style={{ width: "35%" }}>
            <Search placeholder="Pesquisar (Projeto)" onSearch={(value) => this.searchTerm(value)} />
          </div>
          {/* <div style={{ width: "5%", float: "right" }}>
            <center>
              <FilterFilled style={{ fontSize: "20px", padding: "5px" }} onClick={() => this.setState({ drawer: true })} />
            </center>
          </div> */}
        </Row>
      {/* <Spin spinning={fetching} delay={500}> */}
        {/* <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "58%" }}>
            <b style={{ textAlign: "left", fontSize: "30px" }}>Minhas Tarefas</b>
          </div>
        </Row> */}
        <Row>
          <Table
            dataSource={this.state.dataSource}
            columns={columns}
            style={{ width: "100%" }}
            pagination={true}
            size={"small"}
          />
        </Row>
        
        <MyTasksViewModal         
            fetch={() => this.fetch()}
            visible={this.state.modal}
            tasks={this.state.dataNoteModal}
            onClosed={() => this.setState({ modal: false, fetching: false })}
            destroyOnClose={() => this.setState({ modal: false, fetching: false })}
          />
        <Drawer
          title="Filtros"
          placement="right"
          width="400"
          closable={false}
          onClose={() => this.setState({ drawer: false })}
          visible={this.state.drawer} >
          <div style={{ paddingBottom: "3%" }}>
            <div style={{ paddingBottom: "3%" }}>
              <p>Selecione um status:</p>
              <Select value={this.state.selectStatus} options={optionsStatus} style={{ width: "100%" }} onChange={(e) => this.setState({ status: ` Tasks.Status = '${e}' `, selectStatus: e })} />
            </div>
            <div style={{ paddingBottom: "3%" }}>
              <p>Selecione um Projeto:</p>
              <Select value={this.state.selectProject} style={{ width: "100%" }} onChange={(e) => this.setState({ project: ` ProjectId = '${e}' `, selectProject: e })} >
                <Select.Option key={null}>Todos</Select.Option>
                {this.state.projectData && this.state.projectData.map(projectD => (
                  <Select.Option key={projectD.key}>{projectD.Title}</Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ paddingBottom: "3%" }}>
              <Button type="primary" style={{ width: "100%" }} onClick={() => onChangeFilter()}>Pesquisar</Button>
            </div>
            <div style={{ paddingBottom: "3%" }}>
              <Button type="danger" style={{ width: "100%" }} onClick={() => this.setState({
                drawer: false,
                type: null,
                status: null,
                project: null,
                user: null,
                selectType: null,
                selectStatus: null,
                selectProject: null,
                selectUser: null
              })}>Limpar</Button>
            </div>
          </div>
        </Drawer>
      </Spin>
    );
  }
}

export default myNotes;
