import { request } from '../http';

const TimeCourseService = {
  getTimeCourse: () => {
    return request.get('/time-course');
  },
  
  createTimeCourse: (data) => {
    return request.post('/time-course',data);
  }
}

export {
  TimeCourseService
}