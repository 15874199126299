import React from "react";
import { Form, Row, Col,Collapse, Button, Spin} from 'antd';
import { FilterFilled, UserSwitchOutlined, FileSearchOutlined, SnippetsOutlined } from '@ant-design/icons';
import moment from "moment";
import { Route } from "react-router-dom";
moment.locale('pt-BR');

const { Panel } = Collapse;

class ConfigPage extends React.Component {


    state = {
        fetching: false,
        dataSource: undefined,
        modalVisible: false,
    }

    // componentDidMount() {
    //     this.fetch();
    // }

    onSelect({ key, history }) {

        history.push(key);
    }

    render() {
        return (
            <Route render={matchProps => (
                <Spin spinning={this.state.fetching} delay={500}>
                    <Form /* style={{ minHeight: '90vh' }} */>
                        <Row style={{ paddingBottom: "25px" }}>
                            <div style={{ width: "60%" }}>
                                <b style={{ textAlign: "left", fontSize: "30px" }}>Configurações</b>
                            </div>
                        </Row>
                        <Collapse defaultActiveKey={['1']} >
                            <Panel header="Administração" key="1">
                                <Row>
                                    <Col style={{ padding: 4 }}>
                                        <Button type="dashed" style={{ width: 250, height: 70 }} onClick={() => this.onSelect({ key: '/categoryDocument', history: matchProps.history })}><FileSearchOutlined />  - Categoria de Documentos</Button>
                                    </Col>
                                    {/* <Col style={{ padding: 4 }}>
                                        <Button type="dashed" style={{ width: 250, height: 70 }}><UserSwitchOutlined /> - Permissões de Acesso</Button>
                                    </Col>
                                    <Col style={{ padding: 4 }}>
                                        <Button type="dashed" style={{ width: 250, height: 70 }} onClick={() => this.onSelect({ key: '/projectModel', history: matchProps.history })}><SnippetsOutlined /> - Modelo de Projeto</Button>
                                    </Col>
                                    <Col style={{ padding: 4 }}>
                                        <Button type="dashed" style={{ width: 250, height: 70 }}><FilterFilled /> -  Configurações 2</Button>
                                    </Col> */}
                                </Row>
                            </Panel>
                            <Panel header="Usúarios" key="2">

                            </Panel>
                        </Collapse>
                    </Form>
                </Spin>
            )} />
        )
    }
}
export default ConfigPage;