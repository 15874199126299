import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  message,
  DatePicker,
  Card,
  InputNumber, Spin, Input
} from "antd";
import * as _ from 'lodash';
import { UserTimeGoalsService } from "../../services/user-time-goals"

const UserTimeGoalModal = (props) => {

  const [year, setYear] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const [professional, setProfessional] = useState(0);
  const [jan, setJan] = useState(0);
  const [feb, setFeb] = useState(0);
  const [mar, setMar] = useState(0);
  const [apr, setApr] = useState(0);
  const [may, setMay] = useState(0);
  const [jul, setJul] = useState(0);
  const [jun, setJun] = useState(0);
  const [aug, setAug] = useState(0);
  const [sep, setSep] = useState(0);
  const [oct, setOct] = useState(0);
  const [nov, setNov] = useState(0);
  const [dec, setDec] = useState(0);

  const getMonth = () => {
    let month = []
    if (jan) {
      month.push({ label: "Jan", value: jan });
    }
    if (feb) {
      month.push({ label: "Feb", value: feb });
    }
    if (mar) {
      month.push({ label: "Mar", value: mar });
    }
    if (apr) {
      month.push({ label: "Apr", value: apr });
    }
    if (may) {
      month.push({ label: "May", value: may });
    }
    if (jul) {
      month.push({ label: "Jul", value: jul });
    }
    if (jun) {
      month.push({ label: "Jun", value: jun });
    }
    if (aug) {
      month.push({ label: "Aug", value: aug });
    }
    if (sep) {
      month.push({ label: "Sep", value: sep });
    }
    if (oct) {
      month.push({ label: "Oct", value: oct });
    }
    if (nov) {
      month.push({ label: "Nov", value: nov });
    }
    if (dec) {
      month.push({ label: "Dec", value: dec });
    }
    return month;
  }

  const insertUserTimeGoals = async () => {
    if (validateFields()) {
      setSpinning(true);
      debugger

      let verifyProfessional = props.dataSource && props.dataSource.filter(filtered => filtered.Year == year);

      if (!verifyProfessional) {
        message.loading('Inserindo metas de horas ...');
        //create
        let data = {
          Year: year,
          Jan: jan ? jan : "",
          Feb: feb ? feb : "",
          Mar: mar ? mar : "",
          Apr: apr ? apr : "",
          May: may ? may : "",
          Jun: jun ? jun : "",
          Jul: jul ? jul : "",
          Aug: aug ? aug : "",
          Sep: sep ? sep : "",
          Oct: oct ? oct : "",
          Nov: nov ? nov : "",
          Dec: dec ? dec : "",
        }
        await UserTimeGoalsService.insertUserTimeGoals(data).then(response => {
          let InsertUserItmeGoals = response.data;
          if (!InsertUserItmeGoals.error) {
            props.onFeching();
            message.destroy();
            message.success("Meta de horas criada com sucesso.", 3);
          }
          else {
            message.destroy();
            message.error("Ocorreu um erro ao criar meta de horas.", 3);
          }
        });
        setSpinning(false);
      }
      else {
        //update
        message.loading('Atualizando metas de horas ...');
        let updateMonth = getMonth();
        for (const item of updateMonth) {
          let data = {
            // UserId: props.user.ID,
            Year: year,
            Month: item.label,
            Value: item.value
          }
          await UserTimeGoalsService.updateUserTimeGoals(data);
        }
        props.onFeching();
        message.destroy();
        message.success("Meta de horas atualizada com sucesso.", 3);

        setSpinning(false);
      }
    }
  }

  const validateFields = () => {
    if (!year) {
      message.error("Campo ano não preenchido.", 3);
      return false;
    }
    return true
  }

  const onSelectYear = e => {
    if (e) {
      setYear(e.year());
    } else {
      setYear(0);
    }
  }

  const onSelectProfessional = e => {
    setProfessional(e);
  }

  return (
    <Modal
      title={"Cadastro de metas de horas"}
      width={"50%"}
      visible={props.visible}
      footer={null}
      onCancel={props.onCancel}
      destroyOnClose={props.onCancel}>
      <Spin spinning={spinning}>
        <Card style={{ width: "100%", height: "auto", margin: 0, padding: 0 }} bordered={false}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <p>Selecione um ano:</p>
              <div style={{ paddingBottom: "3%" }}>
                <DatePicker picker="year" format="YYYY" placeholder="Escolha um ano" style={{ width: "100%" }} onChange={(e) => onSelectYear(e)} />
              </div>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <p>Janeiro:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setJan(e)} />
            </Col>
            <Col span={8}>
              <p>Fevereiro:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setFeb(e)} />
            </Col>
            <Col span={8}>
              <p>Março:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setMar(e)} />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <p>Abril:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setApr(e)} />
            </Col>
            <Col span={8}>
              <p>Maio:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setMay(e)} />
            </Col>
            <Col span={8}>
              <p>Julho:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setJul(e)} />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <p>Junho:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setJun(e)} />
            </Col>
            <Col span={8}>
              <p>Agosto:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setAug(e)} />
            </Col>
            <Col span={8}>
              <p>Setembro:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setSep(e)} />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <p>Outubro:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setOct(e)} />
            </Col>
            <Col span={8}>
              <p>Novembro:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setNov(e)} />
            </Col>
            <Col span={8}>
              <p>Dezembro:</p>
              <InputNumber style={{ width: "100%" }} onChange={(e) => setDec(e)} />
            </Col>
          </Row>
          <div style={{ float: "right" }}>
            <Button type="primary" onClick={() => insertUserTimeGoals()}>
              Salvar
            </Button>
          </div>
        </Card>
      </Spin>
    </Modal>
  )
}

export default UserTimeGoalModal;