import React from "react";
import {
  Row,
  Table,
  Input,
  Spin,
  Tooltip,
  message,
  Button
} from "antd";
import { FilterFilled, EyeOutlined, FieldTimeOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { usersService } from "../../services/users"
import { UserTimeGoalsService } from '../../services/user-time-goals';
import { permissionsService } from '../../services/permissions'
import UserModal from '../../components/user-modal';
import UserTimeGoalAddModal from '../../components/user-time-goals-add-modal';
import { FloatingButton } from "../../components/floating-button";

moment.locale("pt-BR");

const { Search } = Input;

class Users extends React.Component {

  state = {
    fetching: false,
    dataSource: [],
    modal: false,
    modalTimeGoals: false,
    dataModal: [],
    lockFields: true,
    users: [],
    dataModalUserTimeGoals: []
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ fetching: true });
    const usersData = await usersService.getUsers();
    const data = usersData.data.data;
    const id = localStorage.getItem('userId');
    const userByIdData = await usersService.getUserById(id);
    const userById = userByIdData.data.data[0];
    const permissionData = await permissionsService.getProfileDetails(userById.PROFILE);
    const permission = permissionData.data.data;
    let permissionListAll = Object.values(permission).filter(find => find.Feature == '/taskAssignment')
    
    if (permissionListAll[0]) {
      this.setState({ dataSource: data, lockFields: false });
    }
    else {
      let filteredUsers = data.filter(find => find.ID == id);
      this.setState({ dataSource: filteredUsers });
    }
    this.setState({ fetching: false })

    await permissionsService.getProfiles().then(permissionData => {
      const data = permissionData.data.data;
      this.setState({ permissionsList: data, fetching: false });
    });
  };

  searchTerm = async (value) => {
    console.log(value);
    if (value) {
      this.setState({ fetching: true });
      await this.fetch();
      const filtrado = this.state.dataSource.filter((x) => x.NAME)
      const filtered = filtrado.filter((x) => {
        return x.NAME.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ dataSource: filtered, fetching: false });
    }
    else {
      this.fetch();
    }
  };

  onModal = async (value, type) => {
    this.setState({ users: value });
    if (type == "details") {
      await usersService.getUserById(value.ID).then((r) => {
        if (!r.data.error) {
          this.setState({ dataModal: r.data.data[0], modal: true, Id: value.ID, fetching: true, editForm: true });
        } else {
          message.error('Ocorreu um erro ao obter o detalhes do usuário.');
        }
      });
    } else if (type == 'addUser') {
      const newUser = {
        Id: 0,
        Name: '',
        Username: '',
        Address: '',
        Number: 0,
        Complement: '',
        ZipCode: '',
        City: '',
        State: '',
        Phone: '',
        Mobile: '',
        CPF: '',
        RG: '',
        CivilStatus: '',
        Birthdate: '',
        CompanyAdmission: '',
        Email: '',
        ContractType: '',
        Status: '',
        Cost: 0,
        Position: '',
        BusinessPartner: 0,
        AccessProfileId: '',
        Password: '',
      };

      this.setState({ dataModal: newUser, modal: true, Id: value.ID, fetching: true, editForm: false });
    }
    else {
      await this.setUserTimeGoals();
      this.setState({ modalTimeGoals: true });
    }
  }

  setUserTimeGoals = async () => {
    UserTimeGoalsService.getUserTimeList().then(userTimeGoalsData => {
      let data = [];
     
      data.push(userTimeGoalsData.data.data[0]);
      this.setState({ dataModalUserTimeGoals: data });
    });
  }

  render() {

    const fetching = this.state.fetching;
    const dataSource = this.state.dataSource;

    const columns = [
      {
        title: "Nome",
        dataIndex: "NAME",
        key: "NAME",
        width: "20%",
      },
      {
        title: "Nome de usuário",
        dataIndex: "Analyst",
        key: "Analyst",
        width: "20%",
      },
      {
        title: "Email",
        dataIndex: "EMAIL",
        key: "EMAIL",
        width: "30%",
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        width: "20%",
        render: (text, record) => <span>{record.STATUS == "N" ? "Bloqueado" : "Ativo"}</span>
      },
      {
        title: "Ação",
        id: "Action",
        width: "10%",
        align: 'center',
        render: (text, record) => (
          <Button.Group >
            {/* <div style={{ padding: "10px" }}>
              <Tooltip placement="top" title="Meta de horas">
                <a>
                  <FieldTimeOutlined onClick={() => this.onModal(record, "userTimeGoals")} />
                </a>
              </Tooltip>
            </div> */}
            <div style={{ padding: "10px" }}>
              <Tooltip placement="top" title="Detalhes">
                <a>
                  <EditOutlined onClick={() => this.onModal(record, "details")} />
                </a>
              </Tooltip>
            </div>
          </Button.Group>
        ),
      },
    ];

    return (
      <Spin spinning={fetching} delay={500}>
        <Row style={{ paddingBottom: "25px" }}>
          <div style={{ width: "60%" }}>
            <b style={{ textAlign: "left", fontSize: "30px" }}>
              Listagem de usuários
            </b>
            {this.state.lockFields ?
              <Button style={{ margin: "25px" }} type="primary" onClick={() => this.onModal('', 'userTimeGoals')}> Meta de horas</Button>
              :
              null
            }

          </div>
          <div style={{ width: "35%" }}>
            <Search placeholder="Pesquisar" onSearch={(value) => this.searchTerm(value)} />
          </div>
          {/* <div style={{ width: "5%", float: "right" }}>
            <center>
              <FilterFilled style={{ fontSize: "20px", padding: "5px" }} />
            </center>
          </div> */}
        </Row>
        <Row>
          <FloatingButton icon="+" onClick={() => this.onModal('', 'addUser')} />
        </Row>
        <Row>
          <div style={{ width: "100%" }}>
            <UserModal
              title={this.state.editForm ? "Usuário: " + this.state.users.NAME : "Novo Usuário"}
              visible={this.state.modal}
              data={this.state.dataModal}
              dropdown={this.state.permissionsList}
              onCancel={() => this.setState({ modal: false, fetching: false })}
              fields={this.state.lockFields}
              editForm={this.state.editForm} />

            <Table
              dataSource={dataSource}
              columns={columns}
              style={{ height: "100%" }}
              pagination={true}
            />

            <UserTimeGoalAddModal
              user={this.state.users}
              onCancel={() => this.setState({ modalTimeGoals: false })}
              visible={this.state.modalTimeGoals}
              data={this.state.dataModalUserTimeGoals}
              onFeching={() => this.setUserTimeGoals()} />
          </div>
        </Row>
      </Spin>
    );
  }
}

export default Users;
