import React, { useEffect, useState } from 'react';
import { Table, Checkbox, InputNumber, message, Spin } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { tasksService } from '../../services/tasks';
import { ProjectsService } from '../../services/projects';
import { workflowService } from '../../services/workflow';
import moment from "moment";
import * as _ from "lodash";
import { scheduleService } from '../../services/schedule';
moment.locale('pt-br');

const ProjectActivities = (props) => {
	const [data, setData] = useState([]);
	const [load, setLoad] = useState(false);
	const [input, setInput] = useState([]);

	useEffect(() => {
		if (props.id) {
			loadTable();
		}
	}, [props.id, props.fetchActivities])

	const loadTable = async () => {

		setLoad(true);
		props.hasPendingTasks(false);

		await tasksService.getProjectActivities(props.id).then((res) => {
			const response = res.data.data;
			setLoad(false);

			const getChildrenByFatherId = (fatherId) => {
				const children = response.map(item => {
					return fatherId == item.ParentTask ? item : null
				}).filter(r => { return r !== null });
				return children;
			}

			let TotalizerChildren = 0;

			const recursiveMountData = (list) => {
				const resultList = [];
				for (const item of list) {
					const childrens = getChildrenByFatherId(item.Id);

					const result = {
						Description: item.Description,
						ExpectedStartDate: item.ExpectedStartDate,
						RemainingTime: item.RemainingTime,
						ExpectedEndDate: item.ExpectedEndDate,
						Id: item.Id,
						ReleasedHours: 0,
						Approval: false,
						children: [],
						key: item.Id
					}
					if (childrens.length > 0) {
						let child = recursiveMountData(childrens);
						result.children = child;
					} else {
						TotalizerChildren += parseFloat(item.RemainingTime);
						delete result.children
					}
					resultList.push(result);
				}
				return resultList
			}

			const listData = response.filter(item => {
				return !response.find(data => {
					return item.ParentTask == data.Id
				});
			});

		
			const dataSource = recursiveMountData(listData);

			let Totalizer = 0;
			for (const item of listData) {
				Totalizer += parseFloat(item.RemainingTime)
			}

			if (TotalizerChildren > 0){
				props.hasPendingTasks(true);		
			}


			let listActivities = dataSource.sort((a, b) => a.Id - b.Id);
			// listActivities = listActivities.filter((task) => task.ExpectedStartDate === task.ExpectedEndDate);
			listActivities.splice(0, 0, { Description: 'Total de horas', RemainingTime: Totalizer, Key: '-1' });
			listActivities = listActivities.filter((task) => task.RemainingTime > 0);
			setData(listActivities);

		}).catch(() => {

		});

	}

	

const add = async value => {
	message.loading('Atribuindo atividade ...');
	let inputValue = input.filter(find => find.Id == value.Id);

	if (inputValue.length > 0 && inputValue[0]) {

		let horas = inputValue[0].Value;

		if (!horas) {
			message.destroy();
			message.error('Campo horas não preenchido.');
			return;
		}
		if (horas <= 0) {
			message.destroy();
			message.error('Campo horas não pode ser menor ou igual a 0.');
			return;
		}
		if (horas > parseInt(value.RemainingTime)) {
			message.destroy();
			message.error('Campo horas liberadas são maiores que o esperado.');
			return;
		}
	} else {
		message.destroy();
		message.error('Campo horas não preenchido.');
		return;
	}

	setLoad(true);
	//validar se o projeto tem workflow
	// debugger
	const projectData = await ProjectsService.getProjects();
	const project = projectData.data.data
	let verifyWorkflow = project.map(item => {
		return item.Id == props.id ? item : null
	}).filter(filtered => filtered != null);
	
	let workflow = verifyWorkflow[0];
	if (workflow) {
		let hours = input.filter(find => find.Id == value.Id)
		let data = {
			...value,
			projectId: props.id,
			analyst: props.analyst,
			// Approval: checkbox,
			ReleasedHours: hours[0].Value,
		};

		const assigmentTaskData = await tasksService.postNewTask(data);

		const task = assigmentTaskData.data;
		message.destroy();
		if (task.error) {
			console.log("API Error: ", task.error);
			message.error('Ocorreu um erro ao atribuir a atividade.');
			setLoad(false);
		}
		else {
			message.success('Atividade adicionada com sucesso.');
			loadTable();
			props.onChange();
		}
		setLoad(false);
	}
	else {
		message.destroy();
		message.error('Nenhum workflow definido, contate um supervisor.');
		setLoad(false);
	}
}

const onChange = (value, record) => {
	let values = input.findIndex(find => find.Id == record.Id);
	if (value) {
		if (values == -1) {
			input.push({ Id: record.Id, Value: value });
		}
		else {
			input.splice(values, 1);
			input.push({ Id: record.Id, Value: value });
		}
	}
	else {
		input.splice(values, 1);
	}
}

const columns =
	[
		{
			title: 'Atividade',
			dataIndex: 'Description',
			key: 'Description',
			align: 'left'
		},
		{
			title: 'Início',
			dataIndex: 'ExpectedStartDate',
			key: 'ExpectedStartDate',
			width: 100,
			align: 'center',
			render: (text, record) => record.ExpectedStartDate ? record.ExpectedStartDate : "",
			// sorter: (a, b) => moment(a.ExpectedStartDate) - moment(b.ExpectedStartDate),
			// sortOrder: 'ascend',
		},
		{
			title: 'Término',
			dataIndex: 'ExpectedEndDate',
			key: 'ExpectedEndDate',
			width: 100,
			align: 'center',
			render: (text, record) => record.ExpectedEndDate ? record.ExpectedEndDate : "",
		},
		{
			title: 'Horas disponíveis',
			dataIndex: 'RemainingTime',
			key: 'RemainingTime',
			width: 140,
			align: 'center'
		},
		{
			title: 'Atribuir horas',
			key: 'ReleasedHours',
			width: 100,
			align: 'center',
			render: (_, record) => record.children || parseFloat(record.RemainingTime) == 0 || record.Key == '-1' ? '' : <InputNumber onChange={value => onChange(value, record)} />,
		},
		{
			title: 'Ação',
			key: 'Action',
			width: 50,
			align: 'center',
			render: (_, record) => record.children || parseFloat(record.RemainingTime) == 0 || record.Key == '-1' ? '' :
				<a>
					<PlusCircleOutlined onClick={() => add(record)} />
				</a>,
		},
	];


return (
	<Spin spinning={load}>
		<Table style={{ width: '100%' }} columns={columns} dataSource={data} loading={load} size={"small"} />
	</Spin>
)
}

export default ProjectActivities