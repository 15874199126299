import React, { useState } from "react";
import {
  Row,
  Modal,
  Input,
  Spin,
  message,
  Col,
  Form,
  DatePicker,
  InputNumber,
  Select
} from "antd";
import moment from "moment";
import * as _ from 'lodash';

const { TextArea } = Input;

const MyTasksViewModal = (props) => {

  console.log('entrou');
  const [spinning, setSpinning] = useState(false);

  //const { Option } = Select;

  const formatDuration = (duration) => {
    duration = duration || "0";
    const dur = moment.duration(parseFloat(duration), 'hours');
    var hours = Math.floor(dur.asHours());
    var mins = Math.floor(dur.asMinutes()) - hours * 60;
    return `${_.padStart(hours, 2, '0')}:${_.padStart(mins, 2, '0')}`;
  }

  const dateFormat = 'YYYY/MM/DD';

  return (
    <Modal
      title={"Detalhes de atividade"}
      width={"60%"}
      footer={null}
      visible={props.visible}
      destroyOnClose={props.destroyOnClose}
      onCancel={props.onClosed}
    >
      <Spin spinning={spinning} delay={400}>
        <Form>
          <Row style={{ borderBottom: "1px solid black" }}>
            <div style={{ margin: "1%", width: "100%", display: "inline-block" }}>
              <Row gutter={[8, 8]} style={{ width: "100%", justifyContent: 'center' }} >
                <Col span={12}>
                  <b>Projeto:</b> {props.tasks.Title}
                </Col>
                <Col span={12}>
                    <b>Tarefa:</b> {props.tasks.DescriptionTask}
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <div style={{ margin: "1%", width: "100%", display: "inline-block" }}>
              <Row gutter={[8, 8]} style={{ width: "100%", justifyContent: 'center', paddingTop: "1%" }}>
                <Col span={8}>
                  <b>Cliente:</b> {props.tasks.Company}
                </Col>
                <Col span={8}>
                  <b>Local do Atendimento: </b>
                    {props.tasks.ServiceType ? props.tasks.ServiceType === '1' ? 'Remoto' :
                      props.tasks.ServiceType === '2' ? 'Presencial na Consultoria' :
                        props.tasks.ServiceType === '3' ? 'Presencial no Cliente' : '' : ''}
                </Col>
  
                <Col span={8}>
                  <b>Status: </b>
                  <span className={props.tasks.Approval == 'P' ? "pending" :
                    props.tasks.Approval == 'R' ? "reversal" :
                      props.tasks.Approval == 'N' ? "denied" : 'approved'}>

                    {props.tasks.Approval == 'P' ? "Aguardando aprovação" :
                      props.tasks.Approval == 'I' ? "Faturado" :
                        props.tasks.Approval == 'R' ? "Estornado" :
                          props.tasks.Approval == 'N' ? "Negado" : 'Aprovado'}
                  </span>
                </Col>
              </Row>

              <Row gutter={[8, 8]} style={{ width: "100%", justifyContent: 'center', paddingTop: "1%" }}>
                <Col span={8}>
                  <b>Horas Utilizadas:</b> {formatDuration(props.tasks.ConsumedTime)}
                </Col>
                <Col span={8}>
                  <b>Conclusão:</b> {props.tasks.Percentage}%
                </Col>
                <Col span={8}>
                  <b>Dia do Atendimento:</b> {props.tasks.DateTask}
                </Col>
              </Row>

              <Row>
                <div style={{ width: "100%", paddingTop: '2%' }}>
                  <b>Detalhe da Atividade Realizada:</b>
                  <Form.Item name="text" initialValue={props.tasks.Description}>
                    <TextArea autoSize={{ minRows: "6", maxRows: "12" }} readOnly={true}/>
                  </Form.Item>
                </div>
              </Row>
            </div>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default MyTasksViewModal;