import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { StateProvider } from './state';
import AppReducer from './reducers';
import AppLayout from './components/layout/index'
import AssignTask from './components/assign-task-detail';
import Login from './pages/login';
import Warning from './pages/warnings';
import Permissions from './pages/permissions'
import Home from './pages/home';
import { useCookies } from 'react-cookie';
import Customers from './pages/customers';
import NewProject from './pages/new-project';
import Projects from './pages/projects';
import EletronicDocuments from './pages/eletronic-documents';
import Users from './pages/users';
import CategoryDocument from './pages/category-document';
import ProjectModel from './pages/project-model';
import NotPermission from './pages/not-permission';
import NotFound from './pages/not-found';
import TaskAssignment from './pages/task-assignment';
import myNotes from './pages/my-tasks';
import calendar from './pages/calendar';
import ConfigPage from './pages/config-page';
import WorkFlow from './pages/workflow';
import WorkFlowApprovals from './pages/approvals';
import outgoing from './pages/outgoing';
import Cashback from './pages/cashback';
import Schedule from './pages/schedule';
import SupplierPayment from './pages/supplier-payment';
import Revenue from './pages/revenue';
import Logs from './pages/process-logs';
import CategoryMeasureUnit from './pages/category-measure-unit';
import Reports from './pages/reports';
import PowerbiReport from './pages/powerbi-report';
import ProfessionalPayment from './pages/professional-payment';
import TimeCourse from "./pages/time-course";

const App = () => {

  const [cookies] = useCookies(['authentication']);

  const initialState = {
    authentication: cookies.authentication
  };

  return (
    <Router>
      <StateProvider initialState={initialState} reducer={AppReducer}>
        <Switch>
          <Route path="/login" component={Login} />
          <AppLayout exact path="/" component={Home} permission={0} />
          <AppLayout exact path="/approvals" component={WorkFlowApprovals} permission={17} /> {/* 0 */}
          <AppLayout exact path="/warnings" component={Warning} permission={1} />
          <AppLayout exact path="/customers" component={Customers} permission={2} />
          <AppLayout exact path="/new-project" component={NewProject} permission={35} />
          <AppLayout exact path="/project" component={Projects} permission={3} />
          <AppLayout exact path="/projectModel" component={ProjectModel} permission={25} />
          <AppLayout exact path="/users" component={Users} permission={4} />
          <AppLayout exact path="/permissions" component={Permissions} permission={5} />
          <AppLayout exact path="/eletronicDocuments" component={EletronicDocuments} permission={6} />
          <AppLayout exact path="/outgoing" component={outgoing} permission={7} />
          <AppLayout exact path="/taskAssignment" component={TaskAssignment} permission={8} />
          <AppLayout exact path="/taskAssignment/:id" component={AssignTask} permission={8} />
          <AppLayout exact path="/myNotes" component={myNotes} permission={9} />
          <AppLayout exact path="/calendar" component={calendar} permission={24} /> {/* 27 */}
          <AppLayout exact path="/configPage" component={ConfigPage} permission={10} />
          <AppLayout exact path="/categoryDocument" component={CategoryDocument} permission={11} />
          <AppLayout exact path="/workflow" component={WorkFlow} permission={13} />
          <AppLayout exact path="/cashback" component={Cashback} permission={14} />
          <AppLayout exact path="/schedule" component={Schedule} permission={15} />
          <AppLayout exact path="/schedule/:id" component={Schedule} permission={15} />
          <AppLayout exact path="/logs" component={Logs} permission={16} />
          <AppLayout exact path="/supplierPayment" component={SupplierPayment} permission={18} />
          <AppLayout exact path="/revenues" component={Revenue} permission={19} />
          <AppLayout exact path="/categoryUnit" component={CategoryMeasureUnit} permission={20} />
          <AppLayout exact path="/report" component={Reports} permission={21} />
          <AppLayout exact path="/powerbi" component={PowerbiReport} permission={27} /> {/* 22 */}
          <AppLayout exact path="/timecourse" component={TimeCourse} permission={28} /> {/* 23 */}
          <AppLayout exact path="/error" component={NotPermission} />
          <AppLayout exact path="/professionalPayments" component={ProfessionalPayment} permission={22} />
          <Route component={NotFound} permission={0} />
        </Switch>
      </StateProvider>
    </Router>
  );
}


export default App;
