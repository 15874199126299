import React, { useState } from "react";
import { Row, Input, Modal, Button, message, InputNumber } from 'antd';
import CategoryDocumentService from '../../services/category-document';

const ModalCategoryDocuments = (props) => {

    const [description, setDescription] = useState();
    const [category, setCategory] = useState();

    const AddNew = async () => {
        if (validateFields()) {

            const data = {
                description: !description ? props.data.Description : description,
                category: !category ? props.data.Category : category,
            }
            const categoryData = await CategoryDocumentService.get();
            const categoryDoc = categoryData.data.data;
            if (categoryDoc.find(item => item.Description == data.description)) {
                message.destroy();
                message.error("Essa categoria já existe.", 3)
            }
            else {
                if (props.data.Category && props.data.Description) {
                    message.loading("Atualizando categoria de documento ...", 100);
                    await CategoryDocumentService.patch(data, props.data.Id).then((res) => {
                        if (res.data.error) {
                            message.destroy();
                            message.error("Ocorreu um erro ao atualiza categoria de documento.", 3);
                        }
                        else {
                            message.destroy();
                            props.onfetching();
                            message.success("Categoria de documento atualizado.", 3);
                        }
                        props.onCancel();
                    });
                }
                else {
                    message.loading("Criando categoria de documento ...", 100);
                    await CategoryDocumentService.post(data).then((res) => {
                        if (res.data.error) {
                            message.destroy();
                            message.error("Ocorreu um erro ao criar categoria de documento.", 3);
                        }
                        else {
                            message.destroy();
                            props.onfetching();
                            message.success("Categoria de documento criado.", 3);
                        }
                        props.onCancel();
                    });
                }
            }
        }
    }

    const validateFields = () => {
        if (!category && !props.data.Category) {
            message.error('Campo categoria não preenhido.', 3);
            return false;
        }

        if (!description && !props.data.Description) {
            message.error('Campo descrição não preenhido.', 3);
            return false;
        }
        return true;
    }

    const setCat = e => {
        setCategory(e);
    }
    const setDesc = e => {
        setDescription(e.target.value);
    }

    return (
        <Modal
            title="Categoria de documentos"
            visible={props.visible}
            onOk={props.onOk}
            footer={null}
            onCancel={props.onCancel}
            destroyOnClose={props.onCancel}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={"50%"}>
            <div style={{ width: "100%" }}>
                <Row style={{ width: "100%" }}>
                    <div style={{ width: "25%", paddingRight: "1%" }}>
                        <h3>Categoria</h3>
                        {props.data.Category ? <InputNumber style={{ width: "100%" }} disabled={true} defaultValue={props.data.Category} /> :
                            <InputNumber style={{ width: "100%" }} defaultValue={props.data.Category} onChange={setCat} />
                        }
                    </div>
                    <div style={{ width: "75%" }}>
                        <h3>Descrição</h3>
                        <Input defaultValue={props.data.Description} onChange={setDesc} />
                    </div>
                </Row>
                <Row>
                    <div style={{ float: "right", width: "100%" }}>
                        <div style={{ paddingTop: "5px", float: "right" }}>
                            <Button type="primary" onClick={AddNew}>Salvar</Button>
                        </div>
                    </div>
                </Row>
            </div >
        </Modal >
    )
}

export default ModalCategoryDocuments;