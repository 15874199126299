import React, { useState } from "react";
import {
  Row,
  Modal,
  Input,
  Spin,
  message,
  Col,
  Form,
  Button,
  DatePicker,
  InputNumber,
  Select,
  TimePicker
} from "antd";
import moment from "moment";
import { tasksService } from '../../services/tasks';
import { workflowService } from '../../services/workflow';
import { ProjectsService } from "../../services/projects";
import * as _ from 'lodash';
import { MaskedInput } from 'antd-mask-input';
import { TimeCourseService } from "../../services/time-course";


const { TextArea } = Input;

const MyTasksModal = (props) => {
  const [spinning, setSpinning] = useState(false);
  const [startDate, setStartDate] = useState();
  const [typeService, setTypeService] = useState('');
  const [taskPercentual, setTaskPercentual] = useState(0);
  const [time, setTime] = useState('00:00');

  const { Option } = Select;

  const formatDuration = (duration) => {
    duration = duration || "0";
    const dur = moment.duration(parseFloat(duration), 'hours');
    var hours = Math.floor(dur.asHours());
    var mins = Math.floor(dur.asMinutes()) - hours * 60;
    return `${_.padStart(hours, 2, '0')}:${_.padStart(mins, 2, '0')}`;
  }

  const insertOS = async (values) => {
    if (validateFields(values)) {
      const projectData = await ProjectsService.getProjectById(props.dataFields.ProjectId);
      const project = projectData.data.data;

      const timeCourse = await TimeCourseService.getTimeCourse();
      const courseDate = timeCourse.data.data[0].ReferenceDate;
      
      let floatTime = 0;
      if (time) {
        let hours = time.split(':')[0];
        let minutes = time.split(':')[1];
        floatTime = parseFloat(hours) + (parseFloat(minutes) / 60);
      }
      if (moment(startDate).format('YYYY-MM-DD') <= courseDate) {
        message.error('o campo data de apontamento deve ser maior que o período fechado, data de período:' + moment(courseDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), 3);
      }
      else {
        if (project.Workflow) {
          message.loading("Inserindo apontamento...", 100);
          setSpinning(true);

          const id = localStorage.getItem('userId');

          const data = {
            TaskId: parseInt(props.project.Id),
            UserId: parseInt(id),
            // padronizado nome da chave com nomenclatura dos campos no BD
            ServiceType: typeService,
            DateTask: startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date).format('YYYY-MM-DD'),
            Description: values.text,
            ConsumedTime: parseFloat(floatTime),
            Approval: "P",
            Percentage: parseInt(taskPercentual) ? parseInt(taskPercentual) : 0
          }

          const r = await tasksService.insertTaskHistory(data);
          if (r.data.error) {
            setSpinning(false);
            message.destroy();
            props.onClosed();
            message.error("Ocorreu um erro ao inserir um apontamento.", 3);
          }
          else {
            if (props.project.Status == 'P') {
              await tasksService.updateStatus(props.project.Id, 'W', 'P');
            }

            if ((parseInt(taskPercentual) == 100) /*|| true*/) {
              await tasksService.updateStatus(props.project.Id, 'A', 'Y');
            }

            let workflow = []
            await workflowService.getApprovals(project.Workflow).then((workflowApprovals) => {
              // debugger
              //for (const flow of workflowApprovals.data.data.data) {
              const flow = workflowApprovals.data.data.data

              workflow.push({
                IdType: r.data.data[0].Id,
                Value: r.data.data[0].Value,
                Type: "T",
                Status: "P",
                WorkflowApprovalsId: project.Workflow,
                UserId: parseInt(id),
                ProjectId: project.Id,
                ProjectName: project.Title,
                ResponsibleId: _.first(flow.UserId)
              });

            });
            await workflowService.insert(workflow).then((r) => {
              if (!r.data.error) {
                props.fetch();
                props.onClosed();
                setSpinning(false);
                setTime('00:00');
                setTypeService('');
                message.destroy();
                message.success("Apontamento inserido com sucesso.", 3);
              }
              else {
                setSpinning(false);
                message.destroy();
                props.onClosed();
                message.error("Ocorreu um erro ao inserir um apontamento.", 3);
              }
            });
          }
        }

        else {
          message.error("Nenhum workflow definido, contate um supervisor.", 3);
        }
      }
    }
  }

  const validateFields = (values) => {
    let hours = props.project.Note ? formatDuration(props.project.ApprovedTime - props.project.Note) : formatDuration(props.project.ApprovedTime);
    let percentual = props.project.Percentage
    let notePercentual = parseInt(taskPercentual)

    // Douglas Telles -->> Alterado a forma de comparação do saldo - 15/07/2021
    let hora1 = hours.split(":");
    let hora2 = time.split(":");

    var d = new Date();
    var data1 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), hora1[0], hora1[1]);
    var data2 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), hora2[0], hora2[1]);

    if (!typeService || typeService == '') {
      message.error('Favor informar tipo de Atendimento', 3);
      return false;
    }

    // if (hours < time) {
    if (data1 < data2) {
      message.error('Tempo Apontado maior que o Liberado.', 3);
      return false;
    }

    if (!values.text) {
      message.error('Campo descrição não preenchido.', 3);
      return false;
    }

    if (!time && props.project.Note) {
      message.error('Campo horas trabalhadas não preenchido.', 3);
      return false;
    }
    // if (parseFloat(time) > parseFloat(props.project.ApprovedTime)) {
    //   message.error('Campo horas são maiores que o esperado.', 3);
    //   return false;
    // }
    // if (!values.percentage && !props.project.Percentage) {
    //   message.error('Campo porcentagem não preenchido.', 3);
    //   return false;
    // }

    if (notePercentual <= 0) {
      message.error('Campo porcentagem não pode ser menor do que 0%.', 3);
      return false;
    }

    if (notePercentual > 100) {
      message.error('Campo porcentagem não pode ser acima de 100%.', 3);
      return false;
    }

    if (notePercentual < 100 && notePercentual > 0) {
      if (!time || time == "00:00") {
        message.error('Campo horas trabalhadas não preenchido.', 3);
        return false;
      }
    }

    if (values.text.length > 1000) {
      message.error('Campo descrição não pode ter acima de 1000 caracteres.', 3);
      return false;
    }

    return true;
  }

  const dateFormat = 'YYYY/MM/DD';
  const hoursFormat = 'HH:mm';

  // setTime( props.project.Note ? formatDuration(props.project.ApprovedTime - props.project.Note) : formatDuration(props.project.ApprovedTime) );
  // setTaskPercentual("100");
  // setTypeService("1")
  // setStartDate( moment(props.project.ExpectedEndDate, 'DD/MM/YYYY') );

  return (
    <Modal
      title={"Apontamento de Tarefa"}
      width={"60%"}
      footer={null}
      visible={props.visible}
      destroyOnClose={props.destroyOnClose}
      onCancel={props.onClosed}
    >
      <Spin spinning={spinning} delay={500}>
        <Form onFinish={insertOS}>
          <Row style={{ borderBottom: "1px solid black" }}>
            <div style={{ margin: "1%", width: "100%", display: "inline-block" }}>
              <Row gutter={[8, 8]} style={{ width: "100%", justifyContent: 'center' }} >
                <Col span={12}>
                  <b>Projeto:</b> {props.project.Description}
                </Col>
                <Col span={12}>
                  <b>Atividade:</b> {props.project.TasksDescription}
                </Col>
              </Row>

              <Row gutter={[8, 8]} style={{ width: "100%", justifyContent: 'center', paddingTop: '2%', paddingBottom: '1%' }}>
                <Col span={6}>
                  <b>Coordenador:</b> {props.project.Responsible}
                </Col>
                <Col span={6}>
                  <b>Início previsto:</b> {props.project.ExpectedStartDate ? props.project.ExpectedStartDate : "sem data"}
                </Col>
                <Col span={6}>
                    <b>Término previsto: </b>
                    <span
                      className={(moment(props.project.ExpectedEndDate, 'DD/MM/YYYY').format() < moment().format() ? 'expiredDate' : '')}>
                      {(props.project.ExpectedEndDate ? props.project.ExpectedEndDate : 'sem data')}
                    </span>
                </Col>
                <Col span={6}>
                  <b>Saldo de horas:</b> {props.project.Note ? formatDuration(props.project.ApprovedTime - props.project.Note) : formatDuration(props.project.ApprovedTime)}
                </Col>
              </Row>
            </div>
          </Row>

          <Input.Group compact style={{ paddingTop: "1%" }}>
            <div style={{ width: "20%", paddingRight: "1%" }}>
              <p>Horas trabalhadas:</p>
              <TimePicker
                name="expiry"
                placeholder="Digite suas horas"
                showNow={false}
                format={hoursFormat}
                style={{ width: "100%" }}
                onChange={e => setTime(moment(e).format(hoursFormat))}
                // onChange={e => setTime(e)}
              />
              {/* <Input
                name="expiry"
                placeholder="00:00"
                defaultValue="00:00"
                // defaultValue={props.project.Note ? formatDuration(props.project.ApprovedTime - props.project.Note) : formatDuration(props.project.ApprovedTime)}
                onChange={ e => { setTime(e.target.value); } }
                style={{ width: "100%" }}
                placeholderChar="0"
              /> */}
              {/* <MaskedInput
                mask="11:11"
                name="expiry"
                placeholder="00:00"
                defaultValue="00:00"
                onChange={ e => { setTime(e.target.value); } }
                style={{ width: "100%" }}
                placeholderChar="0"
              /> */}
              {/* <InputNumber
                defaultValue={0}
                formatter={value => {
                  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                }
                parser={value => {
                  return value.replace(/\$\s?|(,*)/g, '')
                }}
                c
              /> */}
            </div>
            <div style={{ width: "20%", paddingRight: "1%" }}>
              <p>% de conclusão:</p>
              {/* <Form.Item name="percentage" initialValue={props.project.Percentage ? props.project.Percentage : ''}>
                <InputNumber style={{ width: "100%" }} placeholderChar="0%" />
              </Form.Item> */}
              <Select
                placeholder="0%"
                // defaultValue="100"
                onChange={(value) => setTaskPercentual(value)}
                style={{ width: "100%" }}
              >
                <Option value="0" >0%</Option>
                <Option value="25">25%</Option>
                <Option value="50">50%</Option>
                <Option value="75">75%</Option>
                <Option value="100">100%</Option>
              </Select>
            </div>
            <div style={{ width: "30%", paddingRight: "1%" }}>
              <p>Tipo Atendimento:</p>
              <Select
                placeholder="Tipo Atendimento"
                // defaultValue="1"
                onChange={(value) => setTypeService(value)}
                style={{ width: "100%" }}
              >
                <Option value="1">Remoto</Option>
                <Option value="2">Presencial na Consultoria</Option>
                <Option value="3">Presencial no Cliente</Option>
              </Select>
            </div>
            <div style={{ width: "30%" }}>
              <p>Data de apontamento:</p>
              <DatePicker
                style={{ width: "100%" }}
                /* panelRender={() => moment.locale(['br'])} */
                placeholder="Selecione uma data"
                defaultValue={moment(new Date(), dateFormat)}
                // defaultValue={moment(props.project.ExpectedEndDate/* new Date */, 'DD/MM/YYYY'/* dateFormat */)}
                format={"DD/MM/YYYY"}
                onChange={(value) => setStartDate(value)}
              />
            </div>
            <div style={{ width: "100%", paddingTop: '1%' }}>
              <p>Descrição:</p>
              <Form.Item name="text">
                <TextArea autoSize={{ minRows: "3", maxRows: "6" }} placeholder="Digite aqui os pontos referentes ao apontamento: Ex.
                  *Listar itens Resolvidos
                  *Listar itens Pendentes
                  *Informar os Proximos passos
                  *Declarar os responsáveis"
                />
              </Form.Item>
            </div>
            <div style={{ float: "right", paddingTop: "1%" }}>
              <Button type="primary" disabled={spinning} htmlType="submit" >Salvar</Button>
            </div>
          </Input.Group>
        </Form>
      </Spin>
    </Modal>
  )
}

export default MyTasksModal;