import { request } from '../http';

const eventsService = {
  getEvents: () => {
    return request.get('/Events');
  },

  getEventsByUser: (userId) => {
    return request.get('/Events/' + userId);
  },

  getEventsHoliday: () => {
    return request.get('/Events/holidays');
  },

  /* getUserById: (id) => {
    return request.get('/Users/' + id);
  },
  verifyByUsername: (name) => {
    return request.get('/Users/verify/' + name);
  },
  getProfileById: (id) => {
    return request.get('/Users/profileId/' + id);
  },
  // atualizar dados do usuário
  updateUser: (userData) => {
    return request.put('/Users/profile-permission/', userData);
  } */
}

export {
  eventsService
}